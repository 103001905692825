import { DataKiSyokenResponse } from './types';

export const dataKiSyokenHiddenByMksHall = (
  dataKiSyoken: DataKiSyokenResponse,
  mksHallsFilter: string[] | undefined
): DataKiSyokenResponse => {
  if (mksHallsFilter == null) {
    return dataKiSyoken;
  }

  const { columns, hallsOfMarketArea, rows } = dataKiSyoken.data;

  // 商圏店舗を考慮したカラムをフラットに展開
  // 商圏店舗に該当する要素には店舗コードを、それ以外はundefinedを格納する
  const flatColumns = columns.flatMap(
    (column) =>
      column.isSyoken
        ? [
            'mksAll', // 商圏項目内の商圏全体
            ...hallsOfMarketArea.map((hall) => hall.code),
          ]
        : [undefined] // 非商圏項目
  );

  const filteredColumns = columns.filter((column, index) => {
    return !mksHallsFilter || mksHallsFilter.length === 0
      ? !column.isSyoken // 商圏店舗が空配列なら非商圏項目のみ残す
      : true;
  });

  const filteredHallsOfMarketArea = hallsOfMarketArea.filter((hall) =>
    mksHallsFilter.includes(hall.code)
  );

  const filteredRows = rows.map((row) => ({
    ...row,
    data: row.data.filter((_, index) => {
      const hallCodeOrAll = flatColumns[index];
      return !mksHallsFilter || mksHallsFilter.length === 0
        ? hallCodeOrAll === undefined // 商圏店舗が空配列なら非商圏項目のみ残す
        : hallCodeOrAll === undefined || mksHallsFilter.includes(hallCodeOrAll); // 非商圏項目 or 選択された商圏店舗のみ残す
    }),
  }));

  return {
    ...dataKiSyoken,
    data: {
      ...dataKiSyoken.data,
      columns: filteredColumns,
      hallsOfMarketArea: filteredHallsOfMarketArea,
      rows: filteredRows,
    },
  };
};
