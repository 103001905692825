import { createSelector } from 'reselect';

import {
  ComparisonGraphParams,
  ComparisonGraphResponse,
} from '../../domain/comparisonGraph';
import { GraphDateType } from '../../domain/dataKiGraph';
import { Option } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { dataHallKiDataByShuSelector } from './dataHallKi';

// Action Types

const FETCH_DATA_HALL_KI_GRAPH = 'FETCH_DATA_HALL_KI_GRAPH' as const;
const FETCH_DATA_HALL_KI_GRAPH_REQUEST =
  'FETCH_DATA_HALL_KI_GRAPH_REQUEST' as const;
const FETCH_DATA_HALL_KI_GRAPH_SUCCESS =
  'FETCH_DATA_HALL_KI_GRAPH_SUCCESS' as const;
const SEARCH_DATA_HALL_KI_GRAPH = 'SEARCH_DATA_HALL_KI_GRAPH' as const;
const CHANGE_DATA_HALL_KI_GRAPH_KI = 'CHANGE_DATA_HALL_KI_GRAPH_KI' as const;
const CHANGE_DATA_HALL_KI_GRAPH_KI_ALL =
  'CHANGE_DATA_HALL_KI_GRAPH_KI_ALL' as const;
const CHANGE_DATA_HALL_KI_GRAPH_FIELD =
  'CHANGE_DATA_HALL_KI_GRAPH_FIELD' as const;
const CHANGE_DATA_HALL_KI_GRAPH_DATE_TYPE =
  'CHANGE_DATA_HALL_KI_GRAPH_DATE_TYPE' as const;
const HIDE_DATA_HALL_KI_GRAPH = 'HIDE_DATA_HALL_KI_GRAPH' as const;
const HIDE_ALL_DATA_HALL_KI_GRAPH = 'HIDE_ALL_DATA_HALL_KI_GRAPH' as const;

const RENEW_DATA_HALL_KI_GRAPH = 'RENEW_DATA_HALL_KI_GRAPH' as const;
const SELECT_DATA_HALL_KI_GRAPH_SETTING_FOR_FAVORITE =
  'SELECT_DATA_HALL_KI_GRAPH_SETTING_FOR_FAVORITE' as const;

export const DataHallKiGraphActionTypes = {
  FETCH_DATA_HALL_KI_GRAPH,
  FETCH_DATA_HALL_KI_GRAPH_REQUEST,
  FETCH_DATA_HALL_KI_GRAPH_SUCCESS,
  SEARCH_DATA_HALL_KI_GRAPH,
  CHANGE_DATA_HALL_KI_GRAPH_KI,
  CHANGE_DATA_HALL_KI_GRAPH_KI_ALL,
  CHANGE_DATA_HALL_KI_GRAPH_FIELD,
  CHANGE_DATA_HALL_KI_GRAPH_DATE_TYPE,
  HIDE_DATA_HALL_KI_GRAPH,
  HIDE_ALL_DATA_HALL_KI_GRAPH,
  RENEW_DATA_HALL_KI_GRAPH,
  SELECT_DATA_HALL_KI_GRAPH_SETTING_FOR_FAVORITE,
};

// Action Creators

/**
 * 推移グラフ（機種比較）データを取得する
 * @param shuCode 表示先テーブルの種別コード
 * @param params 推移グラフ（機種比較）の検索条件
 */
function fetchDataHallKiGraphAction(
  shuCode: string,
  params: ComparisonGraphParams
) {
  return {
    type: FETCH_DATA_HALL_KI_GRAPH,
    payload: { shuCode, params },
  };
}

/**
 * 推移グラフ（機種比較）データ取得前に実行する
 * @param shuCode 表示先テーブルの種別コード
 */
function fetchDataHallKiGraphRequestAction(shuCode: string) {
  return {
    type: FETCH_DATA_HALL_KI_GRAPH_REQUEST,
    payload: { shuCode },
  };
}

/**
 * 推移グラフ（機種比較）データ取得完了時データを登録する
 * @param shuCode 表示先テーブルの種別コード
 * @param graph 取得したグラフデータ
 */
function fetchDataHallKiGraphSuccessAction(
  shuCode: string,
  graph: ComparisonGraphResponse
) {
  return {
    type: FETCH_DATA_HALL_KI_GRAPH_SUCCESS,
    payload: { shuCode, graph },
  };
}

/**
 * 選択した機種の推移グラフする
 * @param shu 種別データ（種別グループ・種別）
 * @param kiList 表示する機種コード一覧
 */
function searchDataHallKiGraph(shu: ShuOption, kiList: string[]) {
  return {
    type: SEARCH_DATA_HALL_KI_GRAPH,
    payload: { shu, kiList },
  };
}

/**
 * 指定した機種コードでグラフを絞り込む（機種選択フォーム用）
 * @param shuCode 表示先テーブルの種別コード
 * @param kiList 絞り込む機種コード一覧
 */
function changeDataHallKiGraphKi(shuCode: string, kiList: string[]) {
  return {
    type: CHANGE_DATA_HALL_KI_GRAPH_KI,
    payload: { shuCode, kiList },
  };
}

/**
 * 絞り込む機種コードを全件登録する（お気に入り適用用）
 * @param kiList 絞り込む機種コード（すべて）
 */
function changeDataHallKiGraphKiAll(kiList: {
  [key: string]: string[] | undefined;
}) {
  return {
    type: CHANGE_DATA_HALL_KI_GRAPH_KI_ALL,
    payload: { kiList },
  };
}

/**
 * 指定したグラフ項目で推移グラフ（機種比較）データを再取得
 * @param shu 種別データ（種別グループ・種別）
 * @param field 変更後のグラフ項目
 */
function changeDataHallKiGraphField(shu: ShuOption, field: string) {
  return {
    type: CHANGE_DATA_HALL_KI_GRAPH_FIELD,
    payload: { shu, field },
  };
}

/**
 * 指定した日付タイプで推移グラフ（機種比較）データを再取得
 * @param shu 種別データ（種別グループ・種別）
 * @param dateType 変更後の日付タイプ
 */
function changeDataHallKiGraphDateType(
  shu: ShuOption,
  dateType: GraphDateType
) {
  return {
    type: CHANGE_DATA_HALL_KI_GRAPH_DATE_TYPE,
    payload: { shu, dateType },
  };
}

/**
 * 店舗レポート 新台/メイン機種 推移グラフ（機種比較）のデータを破棄する
 * @param shuCode 表示先テーブルの種別コード
 */
export function hideDataHallKiGraphAction(shuCode: string) {
  return {
    type: HIDE_DATA_HALL_KI_GRAPH,
    payload: { shuCode },
  };
}

/**
 * 店舗レポート 新台/メイン機種 推移グラフ（機種比較）の全てのデータを破棄する
 */
function hideAllDataHallKiGraphAction() {
  return {
    type: HIDE_ALL_DATA_HALL_KI_GRAPH,
  };
}

/**
 * 初期化
 */
function renewDataHallKiGraphAction() {
  return {
    type: RENEW_DATA_HALL_KI_GRAPH,
  };
}

/**
 * お気に入り復元時にグラフの取得条件のみ復元する
 */
function selectDataHallKiGraphSettingForFavorite(
  dataHallKiGraph: DataHallKiGraphState['dataHallKiGraph']
) {
  return {
    type: SELECT_DATA_HALL_KI_GRAPH_SETTING_FOR_FAVORITE,
    payload: { dataHallKiGraph },
  };
}

export const DataHallKiGraphActionCreators = {
  fetchDataHallKiGraphAction,
  fetchDataHallKiGraphRequestAction,
  fetchDataHallKiGraphSuccessAction,
  searchDataHallKiGraph,
  changeDataHallKiGraphKi,
  changeDataHallKiGraphKiAll,
  changeDataHallKiGraphField,
  changeDataHallKiGraphDateType,
  hideDataHallKiGraphAction,
  hideAllDataHallKiGraphAction,
  renewDataHallKiGraphAction,
  selectDataHallKiGraphSettingForFavorite,
};

// Actions

export type FetchDataHallKiGraphAction = ReturnType<
  typeof fetchDataHallKiGraphAction
>;
export type SearchDataHallKiGraph = ReturnType<typeof searchDataHallKiGraph>;
export type ChangeDataHallKiGraphField = ReturnType<
  typeof changeDataHallKiGraphField
>;
export type ChangeDataHallKiGraphDateType = ReturnType<
  typeof changeDataHallKiGraphDateType
>;

type DataHallKiGraphAction =
  | FetchDataHallKiGraphAction
  | SearchDataHallKiGraph
  | ReturnType<typeof changeDataHallKiGraphKi>
  | ReturnType<typeof changeDataHallKiGraphKiAll>
  | ChangeDataHallKiGraphField
  | ChangeDataHallKiGraphDateType
  | ReturnType<typeof hideDataHallKiGraphAction>
  | ReturnType<typeof hideAllDataHallKiGraphAction>
  | ReturnType<typeof fetchDataHallKiGraphRequestAction>
  | ReturnType<typeof fetchDataHallKiGraphSuccessAction>
  | ReturnType<typeof renewDataHallKiGraphAction>
  | ReturnType<typeof selectDataHallKiGraphSettingForFavorite>
  | ReturnType<typeof selectDataHallKiGraphSettingForFavorite>;

// State

type DataHallKiGraphState = {
  /**
   * 選択中の機種（機種選択フォームで絞り込んだ機種）
   */
  selectKi: {
    [key: string]: string[] | undefined;
  };
  /**
   * グラフデータ
   */
  dataHallKiGraph: {
    [key: string]: ComparisonGraphResponse | undefined;
  };
  /**
   * ローディング状態
   */
  isLoading: {
    [key: string]: boolean | undefined;
  };
};

const initialState: DataHallKiGraphState = {
  selectKi: {},
  dataHallKiGraph: {},
  isLoading: {},
};

// Selector

/**
 * 全てのグラフデータを取得する
 * @returns 全てのグラフデータ
 */
const dataHallKiGraphAllSelector = (state: RootState) =>
  state.dataHallKiGraph.dataHallKiGraph;

/**
 * 種別を元にグラフデータを取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns グラフデータ
 */
export const dataHallKiGraphSelector = (shu: ShuOption) =>
  createSelector(dataHallKiGraphAllSelector, (data) => {
    return data[shu.code];
  });

/**
 * 推移グラフ（機種比較）の検索条件を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 検索条件
 */
export const dataHallKiGraphSearchConditionSelector = (shu: ShuOption) =>
  createSelector(dataHallKiGraphSelector(shu), (data) => {
    return data?.setting ?? {};
  });

/**
 * 全てのローディング状態を取得する
 * @returns 全てのローディング状態
 */
const dataHallKiGraphLoadingAllSelector = (state: RootState) =>
  state.dataHallKiGraph.isLoading;

/**
 * 種別を元にグラフのローディング状態を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns ローディング状態
 */
export const dataHallKiGraphLoadingSelector = (shu: ShuOption) =>
  createSelector(dataHallKiGraphLoadingAllSelector, (loading) => {
    return loading[shu.code] ?? false;
  });

/**
 * チェックボックスで当初選択された機種を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 機種一覧
 */
export const dataHallKiGraphCheckedKiListSelector = (shu: ShuOption) =>
  createSelector(dataHallKiGraphSearchConditionSelector(shu), (params) => {
    return params.kiList ?? [];
  });

/**
 * チェックボックスで当初選択された機種（Option）を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 機種一覧（Selectで使える形式）
 */
export const dataHallKiGraphCheckedKiListOptionSelector = (shu: ShuOption) =>
  createSelector(
    [
      dataHallKiGraphCheckedKiListSelector(shu),
      dataHallKiDataByShuSelector(shu.code),
    ],
    (kiList, tableData) => {
      // チェックボックスでチェック入れた機種情報をテーブルのデータから取得
      const result: Option[] =
        tableData?.data?.rows
          .filter((row) => kiList.includes(row.queryParameter.value))
          .map((row) => ({
            code: row.queryParameter.value,
            name: row.data.at(1)?.value ?? '',
            genres: [row.data.at(2)?.value ?? ''],
          })) || [];

      return result;
    }
  );

/**
 * 全てのグラフの絞り込んでいる機種の一覧全て取得する
 * @returns 全てのグラフの絞り込んでいる機種一覧
 */
export const dataHallKiGraphSelectedKiListAllSelector = (state: RootState) =>
  state.dataHallKiGraph.selectKi;

/**
 * 絞り込んでいる機種の一覧を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 絞り込んでいる機種一覧
 */
export const dataHallKiGraphSelectedKiListSelector = (shu: ShuOption) =>
  createSelector(dataHallKiGraphSelectedKiListAllSelector, (selectedKiList) => {
    return selectedKiList[shu.code] ?? [];
  });

/**
 * 絞り込んでいる機種の一覧（Option)を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 絞り込んでいる機種一覧(Selectで使える形式)
 */
export const dataHallKiGraphSelectedKiListOptionSelector = (shu: ShuOption) =>
  createSelector(
    [
      dataHallKiGraphSelectedKiListSelector(shu),
      dataHallKiDataByShuSelector(shu.code),
    ],
    (selectedKiList, tableData) => {
      const result: Option[] =
        tableData?.data?.rows
          .filter((row) => selectedKiList.includes(row.queryParameter.value))
          .map((row) => ({
            code: row.queryParameter.value,
            name: row.data.at(1)?.value ?? '',
            genres: [row.data.at(2)?.value ?? ''],
          })) || [];

      return result;
    }
  );

/**
 * 選択中のグラフ項目を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns グラフ項目
 */
export const dataHallKiGraphSelectedFieldSelector = (shu: ShuOption) =>
  createSelector(dataHallKiGraphSearchConditionSelector(shu), (params) => {
    return params.field ?? 'out';
  });

/**
 * 選択中の日付タイプを取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 日付タイプ
 */
export const dataHallKiGraphSelectedDateTypeSelector = (shu: ShuOption) =>
  createSelector(dataHallKiGraphSearchConditionSelector(shu), (params) => {
    return params.dateType ?? 'daily';
  });

/**
 * 新台/メイン機種グラフのすべての検索条件を取得する（お気に入り保存用）
 * @returns 現在の新台/メイン機種グラフのすべての検索条件
 */
export const dataHallKiGraphSettingSelector = createSelector(
  dataHallKiGraphAllSelector,
  (data) => {
    return Object.fromEntries(
      Object.entries(data).map((entry) => {
        const [key, value] = entry;
        return [key, value?.setting];
      })
    );
  }
);

export function dataHallKiGraphReducer(
  state = initialState,
  action: DataHallKiGraphAction
): DataHallKiGraphState {
  switch (action.type) {
    case FETCH_DATA_HALL_KI_GRAPH_REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.shuCode]: true,
        },
      };
    case FETCH_DATA_HALL_KI_GRAPH_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.shuCode]: false,
        },
        dataHallKiGraph: {
          ...state.dataHallKiGraph,
          [action.payload.shuCode]: action.payload.graph,
        },
      };
    case CHANGE_DATA_HALL_KI_GRAPH_KI:
      return {
        ...state,
        selectKi: {
          ...state.selectKi,
          [action.payload.shuCode]: action.payload.kiList,
        },
      };
    case CHANGE_DATA_HALL_KI_GRAPH_KI_ALL:
      return {
        ...state,
        selectKi: action.payload.kiList,
      };
    case HIDE_DATA_HALL_KI_GRAPH:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.shuCode]: undefined,
        },
        dataHallKiGraph: {
          ...state.dataHallKiGraph,
          [action.payload.shuCode]: undefined,
        },
        selectKi: {
          ...state.selectKi,
          [action.payload.shuCode]: undefined,
        },
      };
    case HIDE_ALL_DATA_HALL_KI_GRAPH:
      return {
        ...state,
        isLoading: initialState.isLoading,
        dataHallKiGraph: initialState.dataHallKiGraph,
        selectKi: initialState.selectKi,
      };
    case RENEW_DATA_HALL_KI_GRAPH:
      return initialState;
    case SELECT_DATA_HALL_KI_GRAPH_SETTING_FOR_FAVORITE:
      return {
        ...state,
        dataHallKiGraph: action.payload.dataHallKiGraph,
      };
    default:
      return state;
  }
}
