import { format } from 'date-fns';
import { createSelector } from 'reselect';

import {
  DataHallMksSummary,
  DataHallMksTable,
  maxValuesForColumnCodes,
  removeUnwantedUnitFromRowValues,
} from '../../domain/dataHallMks';
import {
  DataCategoryId,
  DateRangeId,
  MKS_GRAPH_DATE_TYPE,
  TimelineValue,
} from '../../domain/hallReportsSettingMks';
import { GraphData, hideColumns } from '../../domain/schemas';

import { RootState } from '../../store';
import { calcStartDateFromDateType } from '../../utils/date';
import { mapGraphDataToActualAndDiff } from '../../utils/mapGraphDataToActualAndDiff';
import { getOrderedTableData } from '../../utils/orderedCell';
import {
  hallReportsSettingMksColumnsOrderSelector,
  hallReportsSettingMksHiddenColumnCodesSelector,
  hallReportsSettingMksSelectedHallNamesSelector,
} from '../ui/hallReportsSetting';

/**
 * Action Types
 */
const FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_REQUEST =
  'FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_REQUEST' as const;
const FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_SUCCESS =
  'FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_SUCCESS' as const;
const FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_REQUEST =
  'FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_REQUEST' as const;
const FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_SUCCESS =
  'FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_SUCCESS' as const;
const FETCH_DATA_HALL_MKS_SUMMARY_REQUEST =
  'FETCH_DATA_HALL_MKS_SUMMARY_REQUEST' as const;
const FETCH_DATA_HALL_MKS_SUMMARY_SUCCESS =
  'FETCH_DATA_HALL_MKS_SUMMARY_SUCCESS' as const;
const CLEAR_DATA_HALL_MKS = 'CLEAR_DATA_HALL_MKS' as const;

const FETCH_DATA_HALL_MKS_TABLE_REQUEST =
  'FETCH_DATA_HALL_MKS_TABLE_REQUEST' as const;
const FETCH_DATA_HALL_MKS_TABLE_SUCCESS =
  'FETCH_DATA_HALL_MKS_TABLE_SUCCESS' as const;

const RENEW_DATA_HALL_MKS = 'RENEW_DATA_HALL_MKS' as const;

export const DataHallMksActionTypes = {
  FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_REQUEST,
  FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_SUCCESS,
  FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_REQUEST,
  FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_SUCCESS,
  FETCH_DATA_HALL_MKS_SUMMARY_REQUEST,
  FETCH_DATA_HALL_MKS_SUMMARY_SUCCESS,
  CLEAR_DATA_HALL_MKS,
  FETCH_DATA_HALL_MKS_TABLE_REQUEST,
  FETCH_DATA_HALL_MKS_TABLE_SUCCESS,
  RENEW_DATA_HALL_MKS,
};

/**
 * Action Creators
 */

/**
 * 商圏実績グラフを取得する
 *
 *  @param ymdList        検索期間
 *  @param hallCode       検索条件で選択したホール
 *  @param timeline       選択中の時間帯
 *  @param shuCode        選択中の種別
 *  @param dataCategory   選択中のデータ種別
 *  @param excludeToday   当日除外
 */
export function fetchDataHallMksComparisonGraphRequestAction(
  ymdList: string[],
  ymdComparisonList: string[],
  hallCode: string,
  timeline: TimelineValue,
  shuCode: string,
  dataCategory: DataCategoryId,
  excludeToday: boolean
) {
  return {
    type: FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_REQUEST,
    payload: {
      ymdList,
      ymdComparisonList,
      halls: [hallCode],
      kadoTimeType: timeline,
      ...(shuCode !== 'all' ? { shuGroupIds: [shuCode] } : {}),
      field: dataCategory,
      excludeToday,
    },
  };
}

export function fetchDataHallMksComparisonGraphSuccessAction(data: GraphData) {
  return {
    type: FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_SUCCESS,
    payload: { data },
  };
}

/**
 * 商圏推移グラフを取得する
 *  @param hallCode       選択中の店舗コード
 *  @param baseDate       期間計算の起点になる日時
 *  @param timeline       選択中の時間帯
 *  @param shuCode        選択中の種別
 *  @param dataCategory   選択中のデータ種別
 *  @param dateRange      グラフの表示期間
 *  @param excludeToday   当日除外
 */
export function fetchDataHallMksTransitionGraphRequestAction(
  hallCode: string,
  baseDate: string,
  timeline: TimelineValue,
  shuCode: string,
  dataCategory: DataCategoryId,
  dateRange: DateRangeId,
  excludeToday: boolean
) {
  const dateType =
    dateRange === MKS_GRAPH_DATE_TYPE.THIRTY_DAYS
      ? 'daily'
      : dateRange === MKS_GRAPH_DATE_TYPE.THIRTEEN_WEEKS
      ? 'weekly'
      : 'monthly';

  const endDate = new Date(baseDate);
  const startDate = calcStartDateFromDateType(dateRange, endDate);

  return {
    type: FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_REQUEST,
    payload: {
      halls: [hallCode],
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      kadoTimeType: timeline,
      ...(shuCode ? { shuGroupIds: [shuCode] } : {}),
      field: dataCategory,
      dateType,
      excludeToday,
    },
  };
}

export function fetchDataHallMksTransitionGraphSuccessAction(data: GraphData) {
  return {
    type: FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_SUCCESS,
    payload: { data },
  };
}

/**
 * サマリを取得する
 *
 *  @param hallCode           検索条件の店舗
 *  @param ymdList            検索期間
 *  @param ymdComparisonList  比較期間
 *  @param shuCode            選択中の種別
 *  @param timelineValue      選択中の時間帯
 *  @param excludeToday       検索条件の当日除外
 */
export function fetchDataHallMksSummaryRequestAction(
  hallCode: string,
  ymdList: string[],
  ymdComparisonList: string[],
  shuCode: string,
  timelineValue: TimelineValue,
  excludeToday: boolean
) {
  return {
    type: FETCH_DATA_HALL_MKS_SUMMARY_REQUEST,
    payload: {
      halls: [hallCode],
      ymdList,
      ymdComparisonList,
      ...(shuCode !== 'all' ? { shuGroupIds: [shuCode] } : {}),
      kadoTimeType: timelineValue,
      excludeToday,
    },
  };
}

export function fetchDataHallMksSummarySuccessAction(
  data: DataHallMksSummary['data']
) {
  return {
    type: FETCH_DATA_HALL_MKS_SUMMARY_SUCCESS,
    payload: { data },
  };
}

function clearDataHallMks() {
  return {
    type: CLEAR_DATA_HALL_MKS,
  };
}

function renewDataHallMks() {
  return {
    type: RENEW_DATA_HALL_MKS,
  };
}

/**
 * 商圏実績テーブルを取得する
 *
 *  @param hallCode           検索条件の店舗
 *  @param ymdList            検索期間
 *  @param ymdComparisonList  比較期間
 *  @param shuCode            選択中の種別
 *  @param timelineValue      選択中の時間帯
 *  @param excludeToday       検索条件の当日除外
 *  @param sort               並べ替えカラムのフィールド名
 *  @param order              並べ替えの昇順・降順
 */
function fetchDataHallMksTableRequestAction(
  hallCode: string,
  ymdList: string[],
  ymdComparisonList: string[],
  shuCode: string | undefined,
  timelineValue: TimelineValue,
  excludeToday: boolean,
  sort = 'hlMei',
  order = 'asc'
) {
  return {
    type: FETCH_DATA_HALL_MKS_TABLE_REQUEST,
    payload: {
      halls: [hallCode],
      ymdList,
      ymdComparisonList,
      ...(shuCode === 'all' ? {} : { shuGroupIds: [shuCode] }),
      kadoTimeType: timelineValue,
      excludeToday,
      sort,
      order,
    },
  };
}

export function fetchDataHallMksTableSuccessAction(data: DataHallMksTable) {
  return {
    type: FETCH_DATA_HALL_MKS_TABLE_SUCCESS,
    payload: { data },
  };
}

export const DataHallMksActionCreators = {
  fetchDataHallMksComparisonGraphRequestAction,
  fetchDataHallMksComparisonGraphSuccessAction,
  fetchDataHallMksTransitionGraphRequestAction,
  fetchDataHallMksTransitionGraphSuccessAction,
  fetchDataHallMksSummaryRequestAction,
  fetchDataHallMksSummarySuccessAction,
  clearDataHallMks,
  fetchDataHallMksTableRequestAction,
  fetchDataHallMksTableSuccessAction,
  renewDataHallMks,
};

/**
 * Actions
 */

export type FetchDataHallMksComparisonGraphRequestAction = ReturnType<
  typeof fetchDataHallMksComparisonGraphRequestAction
>;
export type FetchDataHallMksTransitionGraphRequestAction = ReturnType<
  typeof fetchDataHallMksTransitionGraphRequestAction
>;
export type FetchDataHallMksSummaryRequestAction = ReturnType<
  typeof fetchDataHallMksSummaryRequestAction
>;
export type FetchDataHallMksTableRequestAction = ReturnType<
  typeof fetchDataHallMksTableRequestAction
>;
type FetchDataHallMksTableSuccessAction = ReturnType<
  typeof fetchDataHallMksTableSuccessAction
>;
type ClearDataHallMks = ReturnType<typeof clearDataHallMks>;
type DataHallMksAction =
  | FetchDataHallMksComparisonGraphRequestAction
  | ReturnType<typeof fetchDataHallMksComparisonGraphSuccessAction>
  | FetchDataHallMksTransitionGraphRequestAction
  | ReturnType<typeof fetchDataHallMksTransitionGraphSuccessAction>
  | FetchDataHallMksSummaryRequestAction
  | ReturnType<typeof fetchDataHallMksSummarySuccessAction>
  | ClearDataHallMks
  | FetchDataHallMksTableRequestAction
  | FetchDataHallMksTableSuccessAction
  | ReturnType<typeof renewDataHallMks>;

/**
 * State
 */
type DataHallMksState = {
  isLoading: {
    summary: boolean;
    comparisonGraph: boolean;
    transitionGraph: boolean;
    table: boolean;
  };
  summary: DataHallMksSummary['data'] | null;
  actualGraph: GraphData;
  diffGraph: GraphData;
  transitionGraph: GraphData;
  table: DataHallMksTable;
};

// Stateの初期値
const initialState: DataHallMksState = {
  isLoading: {
    summary: false,
    comparisonGraph: false,
    transitionGraph: false,
    table: false,
  },
  summary: null,
  actualGraph: { columns: [], rows: [] },
  diffGraph: { columns: [], rows: [] },
  transitionGraph: { columns: [], rows: [] },
  table: {
    setting: {
      shuGroupIds: [],
      kadoTimeType: 'all',
    },
    data: {
      columns: [],
      rows: [],
    },
  },
};

/**
 * Selector
 */

/**
 * 商圏に関するデータ全てを取得する
 * @returns 商圏に関するデータ
 */
const dataHallMksSelector = (state: RootState) => state.dataHallMks;

/**
 * 店舗レポート 商圏のローディング状態を全て取得する
 */
export const dataHallMksAllLoadingSelector = createSelector(
  dataHallMksSelector,
  ({ isLoading }) => {
    return Object.values(isLoading).some((item) => Boolean(item));
  }
);

/**
 * 実績・比較差グラフのローディング状態を取得する
 */
export const dataHallMksComparisonGraphIsLoadingSelector = createSelector(
  dataHallMksSelector,
  ({ isLoading }) => isLoading.comparisonGraph
);

/**
 * 実績グラフのデータを取得する
 */
export const dataHallMksActualGraphDataSelector = createSelector(
  [dataHallMksSelector, hallReportsSettingMksSelectedHallNamesSelector],
  ({ actualGraph }, selectedHallNames) => {
    return {
      ...actualGraph,
      rows: actualGraph.rows.filter((row) => {
        return selectedHallNames.includes(row[0] as string);
      }),
    };
  }
);

/**
 * 比較差グラフのデータを取得する
 */
export const dataHallMksDiffGraphDataSelector = createSelector(
  [dataHallMksSelector, hallReportsSettingMksSelectedHallNamesSelector],
  ({ diffGraph }, selectedHallNames) => {
    if (!selectedHallNames) {
      return diffGraph;
    }

    return {
      ...diffGraph,
      rows: diffGraph.rows.filter((row) => {
        return selectedHallNames.includes(row[0] as string);
      }),
    };
  }
);

// 日付から年を消す
const deleteYearFromColumnZero =
  (shouldTrim: boolean) => (cell: string | number, index: number) => {
    if (!shouldTrim) {
      return cell;
    }
    if (index === 0) {
      return (cell as string).slice(5);
    }
    return cell;
  };

/**
 * 推移グラフのデータを取得する
 */
export const dataHallMksTransitionGraphDataSelector = createSelector(
  [dataHallMksSelector, hallReportsSettingMksSelectedHallNamesSelector],
  ({ transitionGraph }, selectedHallNames) => {
    const predicate = deleteYearFromColumnZero(
      transitionGraph.rows.length > 13
    );

    if (!selectedHallNames) {
      return {
        ...transitionGraph,
        rows: transitionGraph.rows.map((row) => row.map(predicate)),
      };
    }

    const filteredColumnIndices = transitionGraph.columns.reduce(
      (accum, curr, index) => {
        if (selectedHallNames.includes(curr.name)) {
          return [...accum, index];
        }
        return accum;
      },
      [0] // 1列目は日付
    );

    return {
      columns: transitionGraph.columns.filter((_, index) =>
        filteredColumnIndices.includes(index)
      ),
      rows: transitionGraph.rows.map((row) =>
        row
          .map(predicate)
          .filter((_, index) => filteredColumnIndices.includes(index))
      ),
    };
  }
);

/**
 * 推移グラフのローディング状態を取得する
 */
export const dataHallMksTransitionGraphIsLoadingSelector = createSelector(
  dataHallMksSelector,
  ({ isLoading }) => isLoading.transitionGraph
);

/**
 * サマリを取得する
 */
export const dataHallMksSummarySelector = createSelector(
  [dataHallMksSelector],
  ({ summary }) => summary
);

/**
 * サマリのローディング状態を取得する
 */
export const dataHallMksSummaryIsLoadingSelector = createSelector(
  dataHallMksSelector,
  ({ isLoading }) => isLoading.summary
);

/**
 * テーブルのローディング状態を取得する
 */
export const dataHallMksTableIsLoadingSelector = createSelector(
  dataHallMksSelector,
  ({ isLoading }) => isLoading.table
);

/**
 * テーブルデータを取得する
 */
const dataHallMksTableDataSelector = createSelector(
  dataHallMksSelector,
  ({ table }) => table.data
);

/**
 * テーブルデータのうち、レスポンスのsettingを取得する
 */
export const dataHallMksTableResponseSettingSelector = createSelector(
  dataHallMksSelector,
  ({ table }) => table?.setting
);

/**
 * 商圏実績テーブルで検索済みの期間を取得する
 * @returns 検索期間
 */
export const dataHallMksTableResponseYmdListSelector = createSelector(
  dataHallMksTableResponseSettingSelector,
  ({ ymdList }) => ymdList
);

/**
 * 商圏実績テーブルで検索済みの店舗を取得する
 * @returns 店舗
 */
export const dataHallMksTableResponseHallCodesSelector = createSelector(
  dataHallMksTableResponseSettingSelector,
  ({ halls }) => halls
);

/**
 * 非表示ホールを除いたテーブルデータを取得する
 */
export const dataHallMksTableDataHallFilteredSelector = createSelector(
  [
    dataHallMksTableDataSelector,
    hallReportsSettingMksSelectedHallNamesSelector,
  ],
  (table, selectedHallNames) => {
    const { columns, rows } = table;
    const filteredRows = rows.filter(
      (row) =>
        selectedHallNames.includes(row.data.at(0)?.value ?? '') ||
        row.data.at(0)?.value.includes('平均')
    );
    return {
      columns,
      rows: filteredRows,
    };
  }
);

/**
 * テーブルのデータから不要な単位を削除する
 */
export const dataHallMksTableDataCleanedSelector = createSelector(
  dataHallMksTableDataHallFilteredSelector,
  ({ columns, rows }) => ({
    columns,
    rows: removeUnwantedUnitFromRowValues(rows),
  })
);

/**
 * カラムの並び順に並べ替えたテーブルデータを取得する
 */
export const dataHallMksTableColumnsOrderedDataSelector = createSelector(
  [
    dataHallMksTableDataCleanedSelector,
    hallReportsSettingMksColumnsOrderSelector,
  ],
  ({ columns, rows }, ordered) => {
    if (!ordered || ordered.length === 0)
      return {
        columns,
        rows,
      };

    return getOrderedTableData(ordered, columns, rows);
  }
);

/**
 * カラム非表示のデータを除いたテーブルデータを取得する
 */
export const dataHallMksTableVisibleDataSelector = createSelector(
  [
    dataHallMksTableColumnsOrderedDataSelector,
    hallReportsSettingMksHiddenColumnCodesSelector,
  ],
  (tableData, hiddenColumnCodes) => {
    const { columns, rows } = tableData;

    return hideColumns(columns, rows, hiddenColumnCodes);
  }
);

/**
 * 各カラムごとに最大値を取得する
 */
export const dataHallMksTableDataMaxValueSelector = createSelector(
  dataHallMksTableDataSelector,
  (table) => {
    const { columns, rows } = table;
    return maxValuesForColumnCodes(columns, rows);
  }
);

/**
 * Reducer
 */

export function dataHallMksReducer(
  state = initialState,
  action: DataHallMksAction
): DataHallMksState {
  switch (action.type) {
    case FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          comparisonGraph: true,
        },
      };
    case FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_SUCCESS: {
      const { actualGraph, diffGraph } = mapGraphDataToActualAndDiff(
        action.payload.data
      );
      return {
        ...state,
        actualGraph,
        diffGraph,
        isLoading: {
          ...state.isLoading,
          comparisonGraph: false,
        },
      };
    }
    case FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          transitionGraph: true,
        },
      };
    case FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_SUCCESS:
      return {
        ...state,
        transitionGraph: action.payload.data,
        isLoading: {
          ...state.isLoading,
          transitionGraph: false,
        },
      };
    case FETCH_DATA_HALL_MKS_SUMMARY_REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          summary: true,
        },
      };
    case FETCH_DATA_HALL_MKS_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload.data,
        isLoading: {
          ...state.isLoading,
          summary: false,
        },
      };
    case CLEAR_DATA_HALL_MKS:
      return initialState;
    case FETCH_DATA_HALL_MKS_TABLE_REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          table: true,
        },
      };
    case FETCH_DATA_HALL_MKS_TABLE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          table: false,
        },
        table: action.payload.data,
      };
    case RENEW_DATA_HALL_MKS:
      return initialState;
    default:
      return state;
  }
}
