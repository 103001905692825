// Action Types
import { OrderType } from '../../domain/schemas';

const SELECT_SETTINGS_MODES_DETAILS_SORT =
  'SELECT_SETTINGS_MODES_DETAILS_SORT' as const;
const RENEW_SETTINGS_MODES_DETAILS = 'RENEW_SETTINGS_MODES_DETAILS' as const;

// Action Creators

function selectSettingsModesDetailsSortAction(sort: string, order: OrderType) {
  return {
    type: SELECT_SETTINGS_MODES_DETAILS_SORT,
    payload: { sort, order },
  };
}

function renewSettingsModesDetailsAction() {
  return {
    type: RENEW_SETTINGS_MODES_DETAILS,
  };
}

export const SettingsModesDetailsActionCreator = {
  selectSettingsModesDetailsSortAction,
  renewSettingsModesDetailsAction,
};

type SettingsModesDetailsAction =
  | ReturnType<typeof selectSettingsModesDetailsSortAction>
  | ReturnType<typeof renewSettingsModesDetailsAction>;

// State

type SettingsModesDetailsState = {
  sort: string;
  order: OrderType;
};

const initialState: SettingsModesDetailsState = {
  sort: '台番号',
  order: 'asc' as const,
};

// Selector

export function settingsModesDetailsSelector(rootState: {
  settingsModesDetails: SettingsModesDetailsState;
}) {
  return rootState.settingsModesDetails;
}

// Reducer
export function settingsModesDetailsReducer(
  state = initialState,
  action: SettingsModesDetailsAction
) {
  switch (action.type) {
    case SELECT_SETTINGS_MODES_DETAILS_SORT:
      return {
        ...state,
        sort: action.payload.sort,
        order: action.payload.order,
      };
    case RENEW_SETTINGS_MODES_DETAILS:
      return initialState;
    default:
      return state;
  }
}
