import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsKiSyokenResponse } from '../domain/kiSyoken/types';

import {
  SettingsOptionsKiSyokenActionCreators,
  SettingsOptionsKiSyokenActionTypes,
} from '../redux/server/settingsOptionsKiSyoken';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* fetchSettingsOptionsKiSyokenSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsKiSyokenActionCreators.fetchSettingsOptionsKiSyokenRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsKiSyokenResponse> = yield call(
      api.get,
      '/settings/options/kiSyoken',
      buildConfig()
    );
    const settingsOptionsKiSyoken = response.data;

    yield put(
      SettingsOptionsKiSyokenActionCreators.fetchSettingsOptionsKiSyokenSuccessAction(
        settingsOptionsKiSyoken
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsKiSyokenActionCreators.renewSettingsOptionsKiSyokenAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsKiSyokenSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsKiSyokenActionTypes.FETCH_SETTINGS_OPTIONS_KISYOKEN,
    fetchSettingsOptionsKiSyokenSaga,
    api
  );
}

export function* settingsOptionsKiSyokenSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsKiSyokenSaga, context.api);
}
