import { fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { DataDepreciationResponse } from '../domain/depreciation/types';
import { LoadingState } from '../domain/schemas';

import {
  DataDepreciationActionCreators,
  DataDepreciationActionTypes,
  dataColumnsSwappedSelector,
  dataDepreciationDataSelector,
  dataDepreciationLoadingStateSelector,
} from '../redux/server/dataDepreciation';
import { settingsOptionsDepreciationFieldsSelector } from '../redux/server/settingsOptionsDepreciation';
import {
  DepreciationSettingActionCreators,
  DepreciationSettingActionTypes,
  SearchDepreciationFieldsAction,
  SearchDepreciationSettingAction,
  TriggerDepreciationSwapFieldsAction,
  depreciationColumnsOrderSelector,
  depreciationSearchParamsSelector,
} from '../redux/ui/depreciationSetting';
import { customSelect } from '../utils/customSelect';
import { recalcColumnOrder } from '../utils/orderedCell';

/**
 * 初回データ取得
 */
function* initFetchDepreciationDataSaga() {
  const loadingStateDepreciation: LoadingState = yield select(
    dataDepreciationLoadingStateSelector
  );

  if (loadingStateDepreciation !== 'prepare') {
    return;
  }

  yield put(DataDepreciationActionCreators.fetchDataDepreciationAction());

  yield take(DataDepreciationActionTypes.FETCH_DATA_DEPRECIATION_SUCCESS);
  const dataDepreciation: DataDepreciationResponse = yield select(
    dataDepreciationDataSelector
  );
  yield put(
    DepreciationSettingActionCreators.selectDepreciationFormCondition(
      dataDepreciation.setting
    )
  );
}

/**
 * 変更後の検索フォームでデータを取得する
 */
function* searchDataSettingsDepreciationSaga(
  action: SearchDepreciationSettingAction
) {
  yield put(
    DataDepreciationActionCreators.fetchDataDepreciationAction(
      action.payload.searchParams
    )
  );
}

/**
 * 変更後の表示項目でデータを取得する
 */
function* searchFieldsSaga(action: SearchDepreciationFieldsAction) {
  const fields = action.payload.fields.map((item) => item.code);

  const searchParams = yield* customSelect(depreciationSearchParamsSelector);

  // 表示項目の変更
  yield put(
    DepreciationSettingActionCreators.selectDepreciationFieldsAction(fields)
  );

  yield put(
    DataDepreciationActionCreators.fetchDataDepreciationAction({
      ...(searchParams ? searchParams : {}),
      fields,
    })
  );

  // 新規追加された表示項目は一律前に追加する
  const depreciationData = yield* customSelect(dataColumnsSwappedSelector);

  if (depreciationData == null) {
    throw new Error('DataDepreciation is not found');
  }
  const columnsOrder = depreciationData.data.columns
    .map((column) => column.code)
    .filter((code): code is string => code != null);
  const difference =
    fields?.filter((field) => !columnsOrder.includes(field)) ?? [];

  // fieldsはユーザーの選択順になっているため、表示順に並び替える
  const allFields = yield* customSelect(
    settingsOptionsDepreciationFieldsSelector
  );
  if (allFields == null) {
    throw new Error('settingsOptions is not found');
  }
  const desiredOrder = allFields.ki.map((item) => item.code);
  const sortedDifference = [...difference].sort((a, b) => {
    if (desiredOrder == null) {
      return 0;
    }
    return desiredOrder.indexOf(a) < desiredOrder.indexOf(b) ? -1 : 1;
  });

  const [kiTushoMei, ...rest] = columnsOrder;
  //fieldsが[]の時にデフォルトの表示項目が表示されるよう、空の配列を入れる
  const resultOrder = fields.length
    ? [kiTushoMei, ...sortedDifference, ...rest]
    : [];

  yield put(
    DepreciationSettingActionCreators.selectDepreciationColumnsOrderAction(
      resultOrder
    )
  );

  yield take(DataDepreciationActionTypes.FETCH_DATA_DEPRECIATION_SUCCESS);

  const dataDepreciation: DataDepreciationResponse = yield select(
    dataDepreciationDataSelector
  );
  // ソートラベルの状態などフロントでソートしている関係上UI側の設定を見ているのでUIの情報を更新
  yield put(
    DepreciationSettingActionCreators.triggerDepreciationSortAction(
      dataDepreciation.setting.sort,
      dataDepreciation.setting.order
    )
  );
}

/**
 * リセットボタン押下
 */
function* searchResetDepreciationSaga() {
  yield put(DepreciationSettingActionCreators.renewDepreciationSettingAction());
  yield put(DataDepreciationActionCreators.renewDataDepreciationAction());
  yield fork(initFetchDepreciationDataSaga);
  return;
}

function* triggerDepreciationSwapFieldsSaga(
  action: TriggerDepreciationSwapFieldsAction
) {
  const { draggedId, droppedId } = action.payload;

  const depreciationData: DataDepreciationResponse | undefined = yield select(
    dataDepreciationDataSelector
  );
  if (depreciationData == null) {
    return;
  }

  const currentOrder: string[] = yield select(depreciationColumnsOrderSelector);

  const ordered = recalcColumnOrder(
    currentOrder.length === 0
      ? depreciationData.data.columns
      : currentOrder.map((code) => ({ code })),
    draggedId,
    droppedId
  );

  yield put(
    DepreciationSettingActionCreators.selectDepreciationColumnsOrderAction(
      ordered
    )
  );
}

/**
 * 初回表示時のデータ取得
 */
function* handleInitFetchSaga() {
  yield takeEvery(
    DepreciationSettingActionTypes.INIT_DEPRECIATION_SETTING,
    initFetchDepreciationDataSaga
  );
}

/**
 * 検索時のデータ取得
 */
function* handleSearchFetchSaga() {
  yield takeEvery(
    DepreciationSettingActionTypes.SEARCH_DEPRECIATION_SETTING,
    searchDataSettingsDepreciationSaga
  );
}

/**
 * リセットボタン押下
 * TODO:お気に入り実装時にお気に入り選択中または未選択時のデフォルト値にする
 */
function* handleSearchResetSaga() {
  yield takeEvery(
    DepreciationSettingActionTypes.SEARCH_RESET_DEPRECIATION_SETTING,
    searchResetDepreciationSaga
  );
}

/**
 * 表示項目の変更
 */
function* handleChangeFieldsSaga() {
  yield takeEvery(
    DepreciationSettingActionTypes.SEARCH_DEPRECIATION_FIELDS,
    searchFieldsSaga
  );
}

/**
 * 表示項目並び順の変更
 */
function* handleFieldOrderSaga() {
  yield takeEvery(
    DepreciationSettingActionTypes.TRIGGER_DEPRECIATION_SWAP_FIELDS,
    triggerDepreciationSwapFieldsSaga
  );
}

export function* depreciationSettingSaga() {
  yield fork(handleInitFetchSaga);
  yield fork(handleSearchFetchSaga);
  yield fork(handleSearchResetSaga);
  yield fork(handleFieldOrderSaga);
  yield fork(handleChangeFieldsSaga);
}
