import { SettingsOptionsChainStore } from '../domain/settingsOptionsChainStore';

// チェーン店レポート用設定の選択肢のデフォルトデータ
export const settingsOptionsChainStoreDefaultValue: SettingsOptionsChainStore =
  {
    searchCondition: {
      shuGroupList: [],
      shuList: [],
      areas: [],
      halls: [],
      latestBaseDateForDailySisData: '',
      latestBaseDateForWeeklySisData: '',
      latestBaseDateForMonthlySisData: '',
    },
    fields: {
      shu: {
        whole: [],
        shu: [],
      },
      ki: {
        shu: [],
      },
    },
    genres: {
      shu: {
        whole: [],
        shu: [],
      },
      ki: {
        shu: [],
      },
    },
    kiCodeToPSensorKiCode: {},
  };
