import { SettingsOptionsPlanProgressTransition } from '../domain/settingsOptionsPlanProgressTransition';

// 検索条件項目のデフォルトデータ
export const settingsOptionsPlanProgressTransitionDefaultValue: SettingsOptionsPlanProgressTransition =
  {
    searchCondition: {
      makers: [],
      kiList: [],
      shuGroupList: [],
      shuList: [],
      areas: [],
      halls: [],
      sisTypes: [],
      latestBaseDateForDailySisData: '',
      latestBaseDateForWeeklySisData: '',
      latestBaseDateForMonthlySisData: '',
    },
    fields: {
      transition: [],
      nonTransition: [],
    },
  };
