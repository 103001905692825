import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsHall } from '../../domain/settingsOptionsHall';

import { RootState } from '../../store';
import { settingsOptionsHallDefaultValue } from '../../utils/settingsOptionsHallDefaultValue';

// Action Types

const FETCH_SETTINGS_OPTIONS_HALL = 'FETCH_SETTINGS_OPTIONS_HALL' as const;
const FETCH_SETTINGS_OPTIONS_HALL_REQUEST =
  'FETCH_SETTINGS_OPTIONS_HALL_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_HALL_SUCCESS =
  'FETCH_SETTINGS_OPTIONS_HALL_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_HALL = 'RENEW_SETTINGS_OPTIONS_HALL' as const;
const CLEAR_SETTINGS_OPTIONS_HALL = 'CLEAR_SETTINGS_OPTIONS_HALL' as const;

export const SettingsOptionsHallActionTypes = {
  FETCH_SETTINGS_OPTIONS_HALL,
  FETCH_SETTINGS_OPTIONS_HALL_REQUEST,
  FETCH_SETTINGS_OPTIONS_HALL_SUCCESS,
  RENEW_SETTINGS_OPTIONS_HALL,
  CLEAR_SETTINGS_OPTIONS_HALL,
};

// Action Creators

function fetchSettingsOptionsHallAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_HALL,
  };
}

function fetchSettingsOptionsHallRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_HALL_REQUEST,
  };
}

function fetchSettingsOptionsHallSuccessAction(
  settingsOptionsHall: SettingsOptionsHall
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_HALL_SUCCESS,
    payload: { settingsOptionsHall },
  };
}

function renewSettingsOptionsHallAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_HALL,
  };
}

function clearSettingsOptionsHallAction() {
  return {
    type: CLEAR_SETTINGS_OPTIONS_HALL,
  };
}

export const SettingsOptionsHallActionCreators = {
  fetchSettingsOptionsHallAction,
  fetchSettingsOptionsHallRequestAction,
  fetchSettingsOptionsHallSuccessAction,
  renewSettingsOptionsHallAction,
  clearSettingsOptionsHallAction,
};

// Actions

type FetchSettingsOptionsHallAction = ReturnType<
  typeof fetchSettingsOptionsHallAction
>;
type ClearSettingsOptionsHallAction = ReturnType<
  typeof clearSettingsOptionsHallAction
>;

type SettingsOptionsHallAction =
  | FetchSettingsOptionsHallAction
  | ReturnType<typeof fetchSettingsOptionsHallRequestAction>
  | ReturnType<typeof fetchSettingsOptionsHallSuccessAction>
  | ReturnType<typeof renewSettingsOptionsHallAction>
  | ClearSettingsOptionsHallAction;

// State

type SettingsOptionsHallState = {
  loadingState: LoadingState;
  settingsOptionsHall?: SettingsOptionsHall;
  isError: boolean;
};

const initialState: SettingsOptionsHallState = {
  loadingState: 'prepare',
  settingsOptionsHall: undefined,
  isError: false,
};

// Selector

/**
 * settingsOptionsを全て取得する
 * @returns settingsOptionsのすべてのデータ
 */
export const settingsOptionsHallSelector = (state: RootState) => {
  return state.settingsOptionsHall.settingsOptionsHall;
};

/**
 * settingsOptionsのローディングステータスを取得する
 * @returns ローディング状態
 */
export const settingsOptionsHallLoadingStateSelector = (state: RootState) =>
  state.settingsOptionsHall.loadingState;

/**
 * SearchConditionだけ取得する
 * @returns SearchCondition
 */
export const settingsOptionsHallSearchConditionSelector = createSelector(
  settingsOptionsHallSelector,
  (settingsOptionsHall) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsHall === undefined)
      return settingsOptionsHallDefaultValue.searchCondition;

    return settingsOptionsHall.searchCondition;
  }
);

/**
 * 表示項目の項目一覧すべて取得する
 * @returns すべての表示項目一覧
 */
const settingsOptionsHallFieldsSelector = createSelector(
  settingsOptionsHallSelector,
  (settingsOptionsHall) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsHall === undefined)
      return settingsOptionsHallDefaultValue.fields;

    return settingsOptionsHall.fields;
  }
);

/**
 * 種別実績の表示項目一覧を取得する
 * @returns 表示項目一覧（種別実績）
 */
export const settingsOptionsHallShuFieldsSelector = createSelector(
  settingsOptionsHallFieldsSelector,
  (fields) => {
    return fields.shu.shu;
  }
);

// 種別実績表示項目のFieldsのisNewがtrueのものがあるかどうかを取得する
export const settingsOptionsHallShuFieldsIsNewSelector = createSelector(
  settingsOptionsHallShuFieldsSelector,
  (fields) => {
    return fields.some((item) => item.isNew);
  }
);

/**
 * 新台/メイン機種の表示項目一覧を取得する
 * @returns 表示項目一覧（新台/メイン機種）
 */
export const settingsOptionsHallKiFieldsSelector = createSelector(
  settingsOptionsHallFieldsSelector,
  (fields) => {
    return fields.ki;
  }
);

/**
 * 新台/メイン機種の表示項目のFieldsのisNewがtrueのものがあるかどうかを取得する
 */
export const settingsOptionsHallKiFieldsIsNewSelector = createSelector(
  settingsOptionsHallKiFieldsSelector,
  (fields) => {
    return fields.some((item) => item.isNew);
  }
);

/**
 * 新台/メイン機種の表示項目のコードを取得する
 */
export const settingsOptionsHallKiFieldCodesSelector = createSelector(
  settingsOptionsHallKiFieldsSelector,
  (options) => {
    return options.map((item) => item.code);
  }
);

/**
 * 新台/メイン機種の推移グラフ（機種比較）のグラフ項目一覧を取得する
 * @returns グラフ項目一覧
 */
export const settingsOptionsHallKiGraphFieldsSelector = createSelector(
  settingsOptionsHallFieldsSelector,
  (fields) => {
    return fields.ki.filter((field) => field.isSelectableForGraph === true);
  }
);

/**
 * 店舗一覧を取得する
 * @returns 店舗一覧
 */
export const settingsOptionsHallHallSelector = createSelector(
  settingsOptionsHallSearchConditionSelector,
  (serchCondition) => {
    return serchCondition.halls;
  }
);

// 種別フィルター用のジャンル一覧を取得する
export const settingsOptionsHallShuGenresSelector = createSelector(
  settingsOptionsHallSelector,
  (settingsOptionsHall) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsHall === undefined)
      return settingsOptionsHallDefaultValue.genres.shu.shu;

    return settingsOptionsHall.genres.shu.shu;
  }
);

// 機種別フィルター用のジャンル一覧を取得する
export const settingsOptionsHallKiGenresSelector = createSelector(
  settingsOptionsHallSelector,
  (settingsOptionsHall) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsHall === undefined)
      return settingsOptionsHallDefaultValue.genres.ki;

    return settingsOptionsHall.genres.ki;
  }
);

/**
 * 商圏実績の表示項目一覧を取得する
 * @returns 表示項目一覧（商圏実績）
 */
export const settingsOptionsHallMarketAreaFieldsSelector = createSelector(
  settingsOptionsHallFieldsSelector,
  (fields) => {
    return fields.marketArea;
  }
);

// 機種コードからP-Sensorの機種コードに変換するテーブルを取得する
export const settingsOptionsHallModelNumberConversionTableSelector =
  createSelector(
    settingsOptionsHallSelector,
    (settingsOptionsHall) => settingsOptionsHall?.kiCodeToPSensorKiCode || {}
  );

// Reducer

export function settingsOptionsHallReducer(
  state = initialState,
  action: SettingsOptionsHallAction
): SettingsOptionsHallState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_HALL_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
        isError: false,
      };
    case FETCH_SETTINGS_OPTIONS_HALL_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsHall: action.payload.settingsOptionsHall,
      };
    case RENEW_SETTINGS_OPTIONS_HALL:
      return initialState;
    case CLEAR_SETTINGS_OPTIONS_HALL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
