import { createSelector } from 'reselect';

import { ModeSettingsOptions } from '../../domain/mode/types';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_SETTINGS_OPTIONS_MODE = 'FETCH_SETTINGS_OPTIONS_MODE' as const;
const FETCH_SETTINGS_OPTIONS_MODE_REQUEST =
  'FETCH_SETTINGS_OPTIONS_MODE_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_MODE_SUCCESS =
  'FETCH_SETTINGS_OPTIONS_MODE_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_MODE = 'RENEW_SETTINGS_OPTIONS_MODE' as const;

export const SettingsOptionsModeActionTypes = {
  FETCH_SETTINGS_OPTIONS_MODE,
  FETCH_SETTINGS_OPTIONS_MODE_REQUEST,
  FETCH_SETTINGS_OPTIONS_MODE_SUCCESS,
  RENEW_SETTINGS_OPTIONS_MODE,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchSettingsOptionsModeAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_MODE,
  };
}

function fetchSettingsOptionsModeRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_MODE_REQUEST,
  };
}

function fetchSettingsOptionsModeSuccessAction(
  settingsOptionsMode: ModeSettingsOptions
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_MODE_SUCCESS,
    payload: { settingsOptionsMode },
  };
}

function renewSettingsOptionsModeAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_MODE,
  };
}

export const SettingsOptionsModeActionCreators = {
  fetchSettingsOptionsModeAction,
  fetchSettingsOptionsModeRequestAction,
  fetchSettingsOptionsModeSuccessAction,
  renewSettingsOptionsModeAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type SettingsOptionsModeAction =
  | ReturnType<typeof fetchSettingsOptionsModeAction>
  | ReturnType<typeof fetchSettingsOptionsModeRequestAction>
  | ReturnType<typeof fetchSettingsOptionsModeSuccessAction>
  | ReturnType<typeof renewSettingsOptionsModeAction>;

/* ---------------------------------------------------------------
 * State
 */

type SettingsOptionsModeState = {
  loadingState: LoadingState;
  settingsOptionsMode: ModeSettingsOptions | undefined;
};

const initialState: SettingsOptionsModeState = {
  loadingState: 'prepare',
  settingsOptionsMode: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 *  [モード別集計] settingsOptionsのデータを全て取得する
 */
const settingsOptionsModeAllSelector = (state: RootState) => {
  return state.settingsOptionsMode;
};

/**
 * [モード別集計] settingsOptionsMode
 */
const settingsOptionsModeSelector = createSelector(
  settingsOptionsModeAllSelector,
  (settingsOptionsMode) => {
    return settingsOptionsMode.settingsOptionsMode;
  }
);

/**
 * [モード別集計] 現在のローディング状態を取得
 */
export const settingsOptionsModeLoadingStateSelector = createSelector(
  settingsOptionsModeAllSelector,
  (settingsOptionsMode) => {
    return settingsOptionsMode.loadingState;
  }
);

/**
 * [モード別集計] settingsOptionsMode.searchCondition
 */
export const settingsOptionsModeSearchConditionSelector = createSelector(
  settingsOptionsModeSelector,
  (settingsOptionsMode) => {
    return settingsOptionsMode?.searchCondition;
  }
);

/**
 * [モード別集計] searchCondition.fields
 */
export const settingsOptionsModeFieldsSelector = createSelector(
  settingsOptionsModeSelector,
  (settingsOptionsMode) => {
    return settingsOptionsMode?.fields;
  }
);

/**
 * [モード別集計] フィルター用のジャンル一覧を取得する
 */
export const settingsOptionsModeGenresSelector = createSelector(
  settingsOptionsModeSelector,
  (settingsOptionsMode) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsMode == null) {
      return [];
    }

    return settingsOptionsMode.genres.mode;
  }
);

/**
 * [モード別集計] isNewをもつ表示項目があればtrue
 */
export const settingsOptionsModeFieldsKiIsNewSelector = createSelector(
  settingsOptionsModeFieldsSelector,
  (fields) => {
    return [...(fields?.mode ?? []), ...(fields?.notMode ?? [])].some(
      (field) => field.isNew
    );
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function settingsOptionsModeReducer(
  state = initialState,
  action: SettingsOptionsModeAction
): SettingsOptionsModeState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_MODE_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_MODE_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsMode: action.payload.settingsOptionsMode,
      };
    case RENEW_SETTINGS_OPTIONS_MODE:
      return initialState;
    default:
      return state;
  }
}
