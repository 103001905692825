import { createSelector } from 'reselect';

import { SharedUser } from '../../domain/favorites';
import { KiTagListOptions, Option, OrderType } from '../../domain/schemas';
import { SisDataType } from '../../domain/settingsShuGroups';
import { DataSis, SisDateRange } from '../../domain/sis/types';

import { favoritesSelector } from './settingsFavorites';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_SIS = 'INIT_SIS' as const;
const SEARCH_SIS = 'SEARCH_SIS' as const;
const SEARCH_RESET_SIS = 'SEARCH_RESET_SIS' as const;
const TRIGGER_SIS_SORT_AND_ORDER = 'TRIGGER_SIS_SORT_AND_ORDER' as const;
const TRIGGER_SIS_SORT = 'TRIGGER_SIS_SORT' as const;
const CHANGE_SIS_SORT_AND_ORDER = 'CHANGE_SIS_SORT_AND_ORDER' as const;
const SEARCH_SIS_FIELDS = 'SEARCH_SIS_FIELDS' as const;
const SELECT_SIS_SUB_MENU_NUMBER_FILTER =
  'SELECT_SIS_SUB_MENU_NUMBER_FILTER' as const;
const RESET_SIS_SUB_MENU_NUMBER_FILTER =
  'RESET_SIS_SUB_MENU_NUMBER_FILTER' as const;
const SELECT_SIS_NAME_FILTER = 'SELECT_SIS_NAME_FILTER' as const;
const CHANGE_SIS_DATA_TYPE = 'CHANGE_SIS_DATA_TYPE' as const;
const ADD_SIS_FIELD_FILTER = 'ADD_SIS_FIELD_FILTER' as const;
const APPLY_SIS_FIELD_FILTER = 'APPLY_SIS_FIELD_FILTER' as const;
const RESET_SIS_FIELD_FILTER = 'RESET_SIS_FIELD_FILTER' as const;
const SELECT_SIS_FIELDS = 'SELECT_SIS_FIELDS' as const;
const TRIGGER_SIS_SWAP_FIELDS = 'TRIGGER_SIS_SWAP_FIELDS' as const;
const SELECT_SIS_COLUMNS_ORDER = 'SELECT_SIS_COLUMNS_ORDER' as const;
const SELECT_SIS_FORM_CONDITION = 'SELECT_SIS_FORM_CONDITION' as const;
const SELECT_SIS_GRAPH_NUMBER_LABEL = 'SELECT_SIS_GRAPH_NUMBER_LABEL' as const;
const SELECT_SIS_CHECKED_KI_LIST = 'SELECT_SIS_CHECKED_KI_LIST' as const;
const SELECT_SIS_CHECKED_TABLE_ALL_KI_LIST =
  'SELECT_SIS_CHECKED_TABLE_ALL_KI_LIST' as const;
const SELECT_SIS_CHECKED_FAVORITE_ALL_KI_LIST =
  'SELECT_SIS_CHECKED_FAVORITE_ALL_KI_LIST' as const;
const REMOVE_SIS_CHECKED_KI_LIST = 'REMOVE_SIS_CHECKED_KI_LIST' as const;
const SELECT_SIS_KI_LIST_FILTER = 'SELECT_SIS_KI_LIST_FILTER' as const;
const SELECT_SIS_DATE_RANGE = 'SELECT_SIS_DATE_RANGE' as const;
const CLEAR_SIS_CHECKED_KI_LIST = 'CLEAR_SIS_CHECKED_KI_LIST' as const;
const CLEAR_SIS_KI_LIST_FILTER = 'CLEAR_SIS_KI_LIST_FILTER' as const;
const CLEAR_ALL_SIS = 'CLEAR_ALL_SIS' as const;
const SAVE_AS_SIS_FAVORITE = 'SAVE_AS_SIS_FAVORITE' as const;
const SAVE_SIS_FAVORITE = 'SAVE_SIS_FAVORITE' as const;
const RESET_SIS_FAVORITE_TO_DEFAULT = 'RESET_SIS_FAVORITE_TO_DEFAULT' as const;
const CHANGE_SIS_FAVORITE = 'CHANGE_SIS_FAVORITE' as const;
const CREATE_SIS_SHORTENED_URL = 'CREATE_SIS_SHORTENED_URL' as const;
const SELECT_SIS_KI_TAG_LIST = 'SELECT_SIS_KI_TAG_LIST' as const;
const SELECT_SIS_SHOW_KI_TAG_LABEL = 'SELECT_SIS_SHOW_KI_TAG_LABEL' as const;

export const SisSettingsActionTypes = {
  INIT_SIS,
  SEARCH_SIS,
  SEARCH_RESET_SIS,
  TRIGGER_SIS_SORT_AND_ORDER,
  TRIGGER_SIS_SORT,
  CHANGE_SIS_SORT_AND_ORDER,
  SEARCH_SIS_FIELDS,
  SELECT_SIS_SUB_MENU_NUMBER_FILTER,
  RESET_SIS_SUB_MENU_NUMBER_FILTER,
  SELECT_SIS_NAME_FILTER,
  CHANGE_SIS_DATA_TYPE,
  ADD_SIS_FIELD_FILTER,
  APPLY_SIS_FIELD_FILTER,
  RESET_SIS_FIELD_FILTER,
  TRIGGER_SIS_SWAP_FIELDS,
  SELECT_SIS_FIELDS,
  SELECT_SIS_COLUMNS_ORDER,
  SELECT_SIS_FORM_CONDITION,
  SELECT_SIS_GRAPH_NUMBER_LABEL,
  SELECT_SIS_CHECKED_KI_LIST,
  SELECT_SIS_CHECKED_TABLE_ALL_KI_LIST,
  SELECT_SIS_CHECKED_FAVORITE_ALL_KI_LIST,
  REMOVE_SIS_CHECKED_KI_LIST,
  SELECT_SIS_KI_LIST_FILTER,
  SELECT_SIS_DATE_RANGE,
  CLEAR_SIS_CHECKED_KI_LIST,
  CLEAR_SIS_KI_LIST_FILTER,
  CLEAR_ALL_SIS,
  SAVE_AS_SIS_FAVORITE,
  SAVE_SIS_FAVORITE,
  RESET_SIS_FAVORITE_TO_DEFAULT,
  CHANGE_SIS_FAVORITE,
  CREATE_SIS_SHORTENED_URL,
  SELECT_SIS_KI_TAG_LIST,
  SELECT_SIS_SHOW_KI_TAG_LABEL,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 初回リクエスト
 */
function initSisAction() {
  return {
    type: INIT_SIS,
  };
}

/**
 * 検索ボタンの押下
 */
function searchSisAction(
  sisStartDate: string,
  sisDateRangeType: 'daily' | 'weekly',
  selectedDateRange: SisDateRange
) {
  return {
    type: SEARCH_SIS,
    payload: {
      sisStartDate,
      sisDateRangeType,
      selectedDateRange,
    },
  };
}

/**
 * リセットボタンを押下した時のアクション
 */
function searchResetSisAction() {
  return {
    type: SEARCH_RESET_SIS,
  };
}

/**
 * 種別セレクタの変更
 */
function changeSisDataTypeAction(sisDataType: SisDataType) {
  return {
    type: CHANGE_SIS_DATA_TYPE,
    payload: { sisDataType },
  };
}

/**
 * サブメニューからのソート変更
 */
function triggerSisSortAndOrderAction(sort: string, order: OrderType) {
  return {
    type: TRIGGER_SIS_SORT_AND_ORDER,
    payload: { sort, order },
  };
}

/**
 * 表示項目にあるソートボタン押下
 */
function triggerSisSortAction(sort: string) {
  return {
    type: TRIGGER_SIS_SORT,
    payload: { sort },
  };
}

/**
 * sortとorderを変更して検索を実行する
 *
 * 直接Reactのコンポーネントから実行せず、Saga経由で実行する
 * Reactから呼び出す場合にはtriggerSisSortAndOrderActionかtriggerSisSortActionを利用する
 */
function changeSisSortAndOrderAction(sort: string, order: OrderType) {
  return {
    type: CHANGE_SIS_SORT_AND_ORDER,
    payload: { sort, order },
  };
}

/**
 * 表示項目の変更
 */
function searchSisFieldAction(fields: Option[]) {
  return {
    type: SEARCH_SIS_FIELDS,
    payload: { fields },
  };
}

/**
 * 表示項目の列の非表示
 */
export function addSisTableFilterAction(columnCode: string) {
  return {
    type: ADD_SIS_FIELD_FILTER,
    payload: { columnCode },
  };
}

/**
 * 表示項目の列の非表示を解除
 */
function resetSisTableFilterAction() {
  return {
    type: RESET_SIS_FIELD_FILTER,
  };
}

/**
 * 表示項目の列の非表示をまとめて反映（お気に入り時に使用）
 */
function applySisTableFilterAction(fieldsFilter: string[]) {
  return {
    type: APPLY_SIS_FIELD_FILTER,
    payload: { fieldsFilter },
  };
}

/**
 * サブメニュー内数値フィルタを変更
 */
function selectSisSubMenuNumberFilterAction(subMenuNumberFilter: {
  [field: string]: {
    minimumNumber: number | undefined;
    maximumNumber: number | undefined;
  };
}) {
  return {
    type: SELECT_SIS_SUB_MENU_NUMBER_FILTER,
    payload: { subMenuNumberFilter },
  };
}

/**
 * 数値フィルタを全てリセットする
 */
function resetSisSubMenuNumberFilterAction() {
  return {
    type: RESET_SIS_SUB_MENU_NUMBER_FILTER,
  };
}

/**
 * 機種名フィルタを変更
 */
function selectSisNameFilterAction(name: string) {
  return {
    type: SELECT_SIS_NAME_FILTER,
    payload: { name },
  };
}

/**
 * 表示項目を変更(fetchしない)
 */
function selectSisFieldsAction(fields: string[]) {
  return {
    type: SELECT_SIS_FIELDS,
    payload: { fields },
  };
}

/**
 * 表示項目の並び順を入替
 */
function triggerSwapFieldsAction(draggedId: string, droppedId: string) {
  return {
    type: TRIGGER_SIS_SWAP_FIELDS,
    payload: { draggedId, droppedId },
  };
}

/**
 * 表示項目の並び順を変更
 */
function selectSisColumnsOrderAction(columnsOrder: string[]) {
  return {
    type: SELECT_SIS_COLUMNS_ORDER,
    payload: { columnsOrder },
  };
}

/**
 * 機種絞り込みを変更
 */
function selectSisKiListFilterAction(kiList: string[]) {
  return {
    type: SELECT_SIS_KI_LIST_FILTER,
    payload: { kiList },
  };
}

/**
 * FormConditionを変更
 */
function selectSisFormCondition(
  formCondition: SisSettingState['searchParams']
) {
  return {
    type: SELECT_SIS_FORM_CONDITION,
    payload: { formCondition },
  };
}

/*
 * テーブルのチェックボックスで選択した機種を追加
 */
function selectSisCheckedKiListAction(checkedKiList: {
  dataType: string;
  ki: string;
}) {
  return {
    type: SELECT_SIS_CHECKED_KI_LIST,
    payload: checkedKiList,
  };
}

/**
 * テーブルのチェックボックスで全選択した場合
 */
function selectSisTableCheckedAllKiListAction(
  checkedKiList: { dataType: string; ki: string }[]
) {
  return {
    type: SELECT_SIS_CHECKED_TABLE_ALL_KI_LIST,
    payload: checkedKiList,
  };
}
/**
 * お気に入り適用時にチェックボックスで選択している機種リストを反映
 */
function selectSisFavoriteCheckedAllKiListAction(
  checkedAllKiList: { dataType: string; ki: string }[]
) {
  return {
    type: SELECT_SIS_CHECKED_FAVORITE_ALL_KI_LIST,
    payload: checkedAllKiList,
  };
}

/*
 * テーブルのチェックボックスで選択した機種を削除
 */
function removeSisCheckedKiListAction(
  checkedKiList: {
    dataType: string;
    ki: string;
  }[]
) {
  return {
    type: REMOVE_SIS_CHECKED_KI_LIST,
    payload: checkedKiList,
  };
}

/**
 * 推移グラフに数値を表示するかどうかのフラグをセットする
 */
function selectSisGraphNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_SIS_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * テーブルのチェックボックスで選択されている機種のリセット
 */
function clearSisCheckedKiListAction() {
  return {
    type: CLEAR_SIS_CHECKED_KI_LIST,
  };
}

/**
 * SisDateSelectで選択している期間のタイプを変更
 */
function selectSisDateSelectAction(selectedDateRange: SisDateRange) {
  return {
    type: SELECT_SIS_DATE_RANGE,
    payload: { selectedDateRange },
  };
}

/**
 * 機種絞り込みのリセット
 */
function clearSisKiListFilterAction() {
  return {
    type: CLEAR_SIS_KI_LIST_FILTER,
  };
}

/**
 * 全ての設定をデフォルトに戻す
 */
function clearAllSisAction() {
  return {
    type: CLEAR_ALL_SIS,
  };
}

/**
 * お気に入りを新規保存する
 */
function saveAsSisFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_SIS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * お気に入りを上書き保存する
 */
function saveSisFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_SIS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * データを取得せずにお気に入りをデフォルトに戻す
 */
function resetSisFavoriteToDefaultAction() {
  return {
    type: RESET_SIS_FAVORITE_TO_DEFAULT,
  };
}

/**
 * 選択したお気に入りでデータを取得する
 */
function changeSisFavoriteAction(favoriteId?: number) {
  return {
    type: CHANGE_SIS_FAVORITE,
    payload: { favoriteId },
  };
}

/**
 * 画面共有用の短縮URLを作成する
 */
function createSisShortenedUrlAction(pageName: string, locationUrl: string) {
  return {
    type: CREATE_SIS_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

/**
 * 表示項目を変更(fetchしない)
 */
function selectSisKiTagListAction(kiTagList: KiTagListOptions[]) {
  return {
    type: SELECT_SIS_KI_TAG_LIST,
    payload: { kiTagList },
  };
}

/**
 * 機種行への機種タグ表示機能のフラグを変更(fetchしない)
 */
function selectSisShowKiTagLabelAction(showKiTagLabel: boolean) {
  return {
    type: SELECT_SIS_SHOW_KI_TAG_LABEL,
    payload: { showKiTagLabel },
  };
}
export const SisSettingsActionCreators = {
  initSisAction,
  searchSisAction,
  searchResetSisAction,
  triggerSisSortAndOrderAction,
  triggerSisSortAction,
  changeSisSortAndOrderAction,
  searchSisFieldAction,
  selectSisSubMenuNumberFilterAction,
  resetSisSubMenuNumberFilterAction,
  selectSisNameFilterAction,
  changeSisDataTypeAction,
  addSisTableFilterAction,
  applySisTableFilterAction,
  resetSisTableFilterAction,
  selectSisFieldsAction,
  triggerSwapFieldsAction,
  selectSisColumnsOrderAction,
  selectSisFormCondition,
  selectSisCheckedKiListAction,
  selectSisTableCheckedAllKiListAction,
  selectSisFavoriteCheckedAllKiListAction,
  removeSisCheckedKiListAction,
  selectSisGraphNumberLabelAction,
  selectSisKiListFilterAction,
  selectSisDateSelectAction,
  clearSisCheckedKiListAction,
  clearSisKiListFilterAction,
  clearAllSisAction,
  saveAsSisFavoriteAction,
  saveSisFavoriteAction,
  resetSisFavoriteToDefaultAction,
  changeSisFavoriteAction,
  createSisShortenedUrlAction,
  selectSisKiTagListAction,
  selectSisShowKiTagLabelAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type InitDataSisAction = ReturnType<typeof initSisAction>;
export type SearchSisAction = ReturnType<typeof searchSisAction>;
export type SearchResetSisAction = ReturnType<typeof searchResetSisAction>;
export type TriggerSisSortAndOrderAction = ReturnType<
  typeof triggerSisSortAndOrderAction
>;
export type TriggerSisSortAction = ReturnType<typeof triggerSisSortAction>;
export type ChangeSisSortAndOrderAction = ReturnType<
  typeof changeSisSortAndOrderAction
>;
export type SearchSisFieldAction = ReturnType<typeof searchSisFieldAction>;
type SelectSisSubMenuNumberFilterAction = ReturnType<
  typeof selectSisSubMenuNumberFilterAction
>;
type ResetSisSubMenuNumberFilterAction = ReturnType<
  typeof resetSisSubMenuNumberFilterAction
>;
type SelectSisNameFilterAction = ReturnType<typeof selectSisNameFilterAction>;
export type ChangeSisDateTypeAction = ReturnType<
  typeof changeSisDataTypeAction
>;
type AddSisTableFilterAction = ReturnType<typeof addSisTableFilterAction>;
type ApplySisTableFilterAction = ReturnType<typeof applySisTableFilterAction>;
type ResetSisTableFilterAction = ReturnType<typeof resetSisTableFilterAction>;
type SelectSisFieldsAction = ReturnType<typeof selectSisFieldsAction>;
export type TriggerSwapFieldsAction = ReturnType<
  typeof triggerSwapFieldsAction
>;
type SelectSisColumnsOrderAction = ReturnType<
  typeof selectSisColumnsOrderAction
>;
type SelectSisFormCondition = ReturnType<typeof selectSisFormCondition>;
type SelectSisCheckedKiListAction = ReturnType<
  typeof selectSisCheckedKiListAction
>;
type SelectSisTableCheckedAllKiListAction = ReturnType<
  typeof selectSisTableCheckedAllKiListAction
>;
type SelectSisFavoriteCheckedAllKiListAction = ReturnType<
  typeof selectSisFavoriteCheckedAllKiListAction
>;
type SelectSisGraphNumberLabelAction = ReturnType<
  typeof selectSisGraphNumberLabelAction
>;
type SelectSisKiListFilterAction = ReturnType<
  typeof selectSisKiListFilterAction
>;
type SelectSisDateRangeAction = ReturnType<typeof selectSisDateSelectAction>;
type ClearSisCheckedKiListAction = ReturnType<
  typeof clearSisCheckedKiListAction
>;
type RemoveSisCheckedKiListAction = ReturnType<
  typeof removeSisCheckedKiListAction
>;
type ClearSisKiListFilterAction = ReturnType<typeof clearSisKiListFilterAction>;
type ClearAllSisAction = ReturnType<typeof clearAllSisAction>;
export type SaveAsSisFavoriteAction = ReturnType<
  typeof saveAsSisFavoriteAction
>;
export type SaveSisFavoriteAction = ReturnType<typeof saveSisFavoriteAction>;
type ResetSisFavoriteToDefaultAction = ReturnType<
  typeof resetSisFavoriteToDefaultAction
>;
export type ChangeSisFavoriteAction = ReturnType<
  typeof changeSisFavoriteAction
>;
export type CreateSisShortenedUrlAction = ReturnType<
  typeof createSisShortenedUrlAction
>;
type SelectSisKiTagListAction = ReturnType<typeof selectSisKiTagListAction>;
type SelectSisShowKiTagLabelAction = ReturnType<
  typeof selectSisShowKiTagLabelAction
>;

type SisSettingsAction =
  | InitDataSisAction
  | SearchSisAction
  | SearchResetSisAction
  | TriggerSisSortAndOrderAction
  | TriggerSisSortAction
  | ChangeSisSortAndOrderAction
  | SearchSisFieldAction
  | SelectSisSubMenuNumberFilterAction
  | ResetSisSubMenuNumberFilterAction
  | SelectSisNameFilterAction
  | ChangeSisDateTypeAction
  | AddSisTableFilterAction
  | ApplySisTableFilterAction
  | ResetSisTableFilterAction
  | SelectSisFieldsAction
  | TriggerSwapFieldsAction
  | SelectSisColumnsOrderAction
  | SelectSisFormCondition
  | SelectSisCheckedKiListAction
  | SelectSisTableCheckedAllKiListAction
  | SelectSisFavoriteCheckedAllKiListAction
  | RemoveSisCheckedKiListAction
  | SelectSisGraphNumberLabelAction
  | SelectSisKiListFilterAction
  | SelectSisDateRangeAction
  | ClearSisCheckedKiListAction
  | ClearSisKiListFilterAction
  | ClearAllSisAction
  | SaveAsSisFavoriteAction
  | SaveSisFavoriteAction
  | ResetSisFavoriteToDefaultAction
  | ChangeSisFavoriteAction
  | CreateSisShortenedUrlAction
  | SelectSisKiTagListAction
  | SelectSisShowKiTagLabelAction;

/* ---------------------------------------------------------------
 * State
 */

export type SisSettingState = {
  /**
   * リクエスト時のクエリパラメータ
   */
  searchParams: DataSis['setting'] | undefined;
  /**
   * サブメニュー内数値フィルタ
   */
  subMenuNumberFilter:
    | {
        [field: string]: {
          minimumNumber: number | undefined;
          maximumNumber: number | undefined;
        };
      }
    | undefined;
  /**
   * 機種名フィルタ
   */
  nameFilter: string | undefined;
  /**
   * テーブルの非表示項目一覧
   */
  fieldsFilter: string[];
  /**
   * 機種絞り込み
   */
  kiListFilter: string[];
  /**
   * 表示項目の列順
   */
  columnsOrder: string[];
  /**
   * SisDateSelectで選択している期間のタイプ
   */
  selectedDateRange: SisDateRange;
  /**
   * テーブルのチェックボックスで選択されている機種
   */
  checkedKiList: {
    dataType: string;
    ki: string;
  }[];
  /**
   * 推移グラフの数値表示
   */
  showGraphNumberLabel: boolean;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId: number | undefined;
  /**
   * タグ絞込で選択されているタグリスト
   */
  kiTagList: KiTagListOptions[];
  /**
   * 機種行への機種タグ表示機能のフラグ
   */
  showKiTagLabel: boolean;
};

const initialState: SisSettingState = {
  searchParams: undefined,
  subMenuNumberFilter: {},
  nameFilter: undefined,
  fieldsFilter: [],
  columnsOrder: [],
  kiListFilter: [],
  selectedDateRange: '最新日',
  checkedKiList: [],
  showGraphNumberLabel: true,
  selectedFavoriteId: undefined,
  kiTagList: [],
  showKiTagLabel: false,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [SIS機種レポート] 設定情報を取得する
 */
function sisSettingSelector(rootState: { sisSettings: SisSettingState }) {
  return rootState.sisSettings;
}

/**
 * [SIS機種レポート] 選択されている検索条件を取得する
 */
export const sisSearchParamsSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.searchParams
);

/**
 * [SIS機種レポート] フィルターされている機種名を取得する
 */
export const sisNameFilterSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.nameFilter
);

/**
 * [SIS機種レポート] 機種絞り込みされている機種リストを取得する
 */
export const sisKiListFilterSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.kiListFilter
);

/**
 * [SIS機種レポート] 機種絞り込みされているかどうかを取得する
 */
export const isSisKiListFilterSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.kiListFilter.length !== 0
);

/**
 * [SIS機種レポート] 列を非表示で非表示にされている表示項目を取得する
 */
export const sisFieldsFilterSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.fieldsFilter
);

/**
 * [SIS機種レポート] 数値フィルターを取得する
 */
export const sisSubMenuNumberFilterSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.subMenuNumberFilter
);

/**
 * [SIS機種レポート] 表示項目の列順を取得する
 */
export const sisColumnsOrderSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.columnsOrder
);

/**
 * [SIS機種レポート] 選択されている期間のタイプを取得する
 */
export const sisSelectedDateRangeSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.selectedDateRange
);

/**
 * [SIS機種レポート] 選択されている種別を取得する
 */
export const sisSelectedDataTypeSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.searchParams?.sisDataType
);

/**
 * [SIS機種レポート] チェックボックで選択されている機種を取得する
 */
export const sisSelectCheckedKiListSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.checkedKiList
);

/**
 * [SIS機種レポート] 指定している種別のテーブルでチェックボックで選択されている機種を取得する
 */
export const sisSelectCheckedShuKiListSelector = createSelector(
  [sisSettingSelector, sisSelectedDataTypeSelector],
  (sisSettings, sisDataType) => {
    return sisSettings.checkedKiList.filter(
      (ki) => ki.dataType === sisDataType
    );
  }
);

/**
 * [SIS機種レポート] グラフの数値表示の有無を取得する
 */
export const sisSelectGraphNumberLabelSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.showGraphNumberLabel
);

/**
 * [SIS機種レポート] 選択中のお気に入りIDを取得する
 */
export const sisSelectedFavoriteSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.selectedFavoriteId
);

/**
 * [SIS機種レポート] 選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const sisSelectedFavoriteDataSelector = createSelector(
  [sisSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId == null) return;

    return favorites?.favorites?.find((favorite) => favorite.id === favoriteId);
  }
);

/**
 * [SIS機種レポート] SIS機種レポートのお気に入りデータを取得する
 * @returns 現在選択中のSIS機種レポートのお気に入りデータ
 */
export const sisSelectedFavoritePageSettingSelector = createSelector(
  sisSelectedFavoriteDataSelector,
  (favorite) => {
    return favorite?.pageSetting?.sis;
  }
);

/**
 * [SIS機種レポート] タグ絞込で選択されているタグリストを取得する
 */
export const sisKiTagListSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.kiTagList
);

/**
 * [SIS機種レポート] 機種行への機種タグ表示機能のフラグを取得する
 */
export const sisShowKiTagLabelSelector = createSelector(
  sisSettingSelector,
  (sisSettings) => sisSettings.showKiTagLabel
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function settingsSisReducer(
  state = initialState,
  action: SisSettingsAction
): SisSettingState {
  switch (action.type) {
    case SEARCH_SIS:
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          sisStartDate: action.payload.sisStartDate,
          sisDateRangeType: action.payload.sisDateRangeType,
        },
        selectedDateRange: action.payload.selectedDateRange,
      };
    case CHANGE_SIS_SORT_AND_ORDER:
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          sort: action.payload.sort,
          order: action.payload.order,
        },
      };
    case SELECT_SIS_SUB_MENU_NUMBER_FILTER:
      return {
        ...state,
        subMenuNumberFilter: {
          ...state.subMenuNumberFilter,
          ...action.payload.subMenuNumberFilter,
        },
      };
    case RESET_SIS_SUB_MENU_NUMBER_FILTER:
      return {
        ...state,
        subMenuNumberFilter: initialState.subMenuNumberFilter,
      };
    case SELECT_SIS_NAME_FILTER:
      return {
        ...state,
        nameFilter: action.payload.name,
      };
    case SELECT_SIS_KI_LIST_FILTER:
      return {
        ...state,
        kiListFilter: action.payload.kiList,
      };
    case SELECT_SIS_FIELDS:
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          fields: action.payload.fields,
        },
      };
    case CHANGE_SIS_DATA_TYPE:
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          sisDataType: action.payload.sisDataType,
        },
      };
    case ADD_SIS_FIELD_FILTER:
      return {
        ...state,
        fieldsFilter: [...state.fieldsFilter, action.payload.columnCode],
      };
    case APPLY_SIS_FIELD_FILTER:
      return {
        ...state,
        fieldsFilter: action.payload.fieldsFilter,
      };
    case RESET_SIS_FIELD_FILTER:
      return {
        ...state,
        fieldsFilter: initialState.fieldsFilter,
      };
    case SELECT_SIS_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
      };
    case SELECT_SIS_FORM_CONDITION:
      return {
        ...state,
        searchParams: action.payload.formCondition,
      };
    case SELECT_SIS_CHECKED_KI_LIST:
      return {
        ...state,
        checkedKiList: [...state.checkedKiList, action.payload],
      };
    case SELECT_SIS_CHECKED_TABLE_ALL_KI_LIST:
      return {
        ...state,
        checkedKiList: [...state.checkedKiList, ...action.payload],
      };
    case SELECT_SIS_CHECKED_FAVORITE_ALL_KI_LIST:
      return {
        ...state,
        checkedKiList: action.payload,
      };
    case REMOVE_SIS_CHECKED_KI_LIST:
      return {
        ...state,
        checkedKiList: action.payload,
      };
    case SELECT_SIS_GRAPH_NUMBER_LABEL:
      return {
        ...state,
        showGraphNumberLabel: action.payload.flag,
      };
    case SELECT_SIS_DATE_RANGE:
      return {
        ...state,
        selectedDateRange: action.payload.selectedDateRange,
      };
    case CLEAR_SIS_CHECKED_KI_LIST:
      return {
        ...state,
        checkedKiList: initialState.checkedKiList,
      };
    case CLEAR_SIS_KI_LIST_FILTER:
      return {
        ...state,
        kiListFilter: initialState.kiListFilter,
      };
    case CLEAR_ALL_SIS:
      return initialState;
    case CHANGE_SIS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favoriteId,
      };
    case SELECT_SIS_KI_TAG_LIST:
      return {
        ...state,
        kiTagList: action.payload.kiTagList,
      };
    case SELECT_SIS_SHOW_KI_TAG_LABEL:
      return {
        ...state,
        showKiTagLabel: action.payload.showKiTagLabel,
      };
    default:
      return state;
  }
}
