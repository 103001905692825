import { createSelector } from 'reselect';

import type {
  DailyComment,
  DailyCommentsResponse,
  DataHallDailyComments,
} from '../../domain/dataHallDailyComments';
import { Hall } from '../../domain/schemas';
import { User } from '../../domain/user';

import { RootState } from '../../store';
import { settingsOptionsChainStoreSearchConditionSelector } from './settingsOptionsChainStore';

// Actions
const FETCH_DATA_HALL_DAILY_COMMENTS_REQUEST =
  'FETCH_DATA_HALL_DAILY_COMMENTS_REQUEST' as const;
const FETCH_DATA_HALL_DAILY_COMMENTS_SUCCESS =
  'FETCH_DATA_HALL_DAILY_COMMENTS_SUCCESS' as const;
const POST_DATA_HALL_DAILY_COMMENTS_REQUEST =
  'POST_DATA_HALL_DAILY_COMMENTS_REQUEST' as const;
const PUT_DATA_HALL_DAILY_COMMENTS_REQUEST =
  'PUT_DATA_HALL_DAILY_COMMENTS_REQUEST' as const;
const PUT_DATA_HALL_DAILY_COMMENTS_SUCCESS =
  'PUT_DATA_HALL_DAILY_COMMENTS_SUCCESS' as const;
const DELETE_DATA_HALL_DAILY_COMMENTS_REQUEST =
  'DELETE_DATA_HALL_DAILY_COMMENTS_REQUEST' as const;
const DELETE_DATA_HALL_DAILY_COMMENTS_SUCCESS =
  'DELETE_DATA_HALL_DAILY_COMMENTS_SUCCESS' as const;
const RENEW_DATA_HALL_DAILY_COMMENTS =
  'RENEW_DATA_HALL_DAILY_COMMENTS' as const;
const CLEAR_DATA_HALL_DAILY_COMMENTS =
  'CLEAR_DATA_HALL_DAILY_COMMENTS' as const;

export const DataHallDailyCommentsActionTypes = {
  FETCH_DATA_HALL_DAILY_COMMENTS_REQUEST,
  FETCH_DATA_HALL_DAILY_COMMENTS_SUCCESS,
  POST_DATA_HALL_DAILY_COMMENTS_REQUEST,
  PUT_DATA_HALL_DAILY_COMMENTS_REQUEST,
  PUT_DATA_HALL_DAILY_COMMENTS_SUCCESS,
  DELETE_DATA_HALL_DAILY_COMMENTS_REQUEST,
  DELETE_DATA_HALL_DAILY_COMMENTS_SUCCESS,
  RENEW_DATA_HALL_DAILY_COMMENTS,
  CLEAR_DATA_HALL_DAILY_COMMENTS,
};

//
const fetchDataHallDailyCommentsRequestAction = (date: string) => ({
  type: FETCH_DATA_HALL_DAILY_COMMENTS_REQUEST,
  payload: { date },
});

const fetchDataHallDailyCommentsSuccessAction = (response: {
  data: DailyCommentsResponse;
  date: string;
}) => ({
  type: FETCH_DATA_HALL_DAILY_COMMENTS_SUCCESS,
  payload: response,
});

export const postDataHallDailyCommentsRequestAction = (
  comment: DailyComment
) => ({
  type: POST_DATA_HALL_DAILY_COMMENTS_REQUEST,
  payload: comment,
});

export const putDataHallDailyCommentsRequestAction = (
  comment: DailyComment
) => ({
  type: PUT_DATA_HALL_DAILY_COMMENTS_REQUEST,
  payload: comment,
});
const putDataHallDailyCommentsSuccessAction = (comment: DailyComment) => ({
  type: PUT_DATA_HALL_DAILY_COMMENTS_SUCCESS,
  payload: comment,
});

export const deleteDataHallDailyCommentsRequestAction = (
  id: string,
  date: string,
  hallCode: string
) => ({
  type: DELETE_DATA_HALL_DAILY_COMMENTS_REQUEST,
  payload: { id, date, hallCode },
});

const deleteDataHallDailyCommentsSuccessAction = (
  id: string,
  date: string,
  hallCode: string
) => ({
  type: DELETE_DATA_HALL_DAILY_COMMENTS_SUCCESS,
  payload: { id, date, hallCode },
});

const renewDataHallDailyCommentsAction = () => ({
  type: RENEW_DATA_HALL_DAILY_COMMENTS,
});

const clearDataHallDailyCommentsAction = (date: string) => ({
  type: CLEAR_DATA_HALL_DAILY_COMMENTS,
  payload: { date },
});

export const DataHallDailyCommentsActionCreators = {
  fetchDataHallDailyCommentsRequestAction,
  fetchDataHallDailyCommentsSuccessAction,

  postDataHallDailyCommentsRequestAction,

  putDataHallDailyCommentsRequestAction,
  putDataHallDailyCommentsSuccessAction,

  deleteDataHallDailyCommentsRequestAction,
  deleteDataHallDailyCommentsSuccessAction,

  renewDataHallDailyCommentsAction,
  clearDataHallDailyCommentsAction,
};

export type FetchDataHallDailyCommentsRequestAction = ReturnType<
  typeof fetchDataHallDailyCommentsRequestAction
>;
export type PostDataHallDailyCommentsRequestAction = ReturnType<
  typeof postDataHallDailyCommentsRequestAction
>;
export type PutDataHallDailyCommentsRequestAction = ReturnType<
  typeof putDataHallDailyCommentsRequestAction
>;
export type DeleteDataHallDailyCommentsRequestAction = ReturnType<
  typeof deleteDataHallDailyCommentsRequestAction
>;
type DataHallDailyCommentsAction =
  | FetchDataHallDailyCommentsRequestAction
  | ReturnType<typeof fetchDataHallDailyCommentsSuccessAction>
  | PostDataHallDailyCommentsRequestAction
  | PutDataHallDailyCommentsRequestAction
  | ReturnType<typeof putDataHallDailyCommentsSuccessAction>
  | DeleteDataHallDailyCommentsRequestAction
  | ReturnType<typeof deleteDataHallDailyCommentsSuccessAction>
  | ReturnType<typeof renewDataHallDailyCommentsAction>
  | ReturnType<typeof clearDataHallDailyCommentsAction>;

// Selectors

const dataHallDailyCommentsSelector = (state: RootState) =>
  state.dataHallDailyComments.data;

const dataHallDailyCommentsIsLoadingAllSelector = (state: RootState) =>
  state.dataHallDailyComments.isLoading;

export const dataHallDailyCommentsIsLoadingSelector = (date: string) =>
  createSelector(
    [dataHallDailyCommentsIsLoadingAllSelector],
    (isLoadingAll) => {
      if (isLoadingAll[date] == null) {
        return false;
      }
      return isLoadingAll[date];
    }
  );

export const dataHallDailyCommentsByDateSelector = (date: string | undefined) =>
  createSelector(
    [
      dataHallDailyCommentsSelector,
      settingsOptionsChainStoreSearchConditionSelector,
    ],
    (
      dailyComments,
      { halls }
    ):
      | {
          [hallCode: string]: DailyComment[];
        }
      | undefined => {
      if (!date || !dailyComments || !dailyComments[date]) {
        return undefined;
      }

      const hallsComments = dailyComments[date];

      return Object.keys(hallsComments).reduce((accum, hallCode) => {
        const dailyComments = hallsComments[hallCode];
        const comments = dailyComments.map((x: DailyComment) => ({
          ...x,
          hallCode: x.hall,
          hall: halls.find(({ code }: Hall) => x.hall === code)?.name,
        }));
        return {
          ...accum,
          [hallCode]: comments,
        };
      }, {});
    }
  );

export const dataHallDailyCommentsByDateAndHallSelector = (
  date: string | undefined,
  hall: Hall | undefined,
  user: User | undefined
) =>
  createSelector(
    dataHallDailyCommentsSelector,
    (dailyComments): DailyComment | undefined => {
      if (!date || !hall || !user || !dailyComments) {
        return undefined;
      }

      const dailyComment = dailyComments[date];
      const commentsArray = dailyComment?.[hall.code];

      if (dailyComment && commentsArray && commentsArray.length > 0) {
        const comment = commentsArray[0];
        return {
          ...comment,
          hall: hall.name,
          hallCode: hall.code,
        };
      }

      const defaultComment: DailyComment = {
        userId: user?.userId ?? '',
        author: user?.name ?? '',
        hallCode: hall.code ?? '',
        hall: hall.name ?? '',
        date,
        content: '',
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
      };
      return defaultComment;
    }
  );

// State
type State = DataHallDailyComments & {
  isLoading: { [date: string]: boolean };
  isPostLoading: boolean;
};

const initialState: State = {
  data: {},
  isLoading: {},
  isPostLoading: false,
};

// Reducer
export const dataHallDailyCommentsReducer = (
  state = initialState,
  action: DataHallDailyCommentsAction
) => {
  switch (action.type) {
    case FETCH_DATA_HALL_DAILY_COMMENTS_REQUEST: {
      return {
        ...state,
        isLoading: { [action.payload.date]: true },
      };
    }
    case FETCH_DATA_HALL_DAILY_COMMENTS_SUCCESS: {
      const hallsComments = action.payload.data;

      return {
        ...state,
        ...action.payload,
        data: {
          ...state.data,
          [action.payload.date]: {
            ...state.data[action.payload.date],
            ...hallsComments,
          },
        },
        isLoading: { [action.payload.date]: false },
      };
    }

    case POST_DATA_HALL_DAILY_COMMENTS_REQUEST: {
      return {
        ...state,
        isPostLoading: true,
      };
    }
    case PUT_DATA_HALL_DAILY_COMMENTS_REQUEST: {
      return {
        ...state,
        isPostLoading: true,
      };
    }
    case PUT_DATA_HALL_DAILY_COMMENTS_SUCCESS: {
      const { id, date, hallCode } = action.payload;
      const hallsDailyComments = state.data[date];
      const comments =
        hallsDailyComments && hallsDailyComments[hallCode]
          ? hallsDailyComments[hallCode]
          : [];

      const updatedComments = [
        ...comments.filter((x) => x.id !== id),
        action.payload,
      ];

      return {
        ...state,
        data: {
          ...state.data,
          [date]: {
            ...(state.data[date] ?? {}),
            [hallCode]: updatedComments,
          },
        },
        isPostLoading: false,
      };
    }
    case DELETE_DATA_HALL_DAILY_COMMENTS_REQUEST: {
      return {
        ...state,
        isPostLoading: true,
      };
    }
    case DELETE_DATA_HALL_DAILY_COMMENTS_SUCCESS: {
      const { id, date, hallCode } = action.payload;
      const hallsDailyComments = state.data[date];
      if (!hallsDailyComments || !hallsDailyComments[hallCode]) {
        return state;
      }

      const comments = hallsDailyComments[hallCode];

      return {
        ...state,
        data: {
          ...state.data,
          [date]: {
            ...(state.data[date] ?? {}),
            [hallCode]: comments.filter((x) => x.id !== id),
          },
        },
        isPostLoading: false,
      };
    }
    case RENEW_DATA_HALL_DAILY_COMMENTS: {
      return initialState;
    }
    case CLEAR_DATA_HALL_DAILY_COMMENTS: {
      const { date } = action.payload;
      if (!state.data[date]) {
        return state;
      }

      const { [date]: _, ...rest } = state.data;
      return {
        ...state,
        data: rest,
      };
    }
    default: {
      return state;
    }
  }
};
