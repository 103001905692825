import { DataKiSyokenResponse } from './types';

export const removeKyakuSuKiSyoken = (
  dataKiSyoken: DataKiSyokenResponse
): DataKiSyokenResponse => {
  if (!dataKiSyoken) {
    return dataKiSyoken;
  }
  // ヘッダ下段の商圏店舗を考慮したカラムの配列を作成
  const flatColumns = dataKiSyoken.data.columns.flatMap((column) => {
    if (column.isSyoken === true) {
      return [
        // 商圏全体のカラム
        {
          code: column.code,
          type: column.type,
        },
        // 商圏店舗のカラム
        ...dataKiSyoken.data.hallsOfMarketArea.map((hall) => {
          return {
            code: `${column.code}_${hall.code}`,
            type: column.type,
          };
        }),
      ];
    }
    return {
      code: column.code,
      type: column.type,
    };
  });

  //flatColumnsに"kyakuSu"が一部でも含まれるindexをすべて取得
  const kyakuSuIndex = flatColumns.reduce<number[]>((acc, column, index) => {
    if (column.code && column.code.includes('kyakuSu')) {
      acc.push(index);
    }
    return acc;
  }, []);

  // dataKiSyoken.data.rowsからkyakuSuIndexの場所から"人"の文字列を削除
  const removedDataKiSyoken: DataKiSyokenResponse = {
    setting: dataKiSyoken.setting,
    data: {
      columns: dataKiSyoken.data.columns,
      hallsOfMarketArea: dataKiSyoken.data.hallsOfMarketArea,
      rows: dataKiSyoken.data.rows.map((row) => {
        return {
          ...row,
          data: row.data.map((x, index) => {
            if (kyakuSuIndex.includes(index)) {
              return {
                ...x,
                value: x.value.replace('人', ''),
              };
            }
            return x;
          }),
        };
      }),
    },
  };

  return removedDataKiSyoken;
};
