import { createSelector } from 'reselect';

import {
  DataChainStoreKi,
  DataChainStoreKiParams,
} from '../../domain/dataChainStoreKi';
import { DataChainStoreKi2ndRowSetting } from '../../domain/favorites';

import { getOrderedTableData } from '../../utils/orderedCell';
import { dataChainStoreKiColumnsOrderSelector } from './dataChainStoreKi';

// Action Types

const SEARCH_DATA_CHAIN_STORE_KI_2NDROW =
  'SEARCH_DATA_CHAIN_STORE_KI_2NDROW' as const;

const FETCH_DATA_CHAIN_STORE_KI_2NDROW =
  'FETCH_DATA_CHAIN_STORE_KI_2NDROW' as const;
const FETCH_DATA_CHAIN_STORE_KI_2NDROW_REQUEST =
  'FETCH_DATA_CHAIN_STORE_KI_2NDROW_REQUEST' as const;
const FETCH_DATA_CHAIN_STORE_KI_2NDROW_SUCCESS =
  'FETCH_DATA_CHAIN_STORE_KI_2NDROW_SUCCESS' as const;

const HIDE_DATA_CHAIN_STORE_KI_2NDROW =
  'HIDE_DATA_CHAIN_STORE_KI_2NDROW' as const;
const HIDE_DATA_CHAIN_STORE_KI_2NDROW_BY_SHUCODE =
  'HIDE_DATA_CHAIN_STORE_KI_2NDROW_BY_SHUCODE' as const;
const HIDE_ALL_DATA_CHAIN_STORE_KI_2NDROW =
  'HIDE_ALL_DATA_CHAIN_STORE_KI_2NDROW' as const;
const TOGGLE_DATA_CHAIN_STORE_KI_2NDROW =
  'TOGGLE_DATA_CHAIN_STORE_KI_2NDROW' as const;

const RENEW_DATA_CHAIN_STORE_KI_2NDROW =
  'RENEW_DATA_CHAIN_STORE_KI_2NDROW' as const;

export const DataChainStoreKi2ndRowActionTypes = {
  SEARCH_DATA_CHAIN_STORE_KI_2NDROW,
  FETCH_DATA_CHAIN_STORE_KI_2NDROW,
  FETCH_DATA_CHAIN_STORE_KI_2NDROW_REQUEST,
  FETCH_DATA_CHAIN_STORE_KI_2NDROW_SUCCESS,
  // テーブルセル操作用のAction
  HIDE_DATA_CHAIN_STORE_KI_2NDROW,
  HIDE_DATA_CHAIN_STORE_KI_2NDROW_BY_SHUCODE,
  HIDE_ALL_DATA_CHAIN_STORE_KI_2NDROW,
  TOGGLE_DATA_CHAIN_STORE_KI_2NDROW,
  RENEW_DATA_CHAIN_STORE_KI_2NDROW,
};

// Action Creators

/**
 * 現在の検索条件を元に展開行を取得する
 * @param kiCode 機種コード
 */
function searchDataChainStoreKi2ndRowAction(shuCode: string, kiCode: string) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_KI_2NDROW,
    payload: { shuCode, kiCode },
  };
}

/**
 * 指定した検索条件で展開行データを取得する
 * @param kiCode 機種コード
 * @param params 取得する展開行の検索条件
 */
function fetchDataChainStoreKi2ndRowAction(
  shuCode: string,
  kiCode: string,
  params: DataChainStoreKiParams
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_KI_2NDROW,
    payload: { shuCode, kiCode, params },
  };
}

/**
 * 展開行データ取得前に呼ぶ
 * @param kiCode 機種コード
 * @param params 取得する展開行の検索条件
 */
function fetchDataChainStoreKi2ndRowRequestAction(
  shuCode: string,
  kiCode: string,
  params: DataChainStoreKiParams
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_KI_2NDROW_REQUEST,
    payload: { shuCode, kiCode, params },
  };
}

/**
 * 展開行データ取得成功時、取得したデータを登録する
 * @param kiCode 機種コード
 * @param dataChainStoreKi2ndRow 取得した展開行データ
 */
function fetchDataChainStoreKi2ndRowSuccessAction(
  shuCode: string,
  kiCode: string,
  dataChainStoreKi2ndRow: DataChainStoreKi
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_KI_2NDROW_SUCCESS,
    payload: { shuCode, kiCode, dataChainStoreKi2ndRow },
  };
}

/**
 * kiCode を元に展開行を削除する（検索時やリセット時に呼ぶ）
 * @param kiCode 機種のコード
 */
function hideDataChainStoreKi2ndRowAction(shuCode: string, kiCode: string) {
  return {
    type: HIDE_DATA_CHAIN_STORE_KI_2NDROW,
    payload: { shuCode, kiCode },
  };
}

/**
 * 指定した種別の展開行を初期化する（検索時やリセット時に呼ぶ）
 * @param shuCode 種別コード
 */
function hideDataChainStoreKi2ndRowByShuCodeAction(shuCode: string) {
  return {
    type: HIDE_DATA_CHAIN_STORE_KI_2NDROW_BY_SHUCODE,
    payload: { shuCode },
  };
}

/**
 * すべての展開行を初期化する（検索時やリセット時に呼ぶ）
 */
function hideAllDataChainStoreKi2ndRowAction() {
  return {
    type: HIDE_ALL_DATA_CHAIN_STORE_KI_2NDROW,
  };
}

/**
 * kiCode を元に展開行を表示・非表示する
 * @param kiCode 機種のコード
 */
function toggleDataChainStoreKi2ndRowAction(shuCode: string, kiCode: string) {
  return {
    type: TOGGLE_DATA_CHAIN_STORE_KI_2NDROW,
    payload: { shuCode, kiCode },
  };
}

/**
 * 初期化
 */
function renewDataChainStoreKi2ndRowAction() {
  return {
    type: RENEW_DATA_CHAIN_STORE_KI_2NDROW,
  };
}

export const DataChainStoreKi2ndRowActionCreators = {
  searchDataChainStoreKi2ndRowAction,
  fetchDataChainStoreKi2ndRowAction,
  fetchDataChainStoreKi2ndRowRequestAction,
  fetchDataChainStoreKi2ndRowSuccessAction,
  hideDataChainStoreKi2ndRowAction,
  hideDataChainStoreKi2ndRowByShuCodeAction,
  hideAllDataChainStoreKi2ndRowAction,
  toggleDataChainStoreKi2ndRowAction,
  renewDataChainStoreKi2ndRowAction,
};

// Actions

export type SearchDataChainStoreKi2ndRowAction = ReturnType<
  typeof searchDataChainStoreKi2ndRowAction
>;

export type FetchDataChainStoreKi2ndRowAction = ReturnType<
  typeof fetchDataChainStoreKi2ndRowAction
>;

type HideAllDataChainStoreKi2ndRowAction = ReturnType<
  typeof hideAllDataChainStoreKi2ndRowAction
>;

type HideDataChainStoreKi2ndRowAction = ReturnType<
  typeof hideDataChainStoreKi2ndRowAction
>;

type HideDataChainStoreKi2ndRowByShuCodeAction = ReturnType<
  typeof hideDataChainStoreKi2ndRowByShuCodeAction
>;

export type ToggleDataChainStoreKi2ndRowAction = ReturnType<
  typeof toggleDataChainStoreKi2ndRowAction
>;

type DataChainStoreKi2ndRowAction =
  | SearchDataChainStoreKi2ndRowAction
  | FetchDataChainStoreKi2ndRowAction
  | ReturnType<typeof fetchDataChainStoreKi2ndRowRequestAction>
  | ReturnType<typeof fetchDataChainStoreKi2ndRowSuccessAction>
  | HideAllDataChainStoreKi2ndRowAction
  | HideDataChainStoreKi2ndRowAction
  | HideDataChainStoreKi2ndRowByShuCodeAction
  | ToggleDataChainStoreKi2ndRowAction
  | ReturnType<typeof renewDataChainStoreKi2ndRowAction>;

// State

export type DataChainStoreKiByHall = {
  [hallId: string]: DataChainStoreKi | undefined;
};

type DataChainStoreKi2ndRowState = {
  isLoadingByShuCode?: {
    [shuCode: string]: boolean | undefined;
  };
  dataChainStoreKi2ndRowByShuCode?: {
    [shuCode: string]: DataChainStoreKiByHall | undefined;
  };
};

const initialState: DataChainStoreKi2ndRowState = {
  isLoadingByShuCode: undefined,
  dataChainStoreKi2ndRowByShuCode: undefined,
};

// Selector

function dataChainStoreKiLoading2ndRowSelector(rootState: {
  dataChainStoreKi2ndRow: DataChainStoreKi2ndRowState;
}) {
  return rootState.dataChainStoreKi2ndRow.isLoadingByShuCode;
}

function dataChainStoreKi2ndRowSelector(rootState: {
  dataChainStoreKi2ndRow: DataChainStoreKi2ndRowState;
}) {
  return rootState.dataChainStoreKi2ndRow.dataChainStoreKi2ndRowByShuCode;
}

function dataChainStoreKi2ndRowByShuCodeSelector(shuCode: string) {
  return (rootState: {
    dataChainStoreKi2ndRow: DataChainStoreKi2ndRowState;
  }) => {
    return (
      rootState?.dataChainStoreKi2ndRow?.dataChainStoreKi2ndRowByShuCode &&
      rootState?.dataChainStoreKi2ndRow?.dataChainStoreKi2ndRowByShuCode[
        shuCode
      ]
    );
  };
}

export const dataChainStoreKiOrdered2ndRowSelector = (shuCode: string) =>
  createSelector(
    [
      dataChainStoreKi2ndRowByShuCodeSelector(shuCode),
      dataChainStoreKiColumnsOrderSelector(shuCode),
    ],
    (secondRows, columnOrders) => {
      if (!secondRows) {
        return undefined;
      }
      if (!columnOrders) {
        return secondRows;
      }

      // 店舗展開行では機種名の列に店舗名が入るので、
      // 列の入れ替え時の並べ替えでは kiTushoMeiの代わりに hlMei を使う
      const secondRowColumnOrders = columnOrders.map((x) => {
        if (x === 'kiTushoMei') return 'hlMei';
        return x;
      });

      // ドラッグアンドドロップの結果に沿ってデータを並べ替え
      return Object.keys(secondRows).reduce((accum, kiCode) => {
        const secondRow = secondRows[kiCode];
        if (!secondRow) {
          return accum;
        }

        const data = getOrderedTableData(
          secondRowColumnOrders,
          secondRow.data.columns,
          secondRow.data.rows
        );

        return {
          ...accum,
          [kiCode]: {
            ...secondRow,
            data,
          },
        };
      }, {});
    }
  );

/**
 * kiCode に該当する展開行を取得する
 * @param kiCode 絞り込む kiCode
 * @returns 該当する展開行（該当する展開行がない場合はundefined）
 */
export const singleDataChainStoreKi2ndRowSelector = (
  shuCode: string,
  kiCode: string
) => {
  return createSelector(
    dataChainStoreKi2ndRowByShuCodeSelector(shuCode),
    (dataChainStoreKi2ndRow) => {
      if (dataChainStoreKi2ndRow === undefined) {
        return;
      }

      return dataChainStoreKi2ndRow[kiCode];
    }
  );
};

/**
 * 現在表示中の店舗行から検索条件だけ抽出したデータを取得する
 * お気に入り登録で必要になる
 * @returns 現在表示中の店舗行の検索条件
 */
export const dataChainStoreKi2ndRowSettingsSelector = createSelector(
  dataChainStoreKi2ndRowSelector,
  (data) => {
    if (data === undefined) return {};

    const settings: DataChainStoreKi2ndRowSetting[] = Object.keys(data).reduce(
      (accum: DataChainStoreKi2ndRowSetting[], shuCode: string) => {
        const secondRowsForShuCode = data[shuCode];
        // 空の場合は登録しない
        if (!secondRowsForShuCode) return accum;

        const temp: DataChainStoreKi2ndRowSetting[] = Object.keys(
          secondRowsForShuCode
        ).reduce((accum2: DataChainStoreKi2ndRowSetting[], kiCode: string) => {
          const secondRow = secondRowsForShuCode[kiCode];
          if (!secondRow) {
            return accum2;
          }
          return [
            ...accum2,
            {
              shuCode,
              kiCode,
              params: secondRow.setting,
            },
          ];
        }, []);

        return [...accum, ...temp];
      },
      []
    );

    return settings;
  }
);

/**
 * Loading の展開行があるかどうかを判定する
 * @returns Loading 中の展開行が存在するか
 */
export const dataChainStoreKiIsLoading2ndRowSelector = createSelector(
  dataChainStoreKiLoading2ndRowSelector,
  (isLoadings) => {
    // MEMO: isLoadings が undefind の時はロード中ではない扱いとする
    if (isLoadings === undefined) return false;

    // MEMO: isLoadings の中身が一つでもローディング中の場合はローディング扱いとする
    const isLoading2ndRow =
      isLoadings === undefined
        ? []
        : Object.keys(isLoadings)
            .map((key) => {
              return isLoadings[key];
            })
            .filter((isLoading) => {
              return isLoading === true;
            });
    return isLoading2ndRow.length > 0;
  }
);

export function dataChainStoreKi2ndRowReducer(
  state = initialState,
  action: DataChainStoreKi2ndRowAction
): DataChainStoreKi2ndRowState {
  switch (action.type) {
    case FETCH_DATA_CHAIN_STORE_KI_2NDROW_REQUEST:
      return {
        ...state,
        isLoadingByShuCode: {
          ...state.isLoadingByShuCode,
          [action.payload.shuCode]: true,
        },
      };
    case FETCH_DATA_CHAIN_STORE_KI_2NDROW_SUCCESS:
      return {
        ...state,
        isLoadingByShuCode: {
          ...state.isLoadingByShuCode,
          [action.payload.shuCode]: false,
        },
        dataChainStoreKi2ndRowByShuCode: {
          ...state.dataChainStoreKi2ndRowByShuCode,
          [action.payload.shuCode]: {
            ...(state.dataChainStoreKi2ndRowByShuCode &&
            state.dataChainStoreKi2ndRowByShuCode[action.payload.shuCode]
              ? state.dataChainStoreKi2ndRowByShuCode[action.payload.shuCode]
              : {}),
            [action.payload.kiCode]: action.payload.dataChainStoreKi2ndRow,
          },
        },
      };
    case HIDE_DATA_CHAIN_STORE_KI_2NDROW:
      return {
        ...state,
        dataChainStoreKi2ndRowByShuCode: {
          ...state.dataChainStoreKi2ndRowByShuCode,
          [action.payload.shuCode]: {
            ...(state.dataChainStoreKi2ndRowByShuCode
              ? state.dataChainStoreKi2ndRowByShuCode[action.payload.shuCode]
              : {}),
            [action.payload.kiCode]: undefined,
          },
        },
      };
    case HIDE_DATA_CHAIN_STORE_KI_2NDROW_BY_SHUCODE:
      return {
        ...state,
        dataChainStoreKi2ndRowByShuCode: {
          ...state.dataChainStoreKi2ndRowByShuCode,
          [action.payload.shuCode]: undefined,
        },
      };
    case HIDE_ALL_DATA_CHAIN_STORE_KI_2NDROW:
    case RENEW_DATA_CHAIN_STORE_KI_2NDROW:
      return initialState;
    default:
      return state;
  }
}
