import {
  add,
  eachDayOfInterval,
  endOfISOWeek,
  endOfMonth,
  startOfISOWeek,
  startOfMonth,
} from 'date-fns';

import { dateRangeToStartEnd } from '../../utils/dateRangeToStartEnd';
import {
  KiSyokenDateRangeParams,
  KiSyokenDateUnit,
  KiSyokenSearchParams,
} from './types';

/**
 * [機種別商圏] 検索パラメータに渡す日付データを算出する
 */
export const makeKiSyokenDateFromDateRange = ({
  dateRangeParams,
  searchParams,
  baseDate = new Date(),
}: {
  dateRangeParams: KiSyokenDateRangeParams;
  searchParams: KiSyokenSearchParams;
  baseDate?: Date;
}):
  | {
      startDate: string;
      endDate: string;
      startComparisonDate: string;
      endComparisonDate: string;
    }
  | {
      ymdList: string[];
      ymdComparisonList: string[];
    } => {
  // カスタムの場合、searchParamsの選択されている日付情報をそのまま戻す
  const dateRange = dateRangeParams.dateRange;
  if (dateRange === 'カスタム') {
    if (
      searchParams.startDate != null &&
      searchParams.endDate != null &&
      searchParams.startComparisonDate != null &&
      searchParams.endComparisonDate != null
    ) {
      return {
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        startComparisonDate: searchParams.startComparisonDate,
        endComparisonDate: searchParams.endComparisonDate,
      };
    }
    return {
      ymdList: searchParams.ymdList ?? [],
      ymdComparisonList: searchParams.ymdComparisonList ?? [],
    };
  }

  return makeKiSyokenDateFromDateRangeWithoutCustom({
    dateRangeParams: { ...dateRangeParams, dateRange },
    baseDate,
  });
};

/**
 * [機種別商圏] 検索パラメータに渡す日付データを算出する、ただしカスタムは考慮しない
 */
export const makeKiSyokenDateFromDateRangeWithoutCustom = ({
  dateRangeParams,
  baseDate = new Date(),
}: {
  dateRangeParams: KiSyokenDateRangeParams & {
    dateRange: Exclude<KiSyokenDateRangeParams['dateRange'], 'カスタム'>;
  };
  baseDate?: Date;
}): {
  startDate: string;
  endDate: string;
  startComparisonDate: string;
  endComparisonDate: string;
} => {
  const { startDate, endDate } = dateRangeToStartEnd(
    dateRangeParams.dateRange,
    baseDate
  );

  const comparisonSection = dateRangeParams.comparisonSection;

  if (comparisonSection === 'カスタム') {
    throw new Error(`指定された比較区分 ${comparisonSection} は未実装です`);
  }

  const { startDate: startComparisonDate, endDate: endComparisonDate } =
    dateRangeToStartEnd(comparisonSection, new Date(startDate));

  return {
    startDate: startDate,
    endDate: endDate,
    startComparisonDate,
    endComparisonDate,
  };
};

/**
 * [機種別商圏] 検索パラメータに渡す日付データを期間単位から算出する
 * 期間単位は「日」「週」「月」の3種類のみ対応
 */
export const makeKiSyokenDateFromDateUnit = (
  dateUnit: Exclude<KiSyokenDateUnit, '範囲選択' | '自由選択'>,
  currentDate: Date = new Date()
): {
  ymdList: Date[];
  ymdComparisonList: Date[];
} => {
  let startDate: Date, endDate: Date;
  let startComparisonDate: Date, endComparisonDate: Date;
  switch (dateUnit) {
    case '日': {
      startDate = currentDate;
      endDate = currentDate;
      const lastDay = add(currentDate, { days: -1 });
      startComparisonDate = lastDay;
      endComparisonDate = lastDay;
      break;
    }
    case '週': {
      startDate = startOfISOWeek(currentDate);
      endDate = endOfISOWeek(currentDate);
      const lastWeek = add(currentDate, { days: -7 });
      startComparisonDate = startOfISOWeek(lastWeek);
      endComparisonDate = endOfISOWeek(lastWeek);
      break;
    }
    case '月': {
      startDate = startOfMonth(currentDate);
      endDate = endOfMonth(currentDate);
      const lastMonth = add(currentDate, { months: -1 });
      startComparisonDate = startOfMonth(lastMonth);
      endComparisonDate = endOfMonth(lastMonth);
      break;
    }
  }
  const today = new Date();
  return {
    // 最終日が本日より大きい場合は本日に補正する
    ymdList: eachDayOfInterval({
      start: startDate,
      end: endDate > today ? today : endDate,
    }),
    ymdComparisonList: eachDayOfInterval({
      start: startComparisonDate,
      end: endComparisonDate,
    }),
  };
};
