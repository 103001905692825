import { createSelector } from 'reselect';

import { Column, Item, LoadingState } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';
import {
  DataTransitionAfterIntroduction,
  DataTransitionAfterIntroductionParams,
} from '../../domain/transitionAfterIntroduction/types';

import { filterModelName } from '../../utils/filterModelName';
import { filterTransitionDataByFields } from '../../utils/filterTransitionDataByFields';
import {
  transitionAfterIntroductionCurrentFieldSelector,
  transitionAfterIntroductionReportsModelNameFilterSelector,
  transitionAfterIntroductionReportsSelectedKiListSelector,
  transitionAfterIntroductionReportsSettingIsHeatmapEnabledSelector,
} from '../ui/transitionAfterIntroductionReportsSetting';

// Action Types

const FETCH_DATA_TRANSITION_AFTER_INTRODUCTION =
  'FETCH_DATA_TRANSITION_AFTER_INTRODUCTION' as const;
const FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_REQUEST =
  'FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_REQUEST' as const;
const FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_SUCCESS =
  'FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_SUCCESS' as const;
const RENEW_DATA_TRANSITION_AFTER_INTRODUCTION =
  'RENEW_DATA_TRANSITION_AFTER_INTRODUCTION' as const;

export const DataTransitionAfterIntroductionActionTypes = {
  FETCH_DATA_TRANSITION_AFTER_INTRODUCTION,
  FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_REQUEST,
  FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_SUCCESS,
  RENEW_DATA_TRANSITION_AFTER_INTRODUCTION,
};

// Action Creators

function fetchDataTransitionAfterIntroductionAction(
  shuOption: ShuOption,
  params: DataTransitionAfterIntroductionParams,
  currentHalls?: string[]
) {
  return {
    type: FETCH_DATA_TRANSITION_AFTER_INTRODUCTION,
    payload: { shuOption, params, currentHalls },
  };
}

function fetchDataTransitionAfterIntroductionRequestAction() {
  return {
    type: FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_REQUEST,
  };
}

function fetchDataTransitionAfterIntroductionSuccessAction(
  dataTransitionAfterIntroduction: DataTransitionAfterIntroduction
) {
  return {
    type: FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_SUCCESS,
    payload: { dataTransitionAfterIntroduction },
  };
}

function renewDataTransitionAfterIntroductionAction() {
  return {
    type: RENEW_DATA_TRANSITION_AFTER_INTRODUCTION,
  };
}

export const DataTransitionAfterIntroductionActionCreators = {
  fetchDataTransitionAfterIntroductionAction,
  fetchDataTransitionAfterIntroductionRequestAction,
  fetchDataTransitionAfterIntroductionSuccessAction,
  renewDataTransitionAfterIntroductionAction,
};

// Actions

export type FetchDataTransitionAfterIntroductionAction = ReturnType<
  typeof fetchDataTransitionAfterIntroductionAction
>;

type DataTransitionAfterIntroductionAction =
  | FetchDataTransitionAfterIntroductionAction
  | ReturnType<typeof fetchDataTransitionAfterIntroductionRequestAction>
  | ReturnType<typeof fetchDataTransitionAfterIntroductionSuccessAction>
  | ReturnType<typeof renewDataTransitionAfterIntroductionAction>;

// State

type DataTransitionAfterIntroductionState = {
  loadingState: LoadingState;
  dataTransitionAfterIntroduction?: DataTransitionAfterIntroduction;
};

const initialState: DataTransitionAfterIntroductionState = {
  loadingState: 'prepare',
  dataTransitionAfterIntroduction: undefined,
};

// Selector

export function dataTransitionAfterIntroductionAllSelector(rootState: {
  dataTransitionAfterIntroduction: DataTransitionAfterIntroductionState;
}) {
  return rootState.dataTransitionAfterIntroduction;
}

export const dataTransitionAfterIntroductionSelector = createSelector(
  [
    dataTransitionAfterIntroductionAllSelector,
    transitionAfterIntroductionReportsModelNameFilterSelector,
    transitionAfterIntroductionCurrentFieldSelector,
  ],
  (dataTransitionAfterIntroduction, modelNameFilter, currentFields) => {
    // データ全体を取得
    const fullData =
      dataTransitionAfterIntroduction.dataTransitionAfterIntroduction;

    // 機種名サーチボックスが使用されていない場合
    if (!modelNameFilter) {
      //選択が解除されている際にも全データを返す
      return currentFields[0] === 'all' || currentFields.length === 0
        ? fullData
        : filterTransitionDataByFields(fullData, currentFields);
    }

    // 機種名サーチボックスが使用されている場合
    const predicate = filterModelName(modelNameFilter);
    const dataSource =
      currentFields[0] === 'all' //項目絞込の判定
        ? fullData
        : filterTransitionDataByFields(fullData, currentFields);

    const result = {
      ...dataSource,
      data: {
        ...dataSource?.data,
        rows: dataSource?.data.rows.filter((row) =>
          predicate(row.dataForNonTransition.at(0)?.value ?? '')
        ),
      },
    } as DataTransitionAfterIntroduction;
    return result;
  }
);

export function dataTransitionAfterIntroductionColumnsForTransitionSelector(rootState: {
  dataTransitionAfterIntroduction: DataTransitionAfterIntroductionState;
}) {
  return (
    rootState.dataTransitionAfterIntroduction.dataTransitionAfterIntroduction
      ?.data.columnsForTransition ?? []
  );
}

export function dataTransitionAfterIntroductionColumnsForNonTransitionSelector(rootState: {
  dataTransitionAfterIntroduction: DataTransitionAfterIntroductionState;
}) {
  return (
    rootState.dataTransitionAfterIntroduction.dataTransitionAfterIntroduction
      ?.data.columnsForNonTransition ?? []
  );
}

/**
 * テーブルの行データを取得する
 * @returns テーブルの行データ
 */
const dataTransitionAfterIntroductionRowsSelector = createSelector(
  dataTransitionAfterIntroductionSelector,
  (data) => data?.data.rows
);

/**
 * 機種コードと機種名を機種コードをkeyとしたで取得する
 * @returns KiOptions
 */
export const dataTransitionAfterIntroductionKiOptionsSelector = createSelector(
  dataTransitionAfterIntroductionRowsSelector,
  (data) => {
    const kiOptions =
      data?.map((row) => {
        return [
          row.queryParameter.kiList[0],
          row.dataForNonTransition.at(0)?.value,
        ];
      }) ?? [];

    return Object.fromEntries(kiOptions) as { [key: string]: string };
  }
);

export function dataTransitionAfterIntroductionDataSearchConditionSelector(rootState: {
  dataTransitionAfterIntroduction: DataTransitionAfterIntroductionState;
}) {
  return rootState.dataTransitionAfterIntroduction
    .dataTransitionAfterIntroduction?.setting;
}

export function dataTransitionAfterIntroductionLoadingStateSelector(rootState: {
  dataTransitionAfterIntroduction: DataTransitionAfterIntroductionState;
}) {
  return rootState.dataTransitionAfterIntroduction.loadingState;
}

/**
 * ヒートマップ機能 アウト導入指標のrowに背景色を設定する
 * @param columnsForTransition テーブルの推移項目データ
 * @param rows テーブルの行データ
 * @returns 背景色を設定した行データ
 */
export const addHeatmapBackgroundColorToTargetField = (
  columnsForTransition: Column[],
  rows: DataTransitionAfterIntroduction['data']['rows']
): DataTransitionAfterIntroduction['data']['rows'] => {
  //推移項目から対象項目のインデックスを取得する
  const targetFieldIndex = columnsForTransition.findIndex(
    (x) => x.code === 'outIntroductionIndex' //アウト導入指標
  );
  //推移項目に対象項目が存在しない場合
  if (targetFieldIndex === -1) {
    return rows;
  }

  /**
   * Itemのvalueに数値が存在する場合は条件に応じて背景色を追加する
   **/
  const addBackgroundColor =
    () => (dataForTransition: Item[], dataTransitionFieldIndex: number) => {
      if (dataTransitionFieldIndex !== targetFieldIndex) {
        return dataForTransition;
      }
      return dataForTransition.map((x: Item, i: number) => {
        const val = Number(x.value.replace(/%|,/g, ''));
        //値が非数（ハイフン）の場合はそのまま返す
        if (Number.isNaN(val)) {
          return x;
        }
        // アウト導入指標の背景色の条件
        // https://www.notion.so/UI-0435277e069c4129b6bdf4d4b6e3e4c7?pvs=4#c9a97060dedd4154918c41af8fe0f76e
        const backgroundColor =
          val >= 120
            ? '#a8d3ff'
            : val >= 110
            ? '#d7eefd'
            : val <= 80
            ? '#ffb3b3'
            : val <= 90
            ? '#fddddb'
            : 'inherit';

        return {
          ...x,
          backgroundColor,
        };
      });
    };

  return rows.map((x) => {
    return {
      ...x,
      dataForTransition: x.dataForTransition.map(addBackgroundColor()),
    };
  });
};

const dataTransitionAfterIntroductionHeatmappedSelector = createSelector(
  [
    dataTransitionAfterIntroductionSelector,
    transitionAfterIntroductionReportsSettingIsHeatmapEnabledSelector,
  ],
  (dataTransitionAfterIntroduction, isHeatmapEnabled) => {
    const tableData = dataTransitionAfterIntroduction?.data;

    if (tableData === undefined) {
      return {
        columnsForNonTransition: [],
        columnsForTransition: [],
        dates: [],
        rows: [],
      };
    }

    if (tableData.rows?.length === 0) {
      return tableData;
    }

    const heatmappedRows = isHeatmapEnabled
      ? addHeatmapBackgroundColorToTargetField(
          tableData?.columnsForTransition,
          tableData?.rows
        )
      : tableData.rows;

    return {
      ...tableData,
      rows: heatmappedRows,
    };
  }
);

export const dataTransitionAfterIntroductionFilteredDataSelector =
  createSelector(
    [
      dataTransitionAfterIntroductionHeatmappedSelector,
      transitionAfterIntroductionReportsSelectedKiListSelector,
    ],
    (tableData, kiList) => {
      // 全件指定の場合はそのまま戻す
      if (!kiList || kiList.length === 0) {
        return tableData;
      }

      // フィルターされている場合
      const filteredRows = tableData.rows?.filter((row) =>
        kiList.includes(row.queryParameter.kiList[0])
      );

      return {
        ...tableData,
        rows: filteredRows,
      };
    }
  );

/**
 * 導入後推移の現在表示中のデータの検索条件を取得する
 * @returns 現在の検索条件
 */
export const dataTransitionAfterIntroductionSettingsSelector = createSelector(
  dataTransitionAfterIntroductionSelector,
  (data) => {
    return data?.setting;
  }
);

/**
 * レスポンスの検索条件からエリア一覧を取得する
 */
export const dataTransitionAfterIntroductionSettingAreasSelector =
  createSelector(
    dataTransitionAfterIntroductionSettingsSelector,
    (setting) => setting?.areas
  );

// Reducer

export function dataTransitionAfterIntroductionReducer(
  state = initialState,
  action: DataTransitionAfterIntroductionAction
): DataTransitionAfterIntroductionState {
  switch (action.type) {
    case FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataTransitionAfterIntroduction:
          action.payload.dataTransitionAfterIntroduction,
      };
    case RENEW_DATA_TRANSITION_AFTER_INTRODUCTION:
      return initialState;
    default:
      return state;
  }
}
