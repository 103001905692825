import {
  add,
  differenceInDays,
  endOfISOWeek,
  endOfMonth,
  format,
  startOfISOWeek,
  startOfMonth,
} from 'date-fns';

import { getYmdList } from '../../utils/date';
import { eachDayOfIntervalInTimezone } from '../../utils/eachDayOfIntervalInTimezone';
import { excludeHoliday } from '../../utils/excludeHoliday';
import { excludeWeekday } from '../../utils/excludeWeekday';
import { formatWeekdayAndHoliday } from '../../utils/formatWeekdayAndHoliday';
import { ModelReportsFormConditions } from '../modelReportsFormConditions';
import { kiDateRanges } from './consistent';
import { defaultExcludeToday } from './defaultValues';
import { KiComparativeSection, KiDateRange } from './types';

/**
 * [機種集計] 期間と比較区分から日付リストを算出する
 */
export const makeYmdList = (
  dateRange: KiDateRange,
  comparativeSection: KiComparativeSection,
  options: {
    currentDate?: Date;
    excludeToday: boolean;
  }
) => {
  const { currentDate = new Date(), excludeToday } = options;

  const { startDate, endDate } = makeStartAndEnd(dateRange, { currentDate });

  const { startComparisonDate, endComparisonDate } = makeComparisonStartAndEnd(
    comparativeSection,
    { startDate, endDate }
  );

  // 今週と今月は比較期間の日数を合わせる
  if (dateRange === '今週' || dateRange === '今月') {
    const diff = differenceInDays(endDate, startDate);
    return {
      ymdList: eachDayOfIntervalInTimezone({
        start: startDate,
        end: endDate,
      }),
      ymdComparisonList: eachDayOfIntervalInTimezone({
        start: startComparisonDate,
        end: add(startComparisonDate, {
          // 当日除外されている場合、当日分が計測されないので比較区分の1日分減らす
          // ただし期間が1日の場合は当日が除外されないAPI側の仕様があるので、その場合は比較区分の1日分減らさない
          days: !excludeToday || diff === 0 ? diff : diff - 1,
        }),
      }),
    };
  }

  return {
    ymdList: eachDayOfIntervalInTimezone({
      start: startDate,
      end: endDate,
    }),
    ymdComparisonList: eachDayOfIntervalInTimezone({
      start: startComparisonDate,
      end: endComparisonDate,
    }),
  };
};

/**
 * [機種集計] 期間から開始日と終了日を算出する
 */
const makeStartAndEnd = (
  dateRange: KiDateRange,
  options: {
    currentDate: Date;
  } = { currentDate: new Date() }
): { startDate: Date; endDate: Date } => {
  const { currentDate } = options;

  switch (dateRange) {
    case '前日': {
      const yesterday = add(currentDate, { days: -1 });
      return {
        startDate: yesterday,
        endDate: yesterday,
      };
    }
    case '当日': {
      return {
        startDate: currentDate,
        endDate: currentDate,
      };
    }
    case '前週': {
      const lastWeek = add(currentDate, { weeks: -1 });
      const startDate = startOfISOWeek(lastWeek);
      return {
        startDate,
        endDate: add(startDate, { days: 6 }),
      };
    }
    case '今週': {
      const startDate = startOfISOWeek(currentDate);
      return {
        startDate,
        endDate: currentDate,
      };
    }
    case '前月': {
      const lastMonth = add(currentDate, { months: -1 });
      return {
        startDate: startOfMonth(lastMonth),
        endDate: endOfMonth(lastMonth),
      };
    }
    case '今月': {
      return {
        startDate: startOfMonth(currentDate),
        endDate: currentDate,
      };
    }
    case '過去7日': {
      return {
        startDate: add(currentDate, { days: -7 }),
        endDate: add(currentDate, { days: -1 }),
      };
    }
    case '過去14日': {
      return {
        startDate: add(currentDate, { days: -14 }),
        endDate: add(currentDate, { days: -1 }),
      };
    }
    case '過去28日': {
      return {
        startDate: add(currentDate, { days: -28 }),
        endDate: add(currentDate, { days: -1 }),
      };
    }
    case 'カスタム':
      return {
        startDate: currentDate,
        endDate: currentDate,
      };
  }
};

/**
 * [機種集計] 比較区分から開始日と終了日を算出する
 */
const makeComparisonStartAndEnd = (
  comparativeSection: KiComparativeSection,
  from: { startDate: Date; endDate: Date }
): { startComparisonDate: Date; endComparisonDate: Date } => {
  const { startDate, endDate } = from;

  switch (comparativeSection) {
    case '前日': {
      const yesterday = add(startDate, { days: -1 });
      return {
        startComparisonDate: yesterday,
        endComparisonDate: yesterday,
      };
    }
    case '前週同曜日': {
      const aWeekAgo = add(startDate, { weeks: -1 });
      return {
        startComparisonDate: aWeekAgo,
        endComparisonDate: aWeekAgo,
      };
    }
    case '前週': {
      const lastWeek = add(startDate, { weeks: -1 });
      return {
        startComparisonDate: startOfISOWeek(lastWeek),
        endComparisonDate: endOfISOWeek(lastWeek),
      };
    }
    case '前月': {
      const lastMonth = add(startDate, { months: -1 });
      return {
        startComparisonDate: startOfMonth(lastMonth),
        endComparisonDate: endOfMonth(lastMonth),
      };
    }
    case '前年同月': {
      const lastYear = add(startDate, { years: -1 });
      return {
        startComparisonDate: startOfMonth(lastYear),
        endComparisonDate: endOfMonth(lastYear),
      };
    }
    case '過去7日': {
      return {
        startComparisonDate: add(startDate, { days: -7 }),
        endComparisonDate: add(endDate, { days: -7 }),
      };
    }
    case '過去14日': {
      return {
        startComparisonDate: add(startDate, { days: -14 }),
        endComparisonDate: add(endDate, { days: -14 }),
      };
    }
    case '過去28日': {
      return {
        startComparisonDate: add(startDate, { days: -28 }),
        endComparisonDate: add(endDate, { days: -28 }),
      };
    }
    case '平日/土日祝比較': {
      return {
        startComparisonDate: startDate,
        endComparisonDate: endDate,
      };
    }
    case 'カスタム': {
      const diff = differenceInDays(startDate, endDate) - 1;
      return {
        startComparisonDate: add(startDate, { days: diff }),
        endComparisonDate: add(endDate, { days: diff }),
      };
    }
  }
};

/**
 * 前回選択していた比較区分を考慮して、期間から初期値を取得する
 *
 * 前回選択していた比較区分と同じ選択肢の比較区分を持つ期間を選択したときに、
 * その選択状態を維持させるために利用します
 * 前回の選択状態が不要の場合、kiDateRangesのdefaultComparisonSectionを直接使用してください
 */
export const findComparisonSection = (
  from: KiDateRange,
  prev: KiComparativeSection
) => {
  const dateRange = kiDateRanges.find((item) => item.name === from);

  if (dateRange == null) {
    throw new Error(`dateRange is not found: ${from}`);
  }

  // 型の問題でincludesが使えないのでsomeで代用
  if (dateRange.comparativeSection.some((item) => item === prev)) {
    return prev;
  }

  return dateRange.defaultComparisonSection;
};

/**
 * [機種集計] 選択中の日付情報をもとに検索条件を作成する
 *
 * お気に入りのカスタムの場合、holidaysをつかった計算は不要になるためundefinedを渡してください
 */
const searchConditionToDateRangeParamsIfCustom = (data: {
  comparativeSection: KiComparativeSection | undefined;
  formConditions: ModelReportsFormConditions;
  holidays: string[] | undefined;
}) => {
  const { comparativeSection, formConditions, holidays } = data;

  // holidaysがundefinedでない場合は、ymdListとymdComparisonListをマージしてからそれぞれを再分離させる
  // 平日/土日祝比較の場合は、holidaysはundefinedになりません
  if (comparativeSection === '平日/土日祝比較' && holidays != null) {
    const dateList = Array.from(
      new Set([
        ...(formConditions?.ymdList ?? []),
        ...(formConditions?.ymdComparisonList ?? []),
      ])
    )
      .sort((a, b) => (a > b ? 1 : -1))
      .map((v) => new Date(v));

    return formatWeekdayAndHoliday({
      ymdList: excludeHoliday(dateList, holidays).map((v) =>
        format(v, 'yyyy-MM-dd')
      ),
      ymdComparisonList: excludeWeekday(dateList, holidays).map((v) =>
        format(v, 'yyyy-MM-dd')
      ),
    });
  }

  if (
    formConditions.ymdList != null &&
    formConditions.ymdComparisonList != null
  ) {
    return {
      ymdList: formConditions.ymdList,
      ymdComparisonList: formConditions.ymdComparisonList,
    };
  }

  if (
    formConditions.startDate != null &&
    formConditions.endDate != null &&
    formConditions.startComparisonDate != null &&
    formConditions.endComparisonDate != null
  ) {
    return {
      ymdList: getYmdList(formConditions.startDate, formConditions.endDate),
      ymdComparisonList: getYmdList(
        formConditions.startComparisonDate,
        formConditions.endComparisonDate
      ),
    };
  }

  return {
    ymdList: undefined,
    ymdComparisonList: undefined,
  };
};

/**
 * [機種集計] 選択中の日付情報をもとに検索条件を作成する
 *
 * comparativeSectionは途中で実装されたので、存在しないundefinedの場合もあります
 */
export const searchConditionToDateRangeParams = (
  dateRange: KiDateRange,
  comparativeSection: KiComparativeSection | undefined,
  formConditions: ModelReportsFormConditions,
  options: {
    holidays: string[] | undefined; // undefinedの場合はお気に入りかつカスタムの場合のみ
  }
): {
  ymdList?: string[] | undefined;
  ymdComparisonList?: string[] | undefined;
  startDate?: string | null;
  endDate?: string | null;
} => {
  if (dateRange === 'カスタム') {
    return searchConditionToDateRangeParamsIfCustom({
      comparativeSection,
      formConditions,
      holidays: options.holidays,
    });
  }

  const range = makeYmdList(
    dateRange,
    comparativeSection ??
      kiDateRanges.find(({ name }) => name === dateRange)
        ?.defaultComparisonSection ??
      'カスタム',
    {
      excludeToday: formConditions.excludeToday ?? defaultExcludeToday,
    }
  );

  if (comparativeSection === '平日/土日祝比較') {
    if (options.holidays == null) {
      // お気に入りのカスタム以外の平日/土日祝比較の場合、必ず祝日情報が必須になる
      throw new Error('祝日情報がありません');
    }
    return formatWeekdayAndHoliday({
      ymdList: excludeHoliday(range.ymdList, options.holidays).map((v) =>
        format(v, 'yyyy-MM-dd')
      ),
      ymdComparisonList: excludeWeekday(range.ymdList, options.holidays).map(
        (v) => format(v, 'yyyy-MM-dd')
      ),
    });
  }

  return {
    ymdList: range.ymdList.map((date) => format(date, 'yyyy-MM-dd')),
    ymdComparisonList: range.ymdComparisonList.map((date) =>
      format(date, 'yyyy-MM-dd')
    ),
  };
};
