import { createSelector } from 'reselect';

import {
  DaiCostsField,
  DaiCostsSearchParams,
} from '../../domain/daiCosts/types';
import { OrderType } from '../../domain/schemas';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_DAI_COSTS_SETTING = 'INIT_DAI_COSTS_SETTING' as const;
const SEARCH_DAI_COSTS_SETTING = 'SEARCH_DAI_COSTS_SETTING' as const;
const RENEW_DAI_COSTS_SETTING = 'RENEW_DAI_COSTS_SETTING' as const;
const SEARCH_DAI_COSTS_SETTING_SORT = 'SEARCH_DAI_COSTS_SETTING_SORT' as const;
const TRIGGER_DAI_COSTS_SETTING_SORT =
  'TRIGGER_DAI_COSTS_SETTING_SORT' as const;
const CHANGE_DAI_COSTS_SETTING_SORT_AND_ORDER =
  'CHANGE_DAI_COSTS_SETTING_SORT_AND_ORDER' as const;
const SELECT_DAI_COSTS_SETTING_NAME_FILTER =
  'SELECT_DAI_COSTS_SETTING_NAME_FILTER' as const;
const SAVE_DAI_COSTS_SETTING_END_DATE =
  'SAVE_DAI_COSTS_SETTING_END_DATE' as const;

export const DaiCostsSettingActionTypes = {
  INIT_DAI_COSTS_SETTING,
  SEARCH_DAI_COSTS_SETTING,
  RENEW_DAI_COSTS_SETTING,
  SEARCH_DAI_COSTS_SETTING_SORT,
  TRIGGER_DAI_COSTS_SETTING_SORT,
  CHANGE_DAI_COSTS_SETTING_SORT_AND_ORDER,
  SELECT_DAI_COSTS_SETTING_NAME_FILTER,
  SAVE_DAI_COSTS_SETTING_END_DATE,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 初回リクエスト
 */
function initDaiCostsSettingAction() {
  return {
    type: INIT_DAI_COSTS_SETTING,
  };
}

/**
 * 検索ボタンの押下
 */
function searchDaiCostsSettingAction(searchParams: DaiCostsSearchParams) {
  return {
    type: SEARCH_DAI_COSTS_SETTING,
    payload: { searchParams },
  };
}

function renewDaiCostsSettingAction() {
  return {
    type: RENEW_DAI_COSTS_SETTING,
  };
}

/**
 * 表示項目にあるソートボタン押下
 */
function triggerDaiCostsSettingSortAction(sort: DaiCostsField) {
  return {
    type: TRIGGER_DAI_COSTS_SETTING_SORT,
    payload: { sort },
  };
}

/**
 * sortとorderを変更して検索を実行する
 *
 * 直接Reactのコンポーネントから実行せず、Saga経由で実行する
 * Reactから呼び出す場合にはtriggerDaiCostsSettingSortActionを利用する
 */
function changeDaiCostsSettingSortAndOrderAction(
  sort: DaiCostsField,
  order: OrderType
) {
  return {
    type: CHANGE_DAI_COSTS_SETTING_SORT_AND_ORDER,
    payload: { sort, order },
  };
}

/**
 * 機種名フィルタを変更
 */
function selectDaiCostsSettingNameFilterAction(name: string) {
  return {
    type: SELECT_DAI_COSTS_SETTING_NAME_FILTER,
    payload: { name },
  };
}

/**
 * 選択可能な最大未来日を保存
 */
function saveDaiCostsSettingEndDateAction(endDate: string) {
  return {
    type: SAVE_DAI_COSTS_SETTING_END_DATE,
    payload: { endDate },
  };
}

export const DaiCostsSettingActionCreators = {
  initDaiCostsSettingAction,
  searchDaiCostsSettingAction,
  renewDaiCostsSettingAction,
  triggerDaiCostsSettingSortAction,
  changeDaiCostsSettingSortAndOrderAction,
  selectDaiCostsSettingNameFilterAction,
  saveDaiCostsSettingEndDateAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type InitSettingDaiCostsAction = ReturnType<typeof initDaiCostsSettingAction>;
export type SearchSettingDaiCostsAction = ReturnType<
  typeof searchDaiCostsSettingAction
>;

type RenewDaiCostsSettingAction = ReturnType<typeof renewDaiCostsSettingAction>;

export type TriggerDaiCostsSettingSortAction = ReturnType<
  typeof triggerDaiCostsSettingSortAction
>;

export type ChangeDaiCostsSettingSortAndOrderAction = ReturnType<
  typeof changeDaiCostsSettingSortAndOrderAction
>;

type SelectDaiCostsSettingNameFilterAction = ReturnType<
  typeof selectDaiCostsSettingNameFilterAction
>;

type SaveDaiCostsSettingEndDateAction = ReturnType<
  typeof saveDaiCostsSettingEndDateAction
>;

type DaiCostsSettingAction =
  | InitSettingDaiCostsAction
  | SearchSettingDaiCostsAction
  | RenewDaiCostsSettingAction
  | TriggerDaiCostsSettingSortAction
  | ChangeDaiCostsSettingSortAndOrderAction
  | SelectDaiCostsSettingNameFilterAction
  | SaveDaiCostsSettingEndDateAction;

/* ---------------------------------------------------------------
 * State
 */

export type DaiCostsSettingState = {
  searchParams: DaiCostsSearchParams | undefined;
  /**
   * 機種名フィルタ
   */
  nameFilter: string | undefined;
  /**
   * 選択可能な最大未来日
   */
  selectableEndDate: string | undefined;
};

const initialState: DaiCostsSettingState = {
  searchParams: undefined,
  nameFilter: undefined,
  selectableEndDate: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */
/**
 * [機械代金登録] 設定情報を取得する
 */
function daiCostsSettingSelector(rootState: {
  daiCostsSetting: DaiCostsSettingState;
}) {
  return rootState.daiCostsSetting;
}

/**
 * [機械代金登録] 選択されている検索条件を取得する
 */
export const daiCostsSettingSearchParamsSelector = createSelector(
  daiCostsSettingSelector,
  (daiCostsSetting) => daiCostsSetting.searchParams
);

/**
 * [機械代金登録] 選択可能な最大未来日を取得する
 */
export const daiCostsSelectableEndDateSelector = createSelector(
  daiCostsSettingSelector,
  (daiCostsSetting) => daiCostsSetting.selectableEndDate
);

/**
 * 機械代金登録 機種名フィルターされている機種名を取得する
 */
export const daiCostsSettingNameFilterSelector = createSelector(
  daiCostsSettingSelector,
  (daiCostsSetting) => daiCostsSetting.nameFilter
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function daiCostsSettingReducer(
  state = initialState,
  action: DaiCostsSettingAction
): DaiCostsSettingState {
  switch (action.type) {
    case SEARCH_DAI_COSTS_SETTING:
      return {
        ...state,
        searchParams: action.payload.searchParams,
      };
    case RENEW_DAI_COSTS_SETTING:
      return initialState;
    case CHANGE_DAI_COSTS_SETTING_SORT_AND_ORDER:
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          sort: action.payload.sort,
          order: action.payload.order,
        },
      };
    case SELECT_DAI_COSTS_SETTING_NAME_FILTER:
      return {
        ...state,
        nameFilter: action.payload.name,
      };
    case SAVE_DAI_COSTS_SETTING_END_DATE:
      return {
        ...state,
        selectableEndDate: action.payload.endDate,
      };
    default:
      return state;
  }
}
