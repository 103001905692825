import { format } from 'date-fns';
import { createSelector } from 'reselect';

import {
  DataSettingsUnitModelAll,
  DataSettingsUnitModelDataByHallAndDate,
  DataSettingsUnitModelDataById,
  PatchUnitModelBody,
  UnitModelSetting,
  UnitModelSettingData,
} from '../../domain/dataSettingsUnitModel';
import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';

// Action Types

const FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_REQUEST =
  'FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_REQUEST' as const;
const FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_SUCCESS =
  'FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_SUCCESS' as const;
const RENEW_DATA_SETTINGS_UNIT_MODEL_LIST_ALL =
  'RENEW_DATA_SETTINGS_UNIT_MODEL_LIST_ALL' as const;
const FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST =
  'FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST' as const;
const FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS =
  'FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS' as const;
const FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE =
  'FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE' as const;
const POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST =
  'POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST' as const;
const POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS =
  'POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS' as const;
const POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE =
  'POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE' as const;
const FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST =
  'FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST' as const;
const FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS =
  'FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS' as const;
const FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE =
  'FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE' as const;
const PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST =
  'PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST' as const;
const PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS =
  'PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS' as const;
const PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE =
  'PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE' as const;
const PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_REQUEST =
  'PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_REQUEST' as const;
const PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_SUCCESS =
  'PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_SUCCESS' as const;
const PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_FAILURE =
  'PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_FAILURE' as const;
const RESET_DATA_SETTINGS_UNIT_MODEL =
  'RESET_DATA_SETTINGS_UNIT_MODEL' as const;

export const DataSettingsUnitModelActionTypes = {
  FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_REQUEST,
  FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_SUCCESS,
  RENEW_DATA_SETTINGS_UNIT_MODEL_LIST_ALL,
  FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST,
  FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS,
  FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE,
  POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST,
  POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS,
  POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE,
  FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST,
  FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS,
  FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE,
  PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST,
  PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS,
  PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE,
  PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_REQUEST,
  PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_SUCCESS,
  PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_FAILURE,
  RESET_DATA_SETTINGS_UNIT_MODEL,
};

// Action Creators
function fetchDataSettingsUnitModelListAllRequestAction(
  startDate: string,
  endDate: string,
  hallCode: string | null
) {
  return {
    type: FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_REQUEST,
    payload: {
      startDate,
      endDate,
      hall: hallCode === 'none' ? null : hallCode,
    },
  };
}

function fetchDataSettingsUnitModelListAllSuccessAction(
  data: DataSettingsUnitModelAll
) {
  return {
    type: FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_SUCCESS,
    payload: { data },
  };
}

function renewDataSettingsUnitModelListAllAction() {
  return {
    type: RENEW_DATA_SETTINGS_UNIT_MODEL_LIST_ALL,
  };
}

function fetchDataSettingsUnitModelDataByHallCodeAndDateRequestAction(
  hallCode: string,
  openingDate: Date
) {
  return {
    type: FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST,
    payload: {
      openingDate: format(openingDate, 'yyyy-MM-dd'),
      hallCode,
    },
  };
}

function fetchDataSettingsUnitModelDataByHallCodeAndDateSuccessAction(data: {
  hallCode: string;
  daiKisyuList: UnitModelSettingData[] | null; // 有効・無効の最大2件しかない
  latestEnabledDaiKisyu: UnitModelSettingData | null; // 入替日の1つ前の有効な台番機種設定
}) {
  return {
    type: FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS,
    payload: { data },
  };
}

function fetchDataSettingsUnitModelDataByHallCodeAndDateFailureAction(
  error: ClarisApiError
) {
  return {
    type: FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE,
    payload: { error },
  };
}

function resetDataSettingsUnitModelDataAction() {
  return {
    type: RESET_DATA_SETTINGS_UNIT_MODEL,
  };
}

function postDataSettingsUnitModelDataByHallCodeAndDateRequestAction(
  hallCode: string,
  openingDate: Date,
  data: UnitModelSetting[]
) {
  return {
    type: POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST,
    payload: {
      hallCode,
      openingDate: format(openingDate, 'yyyy-MM-dd'),
      data,
    },
  };
}

function postDataSettingsUnitModelDataByHallCodeAndDateSuccessAction() {
  return {
    type: POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS,
  };
}

function postDataSettingsUnitModelDataByHallCodeAndDateFailureAction(
  error: ClarisApiError
) {
  return {
    type: POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE,
    payload: {
      error,
    },
  };
}

function fetchDataSettingsUnitModelDataByIdRequestAction(id: string) {
  return {
    type: FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST,
    payload: {
      id,
    },
  };
}

function fetchDataSettingsUnitModelDataByIdSuccessAction(
  data: DataSettingsUnitModelDataById
) {
  return {
    type: FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS,
    payload: {
      data,
    },
  };
}

function fetchDataSettingsUnitModelDataByIdFailureAction(
  error: ClarisApiError
) {
  return {
    type: FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE,
    payload: {
      error,
    },
  };
}

function patchDataSettingsUnitModelDataByIdRequestAction(
  id: string,
  body: PatchUnitModelBody
) {
  return {
    type: PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST,
    payload: { id, body },
  };
}

function patchDataSettingsUnitModelDataByIdSuccessAction() {
  return {
    type: PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS,
  };
}

function patchDataSettingsUnitModelDataByIdFailureAction(
  error: ClarisApiError
) {
  return {
    type: PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE,
    payload: {
      error,
    },
  };
}

function patchDataSettingsUnitModelIsEnabledRequestAction(
  id: string,
  isEnabled: boolean
) {
  return {
    type: PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_REQUEST,
    payload: {
      id,
      body: { isEnabled },
    },
  };
}
function patchDataSettingsUnitModelIsEnabledSuccessAction() {
  return {
    type: PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_SUCCESS,
  };
}
function patchDataSettingsUnitModelIsEnabledFailureAction(
  error: ClarisApiError
) {
  return {
    type: PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_FAILURE,
    payload: {
      error,
    },
  };
}

export const DataSettingsUnitModelActionCreators = {
  fetchDataSettingsUnitModelListAllRequestAction,
  fetchDataSettingsUnitModelListAllSuccessAction,
  renewDataSettingsUnitModelListAllAction,
  fetchDataSettingsUnitModelDataByHallCodeAndDateRequestAction,
  fetchDataSettingsUnitModelDataByHallCodeAndDateSuccessAction,
  fetchDataSettingsUnitModelDataByHallCodeAndDateFailureAction,
  postDataSettingsUnitModelDataByHallCodeAndDateRequestAction,
  postDataSettingsUnitModelDataByHallCodeAndDateSuccessAction,
  postDataSettingsUnitModelDataByHallCodeAndDateFailureAction,
  fetchDataSettingsUnitModelDataByIdRequestAction,
  fetchDataSettingsUnitModelDataByIdSuccessAction,
  fetchDataSettingsUnitModelDataByIdFailureAction,
  patchDataSettingsUnitModelDataByIdRequestAction,
  patchDataSettingsUnitModelDataByIdSuccessAction,
  patchDataSettingsUnitModelDataByIdFailureAction,
  patchDataSettingsUnitModelIsEnabledRequestAction,
  patchDataSettingsUnitModelIsEnabledSuccessAction,
  patchDataSettingsUnitModelIsEnabledFailureAction,
  resetDataSettingsUnitModelDataAction,
};

// Actions
//
export type FetchDataSettingsUnitModelListAllRequest = ReturnType<
  typeof fetchDataSettingsUnitModelListAllRequestAction
>;
export type FetchDataSettingsUnitModelDataByHallCodeAndDateRequest = ReturnType<
  typeof fetchDataSettingsUnitModelDataByHallCodeAndDateRequestAction
>;
export type PostDataSettingsUnitModelDataByHallCodeAndDateRequest = ReturnType<
  typeof postDataSettingsUnitModelDataByHallCodeAndDateRequestAction
>;
export type FetchDataSettingsUnitModelDataByIdRequest = ReturnType<
  typeof fetchDataSettingsUnitModelDataByIdRequestAction
>;
export type PatchDataSettingsUnitModelDataByIdRequest = ReturnType<
  typeof patchDataSettingsUnitModelDataByIdRequestAction
>;
type PatchDataSettingsUnitModelIsEnabledRequest = ReturnType<
  typeof patchDataSettingsUnitModelIsEnabledRequestAction
>;

type DataSettingsUnitModelAction =
  | FetchDataSettingsUnitModelListAllRequest
  | ReturnType<typeof fetchDataSettingsUnitModelListAllSuccessAction>
  | ReturnType<typeof renewDataSettingsUnitModelListAllAction>
  | FetchDataSettingsUnitModelDataByHallCodeAndDateRequest
  | ReturnType<
      typeof fetchDataSettingsUnitModelDataByHallCodeAndDateSuccessAction
    >
  | ReturnType<
      typeof fetchDataSettingsUnitModelDataByHallCodeAndDateFailureAction
    >
  | ReturnType<typeof resetDataSettingsUnitModelDataAction>
  | PostDataSettingsUnitModelDataByHallCodeAndDateRequest
  | ReturnType<
      typeof postDataSettingsUnitModelDataByHallCodeAndDateSuccessAction
    >
  | ReturnType<
      typeof postDataSettingsUnitModelDataByHallCodeAndDateFailureAction
    >
  | FetchDataSettingsUnitModelDataByIdRequest
  | ReturnType<typeof fetchDataSettingsUnitModelDataByIdSuccessAction>
  | ReturnType<typeof fetchDataSettingsUnitModelDataByIdFailureAction>
  | PatchDataSettingsUnitModelDataByIdRequest
  | ReturnType<typeof patchDataSettingsUnitModelDataByIdSuccessAction>
  | ReturnType<typeof patchDataSettingsUnitModelDataByIdFailureAction>
  | PatchDataSettingsUnitModelIsEnabledRequest
  | ReturnType<typeof patchDataSettingsUnitModelIsEnabledSuccessAction>
  | ReturnType<typeof patchDataSettingsUnitModelIsEnabledFailureAction>;

// State
//
type DataSettingsUnitModelState = {
  loadingState: LoadingState;
  error: ClarisApiError | null;
  // ある期間の台番機種設定一覧
  listAll: DataSettingsUnitModelAll | null;
  // 特定のホール・特定の日付のデータ
  dataByHallCodeAndDate: DataSettingsUnitModelDataByHallAndDate;
  // listAllの中の1件に含まれる台番機種設定
  dataById: DataSettingsUnitModelDataById | null;
};

const initialState: DataSettingsUnitModelState = {
  loadingState: 'prepare',
  error: null,
  listAll: null,
  dataByHallCodeAndDate: {
    hallCode: '',
    daiKisyuList: null,
    latestEnabledDaiKisyu: null,
  },
  dataById: null,
};

// Selector

/**
 * whole state selector
 */
function settingsUnitModelSelector(rootState: {
  dataSettingsUnitModel: DataSettingsUnitModelState;
}) {
  return rootState.dataSettingsUnitModel;
}

/**
 * ローディング状態の取得
 */
export const dataSettingsUnitModelLoadingStateSelector = createSelector(
  settingsUnitModelSelector,
  ({ loadingState }) => loadingState
);

/**
 * エラーの取得
 */
export const dataSettingsUnitModelErrorSelector = createSelector(
  settingsUnitModelSelector,
  ({ error }) => error
);

/**
 * テーブル一覧データの取得
 */
export const dataSettingsUnitModelAllListDataSelector = createSelector(
  settingsUnitModelSelector,
  ({ listAll }) => listAll?.data ?? null
);

/**
 * 一覧画面に表示するメッセージの取得
 */
export const dataSettingsUnitModelMessagesSelector = createSelector(
  settingsUnitModelSelector,
  ({ listAll }) => (listAll?.messages ? listAll?.messages : null)
);

/**
 * 一覧画面に表示する契約期間の取得
 */
export const dataSettingsUnitModelContractPeriodSelector = createSelector(
  settingsUnitModelSelector,
  ({ listAll }) =>
    listAll?.setting.hall && listAll?.contractPeriod
      ? listAll?.contractPeriod
      : { startDate: null, endDate: null }
);

/**
 * ホール番号と日付で取得した台番機種データの取得
 */
export const dataSettingsUnitModelDataByHallCodeAndDateSelector =
  createSelector(
    settingsUnitModelSelector,
    ({ dataByHallCodeAndDate }) => dataByHallCodeAndDate
  );

/**
 * 台番機種設定IDで取得したデータの取得
 */
export const dataSettingsUnitModelDataByIdSelector = createSelector(
  settingsUnitModelSelector,
  ({ dataById }) => dataById
);

// Reducer

export function dataSettingsUnitModelReducer(
  state = initialState,
  action: DataSettingsUnitModelAction
): DataSettingsUnitModelState {
  switch (action.type) {
    case FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_REQUEST: {
      return {
        ...state,
        dataByHallCodeAndDate: initialState.dataByHallCodeAndDate,
        loadingState: 'loading',
        error: initialState.error,
      };
    }
    case FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_SUCCESS: {
      return {
        ...state,
        dataByHallCodeAndDate: initialState.dataByHallCodeAndDate,
        loadingState: 'loaded',
        listAll: action.payload.data,
      };
    }
    case FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST: {
      return {
        ...state,
        dataByHallCodeAndDate: initialState.dataByHallCodeAndDate,
        loadingState: 'loading',
        error: initialState.error,
      };
    }
    case FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS: {
      return {
        ...state,
        loadingState: 'loaded',
        dataByHallCodeAndDate: action.payload.data,
      };
    }
    case FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE: {
      return {
        ...state,
        dataByHallCodeAndDate: initialState.dataByHallCodeAndDate,
        loadingState: 'failed',
        error: action.payload.error,
      };
    }
    case POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST: {
      return {
        ...state,
        loadingState: 'loading',
        error: initialState.error,
      };
    }
    case POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_SUCCESS: {
      return {
        ...state,
        dataByHallCodeAndDate: initialState.dataByHallCodeAndDate,
        loadingState: 'loaded',
      };
    }
    case POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_FAILURE: {
      return {
        ...state,
        loadingState: 'failed',
        error: action.payload.error,
      };
    }
    case FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST: {
      return {
        ...state,
        loadingState: 'loading',
        error: initialState.error,
      };
    }
    case FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS: {
      return {
        ...state,
        loadingState: 'loaded',
        dataById: action.payload.data,
      };
    }
    case FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE: {
      return {
        ...state,
        dataById: initialState.dataById,
        loadingState: 'failed',
        error: action.payload.error,
      };
    }
    case PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST: {
      return {
        ...state,
        loadingState: 'loading',
        error: initialState.error,
      };
    }
    case PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_SUCCESS: {
      return {
        ...state,
        dataByHallCodeAndDate: initialState.dataByHallCodeAndDate,
        loadingState: 'loaded',
      };
    }
    case PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_FAILURE: {
      return {
        ...state,
        loadingState: 'failed',
        error: action.payload.error,
      };
    }

    case PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_REQUEST: {
      return {
        ...state,
        loadingState: 'loading',
        error: initialState.error,
      };
    }
    case PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_SUCCESS: {
      return {
        ...state,
        dataByHallCodeAndDate: initialState.dataByHallCodeAndDate,
        loadingState: 'loaded',
      };
    }
    case PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_FAILURE: {
      return {
        ...state,
        loadingState: 'failed',
        error: action.payload.error,
      };
    }
    case RESET_DATA_SETTINGS_UNIT_MODEL: {
      return {
        ...state,
        dataByHallCodeAndDate: initialState.dataByHallCodeAndDate,
        dataById: initialState.dataById,
        error: initialState.error,
      };
    }
    case RENEW_DATA_SETTINGS_UNIT_MODEL_LIST_ALL: {
      return initialState;
    }
    default:
      return state;
  }
}
