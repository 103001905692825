import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsUnitModel } from '../../domain/settingsOptionsUnitModel';

import { RootState } from '../../store';

// Action Types

const FETCH_SETTINGS_OPTIONS_UNIT_MODEL_REQUEST =
  'FETCH_SETTINGS_OPTIONS_UNIT_MODEL_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_UNIT_MODEL_SUCCESS =
  'FETCH_SETTINGS_OPTIONS_UNIT_MODEL_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_UNIT_MODEL =
  'RENEW_SETTINGS_OPTIONS_UNIT_MODEL' as const;

export const SettingsOptionsUnitModelActionTypes = {
  FETCH_SETTINGS_OPTIONS_UNIT_MODEL_REQUEST,
  FETCH_SETTINGS_OPTIONS_UNIT_MODEL_SUCCESS,
  RENEW_SETTINGS_OPTIONS_UNIT_MODEL,
};

// Action Creators

function fetchSettingsOptionsUnitModelRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_UNIT_MODEL_REQUEST,
  };
}

function fetchSettingsOptionsUnitModelSuccessAction(
  settingsOptionsUnitModel: SettingsOptionsUnitModel
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_UNIT_MODEL_SUCCESS,
    payload: { settingsOptionsUnitModel },
  };
}

function renewSettingsOptionsUnitModelAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_UNIT_MODEL,
  };
}

export const SettingsOptionsUnitModelActionCreators = {
  fetchSettingsOptionsUnitModelRequestAction,
  fetchSettingsOptionsUnitModelSuccessAction,
  renewSettingsOptionsUnitModelAction,
};

// Actions

type SettingsOptionsUnitModelAction =
  | ReturnType<typeof fetchSettingsOptionsUnitModelRequestAction>
  | ReturnType<typeof fetchSettingsOptionsUnitModelSuccessAction>
  | ReturnType<typeof renewSettingsOptionsUnitModelAction>;

// State

type SettingsOptionsUnitModelState = {
  loadingState: LoadingState;
  settingsOptionsUnitModel: SettingsOptionsUnitModel | undefined;
};

const initialState: SettingsOptionsUnitModelState = {
  loadingState: 'prepare',
  settingsOptionsUnitModel: undefined,
};

// Selector

// whole selector
const settingsOptionsUnitModelSelector = (state: RootState) =>
  state.settingsOptionsUnitModel;

// ホール一覧を取得
export const settingsOptionsUnitModelHallsSelector = createSelector(
  settingsOptionsUnitModelSelector,
  ({ settingsOptionsUnitModel }) =>
    settingsOptionsUnitModel?.searchCondition.halls ?? []
);

// 機種一覧を取得
export const settingsOptionsUnitModelModelListSelector = createSelector(
  settingsOptionsUnitModelSelector,
  ({ settingsOptionsUnitModel }) =>
    settingsOptionsUnitModel?.searchCondition.kiList ?? []
);

export const settingsOptionsUnitModelLoadingStateSelector = createSelector(
  settingsOptionsUnitModelSelector,
  ({ loadingState }) => loadingState
);

// Reducer

export function settingsOptionsUnitModelReducer(
  state = initialState,
  action: SettingsOptionsUnitModelAction
): SettingsOptionsUnitModelState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_UNIT_MODEL_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_UNIT_MODEL_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsUnitModel: action.payload.settingsOptionsUnitModel,
      };
    case RENEW_SETTINGS_OPTIONS_UNIT_MODEL:
      return {
        ...state,
        loadingState: 'loaded',
      };
    default:
      return state;
  }
}
