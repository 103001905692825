import { createSelector } from 'reselect';

import {
  DataTerminalTransition,
  DataTerminalTransitionDataQueryParameter,
  DataTerminalTransitionParams,
} from '../../domain/dataTerminalTransition';
import { LoadingState } from '../../domain/schemas';

import { filterTransitionDataByField } from '../../utils/filterTransitionDataByField';
import { getShuCodeFromDataTerminalTransitionDataQueryParameter } from '../../utils/shu';
import { terminalTransitionCurrentFieldsSelector } from '../ui/terminalTransitionReportsSetting';

// Action Types

const SEARCH_DATA_TERMINAL_TRANSITION_2NDROW =
  'SEARCH_DATA_TERMINAL_TRANSITION_2NDROW' as const;

const FETCH_DATA_TERMINAL_TRANSITION_2NDROW =
  'FETCH_DATA_TERMINAL_TRANSITION_2NDROW' as const;
const FETCH_DATA_TERMINAL_TRANSITION_2NDROW_REQUEST =
  'FETCH_DATA_TERMINAL_TRANSITION_2NDROW_REQUEST' as const;
const FETCH_DATA_TERMINAL_TRANSITION_2NDROW_SUCCESS =
  'FETCH_DATA_TERMINAL_TRANSITION_2NDROW_SUCCESS' as const;
const HIDE_DATA_TERMINAL_TRANSITION_2NDROW =
  'HIDE_DATA_TERMINAL_TRANSITION_2NDROW' as const;
const TOGGLE_DATA_TERMINAL_TRANSITION_2NDROW =
  'TOGGLE_DATA_TERMINAL_TRANSITION_2NDROW' as const;
const RENEW_DATA_TERMINAL_TRANSITION_2NDROW =
  'RENEW_DATA_TERMINAL_TRANSITION_2NDROW' as const;

export const DataTerminalTransition2ndRowActionTypes = {
  SEARCH_DATA_TERMINAL_TRANSITION_2NDROW,
  FETCH_DATA_TERMINAL_TRANSITION_2NDROW,
  FETCH_DATA_TERMINAL_TRANSITION_2NDROW_REQUEST,
  FETCH_DATA_TERMINAL_TRANSITION_2NDROW_SUCCESS,
  HIDE_DATA_TERMINAL_TRANSITION_2NDROW,
  TOGGLE_DATA_TERMINAL_TRANSITION_2NDROW,
  RENEW_DATA_TERMINAL_TRANSITION_2NDROW,
};

// Action Creators

/**
 * 現在の検索条件を元に展開行を取得する
 * @param queryParameter クエリパラメータ
 */
function searchDataTerminalTransition2ndRowAction(
  queryParameter: DataTerminalTransitionDataQueryParameter
) {
  return {
    type: SEARCH_DATA_TERMINAL_TRANSITION_2NDROW,
    payload: { queryParameter },
  };
}

/**
 * 指定した検索条件で展開行データを取得する
 * @param queryParameter クエリパラメータ
 * @param params 取得する展開行の検索条件
 */
function fetchDataTerminalTransition2ndRowAction(
  queryParameter: DataTerminalTransitionDataQueryParameter,
  params: DataTerminalTransitionParams
) {
  return {
    type: FETCH_DATA_TERMINAL_TRANSITION_2NDROW,
    payload: { queryParameter, params },
  };
}

/**
 * 展開行データ取得前に呼ぶ
 * @param shuCode 種別コード
 * @param params 取得する展開行の検索条件
 */
function fetchDataTerminalTransition2ndRowRequestAction(
  shuCode: string,
  params: DataTerminalTransitionParams
) {
  return {
    type: FETCH_DATA_TERMINAL_TRANSITION_2NDROW_REQUEST,
    payload: { shuCode, params },
  };
}

/**
 * 展開行データ取得成功時、取得したデータを登録する
 * @param shuCode 種別コード
 * @param dataTerminalTransition2ndRow 取得した展開行データ
 */
function fetchDataTerminalTransition2ndRowSuccessAction(
  shuCode: string,
  dataTerminalTransition2ndRow: DataTerminalTransition
) {
  return {
    type: FETCH_DATA_TERMINAL_TRANSITION_2NDROW_SUCCESS,
    payload: { shuCode, dataTerminalTransition2ndRow },
  };
}

/**
 * shuCode を元に展開行を削除する（検索時やリセット時に呼ぶ）
 * @param shuCode 種別一覧・種別グループIDのコード
 */
function hideDataTerminalTransition2ndRowAction(shuCode: string) {
  return {
    type: HIDE_DATA_TERMINAL_TRANSITION_2NDROW,
    payload: { shuCode },
  };
}

/**
 * クエリパラメータ を元に展開行を表示・非表示する
 * @param queryParameter クエリパラメータ
 */
function toggleDataTerminalTransition2ndRowAction(
  queryParameter: DataTerminalTransitionDataQueryParameter
) {
  return {
    type: TOGGLE_DATA_TERMINAL_TRANSITION_2NDROW,
    payload: { queryParameter },
  };
}

/**
 * すべての展開行を初期化する（検索時やリセット時に呼ぶ）
 */
function renewDataTerminalTransition2ndRowAction() {
  return {
    type: RENEW_DATA_TERMINAL_TRANSITION_2NDROW,
  };
}

export const DataTerminalTransition2ndRowActionCreators = {
  searchDataTerminalTransition2ndRowAction,
  fetchDataTerminalTransition2ndRowAction,
  fetchDataTerminalTransition2ndRowRequestAction,
  fetchDataTerminalTransition2ndRowSuccessAction,
  hideDataTerminalTransition2ndRowAction,
  toggleDataTerminalTransition2ndRowAction,
  renewDataTerminalTransition2ndRowAction,
};

// Actions

export type SearchDataTerminalTransition2ndRowAction = ReturnType<
  typeof searchDataTerminalTransition2ndRowAction
>;

export type FetchDataTerminalTransition2ndRowAction = ReturnType<
  typeof fetchDataTerminalTransition2ndRowAction
>;

export type ToggleDataTerminalTransition2ndRowAction = ReturnType<
  typeof toggleDataTerminalTransition2ndRowAction
>;

type DataTerminalTransition2ndRowAction =
  | SearchDataTerminalTransition2ndRowAction
  | FetchDataTerminalTransition2ndRowAction
  | ToggleDataTerminalTransition2ndRowAction
  | ReturnType<typeof fetchDataTerminalTransition2ndRowRequestAction>
  | ReturnType<typeof fetchDataTerminalTransition2ndRowSuccessAction>
  | ReturnType<typeof renewDataTerminalTransition2ndRowAction>
  | ReturnType<typeof hideDataTerminalTransition2ndRowAction>;

// State

type DataTerminalTransition2ndRowState = {
  loadingState: {
    [key: string]: LoadingState;
  };
  // MEMO: shuList / shuGroupIds の code が重複が無い前提で作っているので要注意
  dataTerminalTransition2ndRow?: {
    [key: string]: DataTerminalTransition | undefined;
  };
  selectedCurrentFieldCode: string | undefined;
};

const initialState: DataTerminalTransition2ndRowState = {
  loadingState: {},
  dataTerminalTransition2ndRow: {},
  selectedCurrentFieldCode: undefined,
};

// Selector

const dataTerminalTransitionRootStateSelector = (rootState: {
  dataTerminalTransition2ndRow: DataTerminalTransition2ndRowState;
}) => {
  return rootState.dataTerminalTransition2ndRow.dataTerminalTransition2ndRow;
};

export const dataTerminalTransition2ndRowSelector = createSelector(
  [
    terminalTransitionCurrentFieldsSelector,
    dataTerminalTransitionRootStateSelector,
  ],
  (currentField, dataTerminalTransition2ndRow) => {
    // 項目絞込で、項目を選択されなかった場合の処理
    if (currentField == null || currentField === 'all') {
      return dataTerminalTransition2ndRow;
    }

    if (dataTerminalTransition2ndRow == null) {
      return undefined;
    }

    // 展開行押下時の各種別行の特定
    const keys = Object.keys(dataTerminalTransition2ndRow);

    // 展開行押下時、選択された項目でrowを絞り込む処理
    const filteredData = keys.map((row) => {
      const filtered = filterTransitionDataByField(
        dataTerminalTransition2ndRow[row],
        currentField
      );
      if (filtered == null) {
        return undefined;
      }
      return filtered;
    });

    // 選択された展開行のrow情報を形成し、展開行を作る
    const placeFilteredRows: {
      [key: string]: DataTerminalTransition;
    } = Object.assign(
      {},
      ...filteredData.map((data, i) => {
        // 展開行の種別行
        const code = keys[i];

        // 展開行が展開されたとき
        if (data?.data != null) {
          return {
            [code]: {
              ...dataTerminalTransition2ndRow[code],
              data: data.data,
            },
          };
        }

        // 展開行が縮小されたとき
        // 縮小時にundefinedを定義する方法は、既存の「rootState.dataTerminalTransition2ndRow.dataTerminalTransition2ndRow」と足並みを揃えるため
        return {
          [code]: undefined,
        };
      })
    );

    return placeFilteredRows;
  }
);

/**
 * queryParameter に該当する展開行を取得する
 * @param queryParameter 絞り込む queryParameter
 * @returns 該当する展開行（該当する展開行がない場合はundefined）
 */
export const singleDataTerminalTransition2ndRowSelector = (
  queryParameter?: DataTerminalTransitionDataQueryParameter
) => {
  return createSelector(
    dataTerminalTransition2ndRowSelector,
    (dataTerminalTransition2ndRow) => {
      const code =
        getShuCodeFromDataTerminalTransitionDataQueryParameter(queryParameter);

      if (code === undefined || dataTerminalTransition2ndRow === undefined) {
        return;
      }

      return dataTerminalTransition2ndRow[code];
    }
  );
};

/**
 * 現在表示中の店舗行から検索条件だけ抽出したデータを取得する
 * @returns 現在表示中の店舗行の検索条件
 */
export const dataTerminalTransition2ndRowSettingsSelector = createSelector(
  dataTerminalTransition2ndRowSelector,
  (data) => {
    if (data === undefined) return {};

    const settings: {
      [key: string]: DataTerminalTransitionParams;
    } = {};

    // 現在表示中の店舗行からsettingだけ抽出したデータを作成
    Object.keys(data).forEach((key) => {
      const item = data[key];
      // 空の場合は登録しない
      if (item?.setting == null) {
        return;
      }

      settings[key] = item.setting;
    });

    return settings;
  }
);

function dataTerminalTransitionLoadingState2ndRowSelector(rootState: {
  dataTerminalTransition2ndRow: DataTerminalTransition2ndRowState;
}) {
  return rootState.dataTerminalTransition2ndRow.loadingState;
}

/**
 * Loading の展開行があるかどうかを判定する
 * @returns Loading 中の展開行が存在するか
 */
export const dataTerminalTransitionIsLoading2ndRowSelector = createSelector(
  dataTerminalTransitionLoadingState2ndRowSelector,
  (loadingState) => {
    const result = Object.keys(loadingState)
      .map((key) => loadingState[key])
      .filter((value) => value === 'loading');

    return result.length > 0;
  }
);

// Reducer

export function dataTerminalTransition2ndRowReducer(
  state = initialState,
  action: DataTerminalTransition2ndRowAction
): DataTerminalTransition2ndRowState {
  switch (action.type) {
    case FETCH_DATA_TERMINAL_TRANSITION_2NDROW_REQUEST:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.payload.shuCode]: 'loading',
        },
      };
    case FETCH_DATA_TERMINAL_TRANSITION_2NDROW_SUCCESS:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.payload.shuCode]: 'loaded',
        },
        dataTerminalTransition2ndRow: {
          ...state.dataTerminalTransition2ndRow,
          [action.payload.shuCode]: action.payload.dataTerminalTransition2ndRow,
        },
      };
    case HIDE_DATA_TERMINAL_TRANSITION_2NDROW:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.payload.shuCode]: 'prepare',
        },
        dataTerminalTransition2ndRow: {
          ...state.dataTerminalTransition2ndRow,
          [action.payload.shuCode]: undefined,
        },
      };
    case RENEW_DATA_TERMINAL_TRANSITION_2NDROW:
      return initialState;
    default:
      return state;
  }
}
