import {
  LoadedDataStatusesHalls,
  LoadedDataStatusesHallsParams,
} from '../../domain/loadedDataStatusesHalls';
import { LoadingState } from '../../domain/schemas';

// Action Types

const FETCH_LOADED_DATA_STATUSES_HALLS =
  'FETCH_LOADED_DATA_STATUSES_HALLS' as const;
const FETCH_LOADED_DATA_STATUSES_HALLS_REQUEST =
  'FETCH_LOADED_DATA_STATUSES_HALLS_REQUEST' as const;
const FETCH_LOADED_DATA_STATUSES_HALLS_SUCCESS =
  'FETCH_LOADED_DATA_STATUSES_HALLS_SUCCESS' as const;
const RENEW_LOADED_DATA_STATUSES_HALLS =
  'RENEW_LOADED_DATA_STATUSES_HALLS' as const;

export const LoadedDataStatusesHallsActionTypes = {
  FETCH_LOADED_DATA_STATUSES_HALLS,
  FETCH_LOADED_DATA_STATUSES_HALLS_REQUEST,
  FETCH_LOADED_DATA_STATUSES_HALLS_SUCCESS,
  RENEW_LOADED_DATA_STATUSES_HALLS,
};

// Action Creators

function fetchLoadedDataStatusesHallsAction(
  query: LoadedDataStatusesHallsParams
) {
  return {
    type: FETCH_LOADED_DATA_STATUSES_HALLS,
    payload: { query },
  };
}

function fetchLoadedDataStatusesHallsRequestAction() {
  return {
    type: FETCH_LOADED_DATA_STATUSES_HALLS_REQUEST,
  };
}

function fetchLoadedDataStatusesHallsSuccessAction(
  data: LoadedDataStatusesHalls
) {
  return {
    type: FETCH_LOADED_DATA_STATUSES_HALLS_SUCCESS,
    payload: { data },
  };
}

function renewLoadedDataStatusesHallsAction() {
  return {
    type: RENEW_LOADED_DATA_STATUSES_HALLS,
  };
}

export const LoadedDataStatusesHallsActionCreators = {
  fetchLoadedDataStatusesHallsAction,
  fetchLoadedDataStatusesHallsRequestAction,
  fetchLoadedDataStatusesHallsSuccessAction,
  renewLoadedDataStatusesHallsAction,
};

// Actions

export type FetchLoadedDataStatusesHallsAction = ReturnType<
  typeof fetchLoadedDataStatusesHallsAction
>;

type LoadedDataStatusesHallsAction =
  | FetchLoadedDataStatusesHallsAction
  | ReturnType<typeof fetchLoadedDataStatusesHallsRequestAction>
  | ReturnType<typeof fetchLoadedDataStatusesHallsSuccessAction>
  | ReturnType<typeof renewLoadedDataStatusesHallsAction>;

// State

type LoadedDataStatusesHallsState = {
  loadingState: LoadingState;
  loadedDataStatusesHalls: LoadedDataStatusesHalls | undefined;
};

const initialState: LoadedDataStatusesHallsState = {
  loadingState: 'prepare',
  loadedDataStatusesHalls: undefined,
};

// Selector

export function loadedDataStatusesHallsSelector(rootState: {
  loadedDataStatusesHalls: LoadedDataStatusesHallsState;
}) {
  return rootState.loadedDataStatusesHalls.loadedDataStatusesHalls;
}

export function loadedDataStatusesHallsLoadingStateSelector(rootState: {
  loadedDataStatusesHalls: LoadedDataStatusesHallsState;
}) {
  return rootState.loadedDataStatusesHalls.loadingState;
}

// Reducer

export function loadedDataStatusesHallsReducer(
  state = initialState,
  action: LoadedDataStatusesHallsAction
): LoadedDataStatusesHallsState {
  switch (action.type) {
    case FETCH_LOADED_DATA_STATUSES_HALLS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_LOADED_DATA_STATUSES_HALLS_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        loadedDataStatusesHalls: action.payload.data,
      };
    case RENEW_LOADED_DATA_STATUSES_HALLS:
      return initialState;
    default:
      return state;
  }
}
