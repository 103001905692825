import { createSelector } from 'reselect';

import { OrderType } from '../../domain/schemas';
import { defaultValues } from '../../domain/settingsEvents/defaultValues';
import {
  EventsDateRange,
  EventsResponse,
  SortType,
} from '../../domain/settingsEvents/types';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_EVENTS_SETTING = 'INIT_EVENTS_SETTING' as const;
const SEARCH_EVENT_SETTING = 'SEARCH_EVENT_SETTING' as const;
const RESET_EVENT_SETTING = 'RESET_EVENT_SETTING' as const;
const TRIGGER_EVENTS_SETTING_SORT = 'TRIGGER_EVENTS_SETTING_SORT' as const;
const CHANGE_EVENTS_SETTING_SORT_AND_ORDER =
  'CHANGE_EVENTS_SETTING_SORT_AND_ORDER' as const;
const SELECT_EVENTS_SETTING_DATE_RANGE_ACTION =
  'SELECT_EVENTS_SETTING_DATE_RANGE_ACTION' as const;
const CHANGE_EVENTS_SETTING_PAGE = 'CHANGE_EVENTS_SETTING_PAGE' as const;
const CHANGE_EVENTS_SETTING_ROWS_PER_PAGE =
  'CHANGE_EVENTS_SETTING_ROWS_PER_PAGE' as const;
const RENEW_EVENTS_SETTING = 'RENEW_EVENTS_SETTING' as const;

export const EventsSettingActionTypes = {
  INIT_EVENTS_SETTING,
  SEARCH_EVENT_SETTING,
  RESET_EVENT_SETTING,
  TRIGGER_EVENTS_SETTING_SORT,
  CHANGE_EVENTS_SETTING_SORT_AND_ORDER,
  SELECT_EVENTS_SETTING_DATE_RANGE_ACTION,
  RENEW_EVENTS_SETTING,
  CHANGE_EVENTS_SETTING_PAGE,
  CHANGE_EVENTS_SETTING_ROWS_PER_PAGE,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 初回リクエスト
 */
function initEventsSettingAction() {
  return {
    type: INIT_EVENTS_SETTING,
  };
}

/**
 * 検索ボタンの押下
 */
function searchEventsSettingAction(
  query: EventsResponse['setting'],
  dataRange: EventsDateRange
) {
  return {
    type: SEARCH_EVENT_SETTING,
    payload: { query, dataRange },
  };
}

/**
 * 条件リセットボタンの押下
 */
function resetEventsSettingAction() {
  return {
    type: RESET_EVENT_SETTING,
  };
}

/**
 * 表示項目にあるソートボタン押下
 */
function triggerEventsSettingSortAction(sort: SortType) {
  return {
    type: TRIGGER_EVENTS_SETTING_SORT,
    payload: { sort },
  };
}

/**
 * sortとorderを変更して検索を実行する
 *
 * 直接Reactのコンポーネントから実行せず、Saga経由で実行する
 * Reactから呼び出す場合にはtriggerSettingsEventsSortAndOrderActionかtriggerEventsSettingSortActionを利用する
 */
function changeEventSettingSortAndOrderAction(
  sort: SortType,
  order: OrderType
) {
  return {
    type: CHANGE_EVENTS_SETTING_SORT_AND_ORDER,
    payload: { sort, order },
  };
}

/**
 * 表示項目にあるソートボタン押下
 */
function selectEventsSettingDateRangeAction(dateRange: EventsDateRange) {
  return {
    type: SELECT_EVENTS_SETTING_DATE_RANGE_ACTION,
    payload: { dateRange },
  };
}

function changeEventsSettingPageAction(page: number) {
  return {
    type: CHANGE_EVENTS_SETTING_PAGE,
    payload: { page },
  };
}

function changeEventsSettingRowsPerPageAction(rowsPerPage: number) {
  return {
    type: CHANGE_EVENTS_SETTING_ROWS_PER_PAGE,
    payload: { rowsPerPage },
  };
}

function renewEventsSettingAction() {
  return {
    type: RENEW_EVENTS_SETTING,
  };
}

export const EventsSettingActionCreators = {
  initEventsSettingAction,
  searchEventsSettingAction,
  resetEventsSettingAction,
  triggerEventsSettingSortAction,
  changeEventSettingSortAndOrderAction,
  selectEventsSettingDateRangeAction,
  changeEventsSettingPageAction,
  changeEventsSettingRowsPerPageAction,
  renewEventsSettingAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type InitSettingsEventsAction = ReturnType<typeof initEventsSettingAction>;
export type SearchSettingsEventsAction = ReturnType<
  typeof searchEventsSettingAction
>;
export type TriggerSettingsEventsSortAction = ReturnType<
  typeof triggerEventsSettingSortAction
>;
export type ChangeSettingsEventsSortAndOrderAction = ReturnType<
  typeof changeEventSettingSortAndOrderAction
>;
export type ChangeEventsSettingPageAction = ReturnType<
  typeof changeEventsSettingPageAction
>;
export type ChangeEventsSettingRowsPerPageAction = ReturnType<
  typeof changeEventsSettingRowsPerPageAction
>;

type EventsSettingAction =
  | InitSettingsEventsAction
  | SearchSettingsEventsAction
  | ReturnType<typeof resetEventsSettingAction>
  | TriggerSettingsEventsSortAction
  | ChangeSettingsEventsSortAndOrderAction
  | ReturnType<typeof selectEventsSettingDateRangeAction>
  | ReturnType<typeof renewEventsSettingAction>
  | ChangeEventsSettingPageAction
  | ChangeEventsSettingRowsPerPageAction;

/* ---------------------------------------------------------------
 * State
 */

export type EventsSettingState = {
  searchParams: EventsResponse['setting'] | undefined;
  selectedDateRange: EventsDateRange;
};

const initialState: EventsSettingState = {
  searchParams: undefined,
  selectedDateRange: defaultValues.dateRange,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [操作履歴] 設定情報を取得する
 */
function eventsSettingSelector(rootState: {
  eventsSetting: EventsSettingState;
}) {
  return rootState.eventsSetting;
}

/**
 * [操作履歴] 選択されている検索条件を取得する
 */
export const eventsSettingSearchParamsSelector = createSelector(
  eventsSettingSelector,
  (eventsSetting) => eventsSetting.searchParams
);

/**
 * [操作履歴] 選択されている期間のタイプを取得する
 */
export const eventsSettingSelectedDateRangeSelector = createSelector(
  eventsSettingSelector,
  (eventsSetting) => eventsSetting.selectedDateRange
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function eventsSettingReducer(
  state = initialState,
  action: EventsSettingAction
): EventsSettingState {
  switch (action.type) {
    case SEARCH_EVENT_SETTING:
      return {
        ...state,
        searchParams: action.payload.query,
      };
    case CHANGE_EVENTS_SETTING_SORT_AND_ORDER:
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          sort: action.payload.sort,
          order: action.payload.order,
        },
      };
    case SELECT_EVENTS_SETTING_DATE_RANGE_ACTION:
      return {
        ...state,
        selectedDateRange: action.payload.dateRange,
      };
    case CHANGE_EVENTS_SETTING_PAGE:
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          offset: action.payload.page * (state.searchParams?.limit ?? 100),
        },
      };
    case CHANGE_EVENTS_SETTING_ROWS_PER_PAGE:
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          limit: action.payload.rowsPerPage,
        },
      };
    case RENEW_EVENTS_SETTING:
      return initialState;
    default:
      return state;
  }
}
