import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { LoadingState } from '../domain/schemas';
import { SettingsOptionsPlanProgressTransition } from '../domain/settingsOptionsPlanProgressTransition';

import {
  SettingsOptionsPlanProgressTransitionActionCreators,
  SettingsOptionsPlanProgressTransitionActionTypes,
  settingsOptionsPlanProgressTransitionLoadingStateSelector,
} from '../redux/server/settingsOptionsPlanProgressTransition';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* initSettingsOptionsPlanProgressTransitionSaga() {
  const loadingState: LoadingState = yield select(
    settingsOptionsPlanProgressTransitionLoadingStateSelector
  );

  if (loadingState === 'prepare') {
    yield put(
      SettingsOptionsPlanProgressTransitionActionCreators.fetchSettingsOptionsPlanProgressTransitionAction()
    );
  }
}

export function* fetchSettingsOptionsPlanProgressTransitionSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsPlanProgressTransitionActionCreators.fetchSettingsOptionsPlanProgressTransitionRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsPlanProgressTransition> =
      yield call(
        api.get,
        '/settings/options/planProgressTransition',
        buildConfig()
      );
    const settingsOptionsPlanProgressTransition = response.data;
    yield put(
      SettingsOptionsPlanProgressTransitionActionCreators.fetchSettingsOptionsPlanProgressTransitionSuccessAction(
        settingsOptionsPlanProgressTransition
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsPlanProgressTransitionActionCreators.renewSettingsOptionsPlanProgressTransitionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsPlanProgressTransitionSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsPlanProgressTransitionActionTypes.INIT_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION,
    initSettingsOptionsPlanProgressTransitionSaga
  );

  yield takeEvery(
    SettingsOptionsPlanProgressTransitionActionTypes.FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION,
    fetchSettingsOptionsPlanProgressTransitionSaga,
    api
  );
}

export function* settingsOptionsPlanProgressTransitionSagas(context: {
  api: Api;
}) {
  yield fork(handleFetchSettingsOptionsPlanProgressTransitionSaga, context.api);
}
