import {
  Column,
  DayType,
  DaysType,
  Item,
  MainFieldType,
  OrderType,
} from './schemas';

export const modelTransitiveDateTypes = [
  'daily',
  'weekly',
  'monthly',
  'any',
] as const;
export type ModelTransitiveDateType = (typeof modelTransitiveDateTypes)[number];

export type DataModelTransitionParams = {
  mainField?: MainFieldType;
  transitiveFields?: string[];
  nonTransitiveFields?: string[];
  marking?: string;
  areas?: string[];
  halls?: string[];
  dateType?: ModelTransitiveDateType;
  durationForAnyDateType?: number;
  startDate?: string;
  endDate?: string;
  excludeToday?: boolean;
  days?: DaysType[];
  dayType?: DayType;
  dateSuffixes?: number[];
  dates?: number[];
  kiList?: string[];
  sisTypes?: string[];
  shuGroupIds?: string[];
  shuList?: string[];
  shuGroupIdsForSijiritu?: string[];
  kiListForSijiritu?: string[];
  sortForHeader?: string;
  sortForTransitiveField?: string;
  order?: OrderType;
  isFiltering?: boolean;
};

export type DataModelTransitionData = {
  columnsForNonTransition: Column[];
  columnsForTransition: Column[];
  dates: {
    name: string;
    code: string;
    backgroundColor: string | null;
  }[];
  rows: {
    queryParameter: {
      kiList: string[];
      halls: string[];
    };
    dataForNonTransition: Item[];
    dataForTransition: Item[][];
  }[];
};

export type DataModelTransition = {
  setting: Required<DataModelTransitionParams>;
  data: DataModelTransitionData;
};
