import { sortNumber } from '../../utils/sort/sortNumber';
import { sortNumberOrString } from '../../utils/sort/sortNumberOrString';
import { sortNumberWithPrefix } from '../../utils/sort/sortNumberWithPrefix';
import { sortString } from '../../utils/sort/sortString';
import { OrderType, SortRowsForKey } from '../schemas';
import { DataKiSyokenResponse } from './types';

let sortRows: SortRowsForKey[];

export const sortKiSyoken = (
  dataKiSyoken: DataKiSyokenResponse,
  order: OrderType,
  sort: string
): DataKiSyokenResponse => {
  if (!dataKiSyoken) {
    return dataKiSyoken;
  }

  // ヘッダ下段の商圏店舗を考慮したカラムの配列を作成
  const flatColumns = dataKiSyoken.data.columns.flatMap((column) => {
    if (column.isSyoken === true) {
      return [
        // 商圏全体のカラム
        {
          code: column.code,
          type: column.type,
        },
        // 商圏店舗のカラム
        ...dataKiSyoken.data.hallsOfMarketArea.map((hall) => {
          return {
            code: `${column.code}_${hall.code}`,
            type: column.type,
          };
        }),
      ];
    }
    return {
      code: column.code,
      type: column.type,
    };
  });

  // sortの文字列が一致するカラムのcodeを取得
  const sortColumn = flatColumns.find((column) => column.code === sort);
  const sortColumnCode = sortColumn ? sortColumn.code : null;

  // sortの文字列が一致するindexを取得
  const sortIndex = flatColumns.findIndex((column) => {
    return column.code === sort;
  });

  // sortの文字列が一致するtypeを取得
  const sortType = flatColumns.find((column) => {
    return column.code === sort;
  });

  if (sortIndex == null || sortType == null || sortColumnCode == null) {
    return dataKiSyoken;
  }

  switch (sortType.type) {
    case 'string':
      sortRows = sortString<SortRowsForKey>(
        dataKiSyoken.data.rows,
        sortIndex,
        order,
        sortColumnCode
      );
      break;
    case 'number':
      sortRows = sortNumber<SortRowsForKey>(
        dataKiSyoken.data.rows,
        sortIndex,
        order
      );
      break;
    case 'numberWithPrefix':
      sortRows = sortNumberWithPrefix<SortRowsForKey>(
        dataKiSyoken.data.rows,
        sortIndex,
        order
      );
      break;
    case 'numberOrString':
      sortRows = sortNumberOrString<SortRowsForKey>(
        dataKiSyoken.data.rows,
        sortIndex,
        order
      );
      break;
    default:
      return dataKiSyoken;
  }

  return {
    ...dataKiSyoken,
    // rowsをsortedRowsに変更
    data: {
      ...dataKiSyoken.data,
      rows: sortRows,
    },
  };
};
