import { createSelector } from 'reselect';

import {
  DataModelTransition,
  DataModelTransitionParams,
} from '../../domain/dataModelTransition';
import { LoadingState } from '../../domain/schemas';

import { filterTransitionDataByField } from '../../utils/filterTransitionDataByField';
import { modelTransitionCurrentFieldSelector } from '../ui/modelTransitionReportsSetting';

// Action Types

const SEARCH_DATA_MODEL_TRANSITION_2NDROW =
  'SEARCH_DATA_MODEL_TRANSITION_2NDROW' as const;
const FETCH_DATA_MODEL_TRANSITION_2NDROW =
  'FETCH_DATA_MODEL_TRANSITION_2NDROW' as const;
const FETCH_DATA_MODEL_TRANSITION_2NDROW_REQUEST =
  'FETCH_DATA_MODEL_TRANSITION_2NDROW_REQUEST' as const;
const FETCH_DATA_MODEL_TRANSITION_2NDROW_SUCCESS =
  'FETCH_DATA_MODEL_TRANSITION_2NDROW_SUCCESS' as const;
const HIDE_DATA_MODEL_TRANSITION_2NDROW =
  'HIDE_DATA_MODEL_TRANSITION_2NDROW' as const;
const TOGGLE_DATA_MODEL_TRANSITION_2NDROW =
  'TOGGLE_DATA_MODEL_TRANSITION_2NDROW' as const;
const RENEW_DATA_MODEL_TRANSITION_2NDROW =
  'RENEW_DATA_MODEL_TRANSITION_2NDROW' as const;

export const DataModelTransition2ndRowActionTypes = {
  SEARCH_DATA_MODEL_TRANSITION_2NDROW,
  FETCH_DATA_MODEL_TRANSITION_2NDROW,
  FETCH_DATA_MODEL_TRANSITION_2NDROW_REQUEST,
  FETCH_DATA_MODEL_TRANSITION_2NDROW_SUCCESS,
  RENEW_DATA_MODEL_TRANSITION_2NDROW,
  HIDE_DATA_MODEL_TRANSITION_2NDROW,
  TOGGLE_DATA_MODEL_TRANSITION_2NDROW,
};

// Action Creators

/**
 * 現在の検索条件を元に展開行を取得する
 * @param kiCode 機種コード
 */
function searchDataModelTransition2ndRowAction(kiCode: string) {
  return {
    type: SEARCH_DATA_MODEL_TRANSITION_2NDROW,
    payload: { kiCode },
  };
}

/**
 * 指定した検索条件で展開行データを取得する
 * @param kiCode 機種コード
 * @param params 取得する展開行の検索条件
 */
function fetchDataModelTransition2ndRowAction(
  kiCode: string,
  params: DataModelTransitionParams
) {
  return {
    type: FETCH_DATA_MODEL_TRANSITION_2NDROW,
    payload: { kiCode, params },
  };
}

/**
 * 展開行データ取得前に呼ぶ
 * @param kiCode 機種コード
 * @param params 取得する展開行の検索条件
 */
function fetchDataModelTransition2ndRowRequestAction(
  kiCode: string,
  params: DataModelTransitionParams
) {
  return {
    type: FETCH_DATA_MODEL_TRANSITION_2NDROW_REQUEST,
    payload: { kiCode, params },
  };
}

/**
 * 展開行データ取得成功時、取得したデータを登録する
 * @param kiCode 機種コード
 * @param dataModelTransition2ndRow 取得した展開行データ
 */
function fetchDataModelTransition2ndRowSuccessAction(
  kiCode: string,
  dataModelTransition2ndRow: DataModelTransition
) {
  return {
    type: FETCH_DATA_MODEL_TRANSITION_2NDROW_SUCCESS,
    payload: { kiCode, dataModelTransition2ndRow },
  };
}

/**
 * kiCode を元に展開行を削除する（検索時やリセット時に呼ぶ）
 * @param kiCode 機種のコード
 */
function hideDataModelTransition2ndRowAction(kiCode: string) {
  return {
    type: HIDE_DATA_MODEL_TRANSITION_2NDROW,
    payload: { kiCode },
  };
}

/**
 * kiCode を元に展開行を表示・非表示する
 * @param kiCode 機種のコード
 */
function toggleDataModelTransition2ndRowAction(kiCode: string) {
  return {
    type: TOGGLE_DATA_MODEL_TRANSITION_2NDROW,
    payload: { kiCode },
  };
}

/**
 * すべての展開行を初期化する（検索時やリセット時に呼ぶ）
 */
function renewDataModelTransition2ndRowAction() {
  return {
    type: RENEW_DATA_MODEL_TRANSITION_2NDROW,
  };
}

export const DataModelTransition2ndRowActionCreators = {
  searchDataModelTransition2ndRowAction,
  fetchDataModelTransition2ndRowAction,
  fetchDataModelTransition2ndRowRequestAction,
  fetchDataModelTransition2ndRowSuccessAction,
  hideDataModelTransition2ndRowAction,
  toggleDataModelTransition2ndRowAction,
  renewDataModelTransition2ndRowAction,
};

// Actions

export type SearchDataModelTransition2ndRowAction = ReturnType<
  typeof searchDataModelTransition2ndRowAction
>;

export type FetchDataModelTransition2ndRowAction = ReturnType<
  typeof fetchDataModelTransition2ndRowAction
>;

export type ToggleDataModelTransition2ndRowAction = ReturnType<
  typeof toggleDataModelTransition2ndRowAction
>;

type DataModelTransition2ndRowAction =
  | SearchDataModelTransition2ndRowAction
  | FetchDataModelTransition2ndRowAction
  | ToggleDataModelTransition2ndRowAction
  | ReturnType<typeof fetchDataModelTransition2ndRowRequestAction>
  | ReturnType<typeof fetchDataModelTransition2ndRowSuccessAction>
  | ReturnType<typeof renewDataModelTransition2ndRowAction>
  | ReturnType<typeof hideDataModelTransition2ndRowAction>;

// State

type DataModelTransition2ndRowState = {
  loadingState: {
    [key: string]: LoadingState;
  };
  dataModelTransition2ndRow: {
    [key: string]: DataModelTransition | undefined;
  };
};

const initialState: DataModelTransition2ndRowState = {
  loadingState: {},
  dataModelTransition2ndRow: {},
};

// Selector

function dataModelTransition2ndRowAllSelector(rootState: {
  dataModelTransition2ndRow: DataModelTransition2ndRowState;
}) {
  return rootState.dataModelTransition2ndRow?.dataModelTransition2ndRow;
}

export const dataModelTransition2ndRowSelector = createSelector(
  [modelTransitionCurrentFieldSelector, dataModelTransition2ndRowAllSelector],
  (currentField, dataModelTransition2ndRow) => {
    // 項目絞込で、項目を選択されなかった場合の処理
    if (currentField == null || currentField === 'all') {
      return dataModelTransition2ndRow;
    }

    if (dataModelTransition2ndRow == null) {
      return undefined;
    }

    // 展開行押下時の各種別行の特定
    const keys = Object.keys(dataModelTransition2ndRow);

    // 展開行押下時、選択された項目でrowを絞り込む処理
    const filteredData = keys.map((row) => {
      const filtered = filterTransitionDataByField(
        dataModelTransition2ndRow[row],
        currentField
      );
      if (filtered == null) {
        return undefined;
      }
      return filtered;
    });

    // 選択された展開行のrow情報を形成し、展開行を作る
    const placeFilteredRows: {
      [key: string]: DataModelTransition;
    } = Object.assign(
      {},
      ...filteredData.map((data, i) => {
        // 展開行の種別行
        const code = keys[i];

        // 展開行が展開されたとき
        if (data?.data != null) {
          return {
            [code]: {
              ...dataModelTransition2ndRow[code],
              data: data.data,
            },
          };
        }

        // 展開行が縮小されたとき
        // 縮小時にundefinedを定義する方法は、既存の「rootState.dataModelTransition2ndRow.dataModelTransition2ndRow」と足並みを揃えるため
        return {
          [code]: undefined,
        };
      })
    );

    return placeFilteredRows;
  }
);

/**
 * kiCode に該当する展開行を取得する
 * @param kiCode 絞り込む kiCode
 * @returns 該当する展開行（該当する展開行がない場合はundefined）
 */
export const singleDataModelTransition2ndRowSelector = (kiCode?: string) => {
  return createSelector(
    dataModelTransition2ndRowSelector,
    (dataModelTransition2ndRow) => {
      if (kiCode === undefined || dataModelTransition2ndRow === undefined) {
        return;
      }

      return dataModelTransition2ndRow[kiCode];
    }
  );
};

/**
 * 現在表示中の店舗行から検索条件だけ抽出したデータを取得する
 * @returns 現在表示中の店舗行の検索条件
 */
export const dataModelTransition2ndRowSettingsSelector = createSelector(
  dataModelTransition2ndRowSelector,
  (data) => {
    if (data === undefined) return {};

    const settings: {
      [key: string]: DataModelTransitionParams;
    } = {};

    // 現在表示中の店舗行からsettingだけ抽出したデータを作成
    Object.keys(data).forEach((key) => {
      const item = data[key];
      // 空の場合は登録しない
      if (item?.setting == null) {
        return;
      }

      settings[key] = item.setting;
    });

    return settings;
  }
);

function modelTransitionLoading2ndRowSelector(rootState: {
  dataModelTransition2ndRow: DataModelTransition2ndRowState;
}) {
  return rootState.dataModelTransition2ndRow.loadingState;
}

/**
 * Loading の展開行があるかどうかを判定する
 * @returns Loading 中の展開行が存在するか
 */
export const dataModelTransitionIsLoading2ndRowSelector = createSelector(
  modelTransitionLoading2ndRowSelector,
  (loadingState) => {
    const result = Object.keys(loadingState)
      .map((key) => loadingState[key])
      .filter((value) => value === 'loading');

    return result.length > 0;
  }
);

// Reducer

export function dataModelTransition2ndRowReducer(
  state = initialState,
  action: DataModelTransition2ndRowAction
): DataModelTransition2ndRowState {
  switch (action.type) {
    case FETCH_DATA_MODEL_TRANSITION_2NDROW_REQUEST:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.payload.kiCode]: 'loading',
        },
      };
    case FETCH_DATA_MODEL_TRANSITION_2NDROW_SUCCESS:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.payload.kiCode]: 'loaded',
        },
        dataModelTransition2ndRow: {
          ...state.dataModelTransition2ndRow,
          [action.payload.kiCode]: action.payload.dataModelTransition2ndRow,
        },
      };
    case HIDE_DATA_MODEL_TRANSITION_2NDROW:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.payload.kiCode]: 'prepare',
        },
        dataModelTransition2ndRow: {
          ...state.dataModelTransition2ndRow,
          [action.payload.kiCode]: undefined,
        },
      };
    case RENEW_DATA_MODEL_TRANSITION_2NDROW:
      return initialState;
    default:
      return state;
  }
}
