import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  DataTerminalTransition,
  DataTerminalTransitionData,
  DataTerminalTransitionDataQueryParameter,
  DataTerminalTransitionParams,
} from '../domain/dataTerminalTransition';
import { MAIN_FIELD_TYPE } from '../domain/schemas';
import { SettingsOptionsTerminalTransition } from '../domain/settingsOptionsTerminalTransition';

import {
  DataTerminalTransition2ndRowActionCreators,
  DataTerminalTransition2ndRowActionTypes,
  FetchDataTerminalTransition2ndRowAction,
  SearchDataTerminalTransition2ndRowAction,
  ToggleDataTerminalTransition2ndRowAction,
  singleDataTerminalTransition2ndRowSelector,
} from '../redux/server/dataTerminalTransition2ndRow';
import { settingsOptionsTerminalTransitionSelector } from '../redux/server/settingsOptionsTerminalTransition';
import {
  terminalTransitionReportsSearchConditionSelector,
  terminalTransitionReportsSettingCurrentHallsSelector,
} from '../redux/ui/terminalTransitionReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { getShuCodeFromDataTerminalTransitionDataQueryParameter } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

/**
 * クエリパラメータ を元に検索条件を加工する
 * @param queryParameter クエリパラメータ
 * @param searchParams 期間推移の検索条件
 * @param searchCondition 期間推移の設定項目
 * @returns 加工された検索条件
 */
export const queryParameterToFormConditions = (
  queryParameter: DataTerminalTransitionDataQueryParameter,
  searchParams: DataTerminalTransitionParams,
  searchCondition: SettingsOptionsTerminalTransition
): DataTerminalTransitionParams => {
  // デフォルトの非推移項目
  const defaultNonTransition = searchCondition.fields.nonTransition
    .filter((value) => value.isDefault)
    .map((value) => value.code);
  // デフォルトの推移項目
  const defaultTransition = searchCondition.fields.transition
    .filter((value) => value.isDefault)
    .map((value) => value.code);

  // 表示項目が指定されていない時は、デフォルトの条件を指定する
  const fields = {
    transitiveFields:
      searchParams.transitiveFields === undefined
        ? defaultTransition
        : searchParams.transitiveFields,
    nonTransitiveFields:
      searchParams.nonTransitiveFields === undefined
        ? defaultNonTransition
        : searchParams.nonTransitiveFields,
  };

  // MEMO: 元の検索条件の非推移項目が空のときは、メインフィールドの shu が指定される
  // そのまま送ると展開行の非推移項目がズレてしまうため、該当するデータの際は非推移項目で hlMei を指定する
  if (
    fields.nonTransitiveFields.length === 1 &&
    fields.nonTransitiveFields[0] === MAIN_FIELD_TYPE.SHU
  ) {
    fields.nonTransitiveFields = [MAIN_FIELD_TYPE.HL_MEI];
  }

  return {
    ...searchParams,
    // MEMO: 展開行は hlMei を指定
    mainField: MAIN_FIELD_TYPE.HL_MEI,
    // MEMO: クエリパラメータ で shuList が選択されている場合は shuList の先頭を指定する
    // MEMO: クエリパラメータ で shuGroupIds が選択されている場合は shuGroupIds の先頭を指定する
    ...{
      shuList: queryParameter.shuList
        ? [queryParameter.shuList[0]]
        : searchParams.shuList,
      shuGroupIds: queryParameter.shuGroupIds
        ? [queryParameter.shuGroupIds[0]]
        : searchParams.shuGroupIds,
    },
    // MEMO: ForSijiritu 系のパラメータは検索条件で指定されているものを分母として渡す
    ...{
      shuListForSijiritu: searchParams.shuList,
      shuGroupIdsForSijiritu: searchParams.shuGroupIds,
    },
    // MEMO: 表示項目が指定されていない時は、デフォルトの条件を指定する
    ...fields,
  };
};

/**
 * 期間推移の展開行を表示する
 * @param action Action
 */
function* searchDataTerminalTransition2ndRowSaga(
  action: SearchDataTerminalTransition2ndRowAction
) {
  const queryParameter = action.payload.queryParameter;

  // APIリクエスト前に必要な情報を取得
  const searchParams: DataTerminalTransitionParams = yield select(
    terminalTransitionReportsSearchConditionSelector
  ); // 現在の検索条件

  // APIリクエスト前に必要な設定項目を取得
  const searchCondition: SettingsOptionsTerminalTransition = yield select(
    settingsOptionsTerminalTransitionSelector
  ); // 設定項目

  const currentHalls: string[] = yield select(
    terminalTransitionReportsSettingCurrentHallsSelector
  );

  // 検索条件を加工
  const params = queryParameterToFormConditions(
    queryParameter,
    {
      ...searchParams,
      halls: currentHalls.length === 0 ? searchParams.halls : currentHalls,
    },
    searchCondition
  );

  // 展開行を取得する
  yield put(
    DataTerminalTransition2ndRowActionCreators.fetchDataTerminalTransition2ndRowAction(
      queryParameter,
      params
    )
  );
}

export function* fetchDataTerminalTransition2ndRowSaga(
  api: Api,
  action: FetchDataTerminalTransition2ndRowAction
) {
  const queryParameter = action.payload.queryParameter;
  const params = action.payload.params;

  if (!queryParameter) {
    throw new Error('queryParameter が指定されていません。');
  }

  const shuCode =
    getShuCodeFromDataTerminalTransitionDataQueryParameter(queryParameter);

  if (!shuCode) {
    throw new Error('shuCode が指定されていません。');
  }

  try {
    yield put(
      DataTerminalTransition2ndRowActionCreators.fetchDataTerminalTransition2ndRowRequestAction(
        shuCode,
        params
      )
    );

    const response: AxiosResponse<DataTerminalTransition> = yield call(
      api.get,
      '/data/terminalTransition',
      buildConfig(params)
    );

    yield put(
      DataTerminalTransition2ndRowActionCreators.fetchDataTerminalTransition2ndRowSuccessAction(
        shuCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataTerminalTransition2ndRowActionCreators.renewDataTerminalTransition2ndRowAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 指定したQueryParameterと一致する展開行を表示・非表示する
 * @param action Action
 */
function* toggleDataTerminalTransition2ndRowSaga(
  action: ToggleDataTerminalTransition2ndRowAction
) {
  const queryParameter = action.payload.queryParameter;

  if (!queryParameter) {
    throw new Error('queryParameter が指定されていません。');
  }

  const shuCode =
    getShuCodeFromDataTerminalTransitionDataQueryParameter(queryParameter);

  if (!shuCode) {
    throw new Error('shuCode が指定されていません。');
  }

  // queryParameterをもとに展開行を取得
  const now2ndRow: DataTerminalTransitionData | undefined = yield select(
    singleDataTerminalTransition2ndRowSelector(queryParameter)
  );

  if (now2ndRow === undefined) {
    // 未取得の場合データを取得して表示する
    yield put(
      DataTerminalTransition2ndRowActionCreators.searchDataTerminalTransition2ndRowAction(
        queryParameter
      )
    );
  } else {
    // 取得済みの場合はデータを削除する
    yield put(
      DataTerminalTransition2ndRowActionCreators.hideDataTerminalTransition2ndRowAction(
        shuCode
      )
    );
  }
}

/**
 * SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROWがDispatchされた時の処理
 */
function* handleSearchDataTerminalTransition2ndRowSaga() {
  yield takeEvery(
    DataTerminalTransition2ndRowActionTypes.SEARCH_DATA_TERMINAL_TRANSITION_2NDROW,
    searchDataTerminalTransition2ndRowSaga
  );
}

/**
 * FETCH_DATA_TERMINAL_TRANSITION_2NDROW がDispatchされた時の処理
 */
function* handleFetchDataTerminalTransition2ndRowSaga(api: Api) {
  yield takeEvery(
    DataTerminalTransition2ndRowActionTypes.FETCH_DATA_TERMINAL_TRANSITION_2NDROW,
    fetchDataTerminalTransition2ndRowSaga,
    api
  );
}

/**
 * TOGGLE_DATA_TERMINAL_TRANSITION_2NDROW がDispatchされた時に toggleDataTerminalTransition2ndRowSaga を実行する
 */
function* handleToggleDataTerminalTransition2ndRowSaga() {
  yield takeEvery(
    DataTerminalTransition2ndRowActionTypes.TOGGLE_DATA_TERMINAL_TRANSITION_2NDROW,
    toggleDataTerminalTransition2ndRowSaga
  );
}

export function* dataTerminalTransition2ndRowSagas(context: { api: Api }) {
  yield fork(handleSearchDataTerminalTransition2ndRowSaga);
  yield fork(handleFetchDataTerminalTransition2ndRowSaga, context.api);
  yield fork(handleToggleDataTerminalTransition2ndRowSaga); // 展開行を表示・非表示
}
