import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsDataDeletion } from '../../domain/settingsOptionsDataDeletion';

import { RootState } from '../../store';

// Action Types
const FETCH_SETTINGS_OPTIONS_DATADELETION =
  'FETCH_SETTINGS_OPTIONS_DATADELETION' as const;
const FETCH_SETTINGS_OPTIONS_DATADELETION_REQUEST =
  'FETCH_SETTINGS_OPTIONS_DATADELETION_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_DATADELETION_SUCCESS =
  'FETCH_SETTINGS_OPTIONS_DATADELETION_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_DATADELETION =
  'RENEW_SETTINGS_OPTIONS_DATADELETION' as const;

export const SettingsOptionsDataDeletionActionTypes = {
  FETCH_SETTINGS_OPTIONS_DATADELETION,
  FETCH_SETTINGS_OPTIONS_DATADELETION_REQUEST,
  FETCH_SETTINGS_OPTIONS_DATADELETION_SUCCESS,
  RENEW_SETTINGS_OPTIONS_DATADELETION,
};

// Action Creators

function fetchSettingsOptionsDataDeletionAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_DATADELETION,
  };
}

function fetchSettingsOptionsDataDeletionRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_DATADELETION_REQUEST,
  };
}

function fetchSettingsOptionsDataDeletionSuccessAction(
  settingsOptionsDataDeletion: SettingsOptionsDataDeletion
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_DATADELETION_SUCCESS,
    payload: { settingsOptionsDataDeletion },
  };
}

function renewSettingsOptionsDataDeletionAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_DATADELETION,
  };
}

export const SettingsOptionsDataDeletionActionCreators = {
  fetchSettingsOptionsDataDeletionAction,
  fetchSettingsOptionsDataDeletionRequestAction,
  fetchSettingsOptionsDataDeletionSuccessAction,
  renewSettingsOptionsDataDeletionAction,
};

// Actions
type SettingsOptionsDataDeletionAction =
  | ReturnType<typeof fetchSettingsOptionsDataDeletionAction>
  | ReturnType<typeof fetchSettingsOptionsDataDeletionRequestAction>
  | ReturnType<typeof fetchSettingsOptionsDataDeletionSuccessAction>
  | ReturnType<typeof renewSettingsOptionsDataDeletionAction>;

// State

type SettingsOptionsDataDeletionState = {
  loadingState: LoadingState;
  settingsOptionsDataDeletion: SettingsOptionsDataDeletion;
};

const initialState: SettingsOptionsDataDeletionState = {
  loadingState: 'prepare',
  settingsOptionsDataDeletion: {
    searchCondition: {
      halls: [],
    },
  },
};

// Selector

// whole selector
const settingsOptionsDataDeletionSelector = (state: RootState) =>
  state.settingsOptionsDataDeletion;

//  ホール一覧を取得
export const settingsOptionsDataDeletionHallsSelector = createSelector(
  settingsOptionsDataDeletionSelector,
  ({ settingsOptionsDataDeletion }) =>
    settingsOptionsDataDeletion.searchCondition.halls
);

export const settingsOptionsDataDeletionLoadingStateSelector = createSelector(
  settingsOptionsDataDeletionSelector,
  ({ loadingState }) => loadingState
);

// Reducer

export function settingsOptionsDataDeletionReducer(
  state = initialState,
  action: SettingsOptionsDataDeletionAction
): SettingsOptionsDataDeletionState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_DATADELETION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_DATADELETION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsDataDeletion: action.payload.settingsOptionsDataDeletion,
      };
    case RENEW_SETTINGS_OPTIONS_DATADELETION:
      return initialState;
    default:
      return state;
  }
}
