import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsHome } from '../../domain/settingsOptionsHome';

// Action Types

const FETCH_SETTINGS_OPTIONS_HOME = 'FETCH_SETTINGS_OPTIONS_HOME' as const;
const FETCH_SETTINGS_OPTIONS_HOME_REQUEST =
  'FETCH_SETTINGS_OPTIONS_HOME_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_HOME_SUCCESS =
  'FETCH_SETTINGS_OPTIONS_HOME_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_HOME = 'RENEW_SETTINGS_OPTIONS_HOME' as const;

export const SettingsOptionsHomeActionTypes = {
  FETCH_SETTINGS_OPTIONS_HOME,
  FETCH_SETTINGS_OPTIONS_HOME_REQUEST,
  FETCH_SETTINGS_OPTIONS_HOME_SUCCESS,
  RENEW_SETTINGS_OPTIONS_HOME,
};

// Action Creators

function fetchSettingsOptionsHomeAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_HOME,
  };
}

function fetchSettingsOptionsHomeRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_HOME_REQUEST,
  };
}

function fetchSettingsOptionsHomeSuccessAction(data: SettingsOptionsHome) {
  return {
    type: FETCH_SETTINGS_OPTIONS_HOME_SUCCESS,
    payload: { data },
  };
}

function renewSettingsOptionsHomeAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_HOME,
  };
}

export const SettingsOptionsHomeActionCreators = {
  fetchSettingsOptionsHomeAction,
  fetchSettingsOptionsHomeRequestAction,
  fetchSettingsOptionsHomeSuccessAction,
  renewSettingsOptionsHomeAction,
};

// Actions

type SettingsOptionsHomeAction =
  | ReturnType<typeof fetchSettingsOptionsHomeAction>
  | ReturnType<typeof fetchSettingsOptionsHomeRequestAction>
  | ReturnType<typeof fetchSettingsOptionsHomeSuccessAction>
  | ReturnType<typeof renewSettingsOptionsHomeAction>;

// State

type SettingsOptionsHomeState = {
  loadingState: LoadingState;
  settingsOptionsHome?: SettingsOptionsHome;
};

const initialState: SettingsOptionsHomeState = {
  loadingState: 'prepare',
  settingsOptionsHome: undefined,
};

// Selector

export function settingsOptionsHomeSelector(rootState: {
  settingsOptionsHome: SettingsOptionsHomeState;
}) {
  return rootState.settingsOptionsHome.settingsOptionsHome;
}

export function settingsOptionsHomeLoadingStateSelector(rootState: {
  settingsOptionsHome: SettingsOptionsHomeState;
}) {
  return rootState.settingsOptionsHome.loadingState;
}

// Reducer

export function settingsOptionsHomeReducer(
  state = initialState,
  action: SettingsOptionsHomeAction
): SettingsOptionsHomeState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_HOME_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_HOME_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsHome: action.payload.data,
      };
    case RENEW_SETTINGS_OPTIONS_HOME:
      return initialState;
    default:
      return state;
  }
}
