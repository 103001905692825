import { createSelector } from 'reselect';

import {
  FavoriteItem,
  FavoriteParams,
  SettingsFavorite,
} from '../../domain/favorites';
import { filterFavorites } from '../../domain/home/filterFavorites';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';
import { homeSettingFieldsShareFilterSelector } from './homeSetting';

// Action Types

const FETCH_SETTINGS_FAVORITES = 'FETCH_SETTINGS_FAVORITES' as const;
const FETCH_SETTINGS_FAVORITES_REQUEST =
  'FETCH_SETTINGS_FAVORITES_REQUEST' as const;
const FETCH_SETTINGS_FAVORITES_SUCCESS =
  'FETCH_SETTINGS_FAVORITES_SUCCESS' as const;
const RENEW_SETTINGS_FAVORITES = 'RENEW_SETTINGS_FAVORITES' as const;
const POST_SETTINGS_FAVORITES = 'POST_SETTINGS_FAVORITES' as const;
const POST_SETTINGS_FAVORITES_FAILURE =
  'POST_SETTINGS_FAVORITES_FAILURE' as const;
const PATCH_SETTINGS_FAVORITES = 'PATCH_SETTINGS_FAVORITES' as const;
const PATCH_SETTINGS_FAVORITES_SUCCESS =
  'PATCH_SETTINGS_FAVORITES_SUCCESS' as const;
const PATCH_SETTINGS_FAVORITES_FAILURE =
  'PATCH_SETTINGS_FAVORITES_FAILURE' as const;
const DELETE_SETTINGS_FAVORITES = 'DELETE_SETTINGS_FAVORITES' as const;
const DELETE_SETTINGS_FAVORITES_FAILURE =
  'DELETE_SETTINGS_FAVORITES_FAILURE' as const;
const CLEAR_SETTINGS_FAVORITES = 'CLEAR_SETTINGS_FAVORITES' as const;

export const SettingsFavoritesActionTypes = {
  FETCH_SETTINGS_FAVORITES,
  FETCH_SETTINGS_FAVORITES_REQUEST,
  FETCH_SETTINGS_FAVORITES_SUCCESS,
  RENEW_SETTINGS_FAVORITES,
  POST_SETTINGS_FAVORITES,
  POST_SETTINGS_FAVORITES_FAILURE,
  PATCH_SETTINGS_FAVORITES,
  PATCH_SETTINGS_FAVORITES_SUCCESS,
  PATCH_SETTINGS_FAVORITES_FAILURE,
  DELETE_SETTINGS_FAVORITES,
  DELETE_SETTINGS_FAVORITES_FAILURE,
  CLEAR_SETTINGS_FAVORITES,
};

// Action Creators

// お気に入りデータを取得する
function fetchSettingsFavoritesAction(params?: FavoriteParams) {
  return {
    type: FETCH_SETTINGS_FAVORITES,
    payload: { params },
  };
}

function fetchSettingsFavoritesRequestAction() {
  return {
    type: FETCH_SETTINGS_FAVORITES_REQUEST,
  };
}

function fetchSettingsFavoritesSuccessAction(favorites: SettingsFavorite) {
  return {
    type: FETCH_SETTINGS_FAVORITES_SUCCESS,
    payload: { favorites },
  };
}

function renewSettingsFavoritesAction() {
  return {
    type: RENEW_SETTINGS_FAVORITES,
  };
}

// お気に入りデータを新規登録する
function postSettingsFavoritesAction(
  favorites: FavoriteItem[],
  params?: FavoriteParams
) {
  return {
    type: POST_SETTINGS_FAVORITES,
    payload: { favorites, params },
  };
}

function postSettingsFavoritesFailureAction(error: unknown) {
  return {
    type: POST_SETTINGS_FAVORITES_FAILURE,
    payload: { error },
  };
}

// お気に入りデータを更新する
function patchSettingsFavoritesAction(
  id: number,
  favorite: FavoriteItem,
  params?: FavoriteParams
) {
  return {
    type: PATCH_SETTINGS_FAVORITES,
    payload: { id, favorite, params },
  };
}

function patchSettingsFavoritesSuccessAction(favorites: SettingsFavorite) {
  return {
    type: PATCH_SETTINGS_FAVORITES_SUCCESS,
    payload: { favorites },
  };
}

function patchSettingsFavoritesFailureAction(error: unknown) {
  return {
    type: PATCH_SETTINGS_FAVORITES_FAILURE,
    payload: { error },
  };
}

// お気に入りデータを削除する
function deleteSettingsFavoritesAction(id: number, params?: FavoriteParams) {
  return {
    type: DELETE_SETTINGS_FAVORITES,
    payload: { id, params },
  };
}

function deleteSettingsFavoritesFailureAction(error: unknown) {
  return {
    type: DELETE_SETTINGS_FAVORITES_FAILURE,
    payload: { error },
  };
}

export const SettingsFavoritesActionCreators = {
  fetchSettingsFavoritesAction,
  fetchSettingsFavoritesRequestAction,
  fetchSettingsFavoritesSuccessAction,
  renewSettingsFavoritesAction,
  postSettingsFavoritesAction,
  postSettingsFavoritesFailureAction,
  patchSettingsFavoritesAction,
  patchSettingsFavoritesSuccessAction,
  patchSettingsFavoritesFailureAction,
  deleteSettingsFavoritesAction,
  deleteSettingsFavoritesFailureAction,
};

// Actions

export type FetchSettingsFavoritesAction = ReturnType<
  typeof fetchSettingsFavoritesAction
>;
export type PostSettingsFavoritesAction = ReturnType<
  typeof postSettingsFavoritesAction
>;
export type PatchSettingsFavoritesAction = ReturnType<
  typeof patchSettingsFavoritesAction
>;
export type DeleteSettingsFavoritesAction = ReturnType<
  typeof deleteSettingsFavoritesAction
>;

type SettingsFavoritesAction =
  | FetchSettingsFavoritesAction
  | ReturnType<typeof fetchSettingsFavoritesRequestAction>
  | ReturnType<typeof fetchSettingsFavoritesSuccessAction>
  | ReturnType<typeof renewSettingsFavoritesAction>
  | PostSettingsFavoritesAction
  | PatchSettingsFavoritesAction
  | ReturnType<typeof patchSettingsFavoritesSuccessAction>
  | DeleteSettingsFavoritesAction
  | ReturnType<typeof postSettingsFavoritesFailureAction>
  | ReturnType<typeof patchSettingsFavoritesFailureAction>
  | ReturnType<typeof deleteSettingsFavoritesFailureAction>;

// State

type SettingsFavoritesState = {
  loadingState: LoadingState;
  favorites: SettingsFavorite | undefined;
};

export const initialState: SettingsFavoritesState = {
  loadingState: 'prepare',
  favorites: undefined,
};

// Selector

// お気に入り関連データを全て取得する
export const favoritesSelector = (state: RootState) => {
  return state.settingsFavorites.favorites;
};

/**
 * フィルターされたお気に入りデータ
 */
export const filteredFavoritesSelector = createSelector(
  [favoritesSelector, homeSettingFieldsShareFilterSelector],
  (favorites, shareFilter) => {
    return filterFavorites(favorites, { shareFilter });
  }
);

// ユーザー一覧を取得する
export const favoritesUserSelector = (state: RootState) => {
  return state.settingsFavorites.favorites?.users;
};

// お気に入りのローディングステータスを取得する
export function favoritesLoadingStateSelector(rootState: {
  settingsFavorites: SettingsFavoritesState;
}) {
  return rootState.settingsFavorites.loadingState;
}

/**
 * お気に入り関連データをページ名から取得する
 * @param pageName ページ名
 * @returns お気に入り一覧
 */
export const favoritePageNameSelector = (pageName: string) =>
  createSelector(favoritesSelector, (data) => {
    return data?.favorites?.filter((item) => {
      return item.pageName === pageName;
    });
  });

// Reducer

export function favoritesReducer(
  state = initialState,
  action: SettingsFavoritesAction
): SettingsFavoritesState {
  switch (action.type) {
    case FETCH_SETTINGS_FAVORITES_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_FAVORITES_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        favorites: action.payload.favorites,
      };
    case RENEW_SETTINGS_FAVORITES:
      return initialState;
    case PATCH_SETTINGS_FAVORITES_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        favorites: {
          favorites: action.payload.favorites.favorites,
          setting: state.favorites?.setting,
          users: state.favorites?.users,
        },
      };
    default:
      return state;
  }
}
