import { createSelector } from 'reselect';

import { dataDaiCostsFilteredByName } from '../../domain/daiCosts/dataDaiCostsFilteredByName';
import {
  DaiCostsSearchParams,
  PostDaiCostsErrorResponse,
  PostDaiCostsResponse,
  SettingsDaiCostsResponse,
} from '../../domain/daiCosts/types';
import { LoadingState } from '../../domain/schemas';

import { daiCostsSettingNameFilterSelector } from '../ui/daiCostsSetting';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_SETTINGS_DAI_COSTS = 'FETCH_DATA_SETTINGS_DAI_COSTS' as const;
const FETCH_DATA_SETTINGS_DAI_COSTS_REQUEST =
  'FETCH_DATA_SETTINGS_DAI_COSTS_REQUEST' as const;
const FETCH_DATA_SETTINGS_DAI_COSTS_SUCCESS =
  'FETCH_DATA_SETTINGS_DAI_COSTS_SUCCESS' as const;
const POST_DATA_SETTINGS_DAI_COSTS = 'POST_DATA_SETTINGS_DAI_COSTS' as const;
const POST_DATA_SETTINGS_DAI_COSTS_REQUEST =
  'POST_DATA_SETTINGS_DAI_COSTS_REQUEST' as const;
const POST_DATA_SETTINGS_DAI_COSTS_SUCCESS =
  'POST_DATA_SETTINGS_DAI_COSTS_SUCCESS' as const;
const CLEAR_POST_DATA_SETTINGS_DAI_COSTS =
  'CLEAR_POST_DATA_SETTINGS_DAI_COSTS' as const;
const RENEW_DATA_SETTINGS_DAI_COSTS = 'RENEW_DATA_SETTINGS_DAI_COSTS' as const;
const SELECT_POST_DAI_COSTS_ERROR_DETAILS =
  'SELECT_POST_DAI_COSTS_ERROR_DETAILS' as const;
const CLEAR_POST_DAI_COSTS_ERROR_DETAILS =
  'CLEAR_POST_DAI_COSTS_ERROR_DETAILS' as const;

export const DataSettingsDaiCostsActionTypes = {
  FETCH_DATA_SETTINGS_DAI_COSTS,
  FETCH_DATA_SETTINGS_DAI_COSTS_REQUEST,
  FETCH_DATA_SETTINGS_DAI_COSTS_SUCCESS,
  POST_DATA_SETTINGS_DAI_COSTS,
  POST_DATA_SETTINGS_DAI_COSTS_REQUEST,
  POST_DATA_SETTINGS_DAI_COSTS_SUCCESS,
  CLEAR_POST_DATA_SETTINGS_DAI_COSTS,
  RENEW_DATA_SETTINGS_DAI_COSTS,
  SELECT_POST_DAI_COSTS_ERROR_DETAILS,
  CLEAR_POST_DAI_COSTS_ERROR_DETAILS,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchDataSettingsDaiCostsAction(params?: DaiCostsSearchParams) {
  return {
    type: FETCH_DATA_SETTINGS_DAI_COSTS,
    payload: { params },
  };
}

function fetchDataSettingsDaiCostsRequestAction() {
  return {
    type: FETCH_DATA_SETTINGS_DAI_COSTS_REQUEST,
  };
}

function fetchDataSettingsDaiCostsSuccessAction(
  data: SettingsDaiCostsResponse
) {
  return {
    type: FETCH_DATA_SETTINGS_DAI_COSTS_SUCCESS,
    payload: { data },
  };
}

function postDataSettingsDaiCostsAction(body: string) {
  return {
    type: POST_DATA_SETTINGS_DAI_COSTS,
    payload: { body },
  };
}

function postDataSettingsDaiCostsRequestAction() {
  return {
    type: POST_DATA_SETTINGS_DAI_COSTS_REQUEST,
  };
}

function postDataSettingsDaiCostsSuccessAction(data: PostDaiCostsResponse) {
  return {
    type: POST_DATA_SETTINGS_DAI_COSTS_SUCCESS,
    payload: { data },
  };
}

function clearPostDataSettingsDaiCostsAction() {
  return {
    type: CLEAR_POST_DATA_SETTINGS_DAI_COSTS,
  };
}

function renewDataSettingsDaiCostsAction() {
  return {
    type: RENEW_DATA_SETTINGS_DAI_COSTS,
  };
}

function selectPostDaiCostsErrorDetailsAction(
  errorResponse: PostDaiCostsErrorResponse
) {
  return {
    type: SELECT_POST_DAI_COSTS_ERROR_DETAILS,
    payload: { errorResponse },
  };
}

function clearPostDaiCostsErrorDetailsAction() {
  return {
    type: CLEAR_POST_DAI_COSTS_ERROR_DETAILS,
  };
}

export const DataSettingsDaiCostsActionCreators = {
  fetchDataSettingsDaiCostsAction,
  fetchDataSettingsDaiCostsRequestAction,
  fetchDataSettingsDaiCostsSuccessAction,
  postDataSettingsDaiCostsAction,
  postDataSettingsDaiCostsRequestAction,
  postDataSettingsDaiCostsSuccessAction,
  clearPostDataSettingsDaiCostsAction,
  renewDataSettingsDaiCostsAction,
  selectPostDaiCostsErrorDetailsAction,
  clearPostDaiCostsErrorDetailsAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataSettingsDaiCostsAction = ReturnType<
  typeof fetchDataSettingsDaiCostsAction
>;
type FetchDataSettingsDaiCostsRequestAction = ReturnType<
  typeof fetchDataSettingsDaiCostsRequestAction
>;
type FetchDataSettingsDaiCostsSuccessAction = ReturnType<
  typeof fetchDataSettingsDaiCostsSuccessAction
>;
export type PostDataSettingsDaiCostsAction = ReturnType<
  typeof postDataSettingsDaiCostsAction
>;
type PostDataSettingsDaiCostsRequestAction = ReturnType<
  typeof postDataSettingsDaiCostsRequestAction
>;
type PostDataSettingsDaiCostsSuccessAction = ReturnType<
  typeof postDataSettingsDaiCostsSuccessAction
>;
type ClearPostDataSettingsDaiCostsAction = ReturnType<
  typeof clearPostDataSettingsDaiCostsAction
>;
type RenewDataSettingsDaiCostsAction = ReturnType<
  typeof renewDataSettingsDaiCostsAction
>;
type SelectPostDaiCostsErrorDetailsAction = ReturnType<
  typeof selectPostDaiCostsErrorDetailsAction
>;
type ClearPostDaiCostsErrorDetailsAction = ReturnType<
  typeof clearPostDaiCostsErrorDetailsAction
>;

type DataSettingsDaiCostsAction =
  | FetchDataSettingsDaiCostsAction
  | FetchDataSettingsDaiCostsRequestAction
  | FetchDataSettingsDaiCostsSuccessAction
  | PostDataSettingsDaiCostsAction
  | PostDataSettingsDaiCostsRequestAction
  | PostDataSettingsDaiCostsSuccessAction
  | ClearPostDataSettingsDaiCostsAction
  | RenewDataSettingsDaiCostsAction
  | SelectPostDaiCostsErrorDetailsAction
  | ClearPostDaiCostsErrorDetailsAction;

/* ---------------------------------------------------------------
 * State
 */

type DataSettingsDaiCostsState = {
  fetchLoadingState: LoadingState;
  postLoadingState: LoadingState;
  daiCosts: SettingsDaiCostsResponse | undefined;
  postDaiCosts: PostDaiCostsResponse | undefined;
  postDaiCostsError: PostDaiCostsErrorResponse | undefined;
};

const initialState: DataSettingsDaiCostsState = {
  fetchLoadingState: 'prepare',
  postLoadingState: 'prepare',
  daiCosts: undefined,
  postDaiCosts: undefined,
  postDaiCostsError: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * 機械代金登録 全ての状態を取得する
 */
function allDaiCostsSelector(rootState: {
  dataSettingsDaiCosts: DataSettingsDaiCostsState;
}) {
  return rootState.dataSettingsDaiCosts;
}

/**
 * 機械代金登録 データのローディング状態を取得する
 * @returns ローディング状態
 */
export const dataSettingsDaiCostsFetchLoadingStateSelector = createSelector(
  allDaiCostsSelector,
  ({ fetchLoadingState }) => fetchLoadingState
);

/**
 * 機械代金登録 ポストのローディング状態を取得する
 * @returns ローディング状態
 */
export const dataSettingsDaiCostsPostLoadingStateSelector = createSelector(
  allDaiCostsSelector,
  ({ postLoadingState }) => postLoadingState
);

/**
 * 機械代金登録 レスポンスデータ
 */
export const dataSettingsDaiCostsDataSelector = createSelector(
  allDaiCostsSelector,
  ({ daiCosts }) => daiCosts
);

/**
 * 機械代金登録 フィルターによってフィルタリングされたデータ
 */
export const dataDaiCostsFilteredSelector = createSelector(
  [dataSettingsDaiCostsDataSelector, daiCostsSettingNameFilterSelector],
  (daiCosts, nameFilter) => {
    if (daiCosts == null) {
      return daiCosts;
    }

    const filteredByName = dataDaiCostsFilteredByName(daiCosts, nameFilter);

    return filteredByName;
  }
);

/**
 * 機械代金登録 POST レスポンスデータ
 */
export const dataSettingsPostDaiCostsSelector = createSelector(
  allDaiCostsSelector,
  ({ postDaiCosts }) => postDaiCosts
);

/**
 * 機械代金登録 POST エラー詳細
 */
export const dataSettingsPostDaiCostsErrorSelector = createSelector(
  allDaiCostsSelector,
  ({ postDaiCostsError }) => postDaiCostsError
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataSettingsDaiCostsReducer(
  state = initialState,
  action: DataSettingsDaiCostsAction
): DataSettingsDaiCostsState {
  switch (action.type) {
    case FETCH_DATA_SETTINGS_DAI_COSTS_REQUEST:
      return {
        ...state,
        fetchLoadingState: 'loading',
      };
    case FETCH_DATA_SETTINGS_DAI_COSTS_SUCCESS:
      return {
        ...state,
        fetchLoadingState: 'loaded',
        daiCosts: action.payload.data,
      };
    case POST_DATA_SETTINGS_DAI_COSTS_REQUEST:
      return {
        ...state,
        postLoadingState: 'loading',
      };
    case POST_DATA_SETTINGS_DAI_COSTS_SUCCESS:
      return {
        ...state,
        postLoadingState: 'loaded',
        postDaiCosts: action.payload.data,
      };
    case CLEAR_POST_DATA_SETTINGS_DAI_COSTS:
      return {
        ...state,
        postDaiCosts: undefined,
      };
    case SELECT_POST_DAI_COSTS_ERROR_DETAILS:
      return {
        ...state,
        postLoadingState: 'loaded',
        postDaiCostsError: action.payload.errorResponse,
      };
    case CLEAR_POST_DAI_COSTS_ERROR_DETAILS:
      return {
        ...state,
        postDaiCostsError: undefined,
      };
    case RENEW_DATA_SETTINGS_DAI_COSTS:
      return initialState;
    default:
      return state;
  }
}
