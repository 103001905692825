import { LoadingState } from '../../domain/schemas';
import { SettingsPlansDetails } from '../../domain/settingsPlansDetails';

// Action Types

const FETCH_SETTINGS_PLANS_DETAILS = 'FETCH_SETTINGS_PLANS_DETAILS' as const;
const FETCH_SETTINGS_PLANS_DETAILS_REQUEST =
  'FETCH_SETTINGS_PLANS_DETAILS_REQUEST' as const;
const FETCH_SETTINGS_PLANS_DETAILS_SUCCESS =
  'FETCH_SETTINGS_PLANS_DETAILS_SUCCESS' as const;
const RENEW_SETTINGS_PLANS_DETAILS = 'RENEW_SETTINGS_PLANS_DETAILS' as const;

export const SettingsPlansDetailsActionTypes = {
  FETCH_SETTINGS_PLANS_DETAILS,
  FETCH_SETTINGS_PLANS_DETAILS_REQUEST,
  FETCH_SETTINGS_PLANS_DETAILS_SUCCESS,
  RENEW_SETTINGS_PLANS_DETAILS,
};

// Action Creators

function fetchSettingsPlansDetailsAction(
  params: SettingsPlansDetails['setting']
) {
  return {
    type: FETCH_SETTINGS_PLANS_DETAILS,
    payload: { params },
  };
}

function fetchSettingsPlansDetailsRequestAction() {
  return {
    type: FETCH_SETTINGS_PLANS_DETAILS_REQUEST,
  };
}

function fetchSettingsPlansDetailsSuccessAction(
  settingsPlansDetails: SettingsPlansDetails
) {
  return {
    type: FETCH_SETTINGS_PLANS_DETAILS_SUCCESS,
    payload: { settingsPlansDetails },
  };
}

function renewSettingsPlansDetailsACtion() {
  return {
    type: RENEW_SETTINGS_PLANS_DETAILS,
  };
}

export const SettingsPlansDetailsActionCreators = {
  fetchSettingsPlansDetailsAction,
  fetchSettingsPlansDetailsRequestAction,
  fetchSettingsPlansDetailsSuccessAction,
  renewSettingsPlansDetailsACtion,
};

// Actions

export type FetchSettingsPlansDetailsAction = ReturnType<
  typeof fetchSettingsPlansDetailsAction
>;

type SettingsPlansDetailsAction =
  | FetchSettingsPlansDetailsAction
  | ReturnType<typeof fetchSettingsPlansDetailsRequestAction>
  | ReturnType<typeof fetchSettingsPlansDetailsSuccessAction>
  | ReturnType<typeof renewSettingsPlansDetailsACtion>;

// State

type SettingsPlansDetailsState = {
  settingsPlansDetails?: SettingsPlansDetails;
  loadingState: LoadingState;
};

const initialState: SettingsPlansDetailsState = {
  settingsPlansDetails: undefined,
  loadingState: 'prepare',
};

// Selector

export function settingsPlansDetailsSelector(rootState: {
  settingsPlansDetails: SettingsPlansDetailsState;
}) {
  return rootState.settingsPlansDetails.settingsPlansDetails;
}

// Reducer

export function settingsPlansDetailsReducer(
  state = initialState,
  action: SettingsPlansDetailsAction
): SettingsPlansDetailsState {
  switch (action.type) {
    case FETCH_SETTINGS_PLANS_DETAILS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_PLANS_DETAILS_SUCCESS:
      return {
        ...state,
        settingsPlansDetails: action.payload.settingsPlansDetails,
        loadingState: 'loaded',
      };
    case RENEW_SETTINGS_PLANS_DETAILS:
      return initialState;
    default:
      return state;
  }
}
