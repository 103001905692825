import { createSelector } from 'reselect';

import {
  DataHallShu,
  DataHallShuParams,
  DataHallShuSummary,
  DataHallShuSummaryParams,
} from '../../domain/dataHallShu';
import { HallReportsFormConditions } from '../../domain/hallReportsFormConditions';
import { MarkingOption } from '../../domain/marking';
import { Option, OrderType } from '../../domain/schemas';

import { RootState } from '../../store';
import { dataHallShuDefaultValue } from '../../utils/dataHallDefaultValue';
import { getOrderedTableData } from '../../utils/orderedCell';

/**
 * Action Types
 */

const FETCH_DATA_HALL_SHU = 'FETCH_DATA_HALL_SHU' as const;
const FETCH_DATA_HALL_SHU_REQUEST = 'FETCH_DATA_HALL_SHU_REQUEST' as const;
const FETCH_DATA_HALL_SHU_SUCCESS = 'FETCH_DATA_HALL_SHU_SUCCESS' as const;

const FETCH_DATA_HALL_SHU_SUMMARY = 'FETCH_DATA_HALL_SHU_SUMMARY' as const;
const FETCH_DATA_HALL_SHU_SUMMARY_REQUEST =
  'FETCH_DATA_HALL_SHU_SUMMARY_REQUEST' as const;
const FETCH_DATA_HALL_SHU_SUMMARY_SUCCESS =
  'FETCH_DATA_HALL_SHU_SUMMARY_SUCCESS' as const;

const INIT_DATA_HALL_SHU = 'INIT_DATA_HALL_SHU' as const;

const SEARCH_DATA_HALL_SHU = 'SEARCH_DATA_HALL_SHU' as const;
const SEARCH_DATA_HALL_SHU_SORT = 'SEARCH_DATA_HALL_SHU_SORT' as const;
const SEARCH_DATA_HALL_SHU_MARKING = 'SEARCH_DATA_HALL_SHU_MARKING' as const;
const SEARCH_DATA_HALL_SHU_FIELD_TYPE =
  'SEARCH_DATA_HALL_SHU_FIELD_TYPE' as const;

const CLEAR_ALL_DATA_HALL_SHU = 'CLEAR_ALL_DATA_HALL_SHU' as const;

const SELECT_DATA_HALL_SHU_COLUMNS_ORDER =
  'SELECT_DATA_HALL_SHU_COLUMNS_ORDER' as const;
const CHANGE_DATA_HALL_SHU_COLUMNS_ORDER =
  'CHANGE_DATA_HALL_SHU_COLUMNS_ORDER' as const;

const RENEW_DATA_HALL_SHU = 'RENEW_DATA_HALL_SHU' as const;

export const DataHallShuActionTypes = {
  FETCH_DATA_HALL_SHU,
  FETCH_DATA_HALL_SHU_REQUEST,
  FETCH_DATA_HALL_SHU_SUCCESS,
  FETCH_DATA_HALL_SHU_SUMMARY,
  FETCH_DATA_HALL_SHU_SUMMARY_REQUEST,
  FETCH_DATA_HALL_SHU_SUMMARY_SUCCESS,
  INIT_DATA_HALL_SHU,
  SEARCH_DATA_HALL_SHU,
  SEARCH_DATA_HALL_SHU_SORT,
  SEARCH_DATA_HALL_SHU_MARKING,
  SEARCH_DATA_HALL_SHU_FIELD_TYPE,
  CLEAR_ALL_DATA_HALL_SHU,
  SELECT_DATA_HALL_SHU_COLUMNS_ORDER,
  CHANGE_DATA_HALL_SHU_COLUMNS_ORDER,
  RENEW_DATA_HALL_SHU,
};

/**
 * Action Creators
 */

/**
 * 検索条件を元に種別実績テーブルを取得する
 * @param params 検索条件（種別実績テーブル）
 */
function fetchDataHallShuAction(params: DataHallShuParams) {
  return {
    type: FETCH_DATA_HALL_SHU,
    payload: { params },
  };
}

/**
 * 検索条件を元に種別集計概要を取得取得する
 * @param params 検索条件（種別集計概要）
 */
function fetchDataHallShuSummaryAction(params: DataHallShuSummaryParams) {
  return {
    type: FETCH_DATA_HALL_SHU_SUMMARY,
    payload: { params },
  };
}

/**
 * 種別実績テーブル取得時に呼ばれる
 */
function fetchDataHallShuRequestAction() {
  return {
    type: FETCH_DATA_HALL_SHU_REQUEST,
  };
}

/**
 * 種別実績テーブル取得時に呼ばれる
 */
function fetchDataHallShuSummaryRequestAction() {
  return {
    type: FETCH_DATA_HALL_SHU_SUMMARY_REQUEST,
  };
}

/**
 * 種別実績テーブル取得成功時データを保存する
 * @param shu 種別実績テーブルデータ
 */
function fetchDataHallShuSuccessAction(shu: DataHallShu) {
  return {
    type: FETCH_DATA_HALL_SHU_SUCCESS,
    payload: { shu },
  };
}

/**
 * 種別集計概要取得成功時データを保存する
 * @param shu 種別集計概要データ
 */
function fetchDataHallShuSummarySuccessAction(summary: DataHallShuSummary) {
  return {
    type: FETCH_DATA_HALL_SHU_SUMMARY_SUCCESS,
    payload: { summary },
  };
}

/**
 * 種別実績全体を初回取得する（種別実績テーブル・種別集計概要）
 */
function initDataHallShuAction() {
  return {
    type: INIT_DATA_HALL_SHU,
  };
}

/**
 * 検索条件フォームで変更した検索条件でデータを取得する（種別実績テーブル・種別集計概要）
 * @param params 検索条件（検索フォーム）
 */
function searchDataHallShuAction(params: HallReportsFormConditions) {
  return {
    type: SEARCH_DATA_HALL_SHU,
    payload: { params },
  };
}

/**
 * 選択したソート条件で種別実績テーブルデータを再取得する
 * @param sort ソートする条件
 * @param order 昇順・降順
 */
function searchDataHallShuSortAction(sort: string, order: OrderType) {
  return {
    type: SEARCH_DATA_HALL_SHU_SORT,
    payload: { sort, order },
  };
}

/**
 * 指定したマーキング条件で種別実績テーブルデータを再取得する
 * @param markingOption マーキング項目
 * @param isFiltering 該当行のみ表示するか
 */
function searchDataHallShuMarkingAction(
  markingOption: MarkingOption,
  isFiltering: boolean
) {
  return {
    type: SEARCH_DATA_HALL_SHU_MARKING,
    payload: { markingOption, isFiltering },
  };
}

/**
 * 指定した表示項目に絞って種別実績テーブルデータを再取得する
 * @param fields 表示項目
 */
function searchDataHallShuFieldTypeAction(fields: Option[]) {
  return {
    type: SEARCH_DATA_HALL_SHU_FIELD_TYPE,
    payload: { fields },
  };
}

/**
 * 種別実績のテーブルとSummaryを削除する
 */
function clearAllDataHallShuAction() {
  return {
    type: CLEAR_ALL_DATA_HALL_SHU,
  };
}

/**
 * 種別実績の並び替え情報を登録する（Sagaで並び替え確定時）
 */
function selectDataHallShuColumnsOrderAction(columnsOrder: string[]) {
  return {
    type: SELECT_DATA_HALL_SHU_COLUMNS_ORDER,
    payload: { columnsOrder },
  };
}

/**
 * 種別実績の列の並び替え実行時にSagaで再計算する
 */
function changeDataHallShuColumnsOrderAction(
  draggedId?: string,
  droppedId?: string
) {
  return {
    type: CHANGE_DATA_HALL_SHU_COLUMNS_ORDER,
    payload: { draggedId, droppedId },
  };
}

/**
 * 種別実績のテーブルとSummaryを削除する
 */
function renewDataHallShuAction() {
  return {
    type: RENEW_DATA_HALL_SHU,
  };
}

export const DataHallShuActionCreators = {
  fetchDataHallShuAction,
  fetchDataHallShuRequestAction,
  fetchDataHallShuSuccessAction,
  fetchDataHallShuSummaryAction,
  fetchDataHallShuSummaryRequestAction,
  fetchDataHallShuSummarySuccessAction,
  initDataHallShuAction,
  searchDataHallShuAction,
  searchDataHallShuSortAction,
  searchDataHallShuMarkingAction,
  searchDataHallShuFieldTypeAction,
  clearAllDataHallShuAction,
  selectDataHallShuColumnsOrderAction,
  changeDataHallShuColumnsOrderAction,
  renewDataHallShuAction,
};

/**
 * Actions
 */

export type FetchDataHallShuAction = ReturnType<typeof fetchDataHallShuAction>;

export type FetchDataHallShuSummaryAction = ReturnType<
  typeof fetchDataHallShuSummaryAction
>;
type FetchDataHallShuSuccessAction = ReturnType<
  typeof fetchDataHallShuSuccessAction
>;
type InitDataHallShuAction = ReturnType<typeof initDataHallShuAction>;
export type SearchDataHallShuAction = ReturnType<
  typeof searchDataHallShuAction
>;
export type SearchDataHallShuSortAction = ReturnType<
  typeof searchDataHallShuSortAction
>;
export type SearchDataHallShuMarkingAction = ReturnType<
  typeof searchDataHallShuMarkingAction
>;
export type SearchDataHallShuFieldTypeAction = ReturnType<
  typeof searchDataHallShuFieldTypeAction
>;
type ClearAllDataHallShuAction = ReturnType<typeof clearAllDataHallShuAction>;
type SelectDataHallShuColumnsOrderAction = ReturnType<
  typeof selectDataHallShuColumnsOrderAction
>;
export type ChangeDataHallShuColumnsOrderAction = ReturnType<
  typeof changeDataHallShuColumnsOrderAction
>;

type DataHallShuAction =
  | FetchDataHallShuAction
  | FetchDataHallShuSummaryAction
  | FetchDataHallShuSuccessAction
  | InitDataHallShuAction
  | SearchDataHallShuAction
  | SearchDataHallShuSortAction
  | SearchDataHallShuMarkingAction
  | SearchDataHallShuFieldTypeAction
  | ClearAllDataHallShuAction
  | SelectDataHallShuColumnsOrderAction
  | ChangeDataHallShuColumnsOrderAction
  | ReturnType<typeof fetchDataHallShuRequestAction>
  | ReturnType<typeof fetchDataHallShuSuccessAction>
  | ReturnType<typeof fetchDataHallShuSummaryRequestAction>
  | ReturnType<typeof fetchDataHallShuSummarySuccessAction>
  | ReturnType<typeof renewDataHallShuAction>;

/**
 * State
 */

type DataHallShuState = {
  /**
   * 種別集計概要のローディング状態（ローディング時:true）
   */
  isSummaryLoading: boolean;
  /**
   * 種別集計概要データ（未取得時undefined）
   */
  shuSummary?: DataHallShuSummary;
  /**
   * 種別実績テーブルのローディング状態（ローディング時:true）
   */
  isShuLoading: boolean;
  /**
   * 種別実績テーブルデータ（未取得時undefined）
   */
  shu?: DataHallShu;
  /**
   * 種別実績テーブル並び替えデータ
   */
  columnsOrder?: string[];
};

// Stateの初期値
const initialState: DataHallShuState = {
  isSummaryLoading: false,
  shuSummary: undefined,
  isShuLoading: false,
  shu: undefined,
  columnsOrder: [],
};

/**
 * Selector
 */

/**
 * 種別実績全体のデータを取得する
 * @returns 種別実績全体のデータ
 */
export const dataHallShuSelector = (state: RootState) => state.dataHallShu;

/**
 * 全てのローディング状態を全て取得する
 * isSummaryLoadingとisShuLoadingがどちらかでもローディング中の場合trueを返す
 */
export const dataHallShuAllLoadingSelector = createSelector(
  dataHallShuSelector,
  (data) => data.isSummaryLoading || data.isShuLoading
);

/**
 * 種別実績テーブルのローディング状態を取得する
 * @returns ローディング状態（ローディング時:true）
 */
export const dataHallShuTableLoadingSelector = (state: RootState) =>
  state.dataHallShu.isShuLoading;

/**
 * 種別集計概要のローディング状態を取得する
 * @returns ローディング状態（ローディング時:true）
 */
export const dataHallShuSummaryLoadingSelector = (state: RootState) =>
  state.dataHallShu.isSummaryLoading;

/**
 * 種別実績テーブルが存在するか取得する
 * @returns ローディング状態（ローディング時:true）
 */
export const dataHallShuTableIsExistSelector = (state: RootState) =>
  state.dataHallShu.shu !== undefined;

/**
 * 種別実績テーブルデータすべてを取得する
 * @returns 種別実績テーブルデータ（空の場合はデフォルトのデータ）
 */
const dataHallShuTableSelector = createSelector(dataHallShuSelector, (data) => {
  const defaultValue = dataHallShuDefaultValue();
  return data.shu ?? defaultValue;
});

/**
 * 種別実績テーブルのカラム部分（カラム行）のみ取得
 * @returns テーブルデータのカラム行（rowsを除いた部分）
 */
export const dataHallShuDataColumnsSelector = createSelector(
  dataHallShuTableSelector,
  (data) => data.data.columns ?? []
);

/**
 * 種別実績テーブルの現在の検索条件を取得する
 * @returns 現在の検索条件
 */
export const dataHallShuTableSearchConditionSelector = createSelector(
  dataHallShuTableSelector,
  (data) => data.setting
);

/**
 * 種別実績テーブルで現在選択中の表示項目一覧を取得する
 * @returns 現在選択中の表示項目一覧
 */
export const dataHallShuTableSelectedFieldsSelector = createSelector(
  dataHallShuTableSearchConditionSelector,
  (param) => {
    return param.fields ?? [];
  }
);

/**
 * 種別実績テーブルで現在選択中のマーキングを取得する
 * @returns 現在選択中のマーキング
 */
export const dataHallShuTableSelectedMarkingSelector = createSelector(
  dataHallShuTableSearchConditionSelector,
  (param) => {
    return param.marking ?? '';
  }
);

/**
 * 種別実績テーブルで現在マーキング適用中か取得する
 * @returns マーキング適用中か（マーキング適用時:true）
 */
export const dataHallShuTableIsMarkingSelector = createSelector(
  dataHallShuTableSelectedMarkingSelector,
  (marking) => {
    return marking !== '';
  }
);

/**
 * 種別実績テーブルで現在該当行のみ表示するにチェックが入っているかを取得する
 * @returns 現在該当行のみ表示する（true:チェックあり）
 */
export const dataHallShuTableIsFilteringSelector = createSelector(
  dataHallShuTableSearchConditionSelector,
  (param) => {
    return param.isFiltering ?? false;
  }
);

/**
 * 種別実績テーブルで現在選択中の並び替えキーを取得する
 * @returns 現在選択中の並び替えキー
 */
export const dataHallShuTableSortSelector = createSelector(
  dataHallShuTableSearchConditionSelector,
  (param) => {
    return param.sort ?? 'daisu';
  }
);

/**
 * 種別実績テーブルで現在選択中の並び方を取得する
 * @returns 現在選択中の並び方
 */
export const dataHallShuTableOrderSelector = createSelector(
  dataHallShuTableSearchConditionSelector,
  (param) => {
    return param.order ?? ('desc' as const);
  }
);

/**
 * 種別実績テーブルで検索済みの期間を取得する
 * @returns 検索期間
 */
export const dataHallShuTableYmdListSelector = createSelector(
  dataHallShuTableSearchConditionSelector,
  ({ ymdList }) => ymdList
);

/**
 * 種別実績テーブルで検索済みの店舗を取得する
 * @returns 店舗
 */
export const dataHallShuTableHallsSelector = createSelector(
  dataHallShuTableSearchConditionSelector,
  ({ halls }) => halls
);

/**
 * 種別集計概要が存在するか取得する
 * @returns ローディング状態（ローディング時:true）
 */
export const dataHallShuSummaryIsExistSelector = (state: RootState) =>
  state.dataHallShu.shuSummary !== undefined;

/**
 * 種別実績テーブルの現在の検索条件を取得する（お気に入り保存用）
 * @returns 現在の種別実績テーブル検索条件
 */
export const dataHallShuSettingSelector = (state: RootState) =>
  state.dataHallShu.shu?.setting;

/**
 * 種別実績概要の現在の検索条件を取得する（お気に入り保存用）
 * @returns 現在の種別実績概要の検索条件
 */
export const dataHallShuSummarySettingSelector = (state: RootState) =>
  state.dataHallShu.shuSummary?.setting;

/**
 * 種別実績テーブルの並び替え情報を取得
 * @returns 並び替え情報
 */
export const dataHallShuColumnsOrderSelector = (state: RootState) =>
  state.dataHallShu.columnsOrder;

/**
 * 種別実績テーブルの並び替え後のテーブルデータを取得する
 * @returns 並び替え後のテーブルデータ
 */
export const dataHallShuOrderedData = createSelector(
  [dataHallShuTableSelector, dataHallShuColumnsOrderSelector],
  (tableData, ordered) => {
    if (!ordered) return tableData.data;

    return getOrderedTableData(
      ordered,
      tableData.data.columns,
      tableData.data.rows
    );
  }
);

/**
 * Reducer
 */

export function dataHallShuReducer(
  state = initialState,
  action: DataHallShuAction
): DataHallShuState {
  switch (action.type) {
    case FETCH_DATA_HALL_SHU_REQUEST:
      return {
        ...state,
        isShuLoading: true,
      };
    case FETCH_DATA_HALL_SHU_SUCCESS:
      return {
        ...state,
        isShuLoading: false,
        shu: action.payload.shu,
      };
    case FETCH_DATA_HALL_SHU_SUMMARY_REQUEST:
      return {
        ...state,
        isSummaryLoading: true,
      };
    case FETCH_DATA_HALL_SHU_SUMMARY_SUCCESS:
      return {
        ...state,
        isSummaryLoading: false,
        shuSummary: action.payload.summary,
      };
    case CLEAR_ALL_DATA_HALL_SHU:
      return {
        ...state,
        shu: initialState.shu,
        shuSummary: initialState.shuSummary,
        columnsOrder: initialState.columnsOrder,
      };
    case SELECT_DATA_HALL_SHU_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
      };
    case RENEW_DATA_HALL_SHU:
      return initialState;
    default:
      return state;
  }
}
