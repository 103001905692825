import {
  add,
  eachDayOfInterval,
  endOfISOWeek,
  endOfMonth,
  endOfWeek,
  startOfISOWeek,
  startOfMonth,
} from 'date-fns';

import { calcUnitDataCurrentDate } from './calcUnitDataListCurrentDate';
import {
  ComparativeSection,
  makeComparisonDateFromSelectedComparativeSection,
} from './comparativeSectionUtil';

// 台別データ一覧で使用する期間タイプ
export const unitDataReportsDateRanges = [
  '前日',
  '当日',
  '前週',
  '今週',
  '前月',
  '今月',
  '過去7日',
  '過去14日',
  '過去28日',
  'カスタム',
] as const;
export type UnitDataListReportsDateRange =
  (typeof unitDataReportsDateRanges)[number];

// 日付の単位
export const unitDataReportsDateUnits = [
  '日',
  '週',
  '月',
  '範囲選択',
  'カスタム', //カスタムから範囲選択に名称変更したが、過去のお気に入りを考慮して残す
  '自由選択',
] as const;
export type UnitDataListReportsDateUnit =
  (typeof unitDataReportsDateUnits)[number];

/**
 * 台別データ一覧
 * 検索パラメータに渡す日付データを算出する
 *
 * @param dateRange 期間指定
 * @param dateUnit 日付タイプ
 * @param currentDate 基準日（未指定の場合は本日）
 *
 */
export const makeUnitDataListDateFromDateUnitAndRange = (
  dateRange: UnitDataListReportsDateRange,
  dateUnit: UnitDataListReportsDateUnit,
  currentDate: Date = new Date(),
  currentComparisonDate: Date | undefined = undefined,
  //Todo: 当日除外(excludeToday)などの処理が抜けているため別issueにて精査する
  //src/components/organisms/UnitDataListDateRangeSelect/useUnitDataListDateRangeSelect.tsxにてexcludeTodayが渡されていないので現状は使われていない。
  //https://app.zenhub.com/workspaces/claris-60b56db504e3ff00150ebc22/issues/gh/dkclaris/claris-general/5051
  comparisonSection?: ComparativeSection,
  excludeToday?: boolean
): {
  ymdList: Date[];
  ymdComparisonList: Date[];
} => {
  let startDate = currentDate;
  let endDate = currentDate;
  let ymdList = [currentDate];
  switch (dateRange) {
    case '前日': {
      endDate = add(currentDate, { days: -1 });
      startDate = endDate;
      ymdList = [startDate];
      break;
    }
    case '当日': {
      break;
    }
    case '前週': {
      const lastWeek = add(currentDate, { days: -7 });
      startDate = startOfISOWeek(lastWeek);
      endDate = add(startDate, { days: 6 });
      ymdList = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });
      break;
    }
    case '今週': {
      startDate = startOfISOWeek(currentDate);
      ymdList = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });
      break;
    }
    case '前月': {
      const lastMonth = add(currentDate, { months: -1 });
      startDate = startOfMonth(lastMonth);
      endDate = endOfMonth(lastMonth);
      ymdList = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });
      break;
    }
    case '今月': {
      startDate = startOfMonth(currentDate);
      ymdList = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });
      break;
    }
    case '過去7日': {
      startDate = add(startDate, { days: -7 });
      endDate = add(endDate, { days: -1 });
      break;
    }
    case '過去14日': {
      startDate = add(startDate, { days: -14 });
      endDate = add(endDate, { days: -1 });
      break;
    }
    case '過去28日': {
      startDate = add(startDate, { days: -28 });
      endDate = add(endDate, { days: -1 });
      break;
    }
    case 'カスタム':
      // do nothing
      break;
  }
  let startComparisonDate = startDate;
  let endComparisonDate = endDate;
  let ymdComparisonList = eachDayOfInterval({
    start: startComparisonDate,
    end: endComparisonDate,
  });
  switch (dateUnit) {
    case '日': {
      startDate = endDate;
      ymdList = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });
      if (currentComparisonDate) {
        startComparisonDate = endComparisonDate = currentComparisonDate;
        ymdComparisonList = eachDayOfInterval({
          start: startComparisonDate,
          end: endComparisonDate,
        });
      } else {
        endComparisonDate = add(startDate, { days: -7 });
        startComparisonDate = endComparisonDate;
        ymdComparisonList = eachDayOfInterval({
          start: startComparisonDate,
          end: endComparisonDate,
        });
      }
      break;
    }
    case '週': {
      startDate = startOfISOWeek(endDate);
      endDate = endOfISOWeek(startDate);
      ymdList = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });
      if (currentComparisonDate) {
        startComparisonDate = startOfISOWeek(currentComparisonDate);
        endComparisonDate = endOfISOWeek(currentComparisonDate);
        ymdComparisonList = eachDayOfInterval({
          start: startComparisonDate,
          end: endComparisonDate,
        });
      } else {
        const lastWeek = add(startDate, { days: -7 });
        startComparisonDate = startOfISOWeek(lastWeek);
        endComparisonDate = endOfISOWeek(lastWeek);
        ymdComparisonList = eachDayOfInterval({
          start: startComparisonDate,
          end: endComparisonDate,
        });
      }
      break;
    }
    case '月': {
      startDate = startOfMonth(endDate);
      endDate = endOfMonth(startDate);
      ymdList = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });
      if (currentComparisonDate) {
        startComparisonDate = startOfMonth(currentComparisonDate);
        endComparisonDate = endOfMonth(currentComparisonDate);
        ymdComparisonList = eachDayOfInterval({
          start: startComparisonDate,
          end: endComparisonDate,
        });
      } else {
        const lastMonth = add(startDate, { months: -1 });
        startComparisonDate = startOfMonth(lastMonth);
        endComparisonDate = endOfMonth(lastMonth);
        ymdComparisonList = eachDayOfInterval({
          start: startComparisonDate,
          end: endComparisonDate,
        });
      }
      break;
    }
    case '自由選択': {
      break;
    }
    //currentComparisionDateの考慮が漏れている
    case '範囲選択': {
      if (dateRange === '過去7日') {
        startComparisonDate = add(startDate, { days: -7 });
        endComparisonDate = add(endDate, { days: -7 });
        ymdComparisonList = eachDayOfInterval({
          start: startComparisonDate,
          end: endComparisonDate,
        });
      } else if (dateRange === '過去14日') {
        startComparisonDate = add(startDate, { days: -14 });
        endComparisonDate = add(endDate, { days: -14 });
        ymdComparisonList = eachDayOfInterval({
          start: startComparisonDate,
          end: endComparisonDate,
        });
      } else if (dateRange === '過去28日') {
        startComparisonDate = add(startDate, { days: -28 });
        endComparisonDate = add(endDate, { days: -28 });
        ymdComparisonList = eachDayOfInterval({
          start: startComparisonDate,
          end: endComparisonDate,
        });
      }
      break;
    }
  }
  // 最終日が本日より大きい場合は本日に合わせる
  const today = new Date();
  ymdList = eachDayOfInterval({
    start: startDate,
    end: endDate > today ? today : endDate,
  });

  // comparisonSectionとexcludeTodayが指定されている場合は
  // ymdComparisonListを再計算する
  if (
    comparisonSection &&
    comparisonSection !== 'カスタム' &&
    excludeToday != null
  ) {
    const currentDate = calcUnitDataCurrentDate(dateRange);
    const { ymdComparisonList } =
      makeComparisonDateFromSelectedComparativeSection(
        comparisonSection,
        currentDate,
        new Date(),
        excludeToday
      );
    return {
      ymdList,
      ymdComparisonList,
    };
  }

  return {
    ymdList,
    ymdComparisonList,
  };
};

/**
 * 台別データ一覧
 * 基準日と選択日と日付単位から指定された日付の末尾を取得する
 * @param dateUnit 日付単位
 * @param selectDate 選択された日付
 * @param currentDate 基準日（未指定の場合は本日）
 */
export const makeUnitDataListSelectedDateFromDateUnit = (
  dateUnit: UnitDataListReportsDateUnit,
  selectDate: Date,
  currentDate: Date = new Date()
): Date => {
  switch (dateUnit) {
    case '日': {
      // 日単位の場合は選択された日付をそのまま返す
      if (selectDate > currentDate) {
        return currentDate;
      } else {
        return selectDate;
      }
    }
    case '週': {
      // 週単位で基準日を超えていなければ選択された週の末尾を返す
      const selectedDate = endOfWeek(selectDate, { weekStartsOn: 1 });
      if (selectedDate > currentDate) {
        return currentDate;
      } else {
        return selectedDate;
      }
    }
    case '月': {
      // 月単位で基準日を超えていなければ選択された月の末尾を返す
      const selectedDate = endOfMonth(selectDate);
      if (selectedDate > currentDate) {
        return currentDate;
      } else {
        return selectedDate;
      }
    }
    case 'カスタム':
    case '範囲選択': {
      // 範囲選択（カスタム）の場合は選択日が末尾になるが、最終日が本日より大きい場合は本日に合わせる
      if (selectDate > currentDate) {
        return currentDate;
      }
      return selectDate;
    }
    case '自由選択': {
      // 自由選択の場合は選択日が末尾になるが、最終日が本日より大きい場合は本日に合わせる
      if (selectDate > currentDate) {
        return currentDate;
      }
      return selectDate;
    }
  }
};

/**
 * 台別データ一覧
 * 期間指定から対象の日付単位を取得する
 * @param dateRange 期間指定
 */
export const makeUnitDataListDateUnit = (
  dateRange: UnitDataListReportsDateRange
): UnitDataListReportsDateUnit => {
  switch (dateRange) {
    case '前日':
      return '日';
    case '当日':
      return '日';
    case '前週':
      return '週';
    case '今週':
      return '週';
    case '前月':
      return '月';
    case '今月':
      return '月';
    case 'カスタム':
      return '範囲選択';
    default:
      // もしくは該当しない単位の場合は範囲選択を返す
      return '範囲選択';
  }
};

/**
 * 台別データ一覧
 * 日付タイプの名称変換（過去のお気に入りの呼び出し時に使用）
 * @param dateUnit 日付タイプ
 */
export const changeUnitDataListNameDateUnit = (
  dateUnit: UnitDataListReportsDateUnit
): UnitDataListReportsDateUnit => {
  switch (dateUnit) {
    case 'カスタム':
      return '範囲選択';
    default:
      return dateUnit;
  }
};
