import { createSelector } from 'reselect';

import { ChainStoreReportsFavorite } from '../../domain/chain/types';
import { ChainReportsFormConditions } from '../../domain/chainReportsFormConditions';
import {
  DataChainStoreShu,
  DataChainStoreShuParams,
  DataChainStoreShuSummary,
  DataChainStoreShuSummaryParams,
} from '../../domain/dataChainStoreShu';
import { MarkingOption } from '../../domain/marking';
import { Option, OrderType } from '../../domain/schemas';

import { RootState } from '../../store';
import { getOrderedTableData } from '../../utils/orderedCell';
import {
  ChainStoreSettingActionTypes,
  ResetChainStoreShuAction,
} from '../ui/chainStoreReportsSetting';

const FETCH_DATA_CHAIN_STORE_SHU_WHOLE =
  'FETCH_DATA_CHAIN_STORE_SHU_WHOLE' as const;
const FETCH_DATA_CHAIN_STORE_SHU_WHOLE_REQUEST =
  'FETCH_DATA_CHAIN_STORE_SHU_WHOLE_REQUEST' as const;
const FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUCCESS =
  'FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUCCESS' as const;

const FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY =
  'FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY' as const;
const FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_REQUEST =
  'FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_REQUEST' as const;
const FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_SUCCESS =
  'FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_SUCCESS' as const;

const INIT_DATA_CHAIN_STORE_SHU_WHOLE =
  'INIT_DATA_CHAIN_STORE_SHU_WHOLE' as const;

const SEARCH_DATA_CHAIN_STORE_SHU_WHOLE =
  'SEARCH_DATA_CHAIN_STORE_SHU_WHOLE' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_RESET =
  'SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_RESET' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_SORT =
  'SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_SORT' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_MARKING =
  'SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_MARKING' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FIELD_TYPE =
  'SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FIELD_TYPE' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FAVORITE =
  'SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FAVORITE' as const;

const CLEAR_DATA_CHAIN_STORE_SHU_WHOLE =
  'CLEAR_DATA_CHAIN_STORE_SHU_WHOLE' as const;
const CLEAR_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY =
  'CLEAR_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY' as const;
const CLEAR_ALL_DATA_CHAIN_STORE_SHU_WHOLE =
  'CLEAR_ALL_DATA_CHAIN_STORE_SHU_WHOLE' as const;

const SELECT_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER =
  'SELECT_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER' as const;
const CHANGE_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER =
  'CHANGE_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER' as const;

const RENEW_DATA_CHAIN_STORE_SHU_WHOLE =
  'RENEW_DATA_CHAIN_STORE_SHU_WHOLE' as const;

export const DataChainStoreShuWholeActionTypes = {
  FETCH_DATA_CHAIN_STORE_SHU_WHOLE,
  FETCH_DATA_CHAIN_STORE_SHU_WHOLE_REQUEST,
  FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUCCESS,
  FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY,
  FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_REQUEST,
  FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_SUCCESS,
  INIT_DATA_CHAIN_STORE_SHU_WHOLE,
  SEARCH_DATA_CHAIN_STORE_SHU_WHOLE,
  SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_RESET,
  SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FAVORITE,
  SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_SORT,
  SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_MARKING,
  SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FIELD_TYPE,
  CLEAR_DATA_CHAIN_STORE_SHU_WHOLE,
  CLEAR_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY,
  CLEAR_ALL_DATA_CHAIN_STORE_SHU_WHOLE,
  SELECT_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER,
  CHANGE_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER,
  RENEW_DATA_CHAIN_STORE_SHU_WHOLE,
};

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブルを取得する
 * @param params 検索条件
 */
function fetchDataChainStoreShuWholeAction(params: DataChainStoreShuParams) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_WHOLE,
    payload: { params },
  };
}

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブル取得用APIにリクエスト前に呼ばれるもの
 */
function fetchDataChainStoreShuWholeRequestAction() {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_WHOLE_REQUEST,
  };
}

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブル取得完了時データを更新する
 * @param dataChainStoreShu 種別実績データ
 */
function fetchDataChainStoreShuWholeSuccessAction(
  dataChainStoreShuWhole: DataChainStoreShu
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUCCESS,
    payload: { dataChainStoreShuWhole },
  };
}

/**
 * チェーン店レポート 種別実績（店舗全体実績）Summaryを取得する
 * @param params 検索条件
 */
function fetchDataChainStoreShuWholeSummaryAction(
  params: DataChainStoreShuSummaryParams
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY,
    payload: { params },
  };
}

/**
 * チェーン店レポート 種別実績（店舗全体実績）Summary取得用APIにリクエスト前に呼ばれるもの
 */
function fetchDataChainStoreShuWholeSummaryRequestAction() {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_REQUEST,
  };
}

/**
 * チェーン店レポート 種別実績（店舗全体実績）Summary取得完了時データを更新する
 * @param dataChainStoreShu 種別実績Summaryデータ
 */
function fetchDataChainStoreShuWholeSummarySuccessAction(
  dataChainStoreShuWholeSummary: DataChainStoreShuSummary
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_SUCCESS,
    payload: { dataChainStoreShuWholeSummary },
  };
}

/**
 * チェーン店レポート 種別実績（店舗全体実績）を初回取得する
 */
function initDataChainStoreShuWholeAction() {
  return {
    type: INIT_DATA_CHAIN_STORE_SHU_WHOLE,
  };
}

/**
 * 現在表示されている種別実績を指定した検索条件で再取得する（検索用）
 * @param params 検索フォームの検索条件
 */
function searchDataChainStoreShuWholeAction(
  params: ChainReportsFormConditions
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_WHOLE,
    payload: { params },
  };
}

/**
 * 現在表示されている種別実績を初回の検索条件で再取得する（リセット用）
 * @param params 検索フォームの検索条件
 */
function searchDataChainStoreShuWholeResetAction() {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_RESET,
  };
}

/**
 * 店舗全体実績をお気に入りの検索条件で取得する
 * @param favorite 検索フォームの検索条件
 */
function searchDataChainStoreShuWholeFavoriteAction(
  favorite: ChainStoreReportsFavorite
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FAVORITE,
    payload: { favorite },
  };
}

/**
 * 指定したソート条件で種別実績（店舗全体実績）を再取得する
 * @param sort ソートする条件
 * @param order ソート順
 */
function searchDataChainStoreShuWholeSortAction(
  sort: string,
  order: OrderType
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_SORT,
    payload: { sort, order },
  };
}

/**
 * 指定したマーキング条件で種別実績（店舗全体実績）を再取得する
 * @param markingOption マーキング条件
 * @param isFiltering 該当行のみ表示するか
 */
function searchDataChainStoreShuWholeMarkingAction(
  markingOption: MarkingOption,
  isFiltering: boolean
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_MARKING,
    payload: { markingOption, isFiltering },
  };
}

/**
 * 指定した表示項目に絞って種別実績（店舗全体実績）を再取得する
 * @param fields 選択した表示項目
 */
function searchDataChainStoreShuWholeFieldTypeAction(fields: Option[]) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FIELD_TYPE,
    payload: { fields },
  };
}

/**
 * 指定した種別実績（店舗全体実績）のデータを破棄する
 */
function clearDataChainStoreShuWholeAction() {
  return {
    type: CLEAR_DATA_CHAIN_STORE_SHU_WHOLE,
  };
}

/**
 * 指定した種別実績（店舗全体実績）Summaryのデータを破棄する
 */
function clearDataChainStoreShuWholeSummaryAction() {
  return {
    type: CLEAR_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY,
  };
}

/**
 * 種別実績（店舗全体実績）のテーブルとSummaryデータを全て破棄する
 */
function clearAllDataChainStoreShuWholeAction() {
  return {
    type: CLEAR_ALL_DATA_CHAIN_STORE_SHU_WHOLE,
  };
}

/**
 * 種別実績（店舗全体実績）の並び替え情報を登録する（Sagaで並び替え確定時）
 */
function selectDataChainStoreShuWholeColumnsOrderAction(
  columnsOrder: string[]
) {
  return {
    type: SELECT_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER,
    payload: { columnsOrder },
  };
}

/**
 * 種別実績（店舗全体実績）の列の並び替え実行時にSagaで再計算する
 */
function changeDataChainStoreShuWholeColumnsOrderAction(
  draggedId?: string,
  droppedId?: string
) {
  return {
    type: CHANGE_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER,
    payload: { draggedId, droppedId },
  };
}

/**
 * 初期化
 */
function renewDataChainStoreShuWholeAction() {
  return {
    type: RENEW_DATA_CHAIN_STORE_SHU_WHOLE,
  };
}

export const DataChainStoreShuWholeActionCreators = {
  fetchDataChainStoreShuWholeAction,
  fetchDataChainStoreShuWholeRequestAction,
  fetchDataChainStoreShuWholeSuccessAction,
  fetchDataChainStoreShuWholeSummaryAction,
  fetchDataChainStoreShuWholeSummaryRequestAction,
  fetchDataChainStoreShuWholeSummarySuccessAction,
  initDataChainStoreShuWholeAction,
  searchDataChainStoreShuWholeAction,
  searchDataChainStoreShuWholeResetAction,
  searchDataChainStoreShuWholeSortAction,
  searchDataChainStoreShuWholeMarkingAction,
  searchDataChainStoreShuWholeFieldTypeAction,
  searchDataChainStoreShuWholeFavoriteAction,
  clearDataChainStoreShuWholeAction,
  clearDataChainStoreShuWholeSummaryAction,
  clearAllDataChainStoreShuWholeAction,
  selectDataChainStoreShuWholeColumnsOrderAction,
  changeDataChainStoreShuWholeColumnsOrderAction,
  renewDataChainStoreShuWholeAction,
};

export type FetchDataChainStoreShuWholeAction = ReturnType<
  typeof fetchDataChainStoreShuWholeAction
>;
export type FetchDataChainStoreShuWholeSummaryAction = ReturnType<
  typeof fetchDataChainStoreShuWholeSummaryAction
>;
type FetchDataChainStoreShuWholeSuccessAction = ReturnType<
  typeof fetchDataChainStoreShuWholeSuccessAction
>;
type InitDataChainStoreShuWholeAction = ReturnType<
  typeof initDataChainStoreShuWholeAction
>;
export type SearchDataChainStoreShuWholeAction = ReturnType<
  typeof searchDataChainStoreShuWholeAction
>;
type SearchDataChainStoreShuWholeResetAction = ReturnType<
  typeof searchDataChainStoreShuWholeResetAction
>;
export type SearchDataChainStoreShuWholeSortAction = ReturnType<
  typeof searchDataChainStoreShuWholeSortAction
>;
export type SearchDataChainStoreShuWholeMarkingAction = ReturnType<
  typeof searchDataChainStoreShuWholeMarkingAction
>;
export type SearchDataChainStoreShuWholeFieldTypeAction = ReturnType<
  typeof searchDataChainStoreShuWholeFieldTypeAction
>;
type SelectDataChainStoreShuWholeColumnsOrderAction = ReturnType<
  typeof selectDataChainStoreShuWholeColumnsOrderAction
>;
export type ChangeDataChainStoreShuWholeColumnsOrderAction = ReturnType<
  typeof changeDataChainStoreShuWholeColumnsOrderAction
>;

export type SearchDataChainStoreShuWholeFavoriteAction = ReturnType<
  typeof searchDataChainStoreShuWholeFavoriteAction
>;

type DataChainStoreShuWholeAction =
  | FetchDataChainStoreShuWholeAction
  | FetchDataChainStoreShuWholeSummaryAction
  | FetchDataChainStoreShuWholeSuccessAction
  | InitDataChainStoreShuWholeAction
  | SearchDataChainStoreShuWholeAction
  | SearchDataChainStoreShuWholeResetAction
  | SearchDataChainStoreShuWholeSortAction
  | SearchDataChainStoreShuWholeMarkingAction
  | SearchDataChainStoreShuWholeFieldTypeAction
  | SelectDataChainStoreShuWholeColumnsOrderAction
  | ChangeDataChainStoreShuWholeColumnsOrderAction
  | SearchDataChainStoreShuWholeFavoriteAction
  | ReturnType<typeof fetchDataChainStoreShuWholeRequestAction>
  | ReturnType<typeof fetchDataChainStoreShuWholeSummaryRequestAction>
  | ReturnType<typeof fetchDataChainStoreShuWholeSummarySuccessAction>
  | ReturnType<typeof clearDataChainStoreShuWholeAction>
  | ReturnType<typeof clearDataChainStoreShuWholeSummaryAction>
  | ReturnType<typeof clearAllDataChainStoreShuWholeAction>
  | ResetChainStoreShuAction
  | ReturnType<typeof renewDataChainStoreShuWholeAction>;

type DataChainStoreShuWholeState = {
  shuIsLoading: boolean;
  summaryIsLoading: boolean;
  shu?: DataChainStoreShu;
  summary?: DataChainStoreShuSummary;
  columnsOrder?: string[];
};

const initialState: DataChainStoreShuWholeState = {
  shuIsLoading: false,
  summaryIsLoading: false,
  shu: undefined,
  summary: undefined,
  columnsOrder: [],
};

/**
 * チェーン店レポート 種別実績（店舗全体実績）に関連する全てのデータを取得する
 * @returns 種別実績（店舗全体実績）に関連する全てのデータ
 */
const dataChainStoreShuWholeSelector = (state: RootState) =>
  state.dataChainStoreShuWhole;

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブルのデータを取得する
 * @returns 種別実績（店舗全体実績）テーブルのデータ
 */
export const dataChainStoreShuWholeDataSelector = createSelector(
  dataChainStoreShuWholeSelector,
  (dataChainStoreShuWhole) => {
    const defaultValue: DataChainStoreShu = {
      setting: {},
      data: {
        columns: [],
        rows: [],
      },
    };

    return dataChainStoreShuWhole.shu ?? defaultValue;
  }
);

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブルのカラム部分（カラム行）のみ取得
 * @returns テーブルデータのカラム行（rowsを除いた部分）
 */
export const dataChainStoreShuWholeDataColumnsSelector = createSelector(
  dataChainStoreShuWholeDataSelector,
  (data) => data.data.columns ?? []
);

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブルの検索条件を取得する
 * @returns 種別実績（店舗全体実績）テーブルの検索条件
 */
export const dataChainStoreShuWholeSearchConditionSelector = createSelector(
  dataChainStoreShuWholeDataSelector,
  (data) => data?.setting
);

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブルのデータが存在するか取得する
 * @returns 存在するか（存在する場合: true）
 */
export const dataChainStoreShuWholeIsExistSelector = createSelector(
  dataChainStoreShuWholeSelector,
  (dataChainStoreShuWhole) => !!dataChainStoreShuWhole.shu
);

/**
 * 指定した種別の種別実績（店舗全体実績）で選択されている表示項目を取得する
 * @returns 選択中の表示項目
 */
export const dataChainStoreShuWholeSelectedFieldsSelector = createSelector(
  dataChainStoreShuWholeSearchConditionSelector,
  (searchCondition) => searchCondition.fields ?? []
);

/**
 * 指定した種別の種別実績（店舗全体実績）で適用されているマーキングを取得する
 * @returns 適用中のマーキング
 */
export const dataChainStoreShuWholeSelectedMarkingSelector = createSelector(
  dataChainStoreShuWholeSearchConditionSelector,
  (searchCondition) => searchCondition.marking ?? ''
);

/**
 * 指定した種別の種別実績（店舗全体実績）でフィルタリング設定が適用されているか取得する
 * @returns フィルタリング設定が有効か
 */
export const dataChainStoreShuWholeIsFilteringSelector = createSelector(
  dataChainStoreShuWholeSearchConditionSelector,
  (searchCondition) => searchCondition.isFiltering ?? false
);

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブルのローディング状態を取得する
 * @returns ローディング状態
 */
export const dataChainStoreShuWholeLoadingSelector = (state: RootState) =>
  state.dataChainStoreShuWhole.shuIsLoading;

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブルの並び替え情報を取得
 * @returns 並び替え情報
 */
export const dataChainStoreShuWholeColumnsOrderSelector = (state: RootState) =>
  state.dataChainStoreShuWhole.columnsOrder;

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブルの並び替え後のテーブルデータを取得する
 * @returns 並び替え後のテーブルデータ
 */
export const dataChainStoreShuWholeOrderedData = createSelector(
  [
    dataChainStoreShuWholeDataSelector,
    dataChainStoreShuWholeColumnsOrderSelector,
  ],
  (tableData, ordered) => {
    if (!ordered) return tableData.data;

    return getOrderedTableData(
      ordered,
      tableData.data.columns,
      tableData.data.rows
    );
  }
);

/**
 * チェーン店レポート 種別実績（店舗全体実績）Summaryのデータを取得する
 * @returns 種別実績（店舗全体実績）Summaryのデータ
 */
export const dataChainStoreShuWholeSummaryDataSelector = (state: RootState) =>
  state.dataChainStoreShuWhole.summary;

/**
 * 種別実績店舗全体実績テーブルの現在の検索条件を取得する（お気に入り保存用）
 * @returns 現在の種別実績店舗全体実績テーブルの検索条件
 */
export const dataChainStoreShuWholeSettingSelector = (state: RootState) =>
  state.dataChainStoreShuWhole.shu?.setting;

/**
 * 種別実績店舗全体実績Summaryの現在の検索条件を取得する（お気に入り保存用）
 * @returns 現在の種別実績店舗全体実績Summaryの検索条件
 */
export const dataChainStoreShuWholeSummarySettingSelector = (
  state: RootState
) => state.dataChainStoreShuWhole.summary?.setting;

export function dataChainStoreShuWholeReducer(
  state = initialState,
  action: DataChainStoreShuWholeAction
): DataChainStoreShuWholeState {
  switch (action.type) {
    case FETCH_DATA_CHAIN_STORE_SHU_WHOLE_REQUEST:
      return {
        ...state,
        shuIsLoading: true,
      };
    case FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUCCESS:
      return {
        ...state,
        shuIsLoading: false,
        shu: action.payload.dataChainStoreShuWhole,
      };
    case FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_REQUEST:
      return {
        ...state,
        summaryIsLoading: true,
      };
    case FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryIsLoading: false,
        summary: action.payload.dataChainStoreShuWholeSummary,
      };
    case CLEAR_DATA_CHAIN_STORE_SHU_WHOLE:
      return {
        ...state,
        shu: undefined,
        shuIsLoading: false,
      };
    case CLEAR_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY:
      return {
        ...state,
        summary: undefined,
        summaryIsLoading: false,
      };
    case CLEAR_ALL_DATA_CHAIN_STORE_SHU_WHOLE:
    case RENEW_DATA_CHAIN_STORE_SHU_WHOLE:
      return initialState;
    case SELECT_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
      };
    case ChainStoreSettingActionTypes.RESET_CHAIN_STORE_REPORTS_SHU: {
      // お気に入りでデフォルト適用時に列の並びを初期化する
      return {
        ...state,
        columnsOrder: initialState.columnsOrder,
      };
    }
    default:
      return state;
  }
}
