import { createSelector } from 'reselect';

import { PPM_SHARE_GRAPH_DATE_LABEL_TYPE } from '../../domain/ppmShare/consistent';
import { PpmShareGraphDateLabelType } from '../../domain/ppmShare/types';
import {
  DataPpmShareTransitiveGraph,
  DataPpmShareTransitiveGraphParams,
} from '../../domain/ppmShare/types';
import { LoadingState } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { SelectOption } from '../../components/molecules/SingleSelect';

import { RootState } from '../../store';
import { dataPpmShareDataSelector } from './dataPpmShare';

// Action Types

const FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH =
  'FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH' as const;
const FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_REQUEST =
  'FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_REQUEST' as const;
const FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_SUCCESS =
  'FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_SUCCESS' as const;
const RENEW_DATA_PPM_SHARE_TRANSITIVE_GRAPH =
  'RENEW_DATA_PPM_SHARE_TRANSITIVE_GRAPH' as const;
const SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH =
  'SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH' as const;
const SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_HALL =
  'SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_HALL' as const;
const SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_DATE_TYPE =
  'SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_DATE_TYPE' as const;
const HIDE_DATA_PPM_SHARE_TRANSITIVE_GRAPH =
  'HIDE_DATA_PPM_SHARE_TRANSITIVE_GRAPH' as const;

export const DataPpmShareTransitiveGraphActionTypes = {
  FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
  FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_REQUEST,
  FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_SUCCESS,
  RENEW_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
  SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
  SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_HALL,
  SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_DATE_TYPE,
  HIDE_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
};

// Action Creators

/**
 * 推移グラフデータを取得する
 * @param shuCode 表示先テーブルの種別コード
 * @param params 推移グラフの検索条件
 */
function fetchDataPpmShareTransitiveGraphAction(
  shuCode: string,
  params: DataPpmShareTransitiveGraphParams
) {
  return {
    type: FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
    payload: { params, shuCode },
  };
}

/**
 * 推移グラフ取得前に実行する
 * @param shuCode 表示先テーブルの種別コード
 */
function fetchDataPpmShareTransitiveGraphRequestAction(shuCode: string) {
  return {
    type: FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_REQUEST,
    payload: { shuCode },
  };
}

/**
 * 推移グラフデータ取得完了時データを登録する
 * @param shuCode 表示先テーブルの種別コード
 * @param dataPpmShareTransitiveGraph 取得したグラフデータ
 */
function fetchDataPpmShareTransitiveGraphSuccessAction(
  shuCode: string,
  dataPpmShareTransitiveGraph: DataPpmShareTransitiveGraph
) {
  return {
    type: FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_SUCCESS,
    payload: { dataPpmShareTransitiveGraph, shuCode },
  };
}

function renewDataPpmShareTransitiveGraphAction() {
  return {
    type: RENEW_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
  };
}

/**
 * 選択した店舗のPPMシェア推移グラフを表示する
 * @param halls 表示する店舗コード
 * @param shuOption 種別データ
 */
function searchDataPpmShareTransitiveGraphAction(
  halls: string[],
  shuOption?: ShuOption
) {
  return {
    type: SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
    payload: { halls, shuOption },
  };
}

/**
 * 指定した店舗でグラフを絞り込む（店舗選択フォーム用）
 * @param hall 絞り込む店舗（平均/合計時:'null'）
 * @param shuOption 種別データ
 */
function searchDataPpmShareTransitiveGraphHallAction(
  hall: string,
  shuOption?: ShuOption
) {
  return {
    type: SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_HALL,
    payload: { hall, shuOption },
  };
}

/**
 * 指定した期間で推移グラフデータを再取得
 * @param dateType 変更後の期間
 * @param shuOption 種別データ
 */
function searchDataPpmShareTransitiveGraphDateTypeAction(
  dateType: PpmShareGraphDateLabelType,
  shuOption?: ShuOption
) {
  return {
    type: SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_DATE_TYPE,
    payload: { dateType, shuOption },
  };
}

/**
 * 指定した推移グラフデータを破棄する
 */
function hideDataPpmShareTransitiveGraphAction() {
  return {
    type: HIDE_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
    payload: {},
  };
}

export const DataPpmShareTransitiveGraphActionCreators = {
  fetchDataPpmShareTransitiveGraphAction,
  fetchDataPpmShareTransitiveGraphRequestAction,
  fetchDataPpmShareTransitiveGraphSuccessAction,
  renewDataPpmShareTransitiveGraphAction,
  searchDataPpmShareTransitiveGraphAction,
  searchDataPpmShareTransitiveGraphHallAction,
  searchDataPpmShareTransitiveGraphDateTypeAction,
  hideDataPpmShareTransitiveGraphAction,
};

// Actions

export type FetchDataPpmShareTransitiveGraphAction = ReturnType<
  typeof fetchDataPpmShareTransitiveGraphAction
>;
export type SearchDataPpmShareTransitiveGraphAction = ReturnType<
  typeof searchDataPpmShareTransitiveGraphAction
>;
export type SearchDataPpmShareTransitiveGraphHallAction = ReturnType<
  typeof searchDataPpmShareTransitiveGraphHallAction
>;
export type SearchDataPpmShareTransitiveGraphDateTypeAction = ReturnType<
  typeof searchDataPpmShareTransitiveGraphDateTypeAction
>;

type DataPpmShareTransitiveGraphAction =
  | FetchDataPpmShareTransitiveGraphAction
  | SearchDataPpmShareTransitiveGraphAction
  | SearchDataPpmShareTransitiveGraphHallAction
  | SearchDataPpmShareTransitiveGraphDateTypeAction
  | ReturnType<typeof fetchDataPpmShareTransitiveGraphRequestAction>
  | ReturnType<typeof fetchDataPpmShareTransitiveGraphSuccessAction>
  | ReturnType<typeof renewDataPpmShareTransitiveGraphAction>
  | ReturnType<typeof hideDataPpmShareTransitiveGraphAction>;

// State

type DataPpmShareTransitiveGraphState = {
  dataPpmShareTransitiveGraph: DataPpmShareTransitiveGraph | undefined;
  loadingState: LoadingState;
};

const initialState: DataPpmShareTransitiveGraphState = {
  dataPpmShareTransitiveGraph: {
    setting: {
      dateType: PPM_SHARE_GRAPH_DATE_LABEL_TYPE.TWENTYSIX_WEEKS,
    },
    data: undefined,
  },
  loadingState: 'prepare',
};

// Selector

/**
 * 全てのグラフデータを取得する
 * @returns 全てのグラフデータ
 */
const dataPpmShareTransitiveGraphAllSelector = (state: RootState) =>
  state.dataPpmShareTransitiveGraph || {};

/**
 * グラフデータを取得する
 * @returns グラフデータ
 */
export const dataPpmShareTransitiveGraphDataSelector = createSelector(
  dataPpmShareTransitiveGraphAllSelector,
  (dataPpmShareTransitiveGraph) =>
    dataPpmShareTransitiveGraph.dataPpmShareTransitiveGraph?.data
);

/**
 * 推移グラフの検索条件を取得する
 * @returns 推移グラフの検索条件
 */
export const dataPpmShareTransitiveGraphSearchConditionSelector =
  createSelector(
    dataPpmShareTransitiveGraphAllSelector,
    (dataPpmShareTransitiveGraph) =>
      dataPpmShareTransitiveGraph.dataPpmShareTransitiveGraph?.setting ??
      ({} as DataPpmShareTransitiveGraphParams)
  );

/**
 * 選択された店舗を取得する
 * @returns 選択中の店舗
 */
const dataPpmShareTransitiveGraphHallSelector = createSelector(
  dataPpmShareTransitiveGraphSearchConditionSelector,
  (params) => params?.halls ?? []
);

/**
 * 店舗一覧（SelectOption）を取得する
 * @returns 店舗一覧（Selectで使える形式）
 */
export const dataPpmShareTransitiveGraphHallOptionSelector = createSelector(
  [dataPpmShareDataSelector],
  (dataPpmShare) => {
    const result: SelectOption[] =
      dataPpmShare?.rows.map((row) => ({
        name: row.data.at(0)?.value ?? '',
        value: row.queryParameter.halls[0] ?? 'null',
      })) || [];

    return result;
  }
);

/**
 * 絞り込んでいる店舗の一覧（SelectOption)を取得する
 * @returns 絞り込んでいる店舗一覧(Selectで使える形式)
 */
export const dataPpmShareTransitiveGraphSelectedHallListOptionSelector =
  createSelector(
    [
      dataPpmShareTransitiveGraphHallSelector,
      dataPpmShareTransitiveGraphHallOptionSelector,
    ],
    (selectedHallList, hallsList) => {
      const result: SelectOption = hallsList.find((hall) =>
        selectedHallList.includes(hall.value)
      ) ?? {
        name: '平均/合計',
        value: 'null',
        type: 'halls',
      };

      return result;
    }
  );

/**
 * 現在選択されている期間を取得する
 * @returns 現在選択されている期間
 */
export const dataPpmShareTransitiveGraphDateTypeSelector = createSelector(
  dataPpmShareTransitiveGraphSearchConditionSelector,
  (params) => params.dateType ?? PPM_SHARE_GRAPH_DATE_LABEL_TYPE.TWENTYSIX_WEEKS
);

/**
 * 種別を元にグラフのローディング状態を取得する
 * @returns ローディング状態
 */
export const dataPpmShareTransitiveGraphLoadingStateSelector = createSelector(
  dataPpmShareTransitiveGraphAllSelector,
  (dataPpmShareTransitiveGraph) => dataPpmShareTransitiveGraph.loadingState
);

// dateTypeをAPIのかえす値からグラフ表示期間の型定義に変換する
const convertApiDateTypeToPpmShareGraphDateType = (
  dateType: string | undefined
) => {
  if (dateType === 'weekly') {
    return PPM_SHARE_GRAPH_DATE_LABEL_TYPE.THIRTEEN_WEEKS;
  }
  if (dateType === 'monthly') {
    return PPM_SHARE_GRAPH_DATE_LABEL_TYPE.THIRTEEN_MONTHS;
  }

  // weekly monthly 以外はanyしかなく、現状anyは26週でしか使われていない
  return PPM_SHARE_GRAPH_DATE_LABEL_TYPE.TWENTYSIX_WEEKS;
};

export function dataPpmShareTransitiveGraphReducer(
  state = initialState,
  action: DataPpmShareTransitiveGraphAction
): DataPpmShareTransitiveGraphState {
  switch (action.type) {
    case FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_SUCCESS: {
      const data = action.payload.dataPpmShareTransitiveGraph;
      const dataPpmShareTransitiveGraph = {
        ...data,
        setting: {
          ...data.setting,
          dateType: convertApiDateTypeToPpmShareGraphDateType(
            data.setting.dateType
          ),
        },
      };
      return {
        ...state,
        loadingState: 'loaded',
        dataPpmShareTransitiveGraph: dataPpmShareTransitiveGraph,
      };
    }
    case RENEW_DATA_PPM_SHARE_TRANSITIVE_GRAPH:
    case HIDE_DATA_PPM_SHARE_TRANSITIVE_GRAPH:
      return initialState;
    default:
      return state;
  }
}
