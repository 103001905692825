import { format } from 'date-fns';

import { DataKi } from '../dataKi';
import { DAY_TYPE } from '../schemas';
import { kiDateRanges, kiDateUnits } from './consistent';
import { makeYmdList } from './date';
import { KiReportsSettingDateRangeParams } from './types';

export const defaultExcludeToday = true;

export const defaultDateRangeParams: Required<
  Omit<KiReportsSettingDateRangeParams, 'isComparison'>
> = {
  dateUnit: kiDateUnits.at(0)?.name ?? '範囲選択',
  dateRange: kiDateRanges.at(3)?.name ?? '今週',
  comparisonSection: kiDateRanges.at(3)?.defaultComparisonSection ?? '前週',
};

/**
 * [機種集計] デフォルトレスポンスデータを生成する
 */
export const generateInitialResponse = (): DataKi => {
  // 指定された期間から日付を算出する
  const date = makeYmdList(
    defaultDateRangeParams.dateRange,
    defaultDateRangeParams.comparisonSection,
    { excludeToday: defaultExcludeToday }
  );
  const ymdList = date.ymdList.map((date) => format(date, 'yyyy-MM-dd'));
  const ymdComparisonList = date.ymdComparisonList.map((date) =>
    format(date, 'yyyy-MM-dd')
  );

  return {
    setting: {
      mainField: 'kiTushoMei' as const,
      isFiltering: false,
      dayType: DAY_TYPE.ALL,
      excludeToday: defaultExcludeToday,
      ymdList,
      ymdComparisonList,
    },
    data: {
      columns: [],
      rows: [],
    },
  };
};
