import { createSelector } from 'reselect';

import { dataDaiDefaultValue } from '../../domain/dai/defaultValue';
import { UnitDataListSettingDateRangeParams } from '../../domain/dai/types';
import { UnitDataListFormConditions } from '../../domain/dai/types';
import { SharedUser } from '../../domain/favorites';
import { Option, OrderType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { getYmdList } from '../../utils/date';
import { ReportsDateRange } from '../../utils/reportsDateRange';
import { favoritesSelector } from './settingsFavorites';

/**
 * Action Types
 *
 * プレフィックスの意味
 * SEARCH: ReducerでStateの変更はしないがデータを取得する（検索ボタン等Sagaを発火させたい）
 * CHANGE: Stateを変更してデータも取得する
 * SELECT: Stateを変更する（データ取得は行わない）
 * RESET: Stateを初期状態に戻す（データ取得は行わない）
 */

const SEARCH_UNIT_DATA_LIST_REPORTS = 'SEARCH_UNIT_DATA_LIST_REPORTS' as const;
const SEARCH_RESET_UNIT_DATA_LIST_REPORTS =
  'SEARCH_RESET_UNIT_DATA_LIST_REPORTS' as const;
const SEARCH_UNIT_DATA_LIST_REPORTS_SORT =
  'SEARCH_UNIT_DATA_LIST_REPORTS_SORT' as const;
const SEARCH_UNIT_DATA_LIST_REPORTS_MARKING =
  'SEARCH_UNIT_DATA_LIST_REPORTS_MARKING' as const;
const SEARCH_UNIT_DATA_LIST_REPORTS_FIELDS =
  'SEARCH_UNIT_DATA_LIST_REPORTS_FIELDS' as const;

const CHANGE_UNIT_DATA_LIST_REPORTS_FAVORITE =
  'CHANGE_UNIT_DATA_LIST_REPORTS_FAVORITE' as const;
const CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_SHU =
  'CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_SHU' as const;

const SELECT_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION =
  'SELECT_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION' as const;
const SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_SHU =
  'SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_SHU' as const;
const SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS =
  'SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS' as const;
const SELECT_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS =
  'SELECT_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS' as const;

const RESET_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION =
  'RESET_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION' as const;
const RESET_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS =
  'RESET_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS' as const;

const SAVE_UNIT_DATA_LIST_REPORTS_FAVORITE =
  'SAVE_UNIT_DATA_LIST_REPORTS_FAVORITE' as const;
const SAVE_AS_UNIT_DATA_LIST_REPORTS_FAVORITE =
  'SAVE_AS_UNIT_DATA_LIST_REPORTS_FAVORITE' as const;

const ADD_UNIT_DATA_LIST_REPORTS_TABLE_FILTER =
  'ADD_UNIT_DATA_LIST_REPORTS_TABLE_FILTER' as const;
const REMOVE_UNIT_DATA_LIST_REPORTS_TABLE_FILTER =
  'REMOVE_UNIT_DATA_LIST_REPORTS_TABLE_FILTER' as const;
const APPLY_UNIT_DATA_LIST_REPORTS_TABLE_FILTER =
  'APPLY_UNIT_DATA_LIST_REPORTS_TABLE_FILTER' as const;
const RESET_UNIT_DATA_LIST_REPORTS_TABLE_FILTER =
  'RESET_UNIT_DATA_LIST_REPORTS_TABLE_FILTER' as const;

const SELECT_UNIT_DATA_LIST_REPORTS_MODEL_NAME_FILTER =
  'SELECT_UNIT_DATA_LIST_REPORTS_MODEL_NAME_FILTER' as const;

const SELECT_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER =
  'SELECT_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER' as const;
const RESET_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER =
  'RESET_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER' as const;

const SEARCH_UNIT_DATA_LIST_REPORTS_DATE_RANGE_SLIDE =
  'SEARCH_UNIT_DATA_LIST_REPORTS_DATE_RANGE_SLIDE' as const;

const RESET_UNIT_DATA_LIST_REPORTS_FAVORITE_TO_DEFAULT =
  'RESET_UNIT_DATA_LIST_REPORTS_FAVORITE_TO_DEFAULT' as const;

const CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS =
  'CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS' as const;

const CREATE_UNIT_DATA_LIST_REPORTS_SHORTENED_URL =
  'CREATE_UNIT_DATA_LIST_REPORTS_SHORTENED_URL' as const;

export const UnitDataListReportsSettingActionTypes = {
  SEARCH_UNIT_DATA_LIST_REPORTS,
  SEARCH_RESET_UNIT_DATA_LIST_REPORTS,
  SEARCH_UNIT_DATA_LIST_REPORTS_SORT,
  SEARCH_UNIT_DATA_LIST_REPORTS_MARKING,
  SEARCH_UNIT_DATA_LIST_REPORTS_FIELDS,
  CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_SHU,
  CHANGE_UNIT_DATA_LIST_REPORTS_FAVORITE,
  SELECT_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION,
  SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_SHU,
  SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS,
  SELECT_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS,
  RESET_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION,
  RESET_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS,
  RESET_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER,
  SAVE_UNIT_DATA_LIST_REPORTS_FAVORITE,
  SAVE_AS_UNIT_DATA_LIST_REPORTS_FAVORITE,
  ADD_UNIT_DATA_LIST_REPORTS_TABLE_FILTER,
  REMOVE_UNIT_DATA_LIST_REPORTS_TABLE_FILTER,
  APPLY_UNIT_DATA_LIST_REPORTS_TABLE_FILTER,
  RESET_UNIT_DATA_LIST_REPORTS_TABLE_FILTER,
  SELECT_UNIT_DATA_LIST_REPORTS_MODEL_NAME_FILTER,
  SELECT_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER,
  SEARCH_UNIT_DATA_LIST_REPORTS_DATE_RANGE_SLIDE,
  RESET_UNIT_DATA_LIST_REPORTS_FAVORITE_TO_DEFAULT,
  CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS,
  CREATE_UNIT_DATA_LIST_REPORTS_SHORTENED_URL,
};

/**
 * Action Creators
 */

/**
 * 指定した検索条件でデータを取得する（検索ボタン押下時）
 * @param params 検索条件
 * @param dateRange 期間
 * @param dateUnit 日付単位
 * @param isComparison 比較期間の有無
 */
export function searchUnitDataListReportsAction(
  params: UnitDataListFormConditions,
  dateRange: UnitDataListSettingDateRangeParams['dateRange'],
  dateUnit: UnitDataListSettingDateRangeParams['dateUnit'],
  isComparison: UnitDataListSettingDateRangeParams['isComparison']
) {
  return {
    type: SEARCH_UNIT_DATA_LIST_REPORTS,
    payload: {
      params,
      dateRange,
      dateUnit,
      isComparison,
    },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 * @param halls 店舗コード（未指定で全店舗）
 */
function searchResetUnitDataListReportsAction(halls?: string[]) {
  return {
    type: SEARCH_RESET_UNIT_DATA_LIST_REPORTS,
    payload: { halls },
  };
}

/**
 * 指定したソート条件でデータを再取得する
 * @param sort ソートする条件
 * @param order 昇順・降順
 */
function searchUnitDataListReportsSortAction(sort: string, order: OrderType) {
  return {
    type: SEARCH_UNIT_DATA_LIST_REPORTS_SORT,
    payload: { sort, order },
  };
}

/**
 * 指定した表示項目に絞ってデータを再取得する
 * @param fields 表示項目
 */
export function searchUnitDataListReportsFieldAction(fields: Option[]) {
  return {
    type: SEARCH_UNIT_DATA_LIST_REPORTS_FIELDS,
    payload: { fields },
  };
}

/**
 * 選択した種別のデータを取得する
 *
 * MEMO: 初回取得時とテーブル上部の種別選択で変更時に使用する
 * @param shu 絞り込む種別
 */
function changeUnitDataListReportsCurrentShuAction(shu?: ShuOption) {
  return {
    type: CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 選択したお気に入りでデータを取得する
 *
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changeUnitDataListReportsFavoriteAction(favorite?: number) {
  return {
    type: CHANGE_UNIT_DATA_LIST_REPORTS_FAVORITE,
    payload: { favorite },
  };
}

/**
 * データを取得せずにお気に入りをデフォルトに戻す
 */
function resetUnitDataListReportsFavoriteToDefaultAction() {
  return {
    type: RESET_UNIT_DATA_LIST_REPORTS_FAVORITE_TO_DEFAULT,
    payload: {},
  };
}

/**
 * 検索条件を変更
 */
function selectUnitDataListReportsSearchConditionAction(
  params: UnitDataListFormConditions
) {
  return {
    type: SELECT_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION,
    payload: { params },
  };
}

/**
 * 絞り込む種別を変更
 *
 * MEMO: 検索時やお気に入り適用時に使用する（全体的なStateだけ変更して最終的にFetchしたいケース）
 * @param shu 絞り込む種別
 */
export function selectUnitDataListReportsCurrentShuAction(shu?: ShuOption) {
  return {
    type: SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 選択中の店舗を変更
 *
 * MEMO: 検索時やお気に入り適用時に使用する（全体的なStateだけ変更して最終的にFetchしたいケース）
 * @param halls 選択中の店舗
 */
function selectUnitDataListReportsCurrentHallsAction(halls: string[]) {
  return {
    type: SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 選択中の期間単位を変更
 * @param dateRange 期間単位
 * @param dateUnit 日付単位
 * @param isComparison 比較期間の有無
 */
function selectUnitDataListReportsDateRangeParamsAction(
  dateRange: UnitDataListSettingDateRangeParams['dateRange'],
  dateUnit: UnitDataListSettingDateRangeParams['dateUnit'],
  isComparison: UnitDataListSettingDateRangeParams['isComparison']
) {
  return {
    type: SELECT_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS,
    payload: { dateRange, dateUnit, isComparison },
  };
}

/**
 * 選択中の期間をデフォルトに戻す
 */
function resetUnitDataListReportsDateRangeParamsAction() {
  return {
    type: RESET_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS,
  };
}

/**
 * 検索条件をデフォルトに戻す
 */
function resetUnitDataListReportsSearchConditionAction() {
  return {
    type: RESET_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION,
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 * @param mode メモ更新モード
 */
function saveUnitDataListReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_UNIT_DATA_LIST_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 */
function saveAsUnitDataListReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_UNIT_DATA_LIST_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * テーブルの非表示項目を追加する
 * @param columnCode カラム名
 */
function addUnitDataListReportsTableFilterAction(columnCode: string) {
  return {
    type: ADD_UNIT_DATA_LIST_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を削除する
 * @param columnCode カラム名
 */
function removeUnitDataListReportsTableFilterAction(columnCode: string) {
  return {
    type: REMOVE_UNIT_DATA_LIST_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する
 * @param tableFilterItems テーブルの非表示項目
 */
function applyUnitDataListReportsTableFilterAction(tableFilterItems: string[]) {
  return {
    type: APPLY_UNIT_DATA_LIST_REPORTS_TABLE_FILTER,
    payload: { tableFilterItems },
  };
}

/**
 * テーブルの非表示項目をリセットする
 */
function resetUnitDataListReportsTableFilterAction() {
  return {
    type: RESET_UNIT_DATA_LIST_REPORTS_TABLE_FILTER,
  };
}

/**
 * 機種名フィルタを変更
 * @param filterString 検索する文字列
 */
export function selectUnitDataListReportsModelNameFilterAction(
  filterString: string
) {
  return {
    type: SELECT_UNIT_DATA_LIST_REPORTS_MODEL_NAME_FILTER,
    payload: { filterString },
  };
}

/**
 * サブメニュー内数値フィルタを変更
 * @param filterNumbers 検索する数値フィルタ条件
 */
export function selectUnitDataListReportsSubMenuNumberFilterAction(filterNumbers: {
  [field: string]: {
    minimumNumber: number | undefined;
    maximumNumber: number | undefined;
  };
}) {
  return {
    type: SELECT_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER,
    payload: { filterNumbers },
  };
}

/**
 * 数値フィルタを全てリセットする
 */
export function resetUnitDataListReportsSubMenuNumberFilterAction() {
  return {
    type: RESET_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER,
  };
}

/**
 * 期間スライドコンポーネントがクリックされた時に、期間を変更して検索を実行する
 * @param {ReportsDateRange} newDateRange   期間単位
 * @param {string} newStartDate           検索期間 開始日
 * @param {string} newEndDate             検索期間 終了日
 * @param {string} newStartComparisonDate 比較期間 開始日
 * @param {string} newEndComparisonDate   比較期間 終了日
 */
export function searchUnitDataListReportsDateRangeSlideAction(
  newDateRange: ReportsDateRange,
  newStartDate: string,
  newEndDate: string,
  newStartComparisonDate: string | undefined,
  newEndComparisonDate: string | undefined
) {
  return {
    type: SEARCH_UNIT_DATA_LIST_REPORTS_DATE_RANGE_SLIDE,
    payload: {
      dateRange: newDateRange,
      ymdList: getYmdList(newStartDate, newEndDate),
      ymdComparisonList:
        newStartComparisonDate !== undefined &&
        newEndComparisonDate !== undefined
          ? getYmdList(newStartComparisonDate, newEndComparisonDate)
          : undefined,
    },
  };
}

/**
 * 店舗選択スライダーで選択した店舗のデータを取得する
 *
 * MEMO: 初回取得時とテーブル上部の店舗選択で変更時に使用する
 * @param halls 絞り込む店舗
 */
function changeUnitDataListReportsCurrentHallsAction(halls: string[]) {
  return {
    type: CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 画面共有用の短縮URLを作成する
 */
function createUnitDataListReportsShortenedUrlAction(
  pageName: string,
  locationUrl: string
) {
  return {
    type: CREATE_UNIT_DATA_LIST_REPORTS_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

export const UnitDataListReportsSettingActionCreators = {
  searchUnitDataListReportsAction,
  searchResetUnitDataListReportsAction,
  searchUnitDataListReportsSortAction,
  searchUnitDataListReportsFieldAction,
  changeUnitDataListReportsCurrentShuAction,
  changeUnitDataListReportsFavoriteAction,
  selectUnitDataListReportsSearchConditionAction,
  selectUnitDataListReportsCurrentShuAction,
  selectUnitDataListReportsCurrentHallsAction,
  selectUnitDataListReportsDateRangeParamsAction,
  resetUnitDataListReportsDateRangeParamsAction,
  resetUnitDataListReportsSearchConditionAction,
  saveUnitDataListReportsFavoriteAction,
  saveAsUnitDataListReportsFavoriteAction,
  addUnitDataListReportsTableFilterAction,
  removeUnitDataListReportsTableFilterAction,
  applyUnitDataListReportsTableFilterAction,
  resetUnitDataListReportsTableFilterAction,
  selectUnitDataListReportsModelNameFilterAction,
  selectUnitDataListReportsSubMenuNumberFilterAction,
  resetUnitDataListReportsSubMenuNumberFilterAction,
  searchUnitDataListReportsDateRangeSlideAction,
  changeUnitDataListReportsCurrentHallsAction,
  resetUnitDataListReportsFavoriteToDefaultAction,
  createUnitDataListReportsShortenedUrlAction,
};

/**
 * Actions
 */

export type SearchUnitDataListReportsAction = ReturnType<
  typeof searchUnitDataListReportsAction
>;
export type SearchResetUnitDataListReportsAction = ReturnType<
  typeof searchResetUnitDataListReportsAction
>;
export type SearchUnitDataListReportsSortAction = ReturnType<
  typeof searchUnitDataListReportsSortAction
>;
export type SearchUnitDataListReportsFieldAction = ReturnType<
  typeof searchUnitDataListReportsFieldAction
>;

export type ChangeUnitDataListReportsCurrentShuAction = ReturnType<
  typeof changeUnitDataListReportsCurrentShuAction
>;

export type ChangeUnitDataListReportsFavoriteAction = ReturnType<
  typeof changeUnitDataListReportsFavoriteAction
>;

type SelectUnitDataListReportsSearchConditionAction = ReturnType<
  typeof selectUnitDataListReportsSearchConditionAction
>;

type SelectUnitDataListReportsCurrentShuAction = ReturnType<
  typeof selectUnitDataListReportsCurrentShuAction
>;

type SelectKiCurrentHallsAction = ReturnType<
  typeof selectUnitDataListReportsCurrentHallsAction
>;
type SelectKiDateRangeParamsAction = ReturnType<
  typeof selectUnitDataListReportsDateRangeParamsAction
>;
type ResetUnitDataListReportsDateRangeParamsAction = ReturnType<
  typeof resetUnitDataListReportsDateRangeParamsAction
>;
type ResetUnitDataListReportsSearchConditionAction = ReturnType<
  typeof resetUnitDataListReportsSearchConditionAction
>;

export type SaveUnitDataListReportsFavoriteAction = ReturnType<
  typeof saveUnitDataListReportsFavoriteAction
>;
export type SaveAsUnitDataListReportsFavoriteAction = ReturnType<
  typeof saveAsUnitDataListReportsFavoriteAction
>;

type AddUnitDataListReportsTableFilterAction = ReturnType<
  typeof addUnitDataListReportsTableFilterAction
>;
type RemoveUnitDataListReportsTableFilterAction = ReturnType<
  typeof removeUnitDataListReportsTableFilterAction
>;
type ApplyUnitDataListReportsTableFilterAction = ReturnType<
  typeof applyUnitDataListReportsTableFilterAction
>;
type ResetUnitDataListReportsTableFilterAction = ReturnType<
  typeof resetUnitDataListReportsTableFilterAction
>;
type SelectUnitDataListReportsModelNameFilterAction = ReturnType<
  typeof selectUnitDataListReportsModelNameFilterAction
>;
type SelectUnitDataListReportsSubMenuNumberFilterAction = ReturnType<
  typeof selectUnitDataListReportsSubMenuNumberFilterAction
>;
type ResetUnitDataListReportsSubMenuNumberFilterAction = ReturnType<
  typeof resetUnitDataListReportsSubMenuNumberFilterAction
>;

export type SearchUnitDataListReportsDateRangeSlideAction = ReturnType<
  typeof searchUnitDataListReportsDateRangeSlideAction
>;

export type ChangeUnitDataListReportsCurrentHallsAction = ReturnType<
  typeof changeUnitDataListReportsCurrentHallsAction
>;

type ResetUnitDataListReportsFavoriteToDefaultAction = ReturnType<
  typeof resetUnitDataListReportsFavoriteToDefaultAction
>;

export type CreateUnitDataListReportsShortenedUrlAction = ReturnType<
  typeof createUnitDataListReportsShortenedUrlAction
>;

type UnitDataListReportsSettingAction =
  | SearchUnitDataListReportsAction
  | SearchResetUnitDataListReportsAction
  | ChangeUnitDataListReportsCurrentShuAction
  | ChangeUnitDataListReportsFavoriteAction
  | SelectUnitDataListReportsSearchConditionAction
  | SelectUnitDataListReportsCurrentShuAction
  | SelectKiCurrentHallsAction
  | SelectKiDateRangeParamsAction
  | ResetUnitDataListReportsDateRangeParamsAction
  | ResetUnitDataListReportsSearchConditionAction
  | SaveUnitDataListReportsFavoriteAction
  | SaveAsUnitDataListReportsFavoriteAction
  | AddUnitDataListReportsTableFilterAction
  | RemoveUnitDataListReportsTableFilterAction
  | ApplyUnitDataListReportsTableFilterAction
  | ResetUnitDataListReportsTableFilterAction
  | SelectUnitDataListReportsModelNameFilterAction
  | SelectUnitDataListReportsSubMenuNumberFilterAction
  | ResetUnitDataListReportsSubMenuNumberFilterAction
  | SearchUnitDataListReportsDateRangeSlideAction
  | ChangeUnitDataListReportsCurrentHallsAction
  | ResetUnitDataListReportsFavoriteToDefaultAction
  | CreateUnitDataListReportsShortenedUrlAction;

/**
 * State
 */

export type UnitDataListReportsSettingState = {
  /**
   * 現在の検索条件
   */
  searchCondition: UnitDataListFormConditions;
  /**
   * 現在選択中の種別・種別グループ（テーブルに表示されている種別・種別グループ）
   */
  currentShu?: ShuOption;
  /**
   * 現在選択中の期間指定
   */
  selectedDateRangeParams: UnitDataListSettingDateRangeParams;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId?: number;
  /**
   * 機種名フィルタ
   */
  modelNameFilter: string;
  /**
   * サブメニュー内数値フィルタ
   */
  subMenuNumberFilter: {
    [field: string]: {
      minimumNumber: number | undefined;
      maximumNumber: number | undefined;
    };
  };
  /**
   * テーブルの非表示項目一覧
   */
  tableFilterItems: string[];
};

const initialDateRangeParams: UnitDataListSettingDateRangeParams = {
  dateUnit: dataDaiDefaultValue().dateRangeParams.dateUnit,
  dateRange: dataDaiDefaultValue().dateRangeParams.dateRange,
  isComparison: dataDaiDefaultValue().dateRangeParams.isComparison,
};

// Stateの初期値
const initialState: UnitDataListReportsSettingState = {
  searchCondition: dataDaiDefaultValue().setting,
  currentShu: undefined,
  selectedDateRangeParams: initialDateRangeParams,
  selectedFavoriteId: undefined,
  modelNameFilter: '',
  subMenuNumberFilter: {},
  tableFilterItems: [],
};

/**
 * Selector
 */

/**
 * 台別データ一覧設定全てを取得する
 * @returns 全設定データ
 */
export function unitDataListReportsSettingSelector(rootState: {
  unitDataListReportsSetting: UnitDataListReportsSettingState;
}) {
  return rootState.unitDataListReportsSetting;
}

/**
 * 台別データ一覧の現在選択されている検索条件を取得する
 * @returns 現在選択されている検索条件
 */
export function unitDataListReportsSearchConditionSelector(rootState: {
  unitDataListReportsSetting: UnitDataListReportsSettingState;
}) {
  return rootState.unitDataListReportsSetting.searchCondition;
}

/**
 * 台別データ一覧で選択中の種別・種別グループを取得する（テーブルに表示されている種別・種別グループ）
 * @returns 現在選択されている種別（undefined時未選択）
 */
export function unitDataListReportsSelectedCurrentShuSelector(rootState: {
  unitDataListReportsSetting: UnitDataListReportsSettingState;
}) {
  return rootState.unitDataListReportsSetting.currentShu;
}

/**
 * 台別データ一覧で選択中の期間指定を取得する
 * @returns 選択中の機期間情報
 */
export function unitDataListReportsSelectedDateRangeParamsSelector(rootState: {
  unitDataListReportsSetting: UnitDataListReportsSettingState;
}) {
  return rootState.unitDataListReportsSetting.selectedDateRangeParams;
}

/**
 * 台別データ一覧で選択中のお気に入りIDを取得する
 * @returns 現在選択中のお気に入りID（undefined時未選択）
 */
export const unitDataListReportsSelectedFavoriteSelector = (
  state: RootState
) => {
  return state.unitDataListReportsSetting.selectedFavoriteId;
};

/**
 * 台別データ一覧で現在選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const unitDataListReportsSelectedFavoriteDataSelector = createSelector(
  [unitDataListReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    return favorites?.favorites?.find((favorite) => favorite.id === favoriteId);
  }
);

/**
 * 台別データ一覧で選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const unitDataListReportsSelectedFavoriteSettingSelector =
  createSelector(
    [unitDataListReportsSelectedFavoriteSelector, favoritesSelector],
    (favoriteId, favorites) => {
      if (favoriteId === undefined) return;

      const favoriteItem = favorites?.favorites?.find(
        (favorite) => favorite.id === favoriteId
      );

      return favoriteItem?.pageSetting?.unitDataListReports;
    }
  );

/**
 * 台別で入力されている機種名フィルタを返す
 */
export const unitDataListReportsModelNameFilterSelector = (rootState: {
  unitDataListReportsSetting: UnitDataListReportsSettingState;
}) => rootState.unitDataListReportsSetting.modelNameFilter;

/**
 * 台別データで入力されているサブメニュー内の数値フィルタを返す
 */
export const unitDataListReportsSubMenuNumberFilterSelector = (rootState: {
  unitDataListReportsSetting: UnitDataListReportsSettingState;
}) => rootState.unitDataListReportsSetting.subMenuNumberFilter;

/**
 * 非表示項目一覧を取得する
 * @returns 非表示項目一覧
 */
export const unitDataListReportsSelectedTableFilterSelector = (
  state: RootState
) => {
  return state.unitDataListReportsSetting.tableFilterItems;
};

/**
 * Reducer
 */

export function unitDataListReportsSettingReducer(
  state = initialState,
  action: UnitDataListReportsSettingAction
): UnitDataListReportsSettingState {
  switch (action.type) {
    case SELECT_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.payload.params,
      };
    case RESET_UNIT_DATA_LIST_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
      };
    case SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_SHU:
    case CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_SHU:
      return {
        ...state,
        currentShu: action.payload.shu,
      };
    case SELECT_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS: {
      const dateUnit = action.payload.dateUnit;
      const dateRange = action.payload.dateRange;
      const isComparison = action.payload.isComparison;
      return {
        ...state,
        selectedDateRangeParams: {
          dateUnit,
          dateRange,
          isComparison,
        },
      };
    }
    case SELECT_UNIT_DATA_LIST_REPORTS_MODEL_NAME_FILTER:
      return {
        ...state,
        modelNameFilter: action.payload.filterString,
      };
    case SELECT_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER:
      return {
        ...state,
        subMenuNumberFilter: {
          ...state.subMenuNumberFilter,
          ...action.payload.filterNumbers,
        },
      };
    case RESET_UNIT_DATA_LIST_REPORTS_SUB_MENU_NUMBER_FILTER:
      return {
        ...state,
        subMenuNumberFilter: {},
      };

    case RESET_UNIT_DATA_LIST_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: initialState.selectedDateRangeParams,
      };
    case CHANGE_UNIT_DATA_LIST_REPORTS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favorite,
      };
    case ADD_UNIT_DATA_LIST_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [
          ...state.tableFilterItems,
          action.payload.columnCode,
        ],
      };
    case REMOVE_UNIT_DATA_LIST_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: state.tableFilterItems.filter(
          (columnCode) => columnCode !== action.payload.columnCode
        ),
      };
    case APPLY_UNIT_DATA_LIST_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: action.payload.tableFilterItems,
      };
    case RESET_UNIT_DATA_LIST_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [],
      };
    case RESET_UNIT_DATA_LIST_REPORTS_FAVORITE_TO_DEFAULT:
      return {
        ...state,
        selectedFavoriteId: undefined,
      };
    case SELECT_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS:
    case CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS:
      return {
        ...state,
        searchCondition: {
          ...state.searchCondition,
          halls: action.payload.halls,
        },
      };
    default:
      return state;
  }
}
