import { ClarisApiError } from '../../domain/error';

// Action Types

const DELETE_DATA_SETTINGS_DATADELETION =
  'DELETE_DATA_SETTINGS_DATADELETION' as const;
const DELETE_DATA_SETTINGS_DATADELETION_REQUEST =
  'DELETE_DATA_SETTINGS_DATADELETION_REQUEST' as const;
const DELETE_DATA_SETTINGS_DATADELETION_SUCCESS =
  'DELETE_DATA_SETTINGS_DATADELETION_SUCCESS' as const;
const DELETE_DATA_SETTINGS_DATADELETION_FAILURE =
  'DELETE_DATA_SETTINGS_DATADELETION_FAILURE' as const;

export const DataSettingsDataDeletionActionTypes = {
  DELETE_DATA_SETTINGS_DATADELETION,
  DELETE_DATA_SETTINGS_DATADELETION_REQUEST,
  DELETE_DATA_SETTINGS_DATADELETION_SUCCESS,
  DELETE_DATA_SETTINGS_DATADELETION_FAILURE,
};

// Action Creators

function deleteDataSettingsDataDeletionAction(hallCode: string, date: string) {
  return {
    type: DELETE_DATA_SETTINGS_DATADELETION,
    payload: { hallCode, date },
  };
}

function deleteDataSettingsDataDeletionRequestAction() {
  return {
    type: DELETE_DATA_SETTINGS_DATADELETION_REQUEST,
  };
}

function deleteDataSettingsDataDeletionSuccessAction() {
  return {
    type: DELETE_DATA_SETTINGS_DATADELETION_SUCCESS,
  };
}

function deleteDataSettingsDataDeletionFailureAction(error: ClarisApiError) {
  return {
    type: DELETE_DATA_SETTINGS_DATADELETION_FAILURE,
    payload: { error },
  };
}

export const DataSettingsDataDeletionActionCreators = {
  deleteDataSettingsDataDeletionAction,
  deleteDataSettingsDataDeletionRequestAction,
  deleteDataSettingsDataDeletionSuccessAction,
  deleteDataSettingsDataDeletionFailureAction,
};

// Actions

export type DeleteDataSettingsDataDeletionAction = ReturnType<
  typeof deleteDataSettingsDataDeletionAction
>;

type SettingsDataDeletionAction =
  | DeleteDataSettingsDataDeletionAction
  | ReturnType<typeof deleteDataSettingsDataDeletionRequestAction>
  | ReturnType<typeof deleteDataSettingsDataDeletionSuccessAction>
  | ReturnType<typeof deleteDataSettingsDataDeletionFailureAction>;

// State
type DataSettingsDataDeletionState = {
  isLoading: boolean;
  error: ClarisApiError | undefined;
};

const initialState: DataSettingsDataDeletionState = {
  isLoading: false,
  error: undefined,
};

// Selector
export function dataSettingsDataDeletionLoadingSelector(rootState: {
  dataSettingsDataDeletion: DataSettingsDataDeletionState;
}) {
  return rootState.dataSettingsDataDeletion.isLoading;
}

export function dataSettingsDataDeletionErrorSelector(rootState: {
  dataSettingsDataDeletion: DataSettingsDataDeletionState;
}) {
  return rootState.dataSettingsDataDeletion.error;
}

// Reducer

export function dataSettingsDataDeletionReducer(
  state = initialState,
  action: SettingsDataDeletionAction
): DataSettingsDataDeletionState {
  switch (action.type) {
    case DELETE_DATA_SETTINGS_DATADELETION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case DELETE_DATA_SETTINGS_DATADELETION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_DATA_SETTINGS_DATADELETION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
