import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SisDataType } from '../../domain/settingsShuGroups';
import { GraphSis } from '../../domain/sis/types';

import { RootState } from '../../store';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_SIS_GRAPH = 'FETCH_DATA_SIS_GRAPH' as const;
const FETCH_DATA_SIS_GRAPH_REQUEST = 'FETCH_DATA_SIS_GRAPH_REQUEST' as const;
const FETCH_DATA_SIS_GRAPH_SUCCESS = 'FETCH_DATA_SIS_GRAPH_SUCCESS' as const;
const RENEW_DATA_SIS_GRAPH = 'RENEW_DATA_SIS_GRAPH' as const;
const SEARCH_DATA_SIS_GRAPH = 'SEARCH_DATA_SIS_GRAPH' as const;
const CHANGE_DATA_SIS_GRAPH_KI_LIST = 'CHANGE_DATA_SIS_GRAPH_KI_LIST' as const;
const CHANGE_DATA_SIS_GRAPH_FIELD = 'CHANGE_DATA_SIS_GRAPH_FIELD' as const;
const CHANGE_DATA_SIS_GRAPH_DATE_TYPE =
  'CHANGE_DATA_SIS_GRAPH_DATE_TYPE' as const;
const CLOSE_DATA_SIS_GRAPH = 'CLOSE_DATA_SIS_GRAPH' as const;

export const DataSisGraphActionTypes = {
  FETCH_DATA_SIS_GRAPH,
  FETCH_DATA_SIS_GRAPH_REQUEST,
  FETCH_DATA_SIS_GRAPH_SUCCESS,
  RENEW_DATA_SIS_GRAPH,
  SEARCH_DATA_SIS_GRAPH,
  CHANGE_DATA_SIS_GRAPH_KI_LIST,
  CHANGE_DATA_SIS_GRAPH_FIELD,
  CHANGE_DATA_SIS_GRAPH_DATE_TYPE,
  CLOSE_DATA_SIS_GRAPH,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchDataSisGraphAction(params: GraphSis['setting']) {
  return {
    type: FETCH_DATA_SIS_GRAPH,
    payload: { params },
  };
}

function fetchDataSisGraphRequestAction() {
  return {
    type: FETCH_DATA_SIS_GRAPH_REQUEST,
  };
}

function fetchDataSisGraphSuccessAction(graphSis: GraphSis) {
  return {
    type: FETCH_DATA_SIS_GRAPH_SUCCESS,
    payload: { graphSis },
  };
}

function renewDataSisGraphAction() {
  return {
    type: RENEW_DATA_SIS_GRAPH,
  };
}

function closeDataSisGraphAction() {
  return {
    type: CLOSE_DATA_SIS_GRAPH,
  };
}

function searchDataSisGraph(kiList: string[], selectedDataType: SisDataType) {
  return {
    type: SEARCH_DATA_SIS_GRAPH,
    payload: { kiList, selectedDataType },
  };
}

function changeDataSisGraphKiListAction(kiList: string[]) {
  return {
    type: CHANGE_DATA_SIS_GRAPH_KI_LIST,
    payload: { kiList },
  };
}

function changeDataSisGraphFieldAction(field: string) {
  return {
    type: CHANGE_DATA_SIS_GRAPH_FIELD,
    payload: { field },
  };
}

function changeDataSisGraphDateTypeAction(dateType: 'daily' | 'weekly') {
  return {
    type: CHANGE_DATA_SIS_GRAPH_DATE_TYPE,
    payload: { dateType },
  };
}

export const DataSisGraphActionCreators = {
  fetchDataSisGraphAction,
  fetchDataSisGraphRequestAction,
  fetchDataSisGraphSuccessAction,
  renewDataSisGraphAction,
  searchDataSisGraph,
  changeDataSisGraphKiListAction,
  changeDataSisGraphFieldAction,
  changeDataSisGraphDateTypeAction,
  closeDataSisGraphAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataSisGraphAction = ReturnType<
  typeof fetchDataSisGraphAction
>;
type FetchDataSisGraphRequestAction = ReturnType<
  typeof fetchDataSisGraphRequestAction
>;
type FetchDataSisGraphSuccessAction = ReturnType<
  typeof fetchDataSisGraphSuccessAction
>;
type RenewDataSisGraphAction = ReturnType<typeof renewDataSisGraphAction>;

export type SearchDataSisGraphAction = ReturnType<typeof searchDataSisGraph>;

type ChangeDataSisGraphKiListAction = ReturnType<
  typeof changeDataSisGraphKiListAction
>;
export type ChangeDataSisGraphFieldAction = ReturnType<
  typeof changeDataSisGraphFieldAction
>;
export type ChangeDataSisGraphDateTypeAction = ReturnType<
  typeof changeDataSisGraphDateTypeAction
>;
type CloseDataSisGraphAction = ReturnType<typeof closeDataSisGraphAction>;

type DataSisGraphAction =
  | FetchDataSisGraphAction
  | FetchDataSisGraphRequestAction
  | FetchDataSisGraphSuccessAction
  | RenewDataSisGraphAction
  | SearchDataSisGraphAction
  | ChangeDataSisGraphKiListAction
  | ChangeDataSisGraphFieldAction
  | ChangeDataSisGraphDateTypeAction
  | CloseDataSisGraphAction;

/* ---------------------------------------------------------------
 * State
 */
type DataSisGraphState = {
  /**
   * グラフデータ
   */
  dataSisGraph: GraphSis | undefined;
  /**
   * ローディング状態
   */
  loadingState: LoadingState;
  /**
   * 選択中の機種（機種選択セレクタで絞り込んだ機種）
   */
  selectKi: string[];
};

const initialState: DataSisGraphState = {
  dataSisGraph: undefined,
  loadingState: 'prepare',
  selectKi: [],
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [SIS機種レポート] 全てのグラフデータを取得する
 */
export const dataSisGraphAllSelector = (state: RootState) =>
  state.dataSisGraph.dataSisGraph;

/**
 * [SIS機種レポート] グラフデータのローディング状態を取得する
 */
export const dataSisGraphLoadingSelector = (state: RootState) =>
  state.dataSisGraph.loadingState;

/**
 * [SIS機種レポート] 絞り込んでいる機種一覧を取得する
 */
export const dataSisGraphSelectedKiListSelector = (state: RootState) =>
  state.dataSisGraph.selectKi;

/**
 * [SIS機種レポート] settingを取得する
 */
export const dataSisGraphSettingSelector = createSelector(
  dataSisGraphAllSelector,
  (dataSisGraph) => {
    return dataSisGraph?.setting;
  }
);

/**
 * [SIS機種レポート] 選択中のグラフ項目を取得する
 */
export const dataSisKiGraphSelectedFieldSelector = createSelector(
  dataSisGraphAllSelector,
  (dataSisGraph) => {
    return dataSisGraph?.setting.field;
  }
);

/**
 * [SIS機種レポート] 選択中の期間を取得する
 */
export const dataSisKiGraphSelectedDateTypeSelector = createSelector(
  dataSisGraphAllSelector,
  (dataSisGraph) => {
    return dataSisGraph?.setting.dateType;
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */
export function dataSisGraphReducer(
  state = initialState,
  action: DataSisGraphAction
): DataSisGraphState {
  switch (action.type) {
    case FETCH_DATA_SIS_GRAPH_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_SIS_GRAPH_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataSisGraph: action.payload.graphSis,
      };
    case RENEW_DATA_SIS_GRAPH:
      return initialState;
    case CHANGE_DATA_SIS_GRAPH_KI_LIST:
      return {
        ...state,
        selectKi: action.payload.kiList,
      };
    default:
      return state;
  }
}
