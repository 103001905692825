import { eachDayOfInterval, format } from 'date-fns';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { filteredShuOptions } from '../domain/chain/filteredShuOptions';
import { ChainStoreReportsFavorite } from '../domain/chain/types';
import { ChainReportsFormConditions } from '../domain/chainReportsFormConditions';
import { ChainStoreReportsSettingDateRangeParams } from '../domain/chainStoreReportsSettingDateRangeParams';
import { ComparisonGraphParams } from '../domain/comparisonGraph';
import { DataChainStoreKiParams } from '../domain/dataChainStoreKi';
import {
  DataChainStoreShuParams,
  DataChainStoreShuSummaryParams,
} from '../domain/dataChainStoreShu';
import { DataChainStoreShuGraphParams } from '../domain/dataChainStoreShuGraph';
import { DataKadoGraphParams } from '../domain/dataKadoGraph';
import {
  DataChainStoreKi2ndRowSetting,
  FavoriteItem,
} from '../domain/favorites';
import { LoadingState } from '../domain/schemas';
import { SettingsOptionsChainStore } from '../domain/settingsOptionsChainStore';
import { ShuOption } from '../domain/shu';

import {
  DataChainStoreKiActionCreators,
  dataChainStoreKiColumnsOrderDataSelector,
  dataChainStoreKiSettingSelector,
} from '../redux/server/dataChainStoreKi';
import {
  DataChainStoreKi2ndRowActionCreators,
  dataChainStoreKi2ndRowSettingsSelector,
} from '../redux/server/dataChainStoreKi2ndRow';
import {
  DataChainStoreKiGraphActionCreators,
  dataChainStoreKiGraphSelectedKiListAllSelector,
  dataChainStoreKiGraphSettingSelector,
} from '../redux/server/dataChainStoreKiGraph';
import {
  DataChainStoreShuActionCreators,
  dataChainStoreShuColumnsOrderDataSelector,
  dataChainStoreShuSettingSelector,
  dataChainStoreShuSummarySettingSelector,
} from '../redux/server/dataChainStoreShu';
import {
  DataChainStoreShuGraphActionCreators,
  dataChainStoreShuGraphSettingSelector,
} from '../redux/server/dataChainStoreShuGraph';
import {
  DataChainStoreShuKadoGraphActionCreators,
  dataChainStoreShuKadoGraphSettingSelector,
} from '../redux/server/dataChainStoreShuKadoGraph';
import {
  DataChainStoreShuWholeActionCreators,
  dataChainStoreShuWholeColumnsOrderSelector,
  dataChainStoreShuWholeSettingSelector,
  dataChainStoreShuWholeSummarySettingSelector,
} from '../redux/server/dataChainStoreShuWhole';
import {
  SettingsOptionsChainStoreActionTypes,
  settingsOptionsChainStoreLoadingStateSelector,
  settingsOptionsChainStoreSelector,
} from '../redux/server/settingsOptionsChainStore';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import {
  ChainStoreReportsSettingState,
  ChainStoreSettingActionCreators,
  ChainStoreSettingActionTypes,
  ChangeChainStoreReportsFavoriteAction,
  CreateChainStoreReportsShortenedUrlAction,
  SaveAsChainStoreReportsFavoriteAction,
  SaveChainStoreReportsFavoriteAction,
  SearchChainStoreAction,
  SearchChainStoreReportsDateRangeSlideAction,
  chainReportsComparativeSectionSelector,
  chainReportsKiSelectedTableFilterSelector,
  chainReportsShowKadoNumberLabelSelector,
  chainReportsShowNumberLabelSelector,
  chainReportsShuSelectedTableFilterSelector,
  chainStoreReportsSearchConditionSelector,
  chainStoreReportsSelectedDateRangeParamsSelector,
  chainStoreReportsSelectedFavoriteDataSelector,
  chainStoreReportsSelectedFavoriteSelector,
  chainStoreReportsSelectedFavoriteSettingSelector,
  chainStoreReportsSelectedShuSelector,
  chainStoreReportsSelectedTabIDSelector,
  chainStoreReportsSettingSelector,
  searchChainStoreReportsAction,
} from '../redux/ui/chainStoreReportsSetting';
import { ErrorActionCreators } from '../redux/ui/error';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import { ComparativeSection } from '../utils/comparativeSectionUtil';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import {
  calcEndDateWithExcludeToday,
  calcStartDateFromDateType,
} from '../utils/date';
import { findComparativeSection } from '../utils/findComparativeSection';
import { gaFavoriteEvent } from '../utils/googleAnalytics';
import { isValidArea } from '../utils/isValidArea';
import {
  ReportsDateRange,
  ReportsDateUnit,
  changeNameDateUnit,
  makeDateFromDateUnitAndRange,
} from '../utils/reportsDateRange';
import { selectShus2HallReportSearchCondition } from '../utils/shu';

/**
 * 選択中の種別の情報を元に期間指定部分の検索条件を加工する
 * @param dateRange 現在選択中の期間
 * @param dateUnit 現在選択中の日付単位
 * @param isComparison 比較期間の有無
 * @param formConditions 現在の検索条件
 * @returns 加工された期間指定の検索条件
 */
export const searchConditionToDateRangeParams = (
  dateRange: ReportsDateRange,
  dateUnit: ReportsDateUnit,
  isComparison: boolean,
  formConditions: ChainReportsFormConditions,
  comparativeSection?: ComparativeSection | undefined
): {
  ymdList: string[] | undefined;
  ymdComparisonList: string[] | undefined;
} => {
  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    const date = makeDateFromDateUnitAndRange(
      dateRange,
      dateUnit,
      new Date(),
      undefined,
      comparativeSection,
      formConditions.excludeToday
    );
    return {
      ymdList: date.ymdList.map((date) => format(date, 'yyyy-MM-dd')),
      ymdComparisonList: isComparison
        ? date.ymdComparisonList.map((date) => format(date, 'yyyy-MM-dd'))
        : undefined,
    };
  }

  return {
    // dateが算出されていない場合はカスタムと判断し、保持されている検索パラメータの値をそのまま送る
    // 保持されている検索パラメータにymdListがない場合は、ymdList対応以前のお気に入りと判断し、startDateとendDateからymdListを算出する
    ymdList:
      formConditions.ymdList !== undefined
        ? formConditions.ymdList
        : formConditions.startDate !== undefined &&
          formConditions.endDate !== undefined
        ? eachDayOfInterval({
            start: new Date(formConditions.startDate),
            end: new Date(formConditions.endDate),
          }).map((date) => format(date, 'yyyy-MM-dd'))
        : undefined,
    // 比較期間の有無で startComparisonDateとendComparisonDate を送るか判定する
    ymdComparisonList: isComparison
      ? formConditions.ymdComparisonList !== undefined
        ? formConditions.ymdComparisonList
        : formConditions.startComparisonDate !== undefined &&
          formConditions.endComparisonDate !== undefined
        ? eachDayOfInterval({
            start: new Date(formConditions.startComparisonDate),
            end: new Date(formConditions.endComparisonDate),
          }).map((date) => format(date, 'yyyy-MM-dd'))
        : undefined
      : undefined,
  };
};

/**
 * お気に入り選択時、検索条件に反映する
 * @param action Action
 */
function* applyFavoriteByIdSaga(action: ChangeChainStoreReportsFavoriteAction) {
  // グラフ自動開閉に関する状態を初期化する
  yield put(
    ChainStoreSettingActionCreators.resetChainStoreReportsIsGraphClosedByUser()
  );
  yield put(
    ChainStoreSettingActionCreators.resetChainStoreReportsCheckedKiList()
  );

  // 選択されたお気に入りID
  const selectedFavoriteId = action.payload.favorite;

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (selectedFavoriteId === undefined) {
    yield put(
      ChainStoreSettingActionCreators.searchResetChainStoreReportsAction()
    );
    yield put(
      ChainStoreSettingActionCreators.resetAllChainStoreReportsShuTableFilterAction()
    );
    yield put(
      ChainStoreSettingActionCreators.resetAllChainStoreReportsKiTableFilterAction()
    );
    return;
  }

  // 現在選択中のお気に入りの店舗レポートの各種データ
  const favorite: ChainStoreReportsFavorite | undefined = yield select(
    chainStoreReportsSelectedFavoriteSettingSelector
  );

  if (favorite == null) {
    throw new Error('該当するお気に入りが見つかりません');
  }

  yield fork(applyFavoriteSaga, favorite, selectedFavoriteId);
}

/**
 * ChainStoreReportsFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(
  favorite: ChainStoreReportsFavorite,
  selectedFavoriteId: number | undefined
) {
  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsChainStoreLoadingStateSelector
  );
  if (settingsOptionsLoadingState !== 'loaded') {
    yield take(
      SettingsOptionsChainStoreActionTypes.FETCH_SETTINGS_OPTIONS_CHAIN_STORE_SUCCESS
    );
  }
  // 期間を適用する
  const dateRange = favorite.selectedDateRangeParams.dateRange;
  // ボタン名称が古いお気に入りを変換
  const dateUnit = changeNameDateUnit(
    favorite.selectedDateRangeParams.dateUnit
  );
  const isComparison = favorite.selectedDateRangeParams.isComparison;

  // 過去のお気に入りを考慮してデフォルト値を設定する
  const comparativeSection = findComparativeSection(
    favorite.selectedDateRangeParams.dateRange,
    favorite.selectedComparativeSection
  );

  yield put(
    ChainStoreSettingActionCreators.selectChainStoreReportsDateRangeParamsAction(
      dateRange,
      dateUnit,
      isComparison,
      comparativeSection
    )
  );

  // 検索条件の期間部分を再計算
  const dateRangeParams = searchConditionToDateRangeParams(
    dateRange,
    dateUnit,
    isComparison,
    favorite.searchCondition,
    comparativeSection
  );

  // ymdList対応以前に作成したお気に入りを考慮
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = favorite.searchCondition;

  if (!isValidArea(favorite.searchCondition.areas)) {
    yield put(
      ErrorActionCreators.setError(
        `お気に入りの検索条件に存在しないエリアが指定されています。\n正しいエリアを選択して検索を再度行い、お気に入りに保存してください。`
      )
    );
  }

  // 検索条件に反映
  yield put(
    ChainStoreSettingActionCreators.selectChainStoreReportsSearchConditionAction(
      {
        ...rest,
        // 期間を再算出して上書きする
        ...dateRangeParams,
      }
    )
  );

  // お気に入りの種別を適用
  // 存在しない種別は除外する
  if (favorite.selectedShu.length > 0) {
    const settingsOptions: SettingsOptionsChainStore = yield select(
      settingsOptionsChainStoreSelector
    );
    const shuOptions = filteredShuOptions(
      favorite.selectedShu,
      settingsOptions.searchCondition
    );

    yield put(
      ChainStoreSettingActionCreators.selectChainStoreReportsShuAction(
        shuOptions
      )
    );
  } else {
    yield put(
      ChainStoreSettingActionCreators.selectChainStoreReportsShuAction([])
    );
  }

  // 派生テーブル・グラフのデータを破棄
  yield call(disposeSubTableGraphData);
  // 新台/メイン機種 推移グラフ（機種比較）のデータを破棄
  yield put(
    DataChainStoreKiGraphActionCreators.hideAllDataChainStoreKiGraphAction()
  );

  // 種別実績のお気に入り適用
  if (favorite.selectedTabId === 'chainStoreShu') {
    // 表示中の種別実績 店舗全体実績をお気に入りの条件で取得する
    yield put(
      DataChainStoreShuWholeActionCreators.searchDataChainStoreShuWholeFavoriteAction(
        favorite
      )
    );
    // 種別実績（店舗全体実績）の並び替え情報を適用
    if (favorite.dataChainStoreShuWhole?.columnsOrder) {
      yield put(
        DataChainStoreShuWholeActionCreators.selectDataChainStoreShuWholeColumnsOrderAction(
          favorite.dataChainStoreShuWhole.columnsOrder
        )
      );
    }
    // 種別実績をお気に入りの条件で取得する
    yield put(
      DataChainStoreShuActionCreators.searchDataChainStoreShuFavoriteAction(
        favorite
      )
    );
    // 種別実績の並び替え情報を適用
    if (favorite.dataChainStoreShu?.columnsOrder) {
      yield put(
        DataChainStoreShuActionCreators.selectDataChainStoreShuColumnsOrderAllAction(
          favorite.dataChainStoreShu.columnsOrder
        )
      );
    }

    // 時間帯別稼働数グラフを取得
    const dataChainStoreShuKadoGraph =
      favorite.dataChainStoreShuKadoGraph?.setting;
    if (dataChainStoreShuKadoGraph !== undefined) {
      const fetchList = Object.keys(dataChainStoreShuKadoGraph).map((key) => {
        //ymdList対応以前に作成したお気に入りを考慮
        const {
          startDate,
          endDate,
          startComparisonDate,
          endComparisonDate,
          ...rest
        } = dataChainStoreShuKadoGraph[key];
        return put(
          DataChainStoreShuKadoGraphActionCreators.fetchDataChainStoreShuKadoGraphAction(
            key,
            {
              ...rest,
              // 期間を再算出して上書きする
              ...dateRangeParams,
              // 古いお気に入りで正しくhalls,areasが設定されていないものを考慮
              halls: rest.halls ?? favorite.searchCondition.halls ?? [],
              areas: rest.areas ?? favorite.searchCondition.areas ?? [],
            }
          )
        );
      });
      yield all(fetchList);

      // 種別の時間帯別稼動グラフの数値ラベル表示を復元
      yield put(
        ChainStoreSettingActionCreators.selectShuKadoGraphShowNumberLabelAction(
          favorite.showShuKadoGraphNumberLabel ?? true
        )
      );
    }

    // 推移グラフ（店舗比較）グラフを取得
    const dataChainStoreShuGraph = favorite.dataChainStoreShuGraph?.setting;
    if (dataChainStoreShuGraph !== undefined) {
      const fetchList = Object.keys(dataChainStoreShuGraph).map((key) => {
        // グラフ用に期間を算出する
        const endDate = calcEndDateWithExcludeToday(
          !!favorite.dataChainStoreShuGraph?.setting.isExcludeToday,
          dateRangeParams.ymdList && dateRangeParams.ymdList.slice(-1)[0]
        );
        const dataChainStoreShuGraphParams = dataChainStoreShuGraph[key];
        const startDate = calcStartDateFromDateType(
          dataChainStoreShuGraphParams.dateType,
          endDate
        );
        return put(
          DataChainStoreShuGraphActionCreators.fetchDataChainStoreShuGraphAction(
            key,
            {
              ...dataChainStoreShuGraphParams,
              startDate: format(startDate, 'yyyy-MM-dd'),
              endDate: format(endDate, 'yyyy-MM-dd'),
              // 古いお気に入りで正しくhalls,areas,hallsForSijiritu,areasForSijirituが設定されていないものを考慮
              halls:
                dataChainStoreShuGraphParams.halls ??
                favorite.searchCondition.halls ??
                [],
              areas:
                dataChainStoreShuGraphParams.areas ??
                favorite.searchCondition.areas ??
                [],
              hallsForSijiritu:
                dataChainStoreShuGraphParams.hallsForSijiritu ??
                favorite.searchCondition.halls ??
                [],
              areasForSijiritu:
                dataChainStoreShuGraphParams.areasForSijiritu ??
                favorite.searchCondition.areas ??
                [],
            }
          )
        );
      });
      yield all(fetchList);

      // 種別の推移グラフの数値ラベル表示を復元
      yield put(
        ChainStoreSettingActionCreators.selectShuGraphShowNumberLabelAction(
          favorite.showShuGraphNumberLabel ?? true
        )
      );
    }

    // エリア集計がONの場合はGAイベントを送信
    if (favorite.searchCondition.containsAreaAverage && selectedFavoriteId) {
      gaFavoriteEvent('エリア集計スイッチ', {
        event_click_state: 'ON',
        event_favorite_id: selectedFavoriteId,
        event_favorite_page: 'チェーン店レポート',
      });
    }
    // 新台/メイン機種のデータを破棄する
    yield fork(disposeChainReportsKiData);
  }

  // 新台/メイン機種のお気に入り適用
  if (favorite.selectedTabId === 'chainStoreKi') {
    // 新台/メイン機種をお気に入りの条件で取得する
    yield put(
      DataChainStoreKiActionCreators.searchDataChainStoreKiFavoriteAction(
        favorite
      )
    );

    // 新台/メイン機種の並び替え情報を適用
    if (favorite.dataChainStoreKi?.columnsOrder) {
      yield put(
        DataChainStoreKiActionCreators.selectDataChainStoreKiColumnsOrderAllAction(
          favorite.dataChainStoreKi.columnsOrder
        )
      );
    }

    // 推移グラフ（機種比較）の検索条件復元
    const dataChainStoreKiGraphSetting =
      favorite.dataChainStoreKiGraph?.setting;
    if (dataChainStoreKiGraphSetting !== undefined) {
      const dataChainStoreKiGraph = Object.keys(
        dataChainStoreKiGraphSetting
      ).reduce((acc, shuCode) => {
        return {
          ...acc,
          [shuCode]: {
            setting: dataChainStoreKiGraphSetting[shuCode],
            data: null,
          },
        };
      }, {});

      yield put(
        DataChainStoreKiGraphActionCreators.selectDataChainStoreKiGraphSettingForFavorite(
          dataChainStoreKiGraph
        )
      );

      // 絞り込む機種コードを登録
      yield put(
        DataChainStoreKiGraphActionCreators.changeDataChainStoreKiGraphKiAll(
          favorite.dataChainStoreKiGraph?.selectKi ?? {}
        )
      );
    }

    // 種別実績のデータを破棄する
    yield fork(disposeChainReportsShuData);

    // 展開行を取得
    const secondRowsSettings = favorite.dataChainStoreKi2ndRow?.setting;
    if (secondRowsSettings && secondRowsSettings.length > 0) {
      yield all(
        secondRowsSettings.map(({ shuCode, kiCode, params }) => {
          //ymdList対応以前に作成したお気に入りを考慮
          const {
            startDate,
            endDate,
            startComparisonDate,
            endComparisonDate,
            sisStartDate,
            sisEndDate,
            ...rest
          } = params;
          return put(
            DataChainStoreKi2ndRowActionCreators.fetchDataChainStoreKi2ndRowAction(
              shuCode,
              kiCode,
              // params
              {
                ...rest,
                ...dateRangeParams,
                sisStartDate: sisStartDate
                  ? dateRangeParams.ymdList && dateRangeParams.ymdList[0]
                  : undefined,
                sisEndDate: sisEndDate
                  ? dateRangeParams.ymdList &&
                    dateRangeParams.ymdList.slice(-1)[0]
                  : undefined,
              }
            )
          );
        })
      );
    }

    // 新台/メイン機種タブの推移グラフの数値ラベル表示を復元
    yield put(
      ChainStoreSettingActionCreators.selectKiGraphShowNumberLabelAction(
        favorite.showKiGraphNumberLabel ?? true
      )
    );
  }

  // テーブルフィルター項目を取得する
  // 項目一覧を反映する
  yield put(
    ChainStoreSettingActionCreators.applyChainStoreReportsShuTableFilterAction(
      favorite.tableFilterItemsShu ?? []
    )
  );
  yield put(
    ChainStoreSettingActionCreators.applyChainStoreReportsKiTableFilterAction(
      favorite.tableFilterItemsKi ?? []
    )
  );

  yield put(
    ChainStoreSettingActionCreators.selectChainStoreReportsTabId(
      favorite.selectedTabId ?? 'chainStoreShu'
    )
  );

  // ユーザーが閉じたグラフの種別を復元
  if (favorite.isKiGraphClosedByUser) {
    yield put(
      ChainStoreSettingActionCreators.selectChainStoreReportsIsGraphClosedByUserForFavorite(
        favorite.isKiGraphClosedByUser
      )
    );
  }

  // チェックされた機種を復元
  if (favorite.checkedModelCodes) {
    yield put(
      ChainStoreSettingActionCreators.selectCheckedKiListForFavorite(
        favorite.checkedModelCodes
      )
    );
  }
}

/**
 * お気に入りに保存するデータの生成
 */
function* setPageSetting() {
  // 現在のチェーン店レポートの設定すべて
  const pageSetting: ChainStoreReportsSettingState = yield select(
    chainStoreReportsSettingSelector
  );

  // 種別実績テーブルの検索条件を取得
  const dataChainStoreShu: {
    [key: string]: DataChainStoreShuParams;
  } = yield select(dataChainStoreShuSettingSelector);
  // 種別実績テーブル列の並び順
  const dataChainStoreShuColumnsOrder: {
    [key: string]: string[] | undefined;
  } = yield select(dataChainStoreShuColumnsOrderDataSelector);
  // 種別実績テーブルの検索条件を取得
  const dataChainStoreShuSummary: {
    [key: string]: DataChainStoreShuSummaryParams;
  } = yield select(dataChainStoreShuSummarySettingSelector);
  // 種別実績店舗全体実績テーブルの検索条件を取得
  const dataChainStoreShuWhole: DataChainStoreShuParams | undefined =
    yield select(dataChainStoreShuWholeSettingSelector);
  // 種別実績店舗全体実績の並び順
  const dataChainStoreShuWholeColumnsOrder: string[] | undefined = yield select(
    dataChainStoreShuWholeColumnsOrderSelector
  );
  // 種別実績店舗全体実績テーブルの検索条件を取得
  const dataChainStoreShuWholeSummary:
    | DataChainStoreShuSummaryParams
    | undefined = yield select(dataChainStoreShuWholeSummarySettingSelector);
  // 時間帯別稼働数グラフの検索条件を取得
  const dataChainStoreShuKadoGraph: {
    [key: string]: DataKadoGraphParams;
  } = yield select(dataChainStoreShuKadoGraphSettingSelector);
  // 推移グラフ（店舗比較）の検索条件を取得
  const dataChainStoreShuGraph: {
    [key: string]: DataChainStoreShuGraphParams;
  } = yield select(dataChainStoreShuGraphSettingSelector);

  // 新台/メイン機種テーブルの検索条件を取得
  const dataChainStoreKi: {
    [key: string]: DataChainStoreKiParams;
  } = yield select(dataChainStoreKiSettingSelector);
  // 新台/メイン機種テーブル列の並び順
  const dataChainStoreKiColumnsOrder: {
    [key: string]: string[] | undefined;
  } = yield select(dataChainStoreKiColumnsOrderDataSelector);
  // 推移グラフ（機種比較）の検索条件を取得
  const dataChainStoreKiGraph: {
    [key: string]: ComparisonGraphParams;
  } = yield select(dataChainStoreKiGraphSettingSelector);
  // 推移グラフ（機種比較）の選択中の種別
  const dataChainStoreKiGraphSelectKi: {
    [key: string]: string[] | undefined;
  } = yield select(dataChainStoreKiGraphSelectedKiListAllSelector);
  // 新台/メイン機種テーブルの展開行検索条件を取得
  const dataChainStoreKi2ndRow: DataChainStoreKi2ndRowSetting[] = yield select(
    dataChainStoreKi2ndRowSettingsSelector
  );

  // テーブルフィルターで選択中の項目
  const tableFilterShuSelectedItems: {
    code: string;
    items: string[];
  }[] = yield select(chainReportsShuSelectedTableFilterSelector);
  const tableFilterKiSelectedItems: {
    code: string;
    items: string[];
  }[] = yield select(chainReportsKiSelectedTableFilterSelector);

  // カレンダーモーダル内の比較区分を取得
  const selectedComparativeSection: ComparativeSection = yield select(
    chainReportsComparativeSectionSelector
  );

  // 種別実績の推移グラフの数値ラベル表示
  const showShuGraphNumberLabel: boolean = yield select(
    chainReportsShowNumberLabelSelector
  );

  // 種別実績の時間帯別稼動グラフの数値ラベル表示
  const showShuKadoGraphNumberLabel: boolean = yield select(
    chainReportsShowKadoNumberLabelSelector
  );

  // 変更後のお気に入りデータ
  const favorite: FavoriteItem['pageSetting'] = {
    chainStoreReports: {
      searchCondition: pageSetting.searchCondition,
      selectedShu: pageSetting.selectedShu,
      selectedDateRangeParams: pageSetting.selectedDateRangeParams,
      selectedTabId: pageSetting.selectedTabId,
      // 種別実績の検索条件
      ...(pageSetting.selectedTabId === 'chainStoreShu'
        ? {
            dataChainStoreShu: {
              setting: dataChainStoreShu,
              columnsOrder: dataChainStoreShuColumnsOrder,
            },
            dataChainStoreShuSummary: { setting: dataChainStoreShuSummary },
            dataChainStoreShuWhole: {
              setting: dataChainStoreShuWhole ?? {},
              columnsOrder: dataChainStoreShuWholeColumnsOrder ?? [],
            },
            dataChainStoreShuWholeSummary: {
              setting: dataChainStoreShuWholeSummary ?? {},
            },
            dataChainStoreShuKadoGraph: {
              setting: dataChainStoreShuKadoGraph ?? {},
            },
            dataChainStoreShuGraph: { setting: dataChainStoreShuGraph },
          }
        : {}),
      // 新台/メイン機種の検索条件
      ...(pageSetting.selectedTabId === 'chainStoreKi'
        ? {
            dataChainStoreKi: {
              setting: dataChainStoreKi,
              columnsOrder: dataChainStoreKiColumnsOrder,
            },
            dataChainStoreKi2ndRow: {
              setting: dataChainStoreKi2ndRow,
            },
            dataChainStoreKiGraph: {
              setting: dataChainStoreKiGraph,
              selectKi: dataChainStoreKiGraphSelectKi,
            },
          }
        : {}),
      tableFilterItemsShu: tableFilterShuSelectedItems || [],
      tableFilterItemsKi: tableFilterKiSelectedItems || [],
      selectedComparativeSection: selectedComparativeSection ?? 'カスタム',
      showKiGraphNumberLabel: pageSetting.showKiGraphNumberLabel,
      isKiGraphClosedByUser: pageSetting.isKiGraphClosedByUser,
      checkedModelCodes: pageSetting.checkedModelCodes,
      showShuGraphNumberLabel,
      showShuKadoGraphNumberLabel,
    },
  };

  return favorite;
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(action: SaveChainStoreReportsFavoriteAction) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    chainStoreReportsSelectedFavoriteSelector
  );

  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem = yield select(
    chainStoreReportsSelectedFavoriteDataSelector
  );

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined) return;

  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // 変更後のお気に入りデータ
  let favorite: FavoriteItem = {};
  if (action.payload.mode === 'memo') {
    favorite = {
      pageSetting,
      name: selectedFavorite.name || '',
      isShared: selectedFavorite.isShared || false,
      memo: action.payload.memo,
      privatelySharedUsers: selectedFavorite.privatelySharedUsers || [],
    };
  } else {
    favorite = {
      pageSetting,
      name: action.payload.name,
      isShared: action.payload.isShared,
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    };
  }

  // お気に入りを上書き
  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
      selectedFavoriteId,
      favorite
    )
  );
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(action: SaveAsChainStoreReportsFavoriteAction) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  const favorite: FavoriteItem = {
    name: action.payload.name,
    isShared: action.payload.isShared,
    pageName: 'チェーン店レポート',
    pageSetting,
    memo: action.payload.memo,
    privatelySharedUsers: action.payload.sharedUser,
  };

  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([favorite])
  );
}

/**
 * チェーン店レポート種別実績のテーブルとグラフの全てのデータを破棄する
 */
function* disposeChainReportsShuData() {
  // 種別実績店舗全体実績テーブルとSummaryのデータを破棄
  yield put(
    DataChainStoreShuWholeActionCreators.clearAllDataChainStoreShuWholeAction()
  );
  // 種別実績テーブルとSummaryのデータを破棄
  yield put(DataChainStoreShuActionCreators.clearAllDataChainStoreShuAction());
}

/**
 * チェーン店レポート新台/メイン機種のテーブルとグラフの全てのデータを破棄する
 */
function* disposeChainReportsKiData() {
  // 新台/メイン機種のデータを破棄
  yield put(DataChainStoreKiActionCreators.clearAllDataChainStoreKiAction());
  // 展開行を閉じる
  yield put(
    DataChainStoreKi2ndRowActionCreators.hideAllDataChainStoreKi2ndRowAction()
  );
  // 新台/メイン機種のグラフを閉じる
  yield put(
    DataChainStoreKiGraphActionCreators.renewDataChainStoreKiGraphAction()
  );
}

/**
 * 派生テーブル・グラフのデータを破棄する
 */
function* disposeSubTableGraphData() {
  // 種別実績 推移グラフ（店舗比較）のデータを破棄
  yield put(
    DataChainStoreShuGraphActionCreators.hideAllDataChainStoreShuGraphAction()
  );
  // 種別実績 時間帯別稼働数グラフのデータを破棄
  yield put(
    DataChainStoreShuKadoGraphActionCreators.hideAllDataChainStoreShuKadoGraphAction()
  );
}

/**
 * チェーン店レポート 検索ボタン押下時に行う処理
 */
function* searchChainStoreActionSaga(action: SearchChainStoreAction) {
  const formCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );

  // MEMO: 検索時は現在表示されているテーブルの検索条件に対して、検索フォームで変更した検索条件を上書きしてデータ取得する必要がある
  // 派生テーブル・グラフを全て破棄
  yield fork(disposeSubTableGraphData);

  // 検索条件を登録
  yield put(
    ChainStoreSettingActionCreators.selectChainStoreReportsSearchConditionAction(
      {
        ...action.payload.params,
        // エリア集計は検索条件を引き継ぐ
        // エリア集計は各テーブルだけではなく、ページ全体の設定のためここで追加する
        containsAreaAverage: formCondition.containsAreaAverage,
      }
    )
  );

  // 種別一覧を登録
  yield put(
    ChainStoreSettingActionCreators.selectChainStoreReportsShuAction(
      action.payload.selectedShu
    )
  );

  const { dateRange, dateUnit, isComparison, comparativeSection } =
    action.payload;

  // dateRange・dateUnit（選択中の日付単位・期間）を更新する
  yield put(
    ChainStoreSettingActionCreators.selectChainStoreReportsDateRangeParamsAction(
      dateRange,
      dateUnit,
      isComparison,
      // 日付スライド時にも値を維持するためにfindComparativeSectionをしない
      comparativeSection
    )
  );

  // 検索条件の期間部分を再計算
  const dateRangeParams = searchConditionToDateRangeParams(
    dateRange,
    dateUnit,
    isComparison,
    action.payload.params,
    comparativeSection
  );

  // 検索条件を混ぜる
  const searchCondition = {
    ...action.payload.params,
    ...selectShus2HallReportSearchCondition(action.payload.selectedShu),
    ...dateRangeParams,
  };

  // 現在選択中のタブを取得（表示しているタブだけ再取得を行う）
  const tabID: string = yield select(chainStoreReportsSelectedTabIDSelector);

  // 種別実績
  if (tabID === 'chainStoreShu') {
    // 開いていないタブのデータを破棄
    yield fork(disposeChainReportsKiData);

    // 種別実績店舗全体実績テーブルを再取得
    yield put(
      DataChainStoreShuWholeActionCreators.searchDataChainStoreShuWholeAction(
        searchCondition
      )
    );
    // 種別実績テーブルを再取得
    yield put(
      DataChainStoreShuActionCreators.searchDataChainStoreShuAction(
        searchCondition
      )
    );

    return;
  }

  // 新台/メイン機種
  if (tabID === 'chainStoreKi') {
    // 開いていないタブのデータを破棄
    yield fork(disposeChainReportsShuData);

    // 新台/メイン機種テーブルを再取得
    yield put(
      DataChainStoreKiActionCreators.searchDataChainStoreKiAction(
        searchCondition
      )
    );

    return;
  }
}

/**
 * チェーン店レポート 条件リセットボタン押下時の処理
 */
function* resetSearchActionSaga() {
  // 派生テーブル・グラフのデータを破棄する
  yield fork(disposeSubTableGraphData);
  // 新台/メイン機種 推移グラフ（機種比較）のデータを破棄
  yield put(
    DataChainStoreKiGraphActionCreators.hideAllDataChainStoreKiGraphAction()
  );

  // 現在選択中のお気に入り適用
  const favoriteId: number | undefined = yield select(
    chainStoreReportsSelectedFavoriteSelector
  );

  // お気に入りが選択されている場合はお気に入り適用
  if (favoriteId !== undefined) {
    yield put(
      ChainStoreSettingActionCreators.changeChainStoreReportsFavoriteAction(
        favoriteId
      )
    );

    return;
  }

  // デフォルトの検索条件に戻す
  yield put(
    ChainStoreSettingActionCreators.resetChainStoreReportsSearchConditionAction()
  );
  yield put(ChainStoreSettingActionCreators.resetChainStoreReportsShuAction());
  yield put(
    ChainStoreSettingActionCreators.resetChainStoreReportsDateRangeParamsAction()
  );

  // 現在選択中のタブを取得（表示しているタブだけ再取得を行う）
  const tabID: string = yield select(chainStoreReportsSelectedTabIDSelector);

  // 種別実績
  if (tabID === 'chainStoreShu') {
    // 表示中の種別実績 店舗全体実績を初回条件で取得する
    yield put(
      DataChainStoreShuWholeActionCreators.searchDataChainStoreShuWholeResetAction()
    );
    // 表示中の種別実績を初回条件で取得する
    yield put(
      DataChainStoreShuActionCreators.searchDataChainStoreShuResetAction()
    );

    // 開いていないタブのデータを破棄
    yield fork(disposeChainReportsKiData);

    return;
  }

  // 新台/メイン機種
  if (tabID === 'chainStoreKi') {
    // 表示中の新台/メイン機種を初回条件で取得する
    yield put(
      DataChainStoreKiActionCreators.searchDataChainStoreKiResetAction()
    );

    // 開いていないタブのデータを破棄
    yield fork(disposeChainReportsShuData);

    return;
  }
}

// 期間スライドコンポーネントがクリックされた時に検索期間を設定して再検索を実行する
function* searchDateRangeSlideSaga(
  action: SearchChainStoreReportsDateRangeSlideAction
) {
  const { dateRange, ymdList, ymdComparisonList } = action.payload;
  // 検索条件の項目を取得
  const searchCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );

  // 比較区分
  const comparativeSection: ComparativeSection = yield select(
    chainReportsComparativeSectionSelector
  );

  const { isComparison, dateUnit }: ChainStoreReportsSettingDateRangeParams =
    yield select(chainStoreReportsSelectedDateRangeParamsSelector);

  const shuOptions: ShuOption[] = yield select(
    chainStoreReportsSelectedShuSelector
  );

  yield put(
    searchChainStoreReportsAction(
      {
        ...searchCondition,
        ymdList,
        ymdComparisonList,
      },
      shuOptions,
      dateRange,
      dateUnit,
      isComparison,
      comparativeSection
    )
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createChainStoreReportsShortenedUrlSaga(
  action: CreateChainStoreReportsShortenedUrlAction
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

function* handleSearchSaga() {
  // 検索ボタン押下時の処理
  yield takeEvery(
    ChainStoreSettingActionTypes.SEARCH_CHAIN_STORE_REPORTS,
    searchChainStoreActionSaga
  );
  // リセットボタン押下時の処理
  yield takeEvery(
    ChainStoreSettingActionTypes.SEARCH_RESET_CHAIN_STORE_REPORTS,
    resetSearchActionSaga
  );
}

function* handleChainStoreFavoriteSaga() {
  // お気に入り選択
  yield takeEvery(
    ChainStoreSettingActionTypes.CHANGE_CHAIN_STORE_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    ChainStoreSettingActionTypes.SAVE_AS_CHAIN_STORE_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
  // お気に入り上書き保存
  yield takeEvery(
    ChainStoreSettingActionTypes.SAVE_CHAIN_STORE_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
}

// 期間スライドコンポーネントがクリックされた時に実行する処理
function* handleDateRangeSlideSaga() {
  yield takeEvery(
    ChainStoreSettingActionTypes.SEARCH_CHAIN_STORE_REPORTS_DATE_RANGE_SLIDE,
    searchDateRangeSlideSaga
  );
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    ChainStoreSettingActionTypes.CREATE_CHAIN_STORE_REPORTS_SHORTENED_URL,
    createChainStoreReportsShortenedUrlSaga
  );
}

/**
 * チェーン店レポートの検索フォームに関するタスクを実行する
 */
export function* chainStoreReportsSettingSaga() {
  yield fork(handleSearchSaga);
  yield fork(handleChainStoreFavoriteSaga);
  yield fork(handleDateRangeSlideSaga);
  yield fork(handleShortenedUrlSaga);
}
