// Action Types
import { createSelector } from 'reselect';

import {
  PlanProgressGraphParams,
  PlanProgressTransitionGraph,
} from '../../domain/dataPlanProgressGraph';

import { RootState } from '../../store';

const FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH =
  'FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH' as const;
const FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_REQUEST =
  'FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_REQUEST' as const;
const FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_SUCCESS =
  'FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_SUCCESS' as const;
const RENEW_DATA_PLAN_PROGRESS_TRANSITION_GRAPH =
  'RENEW_DATA_PLAN_PROGRESS_TRANSITION_GRAPH' as const;
const HIDE_ALL_DATA_PLAN_PROGRESS_TRANSITION_GRAPH =
  'HIDE_ALL_DATA_PLAN_PROGRESS_TRANSITION_GRAPH' as const;

const SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH =
  'SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH' as const;
const SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_HALL =
  'SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_HALL' as const;
const SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_DATE =
  'SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_DATE' as const;
const SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_FIELDS =
  'SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_FIELDS' as const;

export const DataPlanProgressTransitionGraphActionTypes = {
  FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH,
  FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_REQUEST,
  FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_SUCCESS,
  RENEW_DATA_PLAN_PROGRESS_TRANSITION_GRAPH,
  HIDE_ALL_DATA_PLAN_PROGRESS_TRANSITION_GRAPH,
  SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH,
  SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_FIELDS,
};
// Action Creators

/**
 * 推移グラフデータを取得する
 * @param params 推移グラフの検索条件
 */
function fetchDataPlanProgressGraphAction(params: PlanProgressGraphParams) {
  return {
    type: FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH,
    payload: { params },
  };
}

/**
 * 推移グラフ取得前に実行する
 */
function fetchDataPlanProgressTransitionGraphRequestAction() {
  return {
    type: FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_REQUEST,
  };
}

/**
 * 推移グラフデータ取得完了時データを登録する
 * @param dataPlanProgressTransitionGraph 取得したグラフデータ
 */
function fetchDataPlanProgressTransitionGraphSuccessAction(
  dataPlanProgressTransitionGraph: PlanProgressTransitionGraph
) {
  return {
    type: FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_SUCCESS,
    payload: { dataPlanProgressTransitionGraph },
  };
}

/**
 * 初期化
 * @param error エラー内容
 */
function renewDataPlanProgressTransitionGraphAction() {
  return {
    type: RENEW_DATA_PLAN_PROGRESS_TRANSITION_GRAPH,
  };
}

/**
 * 選択した店舗の推移グラフを表示する
 * @param shuGroupId 種別データ
 * @param shuList 種別データ
 * @param halls 表示する店舗コード
 * @param field 表示するグラフ項目
 */
function searchDataPlanProgressTransitionGraphAction(
  shuGroupId: string[],
  shuList: string[],
  halls: string[],
  field?: string
) {
  return {
    type: SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH,
    payload: { shuGroupId, shuList, halls, field },
  };
}

/**
 * 指定した店舗でグラフを絞り込む
 * @param hall 絞り込む店舗
 */
function searchDataPlanProgressTransitionGraphHallAction(hall: string[]) {
  return {
    type: SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_HALL,
    payload: { hall },
  };
}

/**
 * 指定した期間で推移グラフデータを再取得
 */
function searchDataPlanProgressTransitionGraphDateAction(
  month: string,
  year: string
) {
  return {
    type: SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_DATE,
    payload: { month, year },
  };
}

/**
 * 指定したグラフ項目で推移グラフデータを再取得
 * @param field 棒グラフのグラフ項目
 */
function searchDataPlanProgressTransitionGraphFieldsAction(
  field: string | undefined
) {
  return {
    type: SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_FIELDS,
    payload: { field },
  };
}

/**
 * 推移グラフの全てのデータを破棄する
 */
function hideAllDataPlanProgressTransitionGraphAction() {
  return {
    type: HIDE_ALL_DATA_PLAN_PROGRESS_TRANSITION_GRAPH,
  };
}

export const DataPlanProgressTransitionGraphActionCreators = {
  fetchDataPlanProgressGraphAction,
  fetchDataPlanProgressTransitionGraphRequestAction,
  fetchDataPlanProgressTransitionGraphSuccessAction,
  renewDataPlanProgressTransitionGraphAction,
  hideAllDataPlanProgressTransitionGraphAction,
  searchDataPlanProgressTransitionGraphAction,
  searchDataPlanProgressTransitionGraphHallAction,
  searchDataPlanProgressTransitionGraphDateAction,
  searchDataPlanProgressTransitionGraphFieldsAction,
};
// Actions
export type FetchDataPlanProgressGraphAction = ReturnType<
  typeof fetchDataPlanProgressGraphAction
>;

type SearchDataPlanProgressTransitionGraphAction = ReturnType<
  typeof searchDataPlanProgressTransitionGraphAction
>;
type SearchDataPlanProgressTransitionGraphHallAction = ReturnType<
  typeof searchDataPlanProgressTransitionGraphHallAction
>;
type SearchDataPlanProgressTransitionGraphDateAction = ReturnType<
  typeof searchDataPlanProgressTransitionGraphDateAction
>;
export type SearchDataPlanProgressTransitionGraphFieldsAction = ReturnType<
  typeof searchDataPlanProgressTransitionGraphFieldsAction
>;

type DataPlanProgressTransitionGraphAction =
  | FetchDataPlanProgressGraphAction
  | SearchDataPlanProgressTransitionGraphAction
  | SearchDataPlanProgressTransitionGraphHallAction
  | SearchDataPlanProgressTransitionGraphDateAction
  | SearchDataPlanProgressTransitionGraphFieldsAction
  | ReturnType<typeof fetchDataPlanProgressTransitionGraphRequestAction>
  | ReturnType<typeof fetchDataPlanProgressTransitionGraphSuccessAction>
  | ReturnType<typeof renewDataPlanProgressTransitionGraphAction>
  | ReturnType<typeof hideAllDataPlanProgressTransitionGraphAction>;

// State

type DataPlanProgressTransitionGraphState = {
  /**
   * グラフデータ
   */
  dataPlanProgressTransitionGraph: PlanProgressTransitionGraph | undefined;
  /**
   * ローディング状態
   */
  isLoading: boolean | undefined;
};

const initialState: DataPlanProgressTransitionGraphState = {
  dataPlanProgressTransitionGraph: undefined,
  isLoading: undefined,
};

// Selector

/**
 * 全てのグラフデータを取得する
 * @returns 全てのグラフデータ
 */
export const dataPlanProgressTransitionGraphAllSelector = (state: RootState) =>
  state.dataPlanProgressTransitionGraph.dataPlanProgressTransitionGraph;

/**
 * 推移グラフの検索条件を取得する
 * @returns 推移グラフの検索条件
 */
export const dataPlanProgressTransitionGraphSearchConditionSelector = () =>
  createSelector(
    dataPlanProgressTransitionGraphAllSelector,
    (data) => data?.setting ?? ({} as PlanProgressGraphParams)
  );

/**
 * 全てのローディング状態を取得する
 * @returns 全てのローディング状態
 */
export const dataPlanProgressTransitionGraphLoadingAllSelector = (
  state: RootState
) => state.dataPlanProgressTransitionGraph.isLoading;

export function dataPlanProgressTransitionGraphReducer(
  state = initialState,
  action: DataPlanProgressTransitionGraphAction
): DataPlanProgressTransitionGraphState {
  switch (action.type) {
    case FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataPlanProgressTransitionGraph:
          action.payload.dataPlanProgressTransitionGraph,
      };
    case RENEW_DATA_PLAN_PROGRESS_TRANSITION_GRAPH:
      return initialState;
    case HIDE_ALL_DATA_PLAN_PROGRESS_TRANSITION_GRAPH:
      return {
        ...state,
        isLoading: initialState.isLoading,
        dataPlanProgressTransitionGraph:
          initialState.dataPlanProgressTransitionGraph,
      };
    default:
      return state;
  }
}
