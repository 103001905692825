import { OrderType, SortRows, SortRowsForKey } from '../../domain/schemas';

/**
 * typeがsortNumberWithPrefixの場合のソート処理
 * @param rows ソート対象のrows
 * @param index ソート対象の列のindex
 * @param order ソート条件
 */
export const sortNumberWithPrefix = <T extends SortRows | SortRowsForKey>(
  rows: T[],
  index: number,
  order: OrderType
): T[] => {
  const compareNumbers = (a: T, b: T, order: OrderType) => {
    const valueA = a.data.at(index)?.value;
    const valueB = b.data.at(index)?.value;

    if (valueA == null || valueB == null) {
      return 0;
    }

    // queryParameterにnameが含まれる場合はvalueがnullの場合は一番上に配置(平均合計行の場合はnullなので一番上に持ってくる)
    if ('name' in a.queryParameter) {
      if (a.queryParameter.value === null) {
        return -1;
      }
    } else {
      // queryParameter.kiList(他の場合も)がnullの場合は一番上に配置(平均合計行の場合はnullなので一番上に持ってくる)
      if (
        a.queryParameter.kiList === null ||
        a.queryParameter.sisTypes === null ||
        a.queryParameter.makers === null ||
        a.queryParameter.halls === null
      ) {
        return -1;
      }
    }
    if ('name' in b.queryParameter) {
      if (b.queryParameter.value === null) {
        return 1;
      }
    } else {
      if (
        b.queryParameter.kiList === null ||
        b.queryParameter.sisTypes === null ||
        b.queryParameter.makers === null ||
        b.queryParameter.halls === null
      ) {
        return 1;
      }
    }

    // ハイフンを後ろにするための条件
    if (valueA === '-') return 1;
    if (valueB === '-') return -1;

    // 接頭辞と空白を取り除く
    const cleanValueA = valueA.replace(/^[*⋄]\s*/, '');
    const cleanValueB = valueB.replace(/^[*⋄]\s*/, '');

    // カンマとパーセント記号が含まれる場合は削除して数値に変換
    const numA = Number(cleanValueA.replace(/,/g, '').replace(/%/g, ''));
    const numB = Number(cleanValueB.replace(/,/g, '').replace(/%/g, ''));

    if (isNaN(numA) || isNaN(numB)) {
      return 0;
    }

    return order === 'asc' ? numA - numB : numB - numA;
  };

  return rows.slice().sort((a, b) => compareNumbers(a, b, order));
};
