import { filterModelName } from '../../utils/filterModelName';
import { DataKiSyokenResponse } from './types';

export const dataKiSyokenFilteredByName = (
  dataKiSyoken: DataKiSyokenResponse,
  name: string | undefined
): DataKiSyokenResponse => {
  if (name == null) {
    return dataKiSyoken;
  }

  const predicate = filterModelName(name ?? '');

  const rows = dataKiSyoken.data.rows.filter(
    (row) => row.data.length > 0 && predicate(row.data.at(0)?.value ?? '')
  );

  return {
    ...dataKiSyoken,
    data: {
      ...dataKiSyoken.data,
      rows,
    },
  };
};
