import { Column, Row } from './schemas';

// データ種別
const DATA_CATEGORY_ID_CUSTOMER = 'kyakuSu' as const;
const DATA_CATEGORY_ID_WORKING_RATE = 'kadoRitu' as const;
const DATA_CATEGORY_ID_SUPPORT_RATE = 'sijiRitu' as const;
const DATA_CATEGORY_ID_SHARE_RATE = 'senyuRitu' as const;

export type DataCategoryId =
  | typeof DATA_CATEGORY_ID_CUSTOMER
  | typeof DATA_CATEGORY_ID_WORKING_RATE
  | typeof DATA_CATEGORY_ID_SUPPORT_RATE
  | typeof DATA_CATEGORY_ID_SHARE_RATE;

const DataCategoryCustomer = {
  id: DATA_CATEGORY_ID_CUSTOMER,
  label: '客数',
} as const;
export const DataCategoryWorkingRate = {
  id: DATA_CATEGORY_ID_WORKING_RATE,
  label: '稼動率',
} as const;
export const DataCategorySupportRate = {
  id: DATA_CATEGORY_ID_SUPPORT_RATE,
  label: '支持率',
} as const;
export const DataCategoryShareRate = {
  id: DATA_CATEGORY_ID_SHARE_RATE,
  label: '占有率',
} as const;

export const DataCategories = [
  DataCategoryCustomer,
  DataCategoryWorkingRate,
  DataCategorySupportRate,
  DataCategoryShareRate,
];

export type DataCategory =
  | typeof DataCategoryCustomer
  | typeof DataCategoryWorkingRate
  | typeof DataCategorySupportRate
  | typeof DataCategoryShareRate;

// 時間帯フィルタ
const TIMELNE_VALUE_ALL = 'all' as const;
const TIMELNE_VALUE_11 = '11' as const;
const TIMELNE_VALUE_15 = '15' as const;
const TIMELNE_VALUE_19 = '19' as const;

export type TimelineValue =
  | typeof TIMELNE_VALUE_ALL
  | typeof TIMELNE_VALUE_11
  | typeof TIMELNE_VALUE_15
  | typeof TIMELNE_VALUE_19;

export const timelineOptions = [
  { name: '3時間帯', value: TIMELNE_VALUE_ALL },
  { name: '11:00', value: TIMELNE_VALUE_11 },
  { name: '15:00', value: TIMELNE_VALUE_15 },
  { name: '19:00', value: TIMELNE_VALUE_19 },
] as const;

export const MKS_GRAPH_DATE_TYPE = {
  THIRTY_DAYS: '30days',
  THIRTEEN_WEEKS: '13weeks',
  THIRTEEN_MONTHS: '13months',
} as const;

// 検索期間
export type DateRangeId =
  (typeof MKS_GRAPH_DATE_TYPE)[keyof typeof MKS_GRAPH_DATE_TYPE];

export const DateRange30Days = {
  id: MKS_GRAPH_DATE_TYPE.THIRTY_DAYS,
  label: '30日',
};
const DateRange13Weeks = {
  id: MKS_GRAPH_DATE_TYPE.THIRTEEN_WEEKS,
  label: '13週',
};
const DateRange13Months = {
  id: MKS_GRAPH_DATE_TYPE.THIRTEEN_MONTHS,
  label: '13ヶ月',
};

export type DateRange =
  | typeof DateRange30Days
  | typeof DateRange13Weeks
  | typeof DateRange13Months;

export const dateRanges = [
  DateRange30Days,
  DateRange13Weeks,
  DateRange13Months,
];

export type Mks = {
  selectedHallCodes: null | string[];
  dataCategory: DataCategory;
  showGraphNumberLabel: boolean;
  shuOptionCode: string;
  timeline: TimelineValue;
  dateRange: DateRange;
  showTransitionGraphNumberLabel: boolean;
  hiddenColumnCodes: string[];
  columnsOrder: string[];
};

// CSV出力用フォーマット関数
export const generateCsv = (columns: Column[], rows: Row[]) => () => {
  if (!columns || !rows || columns.length === 0 || rows.length === 0) {
    return [];
  }

  return [
    columns.map((column) => column.name),
    ...rows.map((row) => row.data.map((datum) => datum.value)),
  ];
};
