import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import {
  EventsPostRequestBody,
  EventsResponse,
} from '../../domain/settingsEvents/types';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_SETTINGS_EVENTS = 'FETCH_DATA_SETTINGS_EVENTS' as const;
const FETCH_DATA_SETTINGS_EVENTS_REQUEST =
  'FETCH_DATA_SETTINGS_EVENTS_REQUEST' as const;
const FETCH_DATA_SETTINGS_EVENTS_SUCCESS =
  'FETCH_DATA_SETTINGS_EVENTS_SUCCESS' as const;
const POST_DATA_SETTINGS_EVENTS = 'POST_DATA_SETTINGS_EVENTS' as const;
const RENEW_DATA_SETTINGS_EVENTS = 'RENEW_DATA_SETTINGS_EVENTS' as const;

export const DataSettingsEventsActionTypes = {
  FETCH_DATA_SETTINGS_EVENTS,
  FETCH_DATA_SETTINGS_EVENTS_REQUEST,
  FETCH_DATA_SETTINGS_EVENTS_SUCCESS,
  POST_DATA_SETTINGS_EVENTS,
  RENEW_DATA_SETTINGS_EVENTS,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchDataSettingsEventsAction(params: EventsResponse['setting']) {
  return {
    type: FETCH_DATA_SETTINGS_EVENTS,
    payload: { params },
  };
}

function fetchDataSettingsEventsRequestAction() {
  return {
    type: FETCH_DATA_SETTINGS_EVENTS_REQUEST,
  };
}

function fetchDataSettingsEventsSuccessAction(data: EventsResponse) {
  return {
    type: FETCH_DATA_SETTINGS_EVENTS_SUCCESS,
    payload: { data },
  };
}

function postDataSettingsEventsAction(body: EventsPostRequestBody) {
  return {
    type: POST_DATA_SETTINGS_EVENTS,
    payload: { body },
  };
}

function renewDataSettingsEventsAction() {
  return {
    type: RENEW_DATA_SETTINGS_EVENTS,
  };
}

export const DataSettingsEventsActionCreators = {
  fetchDataSettingsEventsAction,
  fetchDataSettingsEventsRequestAction,
  fetchDataSettingsEventsSuccessAction,
  postDataSettingsEventsAction,
  renewDataSettingsEventsAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataSettingsEventsAction = ReturnType<
  typeof fetchDataSettingsEventsAction
>;
export type PostDataSettingsEventsAction = ReturnType<
  typeof postDataSettingsEventsAction
>;

type DataSettingsEventsAction =
  | FetchDataSettingsEventsAction
  | ReturnType<typeof fetchDataSettingsEventsRequestAction>
  | ReturnType<typeof fetchDataSettingsEventsSuccessAction>
  | ReturnType<typeof renewDataSettingsEventsAction>;

/* ---------------------------------------------------------------
 * State
 */

type DataSettingsEventsState = {
  loadingState: LoadingState;
  events: EventsResponse | undefined;
};

const initialState: DataSettingsEventsState = {
  loadingState: 'prepare',
  events: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

function allSelector(rootState: {
  dataSettingsEvents: DataSettingsEventsState;
}) {
  return rootState.dataSettingsEvents;
}

export const dataSettingsEventsLoadingStateSelector = createSelector(
  allSelector,
  ({ loadingState }) => loadingState
);

export const dataSettingsEventsSelector = createSelector(
  allSelector,
  ({ events }) => events
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataSettingsEventsReducer(
  state = initialState,
  action: DataSettingsEventsAction
): DataSettingsEventsState {
  switch (action.type) {
    case FETCH_DATA_SETTINGS_EVENTS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_SETTINGS_EVENTS_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        events: action.payload.data,
      };
    case RENEW_DATA_SETTINGS_EVENTS:
      return initialState;
    default:
      return state;
  }
}
