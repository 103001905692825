import { format } from 'date-fns';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsDai } from '../domain/dai/types';
import { DataDai } from '../domain/dai/types';
import { UnitDataListFormConditions } from '../domain/dai/types';
import { FavoriteItem } from '../domain/favorites';
import { Hall } from '../domain/schemas';
import { ShuOption } from '../domain/shu';
import { UnitDataListReportsFavorite } from '../domain/unitDataList/types';

import {
  DataDaiActionCreators,
  dataDaiColumnsOrderSelector,
  dataDaiHighlightAverageSelector,
  dataDaiParamsSelector,
} from '../redux/server/dataDai';
import {
  settingsOptionsDaiSearchConditionSelector,
  settingsOptionsDaiSelector,
} from '../redux/server/settingsOptionsDai';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import {
  ChangeUnitDataListReportsCurrentHallsAction,
  ChangeUnitDataListReportsCurrentShuAction,
  ChangeUnitDataListReportsFavoriteAction,
  CreateUnitDataListReportsShortenedUrlAction,
  SaveAsUnitDataListReportsFavoriteAction,
  SaveUnitDataListReportsFavoriteAction,
  SearchResetUnitDataListReportsAction,
  SearchUnitDataListReportsAction,
  SearchUnitDataListReportsDateRangeSlideAction,
  SearchUnitDataListReportsFieldAction,
  SearchUnitDataListReportsSortAction,
  UnitDataListReportsSettingActionCreators,
  UnitDataListReportsSettingActionTypes,
  UnitDataListReportsSettingState,
  resetUnitDataListReportsSubMenuNumberFilterAction,
  searchUnitDataListReportsAction,
  selectUnitDataListReportsModelNameFilterAction,
  selectUnitDataListReportsSubMenuNumberFilterAction,
  unitDataListReportsModelNameFilterSelector,
  unitDataListReportsSearchConditionSelector,
  unitDataListReportsSelectedCurrentShuSelector,
  unitDataListReportsSelectedDateRangeParamsSelector,
  unitDataListReportsSelectedFavoriteDataSelector,
  unitDataListReportsSelectedFavoriteSelector,
  unitDataListReportsSelectedFavoriteSettingSelector,
  unitDataListReportsSelectedTableFilterSelector,
  unitDataListReportsSettingSelector,
  unitDataListReportsSubMenuNumberFilterSelector,
} from '../redux/ui/unitDataListReportsSetting';
import { userSelector } from '../redux/ui/user';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import {
  checkCurrentShu,
  getShuOption,
  selectShu2SearchCondition,
} from '../utils/shu';
import {
  UnitDataListReportsDateRange,
  UnitDataListReportsDateUnit,
  makeUnitDataListDateFromDateUnitAndRange,
  unitDataReportsDateUnits,
} from '../utils/unitDataListDateRange';

/**
 * 選択中の種別の情報を元に期間指定部分の検索条件を加工する
 * @param dateRange 現在選択中の期間
 * @param isComparison 比較期間の有無
 * @param formConditions 現在の検索条件
 * @returns 加工された期間指定の検索条件
 */
const serachContidionToDateRangeParams = (
  dateRange: UnitDataListReportsDateRange,
  dateUnit: UnitDataListReportsDateUnit,
  isComparison: boolean,
  formConditions: UnitDataListFormConditions
): UnitDataListFormConditions => {
  // 期間を元にstartDateとendDateを算出
  let date:
    | ReturnType<typeof makeUnitDataListDateFromDateUnitAndRange>
    | undefined = undefined;

  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    date = makeUnitDataListDateFromDateUnitAndRange(dateRange, dateUnit);
  }

  return {
    // dateが算出されていない場合はカスタムと判断し、保持されている検索パラメータの値をそのまま送る
    // 保持されている検索パラメータにymdListがない場合は、ymdList対応以前のお気に入りと判断し、startDateとendDateからymdListを算出する
    ymdList:
      date !== undefined
        ? date.ymdList.map((date) => format(date, 'yyyy-MM-dd'))
        : formConditions.ymdList !== undefined
        ? formConditions.ymdList
        : undefined,
    // 比較期間の有無で startComparisonDateとendComparisonDate を送るか判定する
    ymdComparisonList: isComparison
      ? date !== undefined
        ? date.ymdComparisonList.map((date) => format(date, 'yyyy-MM-dd'))
        : formConditions.ymdComparisonList !== undefined
        ? formConditions.ymdComparisonList
        : undefined
      : undefined,
  };
};

/**
 * お気に入り選択時、検索条件に反映する
 * @param action Action
 */
function* applyFavoriteByIdSaga(
  action: ChangeUnitDataListReportsFavoriteAction
) {
  // 選択されたお気に入りID
  const selectedFavoriteId = action.payload.favorite;

  // 現在選択中のお気に入りデータを取得
  const favorite: UnitDataListReportsFavorite | undefined = yield select(
    unitDataListReportsSelectedFavoriteSettingSelector
  );

  // 担当店舗取得
  const { hallCodeInCharge }: { hallCodeInCharge: string } = yield select(
    userSelector
  );

  const {
    searchCondition: halls,
  }: {
    searchCondition: {
      halls: Hall[];
    };
  } = yield select(settingsOptionsDaiSelector);

  // settingsOptionと担当店舗から担当店舗の情報を取得
  const findHallCodeInCharge = halls.halls.find(
    (hall) => hall.code === hallCodeInCharge
  );

  // 店舗設定
  const favoriteHalls = () => {
    if (favorite?.searchCondition.halls) {
      return favorite?.searchCondition.halls;
    }
    // 担当店舗が設定されている場合は担当店舗を設定
    else if (hallCodeInCharge && findHallCodeInCharge) {
      return [findHallCodeInCharge.code];
    } else {
      const selectedHall = halls.halls.at(0);
      if (!selectedHall) {
        throw new Error('settingsOptionsに該当する店舗が存在しません');
      }
      return [selectedHall.code];
    }
  };

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (selectedFavoriteId === undefined) {
    yield put(
      UnitDataListReportsSettingActionCreators.searchResetUnitDataListReportsAction(
        favoriteHalls()
      )
    );
    yield put(
      UnitDataListReportsSettingActionCreators.resetUnitDataListReportsTableFilterAction()
    );
    return;
  }

  if (favorite == null) {
    throw new Error('該当するお気に入りが見つかりません');
  }

  yield fork(applyFavoriteSaga, favorite);
}

/**
 * UnitDataListReportsFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(favorite: UnitDataListReportsFavorite) {
  // 機種名フィルタを変更
  yield put(
    selectUnitDataListReportsModelNameFilterAction(favorite.modelNameFilter)
  );

  // サブメニュー内数値フィルタを変更
  // 一度サブメニュー内数値フィルタをクリア
  yield put(resetUnitDataListReportsSubMenuNumberFilterAction());

  yield put(
    selectUnitDataListReportsSubMenuNumberFilterAction(
      favorite.subMenuNumberFilter
    )
  );

  // 期間を適用する
  const dateRange = favorite.selectedDateRangeParams.dateRange;
  const dateUnit =
    favorite.selectedDateRangeParams.dateUnit ?? unitDataReportsDateUnits[0];
  const isComparison = favorite.selectedDateRangeParams.isComparison;
  yield put(
    UnitDataListReportsSettingActionCreators.selectUnitDataListReportsDateRangeParamsAction(
      dateRange,
      dateUnit,
      isComparison
    )
  );

  // 検索条件の期間部分を再計算
  const dateRangeParams = serachContidionToDateRangeParams(
    dateRange,
    dateUnit,
    isComparison,
    favorite.searchCondition
  );

  // 検索条件を適用する
  const searchConditions = {
    ...favorite.searchCondition,
    // 期間を再算出して上書きする
    ...dateRangeParams,
  };
  yield put(
    UnitDataListReportsSettingActionCreators.selectUnitDataListReportsSearchConditionAction(
      searchConditions
    )
  );
  // 表示中の種別を適用する
  yield put(
    UnitDataListReportsSettingActionCreators.selectUnitDataListReportsCurrentShuAction(
      favorite.currentShu
    )
  );
  // 台別データ一覧テーブルを取得
  if (favorite.dataDai?.setting !== undefined) {
    // お気に入りに登録されたハイライトを適用する
    // お気に入りにハイライト情報があるかの判定にはkiListのLengthで判定することができる
    // https://www.notion.so/e5aceac456664a8e89127712fbb29acd?pvs=4
    if (favorite.dataDai.highlightAverage.kiList.length > 0) {
      yield put(
        DataDaiActionCreators.fetchDataDaiHighlightAverageRequestAction(
          favorite.dataDai.setting,
          favorite.dataDai.highlightAverage.kiList,
          favorite.dataDai.highlightAverage.kiName
        )
      );
    }

    yield put(
      DataDaiActionCreators.fetchDataDaiAction({
        ...favorite.dataDai.setting,
        // 期間を再算出して上書きする
        ...dateRangeParams,
      })
    );

    // テーブル列の並び順を変更
    if (favorite.dataDai.columnsOrder) {
      yield put(
        DataDaiActionCreators.selectDataDaiColumnsOrderAction(
          favorite.dataDai.columnsOrder
        )
      );
    } else {
      // 並び順がない場合はリセットする
      yield put(DataDaiActionCreators.resetDataDaiColumnsOrderAction());
    }
  }

  // テーブルフィルター項目を取得する
  // 項目一覧を反映する
  yield put(
    UnitDataListReportsSettingActionCreators.applyUnitDataListReportsTableFilterAction(
      favorite.tableFilterItems ?? []
    )
  );
}

/**
 * 検索ボタン押下時に行う処理
 */
function* searchActionSaga(action: SearchUnitDataListReportsAction) {
  // 現在の検索条件項目を取得
  const settingsOptions: SettingsOptionsDai['searchCondition'] = yield select(
    settingsOptionsDaiSearchConditionSelector
  );
  // 現在選択中の種別
  const currentShu: ShuOption | undefined = yield select(
    unitDataListReportsSelectedCurrentShuSelector
  );

  // すべての種別・種別グループ一覧
  const allShuOption: ShuOption[] = [
    //MEMO: 種別全体は現状不要のためコメントアウト
    // {
    //   code: '',
    //   name: '種別全体',
    //   group: '',
    //   type: 'none',
    // },
    ...getShuOption(settingsOptions),
  ];

  // currentShuが選択可能な種別一覧に含まれていない場合先頭を選択
  const checkedCurrentShu = checkCurrentShu(allShuOption, currentShu);

  // currentShuがselectedShu（選択可能な種別一覧）にない場合適切な種別を選択する
  if (
    currentShu !== undefined &&
    currentShu.type !== 'none' &&
    checkedCurrentShu.code !== currentShu.code
  ) {
    // currentShu（選択中の種別）を更新する
    yield put(
      UnitDataListReportsSettingActionCreators.selectUnitDataListReportsCurrentShuAction(
        checkedCurrentShu
      )
    );
  }

  // 店舗選択スライダーで選択中の店舗を反映
  yield put(
    UnitDataListReportsSettingActionCreators.selectUnitDataListReportsCurrentHallsAction(
      //検索条件で選択された全店を対象とする
      action.payload.params.halls ?? []
    )
  );

  const dateRange = action.payload.dateRange;
  const dateUnit = action.payload.dateUnit;
  const isComparison = action.payload.isComparison;

  // dateRange・dateUnit（選択中の日付単位・期間）を更新する
  yield put(
    UnitDataListReportsSettingActionCreators.selectUnitDataListReportsDateRangeParamsAction(
      dateRange,
      dateUnit,
      isComparison
    )
  );

  // 検索条件の期間部分を再計算
  const dateRangeParams = serachContidionToDateRangeParams(
    dateRange,
    dateUnit,
    isComparison,
    action.payload.params
  );

  // 検索条件の加工
  const modifyParams = {
    ...action.payload.params,
    // 期間を再算出して上書きする
    ...dateRangeParams,
  };

  // searchCondition(選択中の検索条件）を更新する
  yield put(
    UnitDataListReportsSettingActionCreators.selectUnitDataListReportsSearchConditionAction(
      modifyParams
    )
  );

  //fetchDataDaiActionのparamsの型にないものを除去
  const { selectDateUnit, ...newModifyParams } = modifyParams;

  //ハイライトはリセット
  yield put(DataDaiActionCreators.resetDataDaiHighlightAverageAction());

  // データ取得
  yield put(
    DataDaiActionCreators.fetchDataDaiAction({
      ...newModifyParams,
      ...selectShu2SearchCondition(checkedCurrentShu),
      halls: modifyParams.halls,
    })
  );
}

/**
 * お気に入りに保存するデータの生成
 */
function* setPageSetting() {
  // 現在の台別データ一覧の設定すべて
  const pageSetting: UnitDataListReportsSettingState = yield select(
    unitDataListReportsSettingSelector
  );
  // テーブルの検索条件
  const dataDaiParams: DataDai['setting'] = yield select(dataDaiParamsSelector);

  // テーブル列の並び順
  const dataDaiColumnsOrder: string[] | undefined = yield select(
    dataDaiColumnsOrderSelector
  );
  // 機種名フィルタ
  const modelNameFilter: string = yield select(
    unitDataListReportsModelNameFilterSelector
  );

  // サブメニュー内数値フィルタ
  const subMenuNumberFilter: {
    [field: string]: {
      minimumNumber: number | undefined;
      maximumNumber: number | undefined;
    };
  } = yield select(unitDataListReportsSubMenuNumberFilterSelector);

  // テーブルフィルターで選択中の項目
  const tableFilterSelectedItems: string[] | undefined = yield select(
    unitDataListReportsSelectedTableFilterSelector
  );

  // ハイライト情報取得
  const {
    kiList,
    kiName,
  }: {
    kiList: string[];
    kiName: string;
  } = yield select(dataDaiHighlightAverageSelector);

  // こんなケースはないが念の為
  if (pageSetting.currentShu === undefined) return;

  const result: FavoriteItem['pageSetting'] = {
    unitDataListReports: {
      searchCondition: pageSetting.searchCondition,
      currentShu: pageSetting.currentShu,
      selectedDateRangeParams: pageSetting.selectedDateRangeParams,
      dataDai: {
        setting: dataDaiParams,
        columnsOrder: dataDaiColumnsOrder ?? [],
        highlightAverage: {
          kiList,
          kiName,
        },
      },
      modelNameFilter,
      subMenuNumberFilter,
      tableFilterItems: tableFilterSelectedItems,
    },
  };

  return result;
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(action: SaveUnitDataListReportsFavoriteAction) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    unitDataListReportsSelectedFavoriteSelector
  );
  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem = yield select(
    unitDataListReportsSelectedFavoriteDataSelector
  );
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined) return;

  let favorite: FavoriteItem = {};
  if (action.payload.mode === 'memo') {
    // メモのみ更新モードでの変更後のお気に入りデータ
    favorite = {
      ...selectedFavorite,
      memo: action.payload.memo,
    };
  } else {
    // 変更後のお気に入りデータ
    favorite = {
      name: action.payload.name,
      isShared: action.payload.isShared,
      pageSetting,
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    };
  }

  // お気に入りを上書き
  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
      selectedFavoriteId,
      favorite,
      { pageName: '台別データ一覧' }
    )
  );
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(action: SaveAsUnitDataListReportsFavoriteAction) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // 変更後のお気に入りデータ
  const favorite: FavoriteItem = {
    name: action.payload.name,
    isShared: action.payload.isShared,
    pageName: '台別データ一覧',
    pageSetting,
    memo: action.payload.memo,
    privatelySharedUsers: action.payload.sharedUser,
  };

  // お気に入りを新規保存
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([favorite], {})
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createShortenedUrlSaga(
  action: CreateUnitDataListReportsShortenedUrlAction
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

/**
 * 検索ボタン押下時、新しい検索条件でデータを取得する
 */
function* handleSearchSaga() {
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.SEARCH_UNIT_DATA_LIST_REPORTS,
    searchActionSaga
  );
}

/**
 * リセットボタン押下時、デフォルトの検索条件または選択中のお気に入りでデータを取得する
 */
function* resetSearchActionSaga(action: SearchResetUnitDataListReportsAction) {
  //ハイライトは一旦リセット
  yield put(DataDaiActionCreators.resetDataDaiHighlightAverageAction());

  // 現在選択中のお気に入りを取得する
  const favoriteId: number | undefined = yield select(
    unitDataListReportsSelectedFavoriteSelector
  );

  // お気に入りが選択されている場合はお気に入り適用
  if (favoriteId !== undefined) {
    yield put(
      UnitDataListReportsSettingActionCreators.changeUnitDataListReportsFavoriteAction(
        favoriteId
      )
    );
    return;
  }

  // デフォルトが選択されている場合は検索条件を初期化
  yield put(
    UnitDataListReportsSettingActionCreators.resetUnitDataListReportsSearchConditionAction()
  );
  // 非表示項目をリセットする
  yield put(
    UnitDataListReportsSettingActionCreators.resetUnitDataListReportsTableFilterAction()
  );

  // 検索条件に店舗を指定する（初期化された検索条件に店舗情報は無く、未指定の場合、全店舗のデータがレスポンスされる）
  if (action.payload.halls !== undefined) {
    yield put(
      UnitDataListReportsSettingActionCreators.selectUnitDataListReportsCurrentHallsAction(
        action.payload.halls
      )
    );
  }

  // 選択中の期間をリセットする
  yield put(
    UnitDataListReportsSettingActionCreators.resetUnitDataListReportsDateRangeParamsAction()
  );

  // 現在の検索条件を取得
  const searchCondition: UnitDataListFormConditions = yield select(
    unitDataListReportsSearchConditionSelector
  );
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    unitDataListReportsSelectedCurrentShuSelector
  );

  // セルの並び順をリセットしてデフォルト順にする
  yield put(DataDaiActionCreators.resetDataDaiColumnsOrderAction());

  // 機種名フィルタをクリア
  yield put(selectUnitDataListReportsModelNameFilterAction(''));

  // サブメニュー内数値フィルタをクリア
  yield put(resetUnitDataListReportsSubMenuNumberFilterAction());

  //fetchDataDaiActionのparamsの型にないものを除去
  const { selectDateUnit, ...newModifyParams } = searchCondition;

  // データ取得
  yield put(
    DataDaiActionCreators.fetchDataDaiAction({
      ...newModifyParams,
      ...selectShu2SearchCondition(currentShu),
    })
  );
}

/**
 * リセットボタン押下時台別データ一覧の全てのデータを破棄する
 */
function* handleResetSaga() {
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.SEARCH_RESET_UNIT_DATA_LIST_REPORTS,
    resetSearchActionSaga
  );
}

/**
 * 変更後の種別でデータを取得する
 */
function* changeCurrentShuSaga(
  action: ChangeUnitDataListReportsCurrentShuAction
) {
  // 種別が選択されていない場合は処理を中断
  if (action.payload.shu === undefined) return;

  // 現在の検索条件を取得
  const searchCondition: UnitDataListFormConditions = yield select(
    unitDataListReportsSearchConditionSelector
  );

  //ハイライトはリセット
  yield put(DataDaiActionCreators.resetDataDaiHighlightAverageAction());

  //fetchDataDaiActionのparamsの型にないものを除去
  const { selectDateUnit, ...newModifyParams } = searchCondition;

  // 変更後の種別でデータを取得する
  yield put(
    DataDaiActionCreators.fetchDataDaiAction({
      ...newModifyParams,
      ...selectShu2SearchCondition(action.payload.shu),
    })
  );
}

/**
 * 選択中の種別変更時、データを取得する
 */
function* handleChangeCurrentShuSaga() {
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_SHU,
    changeCurrentShuSaga
  );
}

/**
 * 変更後の店舗でデータを取得する
 */
function* changeCurrentHallsSaga(
  action: ChangeUnitDataListReportsCurrentHallsAction
) {
  // 店舗が選択されていない場合は処理を中断
  if (action.payload.halls === undefined) return;

  // 現在の検索条件を取得
  const searchCondition: UnitDataListFormConditions = yield select(
    unitDataListReportsSearchConditionSelector
  );

  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    unitDataListReportsSelectedCurrentShuSelector
  );

  //ハイライトはリセット
  yield put(DataDaiActionCreators.resetDataDaiHighlightAverageAction());

  //fetchDataDaiActionのparamsの型にないものを除去
  const { selectDateUnit, ...newModifyParams } = searchCondition;

  // 変更後の店舗でデータを取得する
  yield put(
    DataDaiActionCreators.fetchDataDaiAction({
      ...newModifyParams,
      ...selectShu2SearchCondition(currentShu),
      halls: action.payload.halls,
    })
  );
}

/**
 * 選択中の店舗変更時、データを取得する
 */
function* handleChangeCurrentHallsSaga() {
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.CHANGE_UNIT_DATA_LIST_REPORTS_CURRENT_HALLS,
    changeCurrentHallsSaga
  );
}

/**
 * テーブル固有の検索条件を変更してデータを取得する
 * @param params 変更後の検索条件
 */
function* searchSearchConditonSaga(params: UnitDataListFormConditions) {
  // 現在の検索条件を取得
  const searchCondition: UnitDataListFormConditions = yield select(
    unitDataListReportsSearchConditionSelector
  );

  // 新しい検索条件
  const newSearchCondition = {
    ...searchCondition,
    ...params,
  };
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    unitDataListReportsSelectedCurrentShuSelector
  );

  // 検索条件を更新
  yield put(
    UnitDataListReportsSettingActionCreators.selectUnitDataListReportsSearchConditionAction(
      newSearchCondition
    )
  );

  //fetchDataDaiActionのparamsの型にないものを除去
  const { selectDateUnit, ...newModifyParams } = newSearchCondition;

  //ハイライトはリセットする
  yield put(DataDaiActionCreators.resetDataDaiHighlightAverageAction());

  // データ取得
  yield put(
    DataDaiActionCreators.fetchDataDaiAction({
      ...newModifyParams,
      ...selectShu2SearchCondition(currentShu),
    })
  );
}

/**
 * ソート実行時データを取得する
 */
function* searchSortSaga(action: SearchUnitDataListReportsSortAction) {
  yield fork(searchSearchConditonSaga, action.payload);
}

/**
 * 表示項目変更時データを取得する
 */
function* searchFieldsSaga(action: SearchUnitDataListReportsFieldAction) {
  const fields = action.payload.fields.map((item) => item.code);

  yield fork(searchSearchConditonSaga, { fields });
}

// 期間スライドコンポーネントがクリックされた時に検索期間を設定して再検索を実行する
function* searchDateRangeSlideSaga(
  action: SearchUnitDataListReportsDateRangeSlideAction
) {
  const { dateRange, ymdList, ymdComparisonList } = action.payload;
  // 検索条件の項目を取得
  const searchCondition: UnitDataListFormConditions = yield select(
    unitDataListReportsSearchConditionSelector
  );

  const { isComparison, dateUnit } = yield select(
    unitDataListReportsSelectedDateRangeParamsSelector
  );

  //ハイライトはリセットする
  yield put(DataDaiActionCreators.resetDataDaiHighlightAverageAction());

  yield put(
    searchUnitDataListReportsAction(
      {
        ...searchCondition,
        ymdList,
        ymdComparisonList,
      },
      dateRange,
      dateUnit,
      isComparison
    )
  );
}

/**
 * テーブル固有の検索条件変更検知時の処理
 */
function* handleTableSearchSaga() {
  // ソート実行時
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.SEARCH_UNIT_DATA_LIST_REPORTS_SORT,
    searchSortSaga
  );

  // 表示項目変更時
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.SEARCH_UNIT_DATA_LIST_REPORTS_FIELDS,
    searchFieldsSaga
  );
}

/**
 * お気に入り関連の処理
 */
function* handleFavoriteSaga() {
  // お気に入り適用
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.CHANGE_UNIT_DATA_LIST_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // お気に入り上書き保存
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.SAVE_UNIT_DATA_LIST_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.SAVE_AS_UNIT_DATA_LIST_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
}

// 期間スライドコンポーネントがクリックされた時に実行する処理
function* handleDateRangeSlideSaga() {
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.SEARCH_UNIT_DATA_LIST_REPORTS_DATE_RANGE_SLIDE,
    searchDateRangeSlideSaga
  );
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    UnitDataListReportsSettingActionTypes.CREATE_UNIT_DATA_LIST_REPORTS_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

/**
 * 台別データ一覧の検索フォームに関するタスクを実行する
 */
export function* unitDataListReportsSettingSaga() {
  yield fork(handleSearchSaga);
  yield fork(handleResetSaga);
  yield fork(handleTableSearchSaga);
  yield fork(handleChangeCurrentShuSaga);
  yield fork(handleChangeCurrentHallsSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleDateRangeSlideSaga);
  yield fork(handleShortenedUrlSaga);
}
