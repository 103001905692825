import { AxiosResponse, isAxiosError } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { daiCostsErrorMessages } from '../domain/daiCosts/errors';
import {
  DaiCostsSearchParams,
  PostDaiCostsErrorResponse,
  PostDaiCostsResponse,
  SettingsDaiCostsResponse,
} from '../domain/daiCosts/types';

import {
  DataSettingsDaiCostsActionCreators,
  DataSettingsDaiCostsActionTypes,
  FetchDataSettingsDaiCostsAction,
  PostDataSettingsDaiCostsAction,
  dataSettingsDaiCostsDataSelector,
} from '../redux/server/dataSettingsDaiCosts';
import { daiCostsSettingSearchParamsSelector } from '../redux/ui/daiCostsSetting';
import { ErrorActionCreators } from '../redux/ui/error';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* fetchDataSettingsDaiCostsSaga(
  api: Api,
  action: FetchDataSettingsDaiCostsAction
) {
  try {
    yield put(
      DataSettingsDaiCostsActionCreators.fetchDataSettingsDaiCostsRequestAction()
    );

    const response: AxiosResponse<SettingsDaiCostsResponse> = yield call(
      api.get,
      '/settings/daiCosts',
      buildConfig(action.payload.params)
    );

    yield put(
      DataSettingsDaiCostsActionCreators.fetchDataSettingsDaiCostsSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataSettingsDaiCostsActionCreators.renewDataSettingsDaiCostsAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataSettingsDaiCostsSaga(api: Api) {
  yield takeEvery(
    DataSettingsDaiCostsActionTypes.FETCH_DATA_SETTINGS_DAI_COSTS,
    fetchDataSettingsDaiCostsSaga,
    api
  );
}

function* postDataSettingsDaiCostsSaga(
  api: Api,
  action: PostDataSettingsDaiCostsAction
) {
  try {
    yield put(
      DataSettingsDaiCostsActionCreators.postDataSettingsDaiCostsRequestAction()
    );

    const response: AxiosResponse<PostDaiCostsResponse> = yield call(
      api.post,
      '/settings/daiCosts',
      action.payload.body,
      {
        headers: { 'Content-Type': 'text/csv' },
      }
    );

    yield put(
      DataSettingsDaiCostsActionCreators.postDataSettingsDaiCostsSuccessAction(
        response.data
      )
    );

    // 前回のエラーがあるかもしれないので、クリア
    yield put(
      DataSettingsDaiCostsActionCreators.clearPostDaiCostsErrorDetailsAction()
    );

    // テーブルデータの再取得 (更新がない場合は、再取得しない)
    if (response.data.data.length > 0) {
      const daiCostsSettingSearchParams: DaiCostsSearchParams | undefined =
        yield select(daiCostsSettingSearchParamsSelector);
      yield put(
        DataSettingsDaiCostsActionCreators.fetchDataSettingsDaiCostsAction(
          daiCostsSettingSearchParams
        )
      );
    }
  } catch (error: unknown) {
    // 422エラーの場合、エラー詳細をstoreに保存
    if (
      isAxiosError<PostDaiCostsErrorResponse>(error) &&
      error.response?.status === 422
    ) {
      yield put(
        DataSettingsDaiCostsActionCreators.selectPostDaiCostsErrorDetailsAction(
          error.response.data
        )
      );
      return;
    }

    yield fork(handleErrorSaga, error);
  }
}

function* handlePostDataSettingsDaiCostsSage(api: Api) {
  yield takeEvery(
    DataSettingsDaiCostsActionTypes.POST_DATA_SETTINGS_DAI_COSTS,
    postDataSettingsDaiCostsSaga,
    api
  );
}

function* validateFetchDaiCostsSaga() {
  const data: SettingsDaiCostsResponse | undefined = yield select(
    dataSettingsDaiCostsDataSelector
  );
  if (data?.data.length === 0) {
    yield put(ErrorActionCreators.setError(daiCostsErrorMessages.noData));
  }
}

function* handleFetchSuccessSaga() {
  yield takeEvery(
    DataSettingsDaiCostsActionTypes.FETCH_DATA_SETTINGS_DAI_COSTS_SUCCESS,
    validateFetchDaiCostsSaga
  );
}

export function* settingsDaiCostsSagas(context: { api: Api }) {
  yield fork(handleFetchDataSettingsDaiCostsSaga, context.api);
  yield fork(handlePostDataSettingsDaiCostsSage, context.api);
  yield fork(handleFetchSuccessSaga);
}
