import { createSelector } from 'reselect';

import {
  DataModelTransition,
  DataModelTransitionParams,
} from '../../domain/dataModelTransition';
import { Column, Item, LoadingState } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { filterModelName } from '../../utils/filterModelName';
import { filterTransitionDataByField } from '../../utils/filterTransitionDataByField';
import {
  modelTransitionCurrentFieldSelector,
  modelTransitionReportsModelNameFilterSelector,
  modelTransitionReportsSelectedKiSelector,
  modelTransitionReportsSettingIsHeatmapEnabledSelector,
} from '../ui/modelTransitionReportsSetting';

// Action Types

const FETCH_DATA_MODEL_TRANSITION = 'FETCH_DATA_MODEL_TRANSITION' as const;
const FETCH_DATA_MODEL_TRANSITION_REQUEST =
  'FETCH_DATA_MODEL_TRANSITION_REQUEST' as const;
const FETCH_DATA_MODEL_TRANSITION_SUCCESS =
  'FETCH_DATA_MODEL_TRANSITION_SUCCESS' as const;
const RENEW_DATA_MODEL_TRANSITION = 'RENEW_DATA_MODEL_TRANSITION' as const;

export const DataModelTransitionActionTypes = {
  FETCH_DATA_MODEL_TRANSITION,
  FETCH_DATA_MODEL_TRANSITION_REQUEST,
  FETCH_DATA_MODEL_TRANSITION_SUCCESS,
  RENEW_DATA_MODEL_TRANSITION,
};

// Action Creators

function fetchDataModelTransitionAction(
  shuOption: ShuOption,
  params: DataModelTransitionParams
) {
  return {
    type: FETCH_DATA_MODEL_TRANSITION,
    payload: { shuOption, params },
  };
}

function fetchDataModelTransitionRequestAction() {
  return {
    type: FETCH_DATA_MODEL_TRANSITION_REQUEST,
  };
}

function fetchDataModelTransitionSuccessAction(
  dataModelTransition: DataModelTransition
) {
  return {
    type: FETCH_DATA_MODEL_TRANSITION_SUCCESS,
    payload: { dataModelTransition },
  };
}

function renewDataModelTransitionAction() {
  return {
    type: RENEW_DATA_MODEL_TRANSITION,
  };
}

export const DataModelTransitionActionCreators = {
  fetchDataModelTransitionAction,
  fetchDataModelTransitionRequestAction,
  fetchDataModelTransitionSuccessAction,
  renewDataModelTransitionAction,
};

// Actions

export type FetchDataModelTransitionAction = ReturnType<
  typeof fetchDataModelTransitionAction
>;

type DataModelTransitionAction =
  | FetchDataModelTransitionAction
  | ReturnType<typeof fetchDataModelTransitionRequestAction>
  | ReturnType<typeof fetchDataModelTransitionSuccessAction>
  | ReturnType<typeof renewDataModelTransitionAction>;

// State

type DataModelTransitionState = {
  loadingState: LoadingState;
  dataModelTransition?: DataModelTransition;
};

const initialState: DataModelTransitionState = {
  loadingState: 'prepare',
  dataModelTransition: undefined,
};

// Selector

export function dataModelTransitionAllSelector(rootState: {
  dataModelTransition: DataModelTransitionState;
}) {
  return rootState.dataModelTransition;
}

export const dataModelTransitionSelector = createSelector(
  [
    dataModelTransitionAllSelector,
    modelTransitionReportsModelNameFilterSelector,
    modelTransitionCurrentFieldSelector,
  ],
  (dataModelTransition, modelNameFilter, currentFields) => {
    if (!dataModelTransition.dataModelTransition) {
      return;
    }

    const data = dataModelTransition.dataModelTransition?.data;

    const predicate = filterModelName(modelNameFilter);
    const filteredRowsByModelName = modelNameFilter
      ? data?.rows.filter((row) =>
          predicate(row.dataForNonTransition.at(0)?.value ?? '')
        )
      : data?.rows;

    const result: DataModelTransition = {
      ...dataModelTransition.dataModelTransition,
      data: {
        ...data,
        rows: filteredRowsByModelName,
      },
    };

    // 項目絞込の処理
    if (currentFields === 'all') {
      return result;
    }
    const filteredData = filterTransitionDataByField(result, currentFields);
    if (filteredData == null) {
      return dataModelTransition.dataModelTransition;
    }

    return filteredData;
  }
);

export function dataModelTransitionColumnsForTransitionSelector(rootState: {
  dataModelTransition: DataModelTransitionState;
}) {
  return (
    rootState.dataModelTransition.dataModelTransition?.data
      .columnsForTransition ?? []
  );
}

export function dataModelTransitionColumnsForNonTransitionSelector(rootState: {
  dataModelTransition: DataModelTransitionState;
}) {
  return (
    rootState.dataModelTransition.dataModelTransition?.data
      .columnsForNonTransition ?? []
  );
}

/**
 * テーブルの行データを取得する
 * @returns テーブルの行データ
 */
const dataModelTransitionRowsSelector = createSelector(
  dataModelTransitionSelector,
  (data) => data?.data.rows
);

/**
 * 機種コードと機種名を機種コードをkeyとしたで取得する
 * @returns KiOptions
 */
export const dataModelTransitionKiOptionsSelector = createSelector(
  dataModelTransitionRowsSelector,
  (rows) => {
    const kiOptions =
      rows?.map((row) => {
        return [
          row.queryParameter.kiList && row.queryParameter.kiList[0],
          row.dataForNonTransition.at(0)?.value,
        ];
      }) ?? [];

    return Object.fromEntries(kiOptions) as { [key: string]: string };
  }
);

export const dataModelTransitionDataSearchConditionSelector = createSelector(
  dataModelTransitionAllSelector,
  (dataModelTransition) => dataModelTransition.dataModelTransition?.setting
);

export const dataModelTransitionLoadingStateSelector = createSelector(
  dataModelTransitionAllSelector,
  (dataModelTransition) => dataModelTransition.loadingState
);

export const addHeatmapBackgroundColorToOutField = (
  columnsForTransition: Column[],
  rows: DataModelTransition['data']['rows']
) => {
  if (rows.length < 40) {
    return rows;
  }
  const outFieldIndex = columnsForTransition.findIndex((x) => x.code === 'out');
  if (outFieldIndex === -1) {
    return rows;
  }

  const rowsWithOnlyOutValue = rows.map((x) =>
    (x.dataForTransition.at(outFieldIndex) ?? []).map((x) => x.value)
  );
  if (rowsWithOnlyOutValue.length === 0) {
    return rows;
  }

  const borderNumbersPerDate = rowsWithOnlyOutValue
    // transpose
    .reduce(
      (accum: string[][], curr: string[]) =>
        accum.map((xs, i) => [...xs, curr[i]]),
      [...Array(rowsWithOnlyOutValue.at(0)?.length ?? 0)].map((_) => [])
    )
    // filter hypen out and convert string to number
    .map((xs: string[]) =>
      xs
        .filter((x: string) => x !== '-')
        .map((x: string) => Number(x.replace(',', '')))
    )
    // top10, top20, bottom20, bottom10
    .map((xs: number[]) => {
      xs.sort((a, b) => a - b);
      const top10 = xs[xs.length - 10];
      const top20 = xs[xs.length - 20];
      const bottom10 = xs[9];
      const bottom20 = xs[19];

      return [top10, top20, bottom20, bottom10];
    });

  const addBackgroundColor =
    (borderNumbersPerDate: number[][]) =>
    (dataForTransition: Item[], dataTransitionFieldIndex: number) => {
      if (dataTransitionFieldIndex !== outFieldIndex) {
        return dataForTransition;
      }
      return dataForTransition.map((x: Item, i: number) => {
        const [top10, top20, bottom20, bottom10] = borderNumbersPerDate[i];
        const val = Number(x.value.replace(',', ''));
        if (Number.isNaN(val)) {
          return x;
        }

        const backgroundColor =
          val >= top10
            ? '#a8d3ff'
            : val >= top20
            ? '#d7eefd'
            : val <= bottom10
            ? '#ffb3b3'
            : val <= bottom20
            ? '#fddddb'
            : 'inherit';

        return {
          ...x,
          backgroundColor,
        };
      });
    };

  return rows.map((x) => ({
    ...x,
    dataForTransition: x.dataForTransition.map(
      addBackgroundColor(borderNumbersPerDate)
    ),
  }));
};

const dataModelTransitionHeatmappedSelector = createSelector(
  [
    dataModelTransitionSelector,
    modelTransitionReportsSettingIsHeatmapEnabledSelector,
  ],
  (dataModelTransition, isHeatmapEnabled) => {
    const tableData = dataModelTransition?.data;

    if (tableData === undefined) {
      return {
        columnsForNonTransition: [],
        columnsForTransition: [],
        dates: [],
        rows: [],
      };
    }

    if (tableData.rows?.length === 0) {
      return tableData;
    }

    const heatmappedRows = isHeatmapEnabled
      ? addHeatmapBackgroundColorToOutField(
          tableData?.columnsForTransition,
          tableData?.rows
        )
      : tableData.rows;

    return {
      ...tableData,
      rows: heatmappedRows,
    };
  }
);

export const dataModelTransitionFilteredDataSelector = createSelector(
  [
    dataModelTransitionHeatmappedSelector,
    modelTransitionReportsSelectedKiSelector,
  ],
  (tableData, kiList) => {
    // 全件指定の場合はそのまま戻す
    if (!kiList || kiList.length === 0) {
      return tableData;
    }

    // フィルターされている場合
    const filteredRows =
      kiList.length > 0
        ? tableData.rows?.filter(
            (row) =>
              row.queryParameter.kiList &&
              kiList.includes(row.queryParameter.kiList[0])
          )
        : tableData.rows;

    return {
      ...tableData,
      rows: filteredRows,
    };
  }
);

/**
 * 機種別推移の現在表示中のデータの検索条件を取得する
 * @returns 現在の検索条件
 */
export const dataModelTransitionSettingsSelector = createSelector(
  dataModelTransitionSelector,
  (data) => {
    return data?.setting;
  }
);

/**
 * レスポンスの検索条件からエリア一覧を取得する
 */
export const dataModelTransitionSettingAreasSelector = createSelector(
  dataModelTransitionSettingsSelector,
  (setting) => setting?.areas
);

// Reducer

export function dataModelTransitionReducer(
  state = initialState,
  action: DataModelTransitionAction
): DataModelTransitionState {
  switch (action.type) {
    case FETCH_DATA_MODEL_TRANSITION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_MODEL_TRANSITION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataModelTransition: action.payload.dataModelTransition,
      };
    case RENEW_DATA_MODEL_TRANSITION:
      return initialState;
    default:
      return state;
  }
}
