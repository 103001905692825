import { add } from 'date-fns';

// 導入後推移で使用する期間タイプ
export const transitionAfterIntroductionDateRange = [
  '3ヶ月',
  '6ヶ月',
  '1年',
  'カスタム',
] as const;
export type TransitionAfterIntroductionDateRange =
  (typeof transitionAfterIntroductionDateRange)[number];

/**
 * 導入後推移
 * 検索パラメータに渡す日付データを算出する
 * @param dateRange 期間指定
 * @param currentDate 基準日（未指定の場合は本日）
 */
export const makeDateFromDateRange = (
  dateRange: TransitionAfterIntroductionDateRange,
  currentDate: Date = new Date()
): { startDate: Date; endDate: Date } => {
  let startDate = currentDate;
  const endDate = currentDate;
  switch (dateRange) {
    case '3ヶ月': {
      startDate = add(currentDate, { months: -3 });
      break;
    }
    case '6ヶ月': {
      startDate = add(currentDate, { months: -6 });
      break;
    }
    case '1年': {
      startDate = add(currentDate, { years: -1 });
      break;
    }
  }
  return {
    startDate,
    endDate,
  };
};
