import { GraphData } from '../domain/schemas';

export type DataKiGraphParams = {
  field?: GraphFieldType;
  seriesType?: string;
  containsAverage?: boolean;
  dateType?: GraphDateType;
  makers?: string[];
  kiList?: string[];
  sisTypes?: string[];
  shuList?: string[];
  hallsForDonyuJokyo?: string[];
  isDonyuForDonyuJokyo?: boolean;
  halls?: string[];
  areas?: string[];
  startDate?: string | null;
  endDate?: string | null;
  excludeToday?: boolean;
  startComparisonDate?: string | null;
  endComparisonDate?: string | null;
  shuGroupIds?: string[];
  sisBreakEvenForPachinko?: string;
  sisBreakEvenForPachislo?: string;
  sisRank?: string;
  sisArea?: string;
  needsSis?: boolean;
  makersForSijiritu?: string[];
  kiListForSijiritu?: string[];
  sisTypesForSijiritu?: string[];
  tagIds?: string[];
};

export const GRAPH_FIELD_TYPE = {
  DAYS: 'days',
  SO_DAISU: 'soDaisu',
  SO_DAISU_COMPARISON: 'soDaisuComparison',
  HEIKIN_DAISU: 'heikinDaisu',
  GEN_SETSU: 'genSetsu',
  DONYU_HALL_SU: 'donyuHallSu',
  KEIKA_WEEK: 'keikaWeek',
  OUT: 'out',
  OUT_COMPARISON: 'outComparison',
  OUT_SIJI_RITU: 'outSijiRitu',
  OUT_SIJI_RITU_COMPARISON: 'outSijiRituComparison',
  KADO_JIKAN: 'kadoJikan',
  DAI_YUGI_JIKAN: 'daiYugiJikan',
  DAI_YUGI_JIKAN_COMPARISON: 'daiYugiJikanComparison',
  JIKAN_SIJI_RITU: 'jikanSijiRitu',
  JIKAN_OUT: 'jikanOut',
  SAFE: 'safe',
  DIFF: 'diff',
  DTMART: 'dtmart',
  TKSHO: 'tksho',
  KYAKUTAIRT: 'kyakutairt',
  BO_AVG: 'boAvg',
  MAX_MY: 'maxMy',
  DAI_URIAGE: 'daiUriage',
  DAI_URIAGE_SIJI_RITU: 'daiUriageSijiRitu',
  DAI_ARARI: 'daiArari',
  DAI_ARARI_COMPARISON: 'daiArariComparison',
  DAI_ARARI_SIJI_RITU: 'daiArariSijiRitu',
  DAI_ARARI_SIJI_RITU_COMPARISON: 'daiArariSijiRituComparison',
  TMA_TANKA: 'tmaTanka',
  TMA_ARARI: 'tmaArari',
  TMA_ARARI_COMPARISON: 'tmaArariComparison',
  JIKAN_URIAGE: 'jikanUriage',
  JIKAN_ARARI: 'jikanArari',
  YUGI_JIKAN_URIAGE: 'yugiJikanUriage',
  YUGI_JIKAN_ARARI: 'yugiJikanArari',
  RIEKI_RITU: 'riekiRitu',
  RIEKI_RITU_COMPARISON: 'riekiRituComparison',
  KIKAI_WARISU: 'kikaiWarisu',
  GENKIN_URIAGE_GK: 'genkinUriageGk',
  URIAGE_GK: 'uriageGk',
  ARARI_GK: 'arariGk',
  REPLAY_GAK: 'replayGak',
  REPLAY_RITU: 'replayRitu',
  BASE_CNT: 'baseCnt',
  YUKO_S: 'yukoS',
  YUKO_S_COMPARISON: 'yukoSComparison',
  NYUSHO_S: 'nyushoS',
  NYUSHO_S_COMPARISON: 'nyushoSComparison',
  S1: 's1',
  S2: 's2',
  X1000_YEN_START: 'x1000YenStart',
  X1000_YEN_START_COMPARISON: 'x1000YenStartComparison',
  S_YUKO_RITU: 'sYukoRitu',
  BY_CNT: 'byCnt',
  BYMIN: 'bymin',
  BYMIN_COMPARISON: 'byminComparison',
  BA: 'ba',
  SA: 'sa',
  T1_O: 't1O',
  T1_Y: 't1Y',
  HSI_T1_Y: 'hsiT1Y',
  TO_CNT: 'toCnt',
  TY: 'ty',
  TS: 'ts',
  BB_SU: 'bbSu',
  RB_SU: 'rbSu',
  BB_KAKURT: 'bbKakurt',
  RB_KAKURT: 'rbKakurt',
  GSE_KAKURT: 'gseKakurt',
} as const;
export type GraphFieldType =
  (typeof GRAPH_FIELD_TYPE)[keyof typeof GRAPH_FIELD_TYPE];

export const GRAPH_DATE_TYPE = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
} as const;
export type GraphDateType =
  (typeof GRAPH_DATE_TYPE)[keyof typeof GRAPH_DATE_TYPE];

/* eslint-disable no-unused-vars */
export type DataKiGraph = {
  setting: DataKiGraphParams;
  data: GraphData;
};
/* eslint-enable no-unused-vars */
