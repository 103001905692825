import { createSelector } from 'reselect';

import { SharedUser } from '../../domain/favorites';
import { MarkingOption } from '../../domain/marking';
import { OrderType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';
import {
  DataTransitionAfterIntroductionParams,
  TransitionAfterIntroductionReportsSettingDateRangeParams,
} from '../../domain/transitionAfterIntroduction/types';

import { RootState } from '../../store';
import { dataTransitionAfterIntroductionDefaultValue } from '../../utils/dataTransitionAfterIntroductionDefaultValue';
import { TransitionAfterIntroductionDateRange } from '../../utils/transitionAfterIntroductionDateRange';
import { favoritesSelector } from './settingsFavorites';

/**
 * Action Types
 *
 * プレフィックスの意味
 * SEARCH: ReducerでStateの変更はしないがデータを取得する（検索ボタン等Sagaを発火させたい）
 * CHANGE: Stateを変更してデータも取得する
 * SELECT: Stateを変更する（データ取得は行わない）
 * RESET: Stateを初期状態に戻す（データ取得は行わない）
 * SAVE: 上書き保存する（お気に入り用）
 * SAVE_AS: 新規保存する（お気に入り用）
 */

const SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS =
  'SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS' as const;
const SEARCH_RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS =
  'SEARCH_RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS' as const;
const SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_HEADER =
  'SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_HEADER' as const;
const SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_TRANSITIVE_FIELD =
  'SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_TRANSITIVE_FIELD' as const;
const SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_MARKING =
  'SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_MARKING' as const;
const SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_FIELDS =
  'SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_FIELDS' as const;
const SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_ON =
  'SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_ON' as const;
const SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_OFF =
  'SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_OFF' as const;

const CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE =
  'CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE' as const;
const CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU =
  'CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU' as const;

const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_KI_LIST =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_KI_LIST' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER' as const;

const RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION =
  'RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION' as const;
const RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU =
  'RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU' as const;
const RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS =
  'RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS' as const;

const SAVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE =
  'SAVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE' as const;
const SAVE_AS_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE =
  'SAVE_AS_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE' as const;

const SELECT_TRANSITION_AFTER_INTRODUCTION_MODEL_NAME_FILTER =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_MODEL_NAME_FILTER' as const;

const ADD_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER =
  'ADD_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER' as const;
const REMOVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER =
  'REMOVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER' as const;
const APPLY_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER =
  'APPLY_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER' as const;
const RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER =
  'RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI_TABLE =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI_TABLE' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_SHOW_GRAPH_NUMBER_LABEL =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;
const RESET_AFTERINTRODUCTION_FAVORITE_TO_DEFAULT =
  'RESET_AFTERINTRODUCTION_FAVORITE_TO_DEFAULT' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURERNT_HALLS' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SELECTED_HALLS =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SELECTED_HALLS' as const;
const CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS =
  'CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_IS_HEATMAP_ENABLED =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_IS_HEATMAP_ENABLED' as const;
const CREATE_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHORTENED_URL =
  'CREATE_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHORTENED_URL' as const;
const SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_FIELD =
  'SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_FIELD' as const;

export const TransitionAfterIntroductionReportsSettingActionTypes = {
  SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS,
  SEARCH_RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS,
  SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_HEADER,
  SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_TRANSITIVE_FIELD,
  SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_MARKING,
  SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_FIELDS,
  SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_ON,
  SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_OFF,
  CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU,
  CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_KI_LIST,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER,
  RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION,
  RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU,
  RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS,
  SAVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE,
  SAVE_AS_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE,
  SELECT_TRANSITION_AFTER_INTRODUCTION_MODEL_NAME_FILTER,
  ADD_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER,
  REMOVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER,
  APPLY_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER,
  RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI_TABLE,
  SELECT_TRANSITION_AFTER_INTRODUCTION_SHOW_GRAPH_NUMBER_LABEL,
  RESET_AFTERINTRODUCTION_FAVORITE_TO_DEFAULT,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SELECTED_HALLS,
  CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_IS_HEATMAP_ENABLED,
  CREATE_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHORTENED_URL,
  SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_FIELD,
};

/**
 * Action Creators
 */

/**
 *
 */
function resetAfterIntroductionTransitionFavoriteToDefaultAction() {
  return {
    type: RESET_AFTERINTRODUCTION_FAVORITE_TO_DEFAULT,
    payload: {},
  };
}

/**
 * 指定した検索条件でデータを取得する（検索ボタン押下時）
 * @param params 検索条件
 * @param selectedShu 選択中の種別
 */
function searchTransitionAfterIntroductionReportsAction(
  params: DataTransitionAfterIntroductionParams,
  selectedShu: ShuOption[],
  selectedDateRange: TransitionAfterIntroductionDateRange
) {
  return {
    type: SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS,
    payload: { params, selectedShu, selectedDateRange },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 */
function searchResetTransitionAfterIntroductionReportsAction() {
  return {
    type: SEARCH_RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS,
  };
}

/**
 * 指定した非推移項目ソート条件でデータを再取得する
 * @param sort 非推移項目でソートする条件
 * @param order 昇順・降順
 */
function searchTransitionAfterIntroductionReportsSortForHeaderAction(
  sort: string,
  order: OrderType
) {
  return {
    type: SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_HEADER,
    payload: { sort, order },
  };
}

/**
 * 指定した推移項目ソート条件でデータを再取得する
 * @param sort 推移項目でソートする条件
 * @param order 昇順・降順
 */
function searchTransitionAfterIntroductionReportsSortForTransitiveFieldAction(
  sort: string,
  order: OrderType
) {
  return {
    type: SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_TRANSITIVE_FIELD,
    payload: { sort, order },
  };
}

/**
 * DK-SISを有効にする
 */
function searchTransitionAfterIntroductionReportsDkSisOnAction() {
  return {
    type: SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_ON,
  };
}

/**
 * DK-SISを無効にする
 */
function searchTransitionAfterIntroductionReportsDkSisOffAction() {
  return {
    type: SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_OFF,
  };
}

/**
 * 指定したマーキング条件でデータを再取得する
 * @param markingOption マーキング項目
 * @param isFiltering 該当行のみ表示するか
 */
function searchTransitionAfterIntroductionReportsMarkingAction(
  markingOption: MarkingOption,
  isFiltering: boolean
) {
  return {
    type: SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_MARKING,
    payload: { markingOption, isFiltering },
  };
}

/**
 * 指定した表示項目に絞ってデータを再取得する
 * @param transitiveFields 推移項目
 * @param nonTransitiveFields 非推移項目
 */
function searchTransitionAfterIntroductionReportsFieldsAction(
  transitiveFields: string[],
  nonTransitiveFields: string[]
) {
  return {
    type: SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_FIELDS,
    payload: { transitiveFields, nonTransitiveFields },
  };
}

/**
 * 選択した種別のデータを取得する
 *
 * MEMO: 初回取得時とテーブル上部の種別選択で変更時に使用する
 * @param shu 絞り込む種別
 */
function changeTransitionAfterIntroductionReportsCurrentShuAction(
  shu?: ShuOption
) {
  return {
    type: CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 選択したお気に入りでデータを取得する
 *
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changeTransitionAfterIntroductionReportsFavoriteAction(
  favorite?: number
) {
  return {
    type: CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE,
    payload: { favorite },
  };
}

/**
 * 検索条件を変更
 */
function selectTransitionAfterIntroductionReportsSearchConditionAction(
  params: DataTransitionAfterIntroductionParams
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION,
    payload: { params },
  };
}

/**
 * 種別一覧を変更
 * @param shus 種別一覧
 */
export function selectTransitionAfterIntroductionReportsShuAction(
  shus: ShuOption[]
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU,
    payload: { shus },
  };
}

/**
 * 絞り込む種別を変更
 *
 * MEMO: 検索時やお気に入り適用時に使用する（全体的なStateだけ変更して最終的にFetchしたいケース）
 * @param shu 絞り込む種別
 */
export function selectTransitionAfterIntroductionReportsCurrentShuAction(
  shu?: ShuOption
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 絞り込む機種リストを変更
 *
 * @param kiList 絞り込む機種リスト
 */
function selectTransitionAfterIntroductionReportsKiListAction(
  kiList: string[]
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_KI_LIST,
    payload: { kiList },
  };
}

/**
 * 推移項目の並び順を変更
 *
 * @param columnsForTransition
 * @returns
 */
export function selectTransitionAfterIntroductionReportsColumnsForTransitionAction(
  columnsForTransition: string[]
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER,
    payload: { columnsForTransition },
  };
}

/**
 * 非推移項目の並び順を変更
 *
 * @param columnsForNonTransition
 * @returns
 */
export function selectTransitionAfterIntroductionReportsColumnsForNonTransitionAction(
  columnsForNonTransition: string[]
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER,
    payload: { columnsForNonTransition },
  };
}

/**
 * 検索条件をデフォルトに戻す
 */
function resetTransitionAfterIntroductionReportsSearchConditionAction() {
  return {
    type: RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION,
  };
}

/**
 * 選択中の種別一覧をデフォルトに戻す
 */
function resetTransitionAfterIntroductionReportsShuAction() {
  return {
    type: RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU,
  };
}

/**
 * 日付タイプ・期間を変更
 *
 * @param dateRange 変更する期間
 */
function selectTransitionAfterIntroductionReportsDateRangeParamsAction(
  dateRange: TransitionAfterIntroductionDateRange
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS,
    payload: { dateRange },
  };
}

/**
 * 選択中の期間をデフォルトに戻す
 */
function resetTransitionAfterIntroductionReportsDateRangeParamsAction() {
  return {
    type: RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS,
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 *  @param mode メモ更新モード
 */
function saveTransitionAfterIntroductionReportsFavorite(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 */
function saveAsTransitionAfterIntroductionReportsFavorite(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * 機種名フィルタを変更
 * @param filterString 検索する文字列
 */
export const selectTransitionAfterIntroductionReportsModelNameFilterAction = (
  filterString: string
) => ({
  type: SELECT_TRANSITION_AFTER_INTRODUCTION_MODEL_NAME_FILTER,
  payload: { filterString },
});

/**
 * テーブルの非表示項目を追加する
 * @param columnCode カラム名
 */
function addTransitionAfterIntroductionReportsTableFilterAction(
  columnCode: string
) {
  return {
    type: ADD_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの表示項目を削除する
 * @param columnCode カラム名
 */
function removeTransitionAfterIntroductionReportsTableFilterAction(
  columnCode: string
) {
  return {
    type: REMOVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する
 * @param tableFilterItems テーブルの非表示項目
 */
function applyTransitionAfterIntroductionReportsTableFilterAction(
  tableFilterItems: string[]
) {
  return {
    type: APPLY_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER,
    payload: { tableFilterItems },
  };
}

/**
 * テーブルの非表示項目をリセットする
 */
function resetTransitionAfterIntroductionReportsTableFilterAction() {
  return {
    type: RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER,
  };
}

/**
 * テーブルでチェックされた機種
 */
export function selectTransitionAfterIntroductionCheckedKiAction(
  checkedKiList: string[]
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI,
    payload: checkedKiList,
  };
}

/**
 * お気に入り適用時にテーブルでチェックされた機種の種別ごとのテーブルを設定する
 */
export function selectTransitionAfterIntroductionCheckedKiListTableAction(checkedKiListTable: {
  [shuCode: string]: string[];
}) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI_TABLE,
    payload: checkedKiListTable,
  };
}

/**
 * 推移グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectTransitionAfterIntroductionShowGraphNumberLabelAction(
  flag: boolean
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 選択中の店舗を変更
 *
 * MEMO: 検索時やお気に入り適用時に使用する（全体的なStateだけ変更して最終的にFetchしたいケース）
 * @param halls 選択中の店舗
 */
function selectTransitionAfterIntroductionReportsCurrentHallsAction(
  halls: string[]
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 店舗選択スライダーで選択可能な店舗一覧を設定する
 */
function selectTransitionAfterIntroductionReportsSelectedHallsAction(
  halls: string[]
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SELECTED_HALLS,
    payload: { halls },
  };
}

/**
 * 店舗選択スライダーで選択した店舗のデータを取得する
 *
 * MEMO: 初回取得時とテーブル上部の店舗選択で変更時に使用する
 * @param halls 絞り込む店舗
 */
function changeTransitionAfterIntroductionReportsCurrentHallsAction(
  halls: string[]
) {
  return {
    type: CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * ヒートマップ表示の有効無効を変更する
 */
function selectTransitionAfterIntroductionReportsIsHeatmapEnabledAction(
  isEnabled: boolean
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_IS_HEATMAP_ENABLED,
    payload: { isEnabled },
  };
}

/**
 * 画面共有用の短縮URLを作成する
 */
function createTransitionAfterIntroductionReportsShortenedUrlAction(
  pageName: string,
  locationUrl: string
) {
  return {
    type: CREATE_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

/**
 * 項目絞込リストで現在選択中の項目を変更
 */
export function selectTransitionAfterIntroductionCurrentFieldAction(
  fieldCodes: string[]
) {
  return {
    type: SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_FIELD,
    payload: { fieldCodes },
  };
}

export const TransitionAfterIntroductionReportsSettingActionCreators = {
  searchTransitionAfterIntroductionReportsAction,
  searchResetTransitionAfterIntroductionReportsAction,
  searchTransitionAfterIntroductionReportsSortForHeaderAction,
  searchTransitionAfterIntroductionReportsSortForTransitiveFieldAction,
  searchTransitionAfterIntroductionReportsMarkingAction,
  searchTransitionAfterIntroductionReportsFieldsAction,
  searchTransitionAfterIntroductionReportsDkSisOnAction,
  searchTransitionAfterIntroductionReportsDkSisOffAction,
  changeTransitionAfterIntroductionReportsFavoriteAction,
  changeTransitionAfterIntroductionReportsCurrentShuAction,
  selectTransitionAfterIntroductionReportsSearchConditionAction,
  selectTransitionAfterIntroductionReportsShuAction,
  selectTransitionAfterIntroductionReportsCurrentShuAction,
  selectTransitionAfterIntroductionReportsKiListAction,
  selectTransitionAfterIntroductionReportsColumnsForTransitionAction,
  selectTransitionAfterIntroductionReportsColumnsForNonTransitionAction,
  resetTransitionAfterIntroductionReportsSearchConditionAction,
  resetTransitionAfterIntroductionReportsShuAction,
  selectTransitionAfterIntroductionReportsDateRangeParamsAction,
  resetTransitionAfterIntroductionReportsDateRangeParamsAction,
  saveTransitionAfterIntroductionReportsFavorite,
  saveAsTransitionAfterIntroductionReportsFavorite,
  selectTransitionAfterIntroductionReportsModelNameFilterAction,
  addTransitionAfterIntroductionReportsTableFilterAction,
  removeTransitionAfterIntroductionReportsTableFilterAction,
  applyTransitionAfterIntroductionReportsTableFilterAction,
  resetTransitionAfterIntroductionReportsTableFilterAction,
  selectTransitionAfterIntroductionCheckedKiAction,
  selectTransitionAfterIntroductionCheckedKiListTableAction,
  selectTransitionAfterIntroductionShowGraphNumberLabelAction,
  selectTransitionAfterIntroductionReportsCurrentHallsAction,
  selectTransitionAfterIntroductionReportsSelectedHallsAction,
  changeTransitionAfterIntroductionReportsCurrentHallsAction,
  selectTransitionAfterIntroductionReportsIsHeatmapEnabledAction,
  createTransitionAfterIntroductionReportsShortenedUrlAction,
  selectTransitionAfterIntroductionCurrentFieldAction,
};

/**
 * Actions
 */

export type SearchTransitionAfterIntroductionReportsAction = ReturnType<
  typeof searchTransitionAfterIntroductionReportsAction
>;
type SearchResetTransitionAfterIntroductionReportsAction = ReturnType<
  typeof searchResetTransitionAfterIntroductionReportsAction
>;
export type SearchTransitionAfterIntroductionReportsSortForHeaderAction =
  ReturnType<
    typeof searchTransitionAfterIntroductionReportsSortForHeaderAction
  >;
export type SearchTransitionAfterIntroductionReportsSortForTransitiveFieldAction =
  ReturnType<
    typeof searchTransitionAfterIntroductionReportsSortForTransitiveFieldAction
  >;
export type SearchTransitionAfterIntroductionReportsMarkingAction = ReturnType<
  typeof searchTransitionAfterIntroductionReportsMarkingAction
>;
export type SearchTransitionAfterIntroductionReportsFieldsAction = ReturnType<
  typeof searchTransitionAfterIntroductionReportsFieldsAction
>;
type SearchTransitionAfterIntroductionReportsDkSisOnAction = ReturnType<
  typeof searchTransitionAfterIntroductionReportsDkSisOnAction
>;
type SearchTransitionAfterIntroductionReportsDkSisOffAction = ReturnType<
  typeof searchTransitionAfterIntroductionReportsDkSisOffAction
>;
type ChangeTransitionAfterIntroductionReportsFavoriteAction = ReturnType<
  typeof changeTransitionAfterIntroductionReportsFavoriteAction
>;
export type ChangeTransitionAfterIntroductionReportsCurrentShuAction =
  ReturnType<typeof changeTransitionAfterIntroductionReportsCurrentShuAction>;
type SelectKiShuAction = ReturnType<
  typeof selectTransitionAfterIntroductionReportsShuAction
>;
type SelectTransitionAfterIntroductionReportsSearchConditionAction = ReturnType<
  typeof selectTransitionAfterIntroductionReportsSearchConditionAction
>;
type SelectKiCurrentShuAction = ReturnType<
  typeof selectTransitionAfterIntroductionReportsCurrentShuAction
>;
type SelectKiListAction = ReturnType<
  typeof selectTransitionAfterIntroductionReportsKiListAction
>;
type SelectTransitionAfterIntroductionReportsColumnsForTransitionAction =
  ReturnType<
    typeof selectTransitionAfterIntroductionReportsColumnsForTransitionAction
  >;
type SelectTransitionAfterIntroductionReportsColumnsForNonTransitionAction =
  ReturnType<
    typeof selectTransitionAfterIntroductionReportsColumnsForNonTransitionAction
  >;
type ResetTransitionAfterIntroductionReportsSearchConditionAction = ReturnType<
  typeof resetTransitionAfterIntroductionReportsSearchConditionAction
>;
type ResetKiShuAction = ReturnType<
  typeof resetTransitionAfterIntroductionReportsShuAction
>;
type SelectTransitionAfterIntroductionReportsDateRangeParamsAction = ReturnType<
  typeof selectTransitionAfterIntroductionReportsDateRangeParamsAction
>;
type ResetTransitionAfterIntroductionReportsDateRangeParamsAction = ReturnType<
  typeof resetTransitionAfterIntroductionReportsDateRangeParamsAction
>;
export type SaveTransitionAfterIntroductionReportsFavorite = ReturnType<
  typeof saveTransitionAfterIntroductionReportsFavorite
>;
type SaveAsTransitionAfterIntroductionReportsFavorite = ReturnType<
  typeof saveAsTransitionAfterIntroductionReportsFavorite
>;
type SelectTransitionAfterIntroductionReportsModelNameFilterAction = ReturnType<
  typeof selectTransitionAfterIntroductionReportsModelNameFilterAction
>;
type AddTransitionAfterIntroductionReportsTableFilterAction = ReturnType<
  typeof addTransitionAfterIntroductionReportsTableFilterAction
>;
type RemoveTransitionAfterIntroductionReportsTableFilterAction = ReturnType<
  typeof removeTransitionAfterIntroductionReportsTableFilterAction
>;
type ApplyTransitionAfterIntroductionReportsTableFilterAction = ReturnType<
  typeof applyTransitionAfterIntroductionReportsTableFilterAction
>;
type ResetTransitionAfterIntroductionReportsTableFilterAction = ReturnType<
  typeof resetTransitionAfterIntroductionReportsTableFilterAction
>;
type SelectTransitionAfterIntroductionCheckedKiAction = ReturnType<
  typeof selectTransitionAfterIntroductionCheckedKiAction
>;
type SelectTransitionAfterIntroductionCheckedKiListTableAction = ReturnType<
  typeof selectTransitionAfterIntroductionCheckedKiListTableAction
>;
type SelectTransitionAfterIntroductionShowGraphNumberLabelAction = ReturnType<
  typeof selectTransitionAfterIntroductionShowGraphNumberLabelAction
>;
type ResetAfterIntroductionTransitionFavoriteToDefaultAction = ReturnType<
  typeof resetAfterIntroductionTransitionFavoriteToDefaultAction
>;
type SelectTransitionAfterIntroductionReportsCurrentHallsAction = ReturnType<
  typeof selectTransitionAfterIntroductionReportsCurrentHallsAction
>;
type SelectTransitionAfterIntroductionReportsSelectedHallsAction = ReturnType<
  typeof selectTransitionAfterIntroductionReportsSelectedHallsAction
>;
export type ChangeTransitionAfterIntroductionReportsCurrentHallsAction =
  ReturnType<typeof changeTransitionAfterIntroductionReportsCurrentHallsAction>;
type SelectTransitionAfterIntroductionReportsIsHeatmapEnabledAction =
  ReturnType<
    typeof selectTransitionAfterIntroductionReportsIsHeatmapEnabledAction
  >;
export type CreateTransitionAfterIntroductionReportsShortenedUrlAction =
  ReturnType<typeof createTransitionAfterIntroductionReportsShortenedUrlAction>;
export type SelectTransitionAfterIntroductionReportsCurrentFieldAction =
  ReturnType<typeof selectTransitionAfterIntroductionCurrentFieldAction>;
type TransitionAfterIntroductionReportsSettingAction =
  | SearchTransitionAfterIntroductionReportsAction
  | SearchResetTransitionAfterIntroductionReportsAction
  | SearchTransitionAfterIntroductionReportsSortForHeaderAction
  | SearchTransitionAfterIntroductionReportsSortForTransitiveFieldAction
  | SearchTransitionAfterIntroductionReportsMarkingAction
  | SearchTransitionAfterIntroductionReportsFieldsAction
  | SearchTransitionAfterIntroductionReportsDkSisOnAction
  | SearchTransitionAfterIntroductionReportsDkSisOffAction
  | ChangeTransitionAfterIntroductionReportsFavoriteAction
  | ChangeTransitionAfterIntroductionReportsCurrentShuAction
  | SelectTransitionAfterIntroductionReportsSearchConditionAction
  | SelectKiShuAction
  | SelectKiCurrentShuAction
  | SelectKiListAction
  | ResetTransitionAfterIntroductionReportsSearchConditionAction
  | ResetKiShuAction
  | SelectTransitionAfterIntroductionReportsDateRangeParamsAction
  | SelectTransitionAfterIntroductionReportsColumnsForTransitionAction
  | SelectTransitionAfterIntroductionReportsColumnsForNonTransitionAction
  | ResetTransitionAfterIntroductionReportsDateRangeParamsAction
  | SaveTransitionAfterIntroductionReportsFavorite
  | SaveAsTransitionAfterIntroductionReportsFavorite
  | SelectTransitionAfterIntroductionReportsModelNameFilterAction
  | AddTransitionAfterIntroductionReportsTableFilterAction
  | RemoveTransitionAfterIntroductionReportsTableFilterAction
  | ApplyTransitionAfterIntroductionReportsTableFilterAction
  | ResetTransitionAfterIntroductionReportsTableFilterAction
  | SelectTransitionAfterIntroductionCheckedKiAction
  | SelectTransitionAfterIntroductionCheckedKiListTableAction
  | SelectTransitionAfterIntroductionShowGraphNumberLabelAction
  | ResetAfterIntroductionTransitionFavoriteToDefaultAction
  | SelectTransitionAfterIntroductionReportsCurrentHallsAction
  | SelectTransitionAfterIntroductionReportsSelectedHallsAction
  | ChangeTransitionAfterIntroductionReportsCurrentHallsAction
  | SelectTransitionAfterIntroductionReportsIsHeatmapEnabledAction
  | CreateTransitionAfterIntroductionReportsShortenedUrlAction
  | SelectTransitionAfterIntroductionReportsCurrentFieldAction;
/**
 * State
 */

export type TransitionAfterIntroductionReportsSettingState = {
  /**
   * 現在の検索条件
   */
  searchCondition: DataTransitionAfterIntroductionParams;
  /**
   * 現在検索フォームで選択中の店舗（店舗選択スライダーで選択可能な店舗コード一覧）
   */
  selectedHalls: string[];
  /**
   * 店舗選択スライダーで現在選択中の店舗（テーブルに表示されている店舗の店舗コード）
   */
  currentHalls: string[];
  /**
   * 現在検索フォームで選択中の種別・種別グループ（選択可能な種別・種別グループ一覧）
   */
  selectedShu: ShuOption[];
  /**
   * 現在選択中の種別・種別グループ（テーブルに表示されている種別・種別グループ）
   */
  currentShu?: ShuOption;
  /**
   * 現在選択中の日付・期間
   */
  selectedDateRangeParams: TransitionAfterIntroductionReportsSettingDateRangeParams;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId?: number;
  /**
   * フィルターされた機種リスト
   * 古いお気に入りデータにはkiListがないためoptionalとしている
   */
  kiList?: string[];
  /**
   * 導入後推移テーブルの推移項目並び順
   */
  columnsForTransitionOrder?: string[];
  /**
   * 導入後推移テーブルの非推移項目並び順
   */
  columnsForNonTransitionOrder?: string[];
  /**
   * 機種名フィルタ
   */
  modelNameFilter: string;
  /**
   * テーブルの非表示項目一覧
   */
  tableFilterItems: string[];
  /**
   * テーブルでチェックされた機種を種別ごとに保持するテーブル
   */
  checkedKiListTable?: {
    [shuCode: string]: string[];
  };
  showGraphNumberLabel: boolean;
  /**
   * ヒートマップ表示の有効無効
   */
  isHeatmapEnabled: boolean;
  /**
   * 項目絞込で選択されている項目
   */
  selectedCurrentFieldCodes?: string[];
};

// Stateの初期値
const initialState: TransitionAfterIntroductionReportsSettingState = {
  searchCondition: dataTransitionAfterIntroductionDefaultValue().setting,
  selectedHalls: [],
  currentHalls: [],
  selectedShu: [],
  currentShu: undefined,
  selectedDateRangeParams: {
    dateRange:
      dataTransitionAfterIntroductionDefaultValue().dateRangeParams.dateRange,
  },
  selectedFavoriteId: undefined,
  kiList: [],
  columnsForTransitionOrder: [],
  columnsForNonTransitionOrder: [],
  modelNameFilter: '',
  tableFilterItems: [],
  checkedKiListTable: {},
  showGraphNumberLabel: true,
  isHeatmapEnabled: true,
  selectedCurrentFieldCodes: undefined,
};

/**
 * Selector
 */

/**
 * 導入後推移設定全てを取得する
 * @returns 全設定データ
 */
export function transitionAfterIntroductionReportsSettingSelector(rootState: {
  transitionAfterIntroductionReportsSetting: TransitionAfterIntroductionReportsSettingState;
}) {
  return rootState.transitionAfterIntroductionReportsSetting;
}

/**
 * 導入後推移の現在選択されている検索条件を取得する
 * @returns 現在選択されている検索条件
 */
export function transitionAfterIntroductionReportsSearchConditionSelector(rootState: {
  transitionAfterIntroductionReportsSetting: TransitionAfterIntroductionReportsSettingState;
}) {
  return rootState.transitionAfterIntroductionReportsSetting.searchCondition;
}

/**
 * 導入後推移の検索フォームで選択中の種別・種別グループを取得する（選択可能な種別・種別グループ一覧）
 * @returns 選択可能な種別一覧
 */
export function transitionAfterIntroductionReportsSelectedShuSelector(rootState: {
  transitionAfterIntroductionReportsSetting: TransitionAfterIntroductionReportsSettingState;
}) {
  return rootState.transitionAfterIntroductionReportsSetting.selectedShu;
}

/**
 * 導入後推移で選択中の種別・種別グループを取得する（テーブルに表示されている種別・種別グループ）
 * @returns 現在選択されている種別（undefined時未選択）
 */
export function transitionAfterIntroductionReportsSelectedCurrentShuSelector(rootState: {
  transitionAfterIntroductionReportsSetting: TransitionAfterIntroductionReportsSettingState;
}) {
  return rootState.transitionAfterIntroductionReportsSetting.currentShu;
}

/**
 * 導入後推移で選択中の機種を選択する
 * @returns 現在選択されている機種
 */
export function transitionAfterIntroductionReportsSelectedKiListSelector(rootState: {
  transitionAfterIntroductionReportsSetting: TransitionAfterIntroductionReportsSettingState;
}) {
  return rootState.transitionAfterIntroductionReportsSetting.kiList;
}

/**
 * 導入後推移の推移項目の並び順
 * @returns
 */
export function transitionAfterIntroductionReportsColumnsForTransitionOrderSelector(rootState: {
  transitionAfterIntroductionReportsSetting: TransitionAfterIntroductionReportsSettingState;
}) {
  return rootState.transitionAfterIntroductionReportsSetting
    .columnsForTransitionOrder;
}

/**
 * 導入後推移の非推移項目の並び順
 * @returns
 */
export function transitionAfterIntroductionReportsColumnsForNonTransitionOrderSelector(rootState: {
  transitionAfterIntroductionReportsSetting: TransitionAfterIntroductionReportsSettingState;
}) {
  return rootState.transitionAfterIntroductionReportsSetting
    .columnsForNonTransitionOrder;
}

/**
 * 期間推移で選択中の日付タイプを取得する（テーブルに表示されている日付タイプ・期間）
 * @returns 現在選択されている期間
 */
export function transitionAfterIntroductionReportsSelectedDateRangeParamsSelector(rootState: {
  transitionAfterIntroductionReportsSetting: TransitionAfterIntroductionReportsSettingState;
}) {
  return rootState.transitionAfterIntroductionReportsSetting
    .selectedDateRangeParams;
}

/**
 * 導入後推移で選択中のお気に入りIDを取得する
 * @returns 現在選択中のお気に入りID（undefined時未選択）
 */
export const transitionAfterIntroductionReportsSelectedFavoriteSelector = (
  state: RootState
) => {
  return state.transitionAfterIntroductionReportsSetting.selectedFavoriteId;
};

/**
 * 導入後推移で選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const transitionAfterIntroductionReportsSelectedFavoriteDataSelector =
  createSelector(
    [
      transitionAfterIntroductionReportsSelectedFavoriteSelector,
      favoritesSelector,
    ],
    (favoriteId, favorites) => {
      if (favoriteId === undefined) return;

      return favorites?.favorites
        ?.filter((favorite) => favorite.id === favoriteId)
        .shift();
    }
  );

/**
 * 導入後推移で選択中のお気に入りデータ内のidを取得する
 */
export const transitionAfterIntroductionReportsSelectedFavoriteIdSelector =
  createSelector(
    transitionAfterIntroductionReportsSelectedFavoriteDataSelector,
    (favorite) => {
      return favorite?.id;
    }
  );

/**
 * 導入後推移で選択中のお気に入りデータ内の導入後推移データのみを取得する
 * @returns 現在選択中の導入後推移のお気に入りデータ
 */
export const transitionAfterIntroductionReportsSelectedFavoritePageSettingSelector =
  createSelector(
    transitionAfterIntroductionReportsSelectedFavoriteDataSelector,
    (favorite) => {
      return favorite?.pageSetting?.transitionAfterIntroduction;
    }
  );

/**
 * 導入後推移で入力されている機種名フィルタを返す
 */
export const transitionAfterIntroductionReportsModelNameFilterSelector =
  (rootState: {
    transitionAfterIntroductionReportsSetting: TransitionAfterIntroductionReportsSettingState;
  }) => rootState.transitionAfterIntroductionReportsSetting.modelNameFilter;

/**
 * 非表示項目一覧を取得する
 * @returns 非表示項目一覧
 */
export const transitionAfterIntroductionReportsTableFilterSelector = (
  state: RootState
) => {
  return state.transitionAfterIntroductionReportsSetting.tableFilterItems;
};

/**
 * テーブルでチェックされた機種のテーブルを取得する
 */
export const transitionAfterIntroductionReportsCheckedKiListTableSelector = (
  state: RootState
) => {
  return state.transitionAfterIntroductionReportsSetting.checkedKiListTable;
};

/**
 * 種別ごとのチェックされた機種を取得する
 */
export const transitionAfterIntroductionReportsCheckedKiListSelector =
  createSelector(
    [
      transitionAfterIntroductionReportsSelectedCurrentShuSelector,
      transitionAfterIntroductionReportsCheckedKiListTableSelector,
    ],
    (currentShu, checkedKiListTable) => {
      return (
        (checkedKiListTable &&
          currentShu &&
          checkedKiListTable[currentShu.code]) ||
        []
      );
    }
  );

/**
 * 機種絞り込みされているかどうかを取得する
 */
export const isTransitionAfterIntroductionReportsKiListFilterSelector =
  createSelector(
    transitionAfterIntroductionReportsSettingSelector,
    (setting) => setting.kiList?.length !== 0
  );

// 推移グラフで数値ラベルを表示するかどうか
export const transitionAfterIntroductionShowGraphNumberLabelSelector =
  createSelector(
    transitionAfterIntroductionReportsSettingSelector,
    (setting) => setting.showGraphNumberLabel
  );

/**
 * 店舗選択スライダーで選択可能な店舗一覧を取得
 */
export const transitionAfterIntroductionReportsSettingSelectedHallsSelector =
  createSelector(
    transitionAfterIntroductionReportsSettingSelector,
    ({ selectedHalls }) => selectedHalls
  );

/**
 * 店舗選択スライダーで現在選択中の店舗を取得する（テーブルに表示されている店舗）
 * @returns 現在選択されている店舗
 */
export const transitionAfterIntroductionReportsSettingCurrentHallsSelector =
  createSelector(
    transitionAfterIntroductionReportsSettingSelector,
    ({ currentHalls }) => currentHalls
  );

/**
 * ヒートマップ表示の有効無効を取得
 */
export const transitionAfterIntroductionReportsSettingIsHeatmapEnabledSelector =
  createSelector(
    transitionAfterIntroductionReportsSettingSelector,
    ({ isHeatmapEnabled }) => isHeatmapEnabled
  );

/**
 * 項目絞込リストで選択中の項目
 */
export const transitionAfterIntroductionCurrentFieldSelector = createSelector(
  transitionAfterIntroductionReportsSettingSelector,
  ({ selectedCurrentFieldCodes }) => selectedCurrentFieldCodes ?? ['all']
);

/**
 * Reducer
 */

export function transitionAfterIntroductionReportsSettingReducer(
  state = initialState,
  action: TransitionAfterIntroductionReportsSettingAction
): TransitionAfterIntroductionReportsSettingState {
  switch (action.type) {
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.payload.params,
      };
    case RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU:
      return {
        ...state,
        selectedShu: action.payload.shus,
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS: {
      return {
        ...state,
        selectedDateRangeParams: {
          dateRange: action.payload.dateRange,
        },
      };
    }
    case RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: initialState.selectedDateRangeParams,
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU:
    case CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU:
      return {
        ...state,
        currentShu: action.payload.shu,
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_KI_LIST:
      return {
        ...state,
        kiList: action.payload.kiList,
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER:
      return {
        ...state,
        columnsForTransitionOrder: action.payload.columnsForTransition,
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER:
      return {
        ...state,
        columnsForNonTransitionOrder: action.payload.columnsForNonTransition,
      };
    case RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHU:
      return {
        ...state,
        selectedShu: [],
      };
    case CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favorite,
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_MODEL_NAME_FILTER:
      return {
        ...state,
        modelNameFilter: action.payload.filterString,
      };
    case ADD_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [
          ...state.tableFilterItems,
          action.payload.columnCode,
        ],
      };
    case REMOVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: state.tableFilterItems.filter(
          (columnCode) => columnCode !== action.payload.columnCode
        ),
      };
    case APPLY_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: action.payload.tableFilterItems,
      };
    case RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [],
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI:
      if (!state.currentShu) {
        return state;
      }
      return {
        ...state,
        checkedKiListTable: {
          ...state.checkedKiListTable,
          [state.currentShu.code]: action.payload,
        },
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CHECKED_KI_TABLE: {
      return {
        ...state,
        checkedKiListTable: action.payload,
      };
    }
    case SELECT_TRANSITION_AFTER_INTRODUCTION_SHOW_GRAPH_NUMBER_LABEL: {
      return {
        ...state,
        showGraphNumberLabel: action.payload.flag,
      };
    }
    case RESET_AFTERINTRODUCTION_FAVORITE_TO_DEFAULT: {
      return {
        ...state,
        selectedFavoriteId: undefined,
      };
    }
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_SELECTED_HALLS: {
      return {
        ...state,
        selectedHalls: action.payload.halls,
      };
    }
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS:
    case CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS:
      return {
        ...state,
        currentHalls: action.payload.halls,
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_IS_HEATMAP_ENABLED:
      return {
        ...state,
        isHeatmapEnabled: action.payload.isEnabled,
      };
    case SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_FIELD:
      return {
        ...state,
        selectedCurrentFieldCodes: action.payload.fieldCodes,
      };
    default:
      return state;
  }
}
