import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { DataKiSyokenResponse } from '../domain/kiSyoken/types';

import {
  DataKiSyokenActionCreators,
  DataKiSyokenActionTypes,
  FetchDataKiSyokenAction,
} from '../redux/server/dataKiSyoken';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * dataKiSyokenのデータを取得する
 */
function* fetchDataKiSyokenSaga(api: Api, action: FetchDataKiSyokenAction) {
  try {
    yield put(DataKiSyokenActionCreators.fetchDataKiSyokenRequestAction());
    const response: AxiosResponse<DataKiSyokenResponse> = yield call(
      api.get,
      '/data/kiSyoken',
      buildConfig(action.payload.params)
    );

    const dataKiSyoken = response.data;

    yield put(
      DataKiSyokenActionCreators.fetchDataKiSyokenSuccessAction(dataKiSyoken)
    );
  } catch (error: unknown) {
    yield put(DataKiSyokenActionCreators.renewDataKiSyokenAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataKiSyokenSaga(api: Api) {
  yield takeEvery(
    DataKiSyokenActionTypes.FETCH_DATA_KISYOKEN,
    fetchDataKiSyokenSaga,
    api
  );
}

export function* dataKiSyokenSaga(context: { api: Api }) {
  yield fork(handleFetchDataKiSyokenSaga, context.api);
}
