import { createSelector } from 'reselect';

import { dataDaiDefaultValue } from '../../domain/dai/defaultValue';
import { DataDai } from '../../domain/dai/types';
import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';

import { ConvertRowsToFilteredRows } from '../../components/organisms/TableHeaderSubMenu/ConvertRowsToFilteredRows';

import { RootState } from '../../store';
import { filterModelName } from '../../utils/filterModelName';
import { getOrderedTableData } from '../../utils/orderedCell';

// Action Types
const FETCH_DATA_DAI = 'FETCH_DATA_DAI' as const;
const FETCH_DATA_DAI_REQUEST = 'FETCH_DATA_DAI_REQUEST' as const;
const FETCH_DATA_DAI_SUCCESS = 'FETCH_DATA_DAI_SUCCESS' as const;
const RENEW_DATA_DAI = 'RENEW_DATA_DAI' as const;
const SELECT_DATA_DAI_COLUMNS_ORDER = 'SELECT_DATA_DAI_COLUMNS_ORDER' as const;
const CHANGE_DATA_DAI_COLUMNS_ORDER = 'CHANGE_DATA_DAI_COLUMNS_ORDER' as const;
const RESET_DATA_DAI_COLUMNS_ORDER = 'RESET_DATA_DAI_COLUMNS_ORDER' as const;
const FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_REQUEST =
  'FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_REQUEST' as const;
const FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_SUCCESS =
  'FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_SUCCESS' as const;
const RESET_DATA_DAI_HIGHLIGHT_AVERAGE =
  'RESET_DATA_DAI_HIGHLIGHT_AVERAGE' as const;

export const DataDaiActionTypes = {
  FETCH_DATA_DAI,
  FETCH_DATA_DAI_REQUEST,
  FETCH_DATA_DAI_SUCCESS,
  RENEW_DATA_DAI,
  SELECT_DATA_DAI_COLUMNS_ORDER,
  CHANGE_DATA_DAI_COLUMNS_ORDER,
  RESET_DATA_DAI_COLUMNS_ORDER,
  FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_REQUEST,
  FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_SUCCESS,
  RESET_DATA_DAI_HIGHLIGHT_AVERAGE,
};

// Action Creators

function fetchDataDaiAction(params: DataDai['setting']) {
  return {
    type: FETCH_DATA_DAI,
    payload: { params },
  };
}

function fetchDataDaiRequestAction() {
  return {
    type: FETCH_DATA_DAI_REQUEST,
  };
}

function fetchDataDaiSuccessAction(dataDai: DataDai) {
  return {
    type: FETCH_DATA_DAI_SUCCESS,
    payload: { dataDai },
  };
}

function renewDataDaiAction() {
  return {
    type: RENEW_DATA_DAI,
  };
}

/**
 *
 * 台別データ一覧の並び替え情報を登録する（Sagaで並び替え確定時）
 */
function selectDataDaiColumnsOrderAction(columnsOrder: string[]) {
  return {
    type: SELECT_DATA_DAI_COLUMNS_ORDER,
    payload: { columnsOrder },
  };
}

/**
 * 台別データ一覧の列の並び替え実行時にSagaで再計算する
 */
function changeDataDaiColumnsOrderAction(
  draggedId?: string,
  droppedId?: string
) {
  return {
    type: CHANGE_DATA_DAI_COLUMNS_ORDER,
    payload: { draggedId, droppedId },
  };
}

/**
 * 台別データ一覧の並び替え情報をリセットする
 */
function resetDataDaiColumnsOrderAction() {
  return {
    type: RESET_DATA_DAI_COLUMNS_ORDER,
  };
}

/**
 * ハイライト機種のリクエスト
 * @param searchResultSetting 検索条件
 * @param kiList ハイライト機種
 * @param kiName ハイライト機種名（バックエンドからのレスポンスに機種名がないが、テーブルの表示には必要なので引数で受け取って登録している。ここでは正しい動きとなるが、基本的にはfetchにリクエストに必要なパラメータ以外を入れるのはよろしくないので真似しないように）
 */
function fetchDataDaiHighlightAverageRequestAction(
  searchResultSetting: DataDai['setting'],
  kiList: string[] = [],
  kiName = ''
) {
  return {
    type: FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_REQUEST,
    payload: {
      params: {
        ...searchResultSetting,
        kiList: kiList,
      },
      kiName: kiName,
    },
  };
}

/**
 * ハイライト機種のリクエスト成功時のアクション
 * @param data APIレスポンス
 */
function fetchDataDaiHighlightAverageSuccessAction(data: DataDai) {
  return {
    type: FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_SUCCESS,
    payload: { data },
  };
}

/**
 * ハイライト機種をリセットする
 */
function resetDataDaiHighlightAverageAction() {
  return {
    type: RESET_DATA_DAI_HIGHLIGHT_AVERAGE,
  };
}

export const DataDaiActionCreators = {
  fetchDataDaiAction,
  fetchDataDaiRequestAction,
  fetchDataDaiSuccessAction,
  renewDataDaiAction,
  selectDataDaiColumnsOrderAction,
  changeDataDaiColumnsOrderAction,
  resetDataDaiColumnsOrderAction,
  fetchDataDaiHighlightAverageRequestAction,
  fetchDataDaiHighlightAverageSuccessAction,
  resetDataDaiHighlightAverageAction,
};

// Actions

export type FetchDataDaiAction = ReturnType<typeof fetchDataDaiAction>;
export type ChangeDataDaiColumnsOrderAction = ReturnType<
  typeof changeDataDaiColumnsOrderAction
>;
type DataDaiAction =
  | FetchDataDaiAction
  | ReturnType<typeof fetchDataDaiRequestAction>
  | ReturnType<typeof fetchDataDaiSuccessAction>
  | ReturnType<typeof renewDataDaiAction>
  | ReturnType<typeof selectDataDaiColumnsOrderAction>
  | ReturnType<typeof resetDataDaiColumnsOrderAction>
  | ChangeDataDaiColumnsOrderAction
  | ReturnType<typeof fetchDataDaiHighlightAverageRequestAction>
  | ReturnType<typeof fetchDataDaiHighlightAverageSuccessAction>
  | ReturnType<typeof resetDataDaiHighlightAverageAction>;

// State

type DataDaiState = {
  loadingState: LoadingState;
  dataDai: DataDai;
  columnsOrder?: string[];
  /**
   * ハイライト機種の平均合計行データ
   */
  highlightAverage: {
    loadingState: LoadingState;
    error: ClarisApiError | null;
    data: DataDai | null;
    kiList: string[];
    kiName: string;
  };
};

const initialState: DataDaiState = {
  loadingState: 'prepare',
  dataDai: dataDaiDefaultValue(),
  columnsOrder: [],
  highlightAverage: {
    loadingState: 'prepare',
    error: null,
    data: null,
    kiList: [],
    kiName: '',
  },
};

/**
 * 台別データ一覧 レスポンスデータを取得する（フィルターされていない）
 * @returns データ
 */
const dataDaiAllDataSelector = (state: RootState) => {
  return state.dataDai.dataDai.data;
};

/**
 * 台別データ一覧 データを取得する
 * @returns データ
 */
export const dataDaiSelector = (state: RootState) => {
  const modelNameFilter = state.unitDataListReportsSetting.modelNameFilter;

  const subMenuNumberFilter =
    state.unitDataListReportsSetting.subMenuNumberFilter;

  const { columns, rows } = state.dataDai.dataDai.data;

  const { filteredRows } = ConvertRowsToFilteredRows(
    subMenuNumberFilter,
    columns,
    rows
  );

  //サブメニュー内の数値でフィルタ
  if (!modelNameFilter) {
    return {
      ...state.dataDai.dataDai,
      data: {
        ...state.dataDai.dataDai.data,
        rows: filteredRows,
      },
    };
  }

  // 機種名でフィルタ
  const predicate = filterModelName(modelNameFilter);
  return {
    ...state.dataDai.dataDai,
    data: {
      ...state.dataDai.dataDai.data,
      rows: filteredRows.filter(
        (row) => row.data.length > 0 && predicate(row.data.at(0)?.value ?? '')
      ),
    },
  };
};

/**
 * 台別データ一覧のテーブルデータのデータ部分のみ取得
 * @returns テーブルデータ（検索条件除いた部分）
 */
const dataDaiDataSelector = createSelector(dataDaiSelector, (dataDai) => {
  return dataDai.data;
});

/**
 * 台別データ一覧のテーブルデータのカラムのみ取得
 * @returns テーブルデータのカラム
 */
export const dataDaiDataColumnsSelector = createSelector(
  dataDaiDataSelector,
  (data) => {
    return data.columns;
  }
);

/**
 * 台別データ一覧のテーブルの並び替え情報を取得
 * @returns 並び替え情報
 */
export const dataDaiColumnsOrderSelector = (state: RootState) =>
  state.dataDai.columnsOrder;

/**
 * 台別データ一覧のテーブルの並び替え後のテーブルデータを取得する
 * @returns 並び替え後のテーブルデータ
 */
export const dataDaiOrderedDataSelector = createSelector(
  [dataDaiDataSelector, dataDaiColumnsOrderSelector],
  (tableData, ordered) => {
    // テーブルが空の場合はそのまま戻す
    if (tableData === undefined) {
      return { columns: [], rows: [] };
    }

    // 並べ替え後の項目数と、テーブルデータの項目数が一致しない場合、データ取得中
    if (ordered && tableData.columns.length !== ordered.length) {
      return { columns: [], rows: [] };
    }

    const rows = tableData.rows;

    if (!ordered) {
      return { columns: tableData.columns, rows };
    } else {
      return getOrderedTableData(ordered, tableData.columns, rows);
    }
  }
);

/**
 * 台別データ一覧 ローディング状態を取得する
 * @returns ローディング状態
 */
export const dataDaiLoadingStateSelector = (state: RootState) =>
  state.dataDai.loadingState;

/**
 * 台別データの現在の検索条件
 * @returns 検索条件
 */
export const dataDaiParamsSelector = (state: RootState) =>
  state.dataDai.dataDai?.setting;

/**
 * 現在選択中の表示項目を取得する
 * @returns 選択中の表示項目
 */
export const dataDaiParamsFieldsSelector = createSelector(
  [dataDaiParamsSelector],
  (params) => {
    const selectedFields = params?.fields;

    return selectedFields ? selectedFields : [];
  }
);

/**
 * ハイライト機種の情報を取得
 */
export const dataDaiHighlightAverageSelector = (state: RootState) => {
  return state.dataDai.highlightAverage;
};

/**
 * ハイライトで選択された機種を取得
 */
export const dataDaiHighlightAverageKiListSelector = createSelector(
  dataDaiHighlightAverageSelector,
  (highlightAverage) => highlightAverage.kiList
);

/**
 * ハイライト機種の平均合計のローディング状態を取得
 */
export const dataDaiHighlightAverageLoadingSelector = createSelector(
  dataDaiHighlightAverageSelector,
  (highlightAverage) => highlightAverage.loadingState
);

/**
 * ハイライトで選択した機種の平均合計行のデータを取得
 */
export const dataDaiHighlightAverageDataSelector = createSelector(
  [
    dataDaiHighlightAverageSelector,
    dataDaiColumnsOrderSelector,
    dataDaiAllDataSelector,
  ],
  (highlightAverage, ordered, dataDai) => {
    if (
      !highlightAverage.data ||
      !highlightAverage.data.data.rows[0] ||
      !highlightAverage.data.setting.kiList
    ) {
      return null;
    }

    const { columns, rows } = highlightAverage.data.data;

    // 並べ替え後の項目数と、テーブルデータの項目数が一致しない場合nullを返す
    if (ordered && columns.length !== ordered.length) {
      return null;
    }

    // 配列DataDaiの内、highlightAverage.kiListに含まれる機種のみを抽出する
    const kiList = highlightAverage.kiList;
    const filteredRows = dataDai.rows.filter((row) => {
      return kiList.includes(row.queryParameter.kiList[0]);
    });

    const firstRow = rows.at(0);

    if (!firstRow) {
      return {
        setting: highlightAverage.data.setting,
        data: { columns, rows: [] },
      };
    }

    // 一列目の文字列を加工する
    const updatedRows = [
      {
        ...rows[0],
        data: [
          {
            ...firstRow.data[0],
            value: `${highlightAverage.kiName} 平均/合計(${filteredRows.length}台)`,
          },
          ...firstRow.data.slice(1),
        ],
      },
    ];

    if (!ordered) {
      return {
        setting: highlightAverage.data.setting,
        data: { columns, rows: updatedRows },
      };
    } else {
      return {
        setting: highlightAverage.data.setting,
        data: getOrderedTableData(ordered, columns, updatedRows),
      };
    }
  }
);

// Reducer

export function dataDaiReducer(
  state = initialState,
  action: DataDaiAction
): DataDaiState {
  switch (action.type) {
    case FETCH_DATA_DAI_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_DAI_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataDai: action.payload.dataDai,
      };
    case RENEW_DATA_DAI: {
      return initialState;
    }
    case SELECT_DATA_DAI_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
      };
    case RESET_DATA_DAI_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: initialState.columnsOrder,
      };
    case FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_REQUEST: {
      return {
        ...state,
        highlightAverage: {
          loadingState: 'loading',
          error: null,
          data: null,
          kiList: action.payload.params.kiList,
          kiName: action.payload.kiName,
        },
      };
    }
    case FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_SUCCESS: {
      return {
        ...state,
        highlightAverage: {
          ...state.highlightAverage,
          loadingState: 'loaded',
          error: null,
          data: action.payload.data,
        },
      };
    }
    case RESET_DATA_DAI_HIGHLIGHT_AVERAGE: {
      return {
        ...state,
        highlightAverage: initialState.highlightAverage,
      };
    }
    default:
      return state;
  }
}
