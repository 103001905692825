/**
 * アイコン
 */
export const icons = {
  arrowDropDown:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTcgMTBsNSA1IDUtNXoiLz48L3N2Zz4=',
  newRed:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDRINEMyLjg5IDQgMi4wMSA0Ljg5IDIuMDEgNkwyIDE4QzIgMTkuMTEgMi44OSAyMCA0IDIwSDIwQzIxLjExIDIwIDIyIDE5LjExIDIyIDE4VjZDMjIgNC44OSAyMS4xMSA0IDIwIDRaTTguNSAxNUg3LjNMNC43NSAxMS41VjE1SDMuNVY5SDQuNzVMNy4yNSAxMi41VjlIOC41VjE1Wk0xMy41IDEwLjI2SDExVjExLjM4SDEzLjVWMTIuNjRIMTFWMTMuNzVIMTMuNVYxNUg5LjVWOUgxMy41VjEwLjI2Wk0yMC41IDE0QzIwLjUgMTQuNTUgMjAuMDUgMTUgMTkuNSAxNUgxNS41QzE0Ljk1IDE1IDE0LjUgMTQuNTUgMTQuNSAxNFY5SDE1Ljc1VjEzLjUxSDE2Ljg4VjkuOTlIMTguMTNWMTMuNUgxOS4yNVY5SDIwLjVWMTRaIiBmaWxsPSIjRjQ0MjM3Ii8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMiAxOEwyLjAxIDZDMi4wMSA0Ljg5IDIuODkgNCA0IDRIMjBDMjEuMTEgNCAyMiA0Ljg5IDIyIDZWMThDMjIgMTkuMTEgMjEuMTEgMjAgMjAgMjBINEMyLjg5IDIwIDIgMTkuMTEgMiAxOFpNMTYuODggMTMuNTFIMTUuNzVWOUgxNC41VjE0QzE0LjUgMTQuNTUgMTQuOTUgMTUgMTUuNSAxNUgxOS41QzIwLjA1IDE1IDIwLjUgMTQuNTUgMjAuNSAxNFY5SDE5LjI1VjEzLjVIMTguMTNWOS45OUgxNi44OFYxMy41MVpNMSAxNy45OTkyQzEgMTkuNjYxNSAyLjMzNzcyIDIxIDQgMjFIMjBDMjEuNjYyMyAyMSAyMyAxOS42NjIzIDIzIDE4VjZDMjMgNC4zMzc3MiAyMS42NjIzIDMgMjAgM0g0QzIuMzMxMzMgMyAxLjAxMDIzIDQuMzQ0NDggMS4wMSA2TTQuNzUgMTEuNUw3LjMgMTVIOC41VjlINy4yNVYxMi41TDQuNzUgOUgzLjVWMTVINC43NVYxMS41Wk0xMSAxMS4zOFYxMC4yNkgxMy41VjlIOS41VjE1SDEzLjVWMTMuNzVIMTFWMTIuNjRIMTMuNVYxMS4zOEgxMVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  updateRed:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPgogICAgPHBhdGggZmlsbD0icmVkIiBkPSJNNDgwLTgwcS04MyAwLTE1Ni0zMS41VDE5Ny0xOTdxLTU0LTU0LTg1LjUtMTI3VDgwLTQ4MHEwLTgzIDMxLjUtMTU2VDE5Ny03NjNxNTQtNTQgMTI3LTg1LjVUNDgwLTg4MHE4MyAwIDE1NiAzMS41VDc2My03NjNxNTQgNTQgODUuNSAxMjdUODgwLTQ4MHEwIDgzLTMxLjUgMTU2VDc2My0xOTdxLTU0IDU0LTEyNyA4NS41VDQ4MC04MFoiLz4KPC9zdmc+',
  edit: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTMgMTcuMjVWMjFoMy43NUwxNy44MSA5Ljk0bC0zLjc1LTMuNzVMMyAxNy4yNXpNMjAuNzEgNy4wNGMuMzktLjM5LjM5LTEuMDIgMC0xLjQxbC0yLjM0LTIuMzRjLS4zOS0uMzktMS4wMi0uMzktMS40MSAwbC0xLjgzIDEuODMgMy43NSAzLjc1IDEuODMtMS44M3oiLz48L3N2Zz4=',
  dragHandle:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCI+PGc+PHJlY3QgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgd2lkdGg9IjI0Ii8+PC9nPjxnPjxnPjxnPjxwYXRoIGQ9Ik0yMCw5SDR2MmgxNlY5eiBNNCwxNWgxNnYtMkg0VjE1eiIvPjwvZz48L2c+PC9nPjwvc3ZnPg==',
};
