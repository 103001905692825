import { DataKiSyokenResponse } from './types';

export const dataKiSyokenSwapColumnsOrder = (
  dataKiSyoken: DataKiSyokenResponse,
  columnsOrder: string[]
): DataKiSyokenResponse => {
  if (columnsOrder.length === 0) {
    return dataKiSyoken;
  }

  const { columns, hallsOfMarketArea, rows } = dataKiSyoken.data;

  // columnsOrderに対応するインデックスを取得
  const columnsIndexes = columnsOrder
    .map((code) => columns.findIndex((col) => col.code === code))
    .filter((index) => index !== -1);

  // 商圏店舗を考慮したカラムをフラットに展開
  const flatColumns = columns.flatMap((column) =>
    column.isSyoken
      ? [
          { code: column.code, type: column.type },
          ...hallsOfMarketArea.map((hall) => ({
            code: `${column.code}${hall.code}`,
            type: column.type,
          })),
        ]
      : { code: column.code, type: column.type }
  );

  // columnsOrderを商圏店舗対応のカラムに変換
  const convertedColumnsOrder = columnsOrder.flatMap((code) => {
    const column = columns.find((col) => col.code === code);
    return column?.isSyoken
      ? [
          column.code,
          ...hallsOfMarketArea.map((hall) => `${column.code}${hall.code}`),
        ]
      : column?.code;
  });

  // 商圏対応カラムに基づき、rowsの順番を変換
  const rowsIndexes = convertedColumnsOrder
    .map((code) => flatColumns.findIndex((col) => col.code === code))
    .filter((index) => index !== -1);

  return {
    ...dataKiSyoken,
    data: {
      ...dataKiSyoken.data,
      columns: columnsIndexes.map((index) => columns[index]),
      rows: rows.map((row) => ({
        ...row,
        data: rowsIndexes.map((index) => row.data[index]),
      })),
    },
  };
};
