import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';
import {
  FetchShortenedUrlOriginalHashParams,
  FetchShortenedUrlOriginalHashResponse,
} from '../../domain/shortenedUrlOriginalHash';

// Action Types

const FETCH_SHORTENED_URL_ORIGINAL_HASH =
  'FETCH_SHORTENED_URL_ORIGINAL_HASH' as const;
const FETCH_SHORTENED_URL_ORIGINAL_HASH_REQUEST =
  'FETCH_SHORTENED_URL_ORIGINAL_HASH_REQUEST' as const;
const FETCH_SHORTENED_URL_ORIGINAL_HASH_SUCCESS =
  'FETCH_SHORTENED_URL_ORIGINAL_HASH_SUCCESS' as const;
const RENEW_SHORTENED_URL_ORIGINAL_HASH =
  'RENEW_SHORTENED_URL_ORIGINAL_HASH' as const;

export const ShortenedUrlOriginalHashActionTypes = {
  FETCH_SHORTENED_URL_ORIGINAL_HASH,
  FETCH_SHORTENED_URL_ORIGINAL_HASH_REQUEST,
  FETCH_SHORTENED_URL_ORIGINAL_HASH_SUCCESS,
  RENEW_SHORTENED_URL_ORIGINAL_HASH,
};

// Action Creators
function fetchShortenedUrlOriginalHashAction(
  params: FetchShortenedUrlOriginalHashParams
) {
  return {
    type: FETCH_SHORTENED_URL_ORIGINAL_HASH,
    payload: { params },
  };
}

function fetchShortenedUrlOriginalHashRequestAction() {
  return {
    type: FETCH_SHORTENED_URL_ORIGINAL_HASH_REQUEST,
  };
}

function fetchShortenedUrlOriginalHashSuccessAction(
  originalHashData: FetchShortenedUrlOriginalHashResponse
) {
  return {
    type: FETCH_SHORTENED_URL_ORIGINAL_HASH_SUCCESS,
    payload: { originalHashData },
  };
}

function renewShortenedUrlOriginalHashAction() {
  return {
    type: RENEW_SHORTENED_URL_ORIGINAL_HASH,
  };
}

export const ShortenedUrlOriginalHashActionCreators = {
  fetchShortenedUrlOriginalHashAction,
  fetchShortenedUrlOriginalHashRequestAction,
  fetchShortenedUrlOriginalHashSuccessAction,
  renewShortenedUrlOriginalHashAction,
};

// Actions

export type FetchShortenedUrlOriginalHashAction = ReturnType<
  typeof fetchShortenedUrlOriginalHashAction
>;

type ShortenedUrlOriginalHashAction =
  | FetchShortenedUrlOriginalHashAction
  | ReturnType<typeof fetchShortenedUrlOriginalHashRequestAction>
  | ReturnType<typeof fetchShortenedUrlOriginalHashSuccessAction>
  | ReturnType<typeof renewShortenedUrlOriginalHashAction>;

// State

type ShortenedUrlOriginalHashState = {
  loadingState: LoadingState;
  error: ClarisApiError | undefined;
  originalHash:
    | FetchShortenedUrlOriginalHashResponse['originalHash']
    | undefined;
};

const initialState: ShortenedUrlOriginalHashState = {
  loadingState: 'prepare',
  error: undefined,
  originalHash: undefined,
};

// Selector

export function originalHashSelector(rootState: {
  originalHash: ShortenedUrlOriginalHashState;
}) {
  return rootState.originalHash.originalHash;
}

// Reducer

export function shortenedUrlOriginalHashReducer(
  state = initialState,
  action: ShortenedUrlOriginalHashAction
): ShortenedUrlOriginalHashState {
  switch (action.type) {
    case FETCH_SHORTENED_URL_ORIGINAL_HASH_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
        error: undefined,
      };

    case FETCH_SHORTENED_URL_ORIGINAL_HASH_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        originalHash: action.payload.originalHashData.originalHash,
      };
    case RENEW_SHORTENED_URL_ORIGINAL_HASH:
      return initialState;
    default:
      return state;
  }
}
