import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsDepreciationResponse } from '../domain/depreciation/types';

import { SettingsOptionsDepreciationActionCreators } from '../redux/server/settingsOptionsDepreciation';
import { SettingsOptionsDepreciationActionTypes } from '../redux/server/settingsOptionsDepreciation';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsDepreciationSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsDepreciationActionCreators.fetchSettingsOptionsDepreciationRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsDepreciationResponse> =
      yield call(api.get, '/settings/options/depreciation', buildConfig());
    const settingsOptionsDepreciation: SettingsOptionsDepreciationResponse =
      response.data;
    yield put(
      SettingsOptionsDepreciationActionCreators.fetchSettingsOptionsDepreciationSuccessAction(
        settingsOptionsDepreciation
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsDepreciationActionCreators.renewSettingsOptionsDepreciationAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsDepreciationSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsDepreciationActionTypes.FETCH_SETTINGS_OPTIONS_DEPRECIATION,
    fetchSettingsOptionsDepreciationSaga,
    api
  );
}

export function* settingsOptionsDepreciationSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsDepreciationSaga, context.api);
}
