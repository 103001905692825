import { createSelector } from 'reselect';

import { generateInitialResponse } from '../../domain/ppmShare/defaultValue';
import { PpmShareReportsFormConditions } from '../../domain/ppmShare/types';
import { Column, DataPpmShare } from '../../domain/ppmShare/types';
import { LoadingState, Option } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';

// Action Types

const FETCH_DATA_PPM_SHARE = 'FETCH_DATA_PPM_SHARE' as const;
const FETCH_DATA_PPM_SHARE_REQUEST = 'FETCH_DATA_PPM_SHARE_REQUEST' as const;
const FETCH_DATA_PPM_SHARE_SUCCESS = 'FETCH_DATA_PPM_SHARE_SUCCESS' as const;
const RENEW_DATA_PPM_SHARE = 'RENEW_DATA_PPM_SHARE' as const;
const SEARCH_DATA_PPM_SHARE_FIELD_TYPE =
  'SEARCH_DATA_PPM_SHARE_FIELD_TYPE' as const;
const SELECT_DATA_PPM_SHARE_COLUMNS_ORDER =
  'SELECT_DATA_PPM_SHARE_COLUMNS_ORDER' as const;
const CHANGE_DATA_PPM_SHARE_COLUMNS_ORDER =
  'CHANGE_DATA_PPM_SHARE_COLUMNS_ORDER' as const;
const RESET_DATA_PPM_SHARE_COLUMNS_ORDER =
  'RESET_DATA_PPM_SHARE_COLUMNS_ORDER' as const;
const SELECT_DATA_PPM_SHARE_MARKING = 'SELECT_DATA_PPM_SHARE_MARKING' as const;

export const DataPpmShareActionTypes = {
  FETCH_DATA_PPM_SHARE,
  FETCH_DATA_PPM_SHARE_REQUEST,
  FETCH_DATA_PPM_SHARE_SUCCESS,
  RENEW_DATA_PPM_SHARE,
  SEARCH_DATA_PPM_SHARE_FIELD_TYPE,
  SELECT_DATA_PPM_SHARE_COLUMNS_ORDER,
  CHANGE_DATA_PPM_SHARE_COLUMNS_ORDER,
  RESET_DATA_PPM_SHARE_COLUMNS_ORDER,
  SELECT_DATA_PPM_SHARE_MARKING,
};

// Action Creators

function fetchDataPpmShareAction(
  params: PpmShareReportsFormConditions,
  currentShu: ShuOption
) {
  return {
    type: FETCH_DATA_PPM_SHARE,
    payload: { params, currentShu },
  };
}

function fetchDataPpmShareRequestAction() {
  return {
    type: FETCH_DATA_PPM_SHARE_REQUEST,
  };
}

function fetchDataPpmShareSuccessAction(dataPpmShare: DataPpmShare) {
  return {
    type: FETCH_DATA_PPM_SHARE_SUCCESS,
    payload: { dataPpmShare },
  };
}

function renewDataPpmShareAction() {
  return {
    type: RENEW_DATA_PPM_SHARE,
  };
}

function searchDataPpmShareFieldAction(shuOption: ShuOption, fields: Option[]) {
  return {
    type: SEARCH_DATA_PPM_SHARE_FIELD_TYPE,
    payload: { shuOption, fields },
  };
}

/**
 * PPMシェアの列の並び替え情報を登録する（Sagaで並び替え確定時）
 */
function selectDataPpmShareColumnsOrderAction(columnsOrder: string[]) {
  return {
    type: SELECT_DATA_PPM_SHARE_COLUMNS_ORDER,
    payload: { columnsOrder },
  };
}

/**
 * PPMシェアの列の並び替え実行時にSagaで再計算する
 */
function changeDataPpmShareColumnsOrderAction(
  draggedId?: string,
  droppedId?: string
) {
  return {
    type: CHANGE_DATA_PPM_SHARE_COLUMNS_ORDER,
    payload: { draggedId, droppedId },
  };
}

/**
 * PPMシェアの列の並び替え情報をリセットする
 */
function resetDataPpmShareColumnsOrderAction() {
  return {
    type: RESET_DATA_PPM_SHARE_COLUMNS_ORDER,
  };
}

export const DataPpmShareActionCreators = {
  fetchDataPpmShareAction,
  fetchDataPpmShareRequestAction,
  fetchDataPpmShareSuccessAction,
  renewDataPpmShareAction,
  selectDataPpmShareColumnsOrderAction,
  changeDataPpmShareColumnsOrderAction,
  resetDataPpmShareColumnsOrderAction,
  searchDataPpmShareFieldAction,
};

// Actions

export type FetchDataPpmShareAction = ReturnType<
  typeof fetchDataPpmShareAction
>;
export type ChangeDataPpmShareColumnsOrderAction = ReturnType<
  typeof changeDataPpmShareColumnsOrderAction
>;
export type SearchDataPpmShareFieldAction = ReturnType<
  typeof searchDataPpmShareFieldAction
>;

type DataPpmShareAction =
  | FetchDataPpmShareAction
  | ReturnType<typeof fetchDataPpmShareRequestAction>
  | ReturnType<typeof fetchDataPpmShareSuccessAction>
  | ReturnType<typeof renewDataPpmShareAction>
  | ReturnType<typeof resetDataPpmShareColumnsOrderAction>
  | ReturnType<typeof selectDataPpmShareColumnsOrderAction>
  | ChangeDataPpmShareColumnsOrderAction
  | SearchDataPpmShareFieldAction;

// State

type DataPpmShareState = {
  dataPpmShare: DataPpmShare;
  loadingState: LoadingState;
  columnsOrder?: string[];
};

const initialState: DataPpmShareState = {
  dataPpmShare: generateInitialResponse(),
  loadingState: 'prepare',
  columnsOrder: [],
};

// Selector

/**
 * PPMシェアのテーブルデータすべて取得する
 * @returns テーブルデータ
 */
const dataPpmShareSelector = (state: RootState) => {
  return state.dataPpmShare.dataPpmShare;
};

/**
 * PPMシェアのテーブルの現在の検索条件を取得する
 * @returns 検索条件
 */
export const dataPpmShareParamsSelector = createSelector(
  dataPpmShareSelector,
  (dataPpmShare) => dataPpmShare.setting
);
/**
 * PPMシェアのテーブルの現在の検索条件のフィールドを取得する
 * @returns フィールド
 */
export const dataPpmShareSettingFieldsSelector = createSelector(
  dataPpmShareParamsSelector,
  (setting) => setting.fields || []
);

/**
 * PPMシェアのテーブルの現在のマーキングを取得する
 * @returns {string} マーキング種類名
 */
export const dataPpmShareTableMarkingSelector = createSelector(
  dataPpmShareParamsSelector,
  (setting) => setting.marking || ''
);

/**
 * PPMシェアのテーブルの現在のソート列と降順・昇順
 * @returns  {sortOrder: OrderType, sortColumn: string}
 */
export const dataPpmShareTableSortSettingSelector = createSelector(
  dataPpmShareParamsSelector,
  (setting) => ({ sortOrder: setting.order, sortColumn: setting.sort })
);

/**
 * PPMシェアのテーブルのデータ部分のみ取得
 * @returns テーブルデータ（検索条件除いた部分）
 */
export const dataPpmShareDataSelector = createSelector(
  dataPpmShareSelector,
  (dataPpmShare) => {
    return dataPpmShare.data;
  }
);

/**
 * PPMシェアのテーブルのカラムのみ取得
 * @returns テーブルデータのカラム
 */
export const dataPpmShareDataColumnsSelector = createSelector(
  dataPpmShareDataSelector,
  (data) => {
    return data?.columns;
  }
);

/**
 * PPMシェアのテーブル列並び替え情報を取得
 * @returns 並び替え情報
 */
export const dataPpmShareColumnsOrderSelector = (state: RootState) =>
  state.dataPpmShare.columnsOrder;

/**
 * PPMシェアのテーブル列並べ替えで必要になるカラム数を計算する
 * @returns {{fixedOrderColumnsCount: number, fixedOrderRowDataColumnsCount:number}}
 */
const dataPpmShareCalcColumnsCount = createSelector(
  dataPpmShareDataSelector,
  (data) => {
    if (!data) {
      return {
        fixedOrderColumnsCount: 0,
        fixedOrderRowDataColumnsCount: 0,
      };
    }

    const { columns } = data;

    // * 先頭から4項目(店舗名、種別、日数、台数)と、カラムが入れ子になっている列は固定
    // ヘッダの位置固定の列数
    const fixedOrderColumnsCount: number = columns.filter(
      (x, index) => index < 4 || x.columns
    ).length;

    //  行データの位置固定の列数
    const fixedOrderRowDataColumnsCount: number = columns
      .filter((x, index) => index < 4 || x.columns)
      .map((x) => (x.columns ? x.columns.length : 1))
      .reduce((accum, curr) => accum + curr, 0);

    return {
      fixedOrderColumnsCount,
      fixedOrderRowDataColumnsCount,
    };
  }
);

/**
 * PPMシェアのテーブル列並び替え後のテーブルデータを取得する
 * @returns 並び替え後のテーブルデータ
 */
export const dataPpmShareOrderedDataSelector = createSelector(
  [
    dataPpmShareDataSelector,
    dataPpmShareColumnsOrderSelector,
    dataPpmShareCalcColumnsCount,
  ],
  (
    data,
    ordered,
    { fixedOrderColumnsCount, fixedOrderRowDataColumnsCount }
  ) => {
    if (!data) {
      return undefined;
    }

    if (!ordered) {
      return data;
    }

    const { rows, columns } = data;

    // それ以外の列を並べ替える
    const columnsToChangeOrder: Column[] = columns.filter(
      (x, index) => index > 4 && !x.columns
    );

    // ヘッダの並べ替え対象列の新しい並び順
    const newColumnOrderIndicesForHeader: number[] = ordered.reduce<number[]>(
      (acc, curr) => {
        const index = columnsToChangeOrder.findIndex((x) => x.code === curr);
        if (index > -1) {
          return [...acc, index + fixedOrderColumnsCount];
        }
        return acc;
      },
      []
    );

    // 行データの並べ替え対象列の新しい並び順
    const newColumnOrderIndicesForRowData: number[] = ordered.reduce<number[]>(
      (acc, curr) => {
        const index = columnsToChangeOrder.findIndex((x) => x.code === curr);
        if (index > -1) {
          return [...acc, index + fixedOrderRowDataColumnsCount];
        }
        return acc;
      },
      []
    );

    return {
      ...data,
      columns: [
        ...columns.slice(0, fixedOrderColumnsCount),
        ...newColumnOrderIndicesForHeader.map((i) => columns[i]),
      ],
      rows: rows.map((x) => ({
        ...x,
        data: [
          ...x.data.slice(0, fixedOrderRowDataColumnsCount),
          ...newColumnOrderIndicesForRowData.map((i) => x.data[i]),
        ],
      })),
    };
  }
);

/**
 * 現在のローディング状態のみ取得
 * @returns ローディング状態（ローディング時true）
 */
export const dataPpmShareLoadingStateSelector = (state: RootState) => {
  return state.dataPpmShare.loadingState;
};

// Reducer

export function dataPpmShareReducer(
  state = initialState,
  action: DataPpmShareAction
): DataPpmShareState {
  switch (action.type) {
    case FETCH_DATA_PPM_SHARE_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_PPM_SHARE_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataPpmShare: action.payload.dataPpmShare,
      };
    case SELECT_DATA_PPM_SHARE_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
      };
    case RESET_DATA_PPM_SHARE_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: initialState.columnsOrder,
      };
    case RENEW_DATA_PPM_SHARE:
      return initialState;
    default:
      return state;
  }
}
