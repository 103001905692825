import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsSis } from '../../domain/sis/types';

import { RootState } from '../../store';

// Action Types

const FETCH_SETTINGS_OPTIONS_SIS = 'FETCH_SETTINGS_OPTIONS_SIS' as const;
const FETCH_SETTINGS_OPTIONS_SIS_REQUEST =
  'FETCH_SETTINGS_OPTIONS_SIS_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_SIS_SUCCESS =
  'FETCH_SETTINGS_OPTIONS_SIS_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_SIS = 'RENEW_SETTINGS_OPTIONS_SIS' as const;
const CLEAR_SETTINGS_OPTIONS_SIS = 'CLEAR_SETTINGS_OPTIONS_SIS' as const;

export const SettingsOptionsSisActionTypes = {
  FETCH_SETTINGS_OPTIONS_SIS,
  FETCH_SETTINGS_OPTIONS_SIS_REQUEST,
  FETCH_SETTINGS_OPTIONS_SIS_SUCCESS,
  RENEW_SETTINGS_OPTIONS_SIS,
  CLEAR_SETTINGS_OPTIONS_SIS,
};

// Action Creators

function fetchSettingsOptionsSisAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_SIS,
  };
}

function fetchSettingsOptionsSisRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_SIS_REQUEST,
  };
}

function fetchSettingsOptionsSisSuccessAction(
  settingsOptionsSis: SettingsOptionsSis
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_SIS_SUCCESS,
    payload: { settingsOptionsSis },
  };
}

function renewSettingsOptionsSisAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_SIS,
  };
}

function clearSettingsOptionsSisAction() {
  return {
    type: CLEAR_SETTINGS_OPTIONS_SIS,
  };
}

export const SettingsOptionsSisActionCreators = {
  fetchSettingsOptionsSisAction,
  fetchSettingsOptionsSisRequestAction,
  fetchSettingsOptionsSisSuccessAction,
  renewSettingsOptionsSisAction,
  clearSettingsOptionsSisAction,
};

// Actions

type FetchSettingsOptionsSisAction = ReturnType<
  typeof fetchSettingsOptionsSisAction
>;
type FetchSettingsOptionsSisRequestAction = ReturnType<
  typeof fetchSettingsOptionsSisRequestAction
>;
type FetchSettingsOptionsSisSuccessAction = ReturnType<
  typeof fetchSettingsOptionsSisSuccessAction
>;
type RenewSettingsOptionsSisAction = ReturnType<
  typeof renewSettingsOptionsSisAction
>;
type ClearSettingsOptionsSisAction = ReturnType<
  typeof clearSettingsOptionsSisAction
>;

type SettingsOptionsSisAction =
  | FetchSettingsOptionsSisAction
  | FetchSettingsOptionsSisRequestAction
  | FetchSettingsOptionsSisSuccessAction
  | RenewSettingsOptionsSisAction
  | ClearSettingsOptionsSisAction;

// State

type SettingsOptionsSisState = {
  loadingState: LoadingState;
  settingsOptionsSis: SettingsOptionsSis | undefined;
};

const initialState: SettingsOptionsSisState = {
  loadingState: 'prepare',
  settingsOptionsSis: undefined,
};

// Selector

/**
 * settingOptionsの取得
 */
export function settingsOptionsSisSelector(state: RootState) {
  return state.settingsOptionsSis.settingsOptionsSis;
}

/**
 * 現在のローディング状況
 */
export const settingsOptionsSisLoadingStateSelector = (state: RootState) => {
  return state.settingsOptionsSis.loadingState;
};

/**
 * searchConditionだけ取得
 */
export const settingsOptionsSisSearchConditionSelector = createSelector(
  settingsOptionsSisSelector,
  (settingsOptionsSis) => {
    return settingsOptionsSis?.searchCondition;
  }
);

/**
 * kiのFieldsだけ取得
 */
export const settingsOptionsSisFieldsSelector = createSelector(
  settingsOptionsSisSelector,
  (settingsOptionsSis) => {
    return settingsOptionsSis?.fields?.ki;
  }
);

/**
 * sisDataTypesだけ取得
 */
export const settingsOptionsSisSisDataTypeSelector = createSelector(
  settingsOptionsSisSelector,
  (settingsOptionsSis) => {
    return settingsOptionsSis?.searchCondition.sisDataTypes;
  }
);

/**
 * P-Sensorの機種コード取得
 */
export const settingsOptionsSisKiCodeToPSensorSelector = createSelector(
  settingsOptionsSisSelector,
  (settingsOptionsSis) => settingsOptionsSis?.kiCodeToPSensorKiCode
);

/**
 * fieldsのisSelectableForGraphのみ取得
 */
export const settingsOptionsSisGraphFieldsSelector = createSelector(
  settingsOptionsSisFieldsSelector,
  (fields) => {
    return fields?.filter((field) => field.isSelectableForGraph);
  }
);

/**
 * searchConditionのkiTagListだけ取得
 */
export const settingsOptionsSisKiTagListSelector = createSelector(
  settingsOptionsSisSelector,
  (settingsOptionsSis) => {
    return settingsOptionsSis?.searchCondition.kiTagList;
  }
);

// Reducer

export function settingsOptionsSisReducer(
  state = initialState,
  action: SettingsOptionsSisAction
): SettingsOptionsSisState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_SIS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_SIS_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsSis: action.payload.settingsOptionsSis,
      };
    case RENEW_SETTINGS_OPTIONS_SIS:
      return initialState;
    case CLEAR_SETTINGS_OPTIONS_SIS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
