import { add } from 'date-fns';

import { UnitDataListReportsDateRange } from './unitDataListDateRange';

/**
 * dateRangeが何月何日かの日付を計算する
 */
export const calcUnitDataCurrentDate = (
  dateRange: UnitDataListReportsDateRange,
  currentDate = new Date()
) => {
  switch (dateRange) {
    case '前日':
      return add(currentDate, { days: -1 });
    case '前週':
      return add(currentDate, { weeks: -1 });
    case '前月':
      return add(currentDate, { months: -1 });
    case '当日':
    case '今週':
    case '今月':
    case '過去7日':
    case '過去14日':
    case '過去28日':
    case 'カスタム':
      return currentDate;
  }
};
