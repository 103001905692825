import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { DataKiSyokenKasidamaResponse } from '../domain/kiSyoken/types';

import {
  DataKiSyokenKasidamaActionCreators,
  DataKiSyokenKasidamaActionTypes,
  FetchDataKiSyokenKasidamaAction,
} from '../redux/server/dataKiSyokenKasidama';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * dataKiSyokenKasidamaのデータを取得する
 */
function* fetchDataKiSyokenKasidamaSaga(
  api: Api,
  action: FetchDataKiSyokenKasidamaAction
) {
  try {
    yield put(
      DataKiSyokenKasidamaActionCreators.fetchDataKiSyokenKasidamaRequestAction()
    );
    const response: AxiosResponse<DataKiSyokenKasidamaResponse> = yield call(
      api.get,
      '/data/kiSyoken/kasidama',
      buildConfig(action.payload.params)
    );

    const dataKiSyokenKasidama = response.data;

    yield put(
      DataKiSyokenKasidamaActionCreators.fetchDataKiSyokenKasidamaSuccessAction(
        dataKiSyokenKasidama
      )
    );
  } catch (error: unknown) {
    yield put(
      DataKiSyokenKasidamaActionCreators.renewDataKiSyokenKasidamaAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataKiSyokenKasidamaSaga(api: Api) {
  yield takeEvery(
    DataKiSyokenKasidamaActionTypes.FETCH_DATA_KISYOKEN_KASIDAMA,
    fetchDataKiSyokenKasidamaSaga,
    api
  );
}

export function* dataKiSyokenKasidamaSaga(context: { api: Api }) {
  yield fork(handleFetchDataKiSyokenKasidamaSaga, context.api);
}
