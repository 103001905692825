import { createSelector } from 'reselect';

import { GRAPH_FIELD_TYPE } from '../../domain/dataKiGraph';
import {
  DataModelTransitionGraph,
  DataModelTransitionGraphParams,
} from '../../domain/dataModelTransitionGraph';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';
import { ChangeModelTransitionReportsCurrentHallsAction } from '../ui/modelTransitionReportsSetting';

// Action Types

const FETCH_DATA_MODEL_TRANSITION_GRAPH =
  'FETCH_DATA_MODEL_TRANSITION_GRAPH' as const;
const FETCH_DATA_MODEL_TRANSITION_GRAPH_REQUEST =
  'FETCH_DATA_MODEL_TRANSITION_GRAPH_REQUEST' as const;
const FETCH_DATA_MODEL_TRANSITION_GRAPH_SUCCESS =
  'FETCH_DATA_MODEL_TRANSITION_GRAPH_SUCCESS' as const;
const SEARCH_DATA_MODEL_TRANSITION_GRAPH =
  'SEARCH_DATA_MODEL_TRANSITION_GRAPH' as const;
const CHANGE_DATA_MODEL_TRANSITION_GRAPH_KI =
  'CHANGE_DATA_MODEL_TRANSITION_GRAPH_KI' as const;
const CHANGE_DATA_MODEL_TRANSITION_GRAPH_FIELD =
  'CHANGE_DATA_MODEL_TRANSITION_GRAPH_FIELD' as const;
const RENEW_DATA_MODEL_TRANSITION_GRAPH =
  'RENEW_DATA_MODEL_TRANSITION_GRAPH' as const;

export const DataModelTransitionGraphActionTypes = {
  FETCH_DATA_MODEL_TRANSITION_GRAPH,
  FETCH_DATA_MODEL_TRANSITION_GRAPH_REQUEST,
  FETCH_DATA_MODEL_TRANSITION_GRAPH_SUCCESS,
  SEARCH_DATA_MODEL_TRANSITION_GRAPH,
  CHANGE_DATA_MODEL_TRANSITION_GRAPH_KI,
  CHANGE_DATA_MODEL_TRANSITION_GRAPH_FIELD,
  RENEW_DATA_MODEL_TRANSITION_GRAPH,
};
// Action Creators

/**
 * 推移グラフ（機種比較）データを取得する
 * @param params 推移グラフ（機種比較）の検索条件
 */
function fetchDataModelTransitionGraphAction(
  params: DataModelTransitionGraphParams
) {
  return {
    type: FETCH_DATA_MODEL_TRANSITION_GRAPH,
    payload: { params },
  };
}

/**
 * 推移グラフ（機種比較）データ取得前に実行する
 */
function fetchDataModelTransitionGraphRequestAction() {
  return {
    type: FETCH_DATA_MODEL_TRANSITION_GRAPH_REQUEST,
  };
}

/**
 * 推移グラフ（機種比較）データ取得完了時データを登録する
 * @param graph 取得したグラフデータ
 */
function fetchDataModelTransitionGraphSuccessAction(
  graph: DataModelTransitionGraph
) {
  return {
    type: FETCH_DATA_MODEL_TRANSITION_GRAPH_SUCCESS,
    payload: { graph },
  };
}

/**
 * 選択した機種の推移グラフする
 * @param kiList 表示する機種コード一覧
 */
function searchDataModelTransitionGraphAction(kiList: string[]) {
  return {
    type: SEARCH_DATA_MODEL_TRANSITION_GRAPH,
    payload: { kiList },
  };
}

/**
 * 指定した機種コードでグラフを絞り込む（機種選択フォーム用）
 * @param kiList 絞り込む機種コード一覧
 */
function changeDataModelTransitionGraphKiAction(kiList: string[]) {
  return {
    type: CHANGE_DATA_MODEL_TRANSITION_GRAPH_KI,
    payload: { kiList },
  };
}

/**
 * 指定したグラフ項目で推移グラフ（機種比較）データを再取得
 * @param field 変更後のグラフ項目
 */
function changeDataModelTransitionGraphFieldAction(field: string) {
  return {
    type: CHANGE_DATA_MODEL_TRANSITION_GRAPH_FIELD,
    payload: { field },
  };
}

/**
 * 導入後推移 推移グラフ（機種比較）のデータを破棄する
 */
function renewDataModelTransitionGraphAction() {
  return {
    type: RENEW_DATA_MODEL_TRANSITION_GRAPH,
  };
}

export const DataModelTransitionGraphActionCreators = {
  fetchDataModelTransitionGraphAction,
  fetchDataModelTransitionGraphRequestAction,
  fetchDataModelTransitionGraphSuccessAction,
  searchDataModelTransitionGraphAction,
  changeDataModelTransitionGraphKiAction,
  changeDataModelTransitionGraphFieldAction,
  renewDataModelTransitionGraphAction,
};

// Actions

export type FetchDataModelTransitionGraphAction = ReturnType<
  typeof fetchDataModelTransitionGraphAction
>;
export type SearchDataModelTransitionGraphAction = ReturnType<
  typeof searchDataModelTransitionGraphAction
>;
export type ChangeDataModelTransitionGraphFieldAction = ReturnType<
  typeof changeDataModelTransitionGraphFieldAction
>;

type DataModelTransitionGraphAction =
  | FetchDataModelTransitionGraphAction
  | SearchDataModelTransitionGraphAction
  | ChangeDataModelTransitionGraphFieldAction
  | ChangeModelTransitionReportsCurrentHallsAction
  | ReturnType<typeof changeDataModelTransitionGraphKiAction>
  | ReturnType<typeof fetchDataModelTransitionGraphRequestAction>
  | ReturnType<typeof fetchDataModelTransitionGraphSuccessAction>
  | ReturnType<typeof renewDataModelTransitionGraphAction>;

// State

type DataModelTransitionGraphState = {
  /**
   * 選択中の機種（機種選択フォームで絞り込んだ機種）
   */
  selectKi: string[];
  /**
   * グラフデータ
   */
  dataModelTransitionGraph: DataModelTransitionGraph | undefined;
  /**
   * ローディング状態
   */
  loadingState: LoadingState;
};

const initialState: DataModelTransitionGraphState = {
  selectKi: [],
  dataModelTransitionGraph: undefined,
  loadingState: 'prepare',
};

// Selector

/**
 * グラフデータを取得する
 * @returns グラフデータ
 */
export const dataModelTransitionGraphSelector = (state: RootState) =>
  state.dataModelTransitionGraph.dataModelTransitionGraph;

/**
 * グラフの検索条件を取得する
 * @returns グラフ検索条件
 */
export const dataModelTransitionGraphSettingSelector = createSelector(
  dataModelTransitionGraphSelector,
  (data) => data?.setting
);

/**
 * 選択中のグラフ項目を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns グラフ項目
 */
export const dataModelTransitionGraphSelectedFieldSelector = createSelector(
  dataModelTransitionGraphSettingSelector,
  (data) => {
    return data?.field ?? GRAPH_FIELD_TYPE.OUT;
  }
);

/**
 * グラフデータのロード状態を取得する
 * @returns グラフデータ
 */
export const dataModelTransitionGraphSettingLoadingStateSelector = (
  state: RootState
) => state.dataModelTransitionGraph.loadingState;

/**
 * 絞り込んでいる機種一覧を取得する
 * @returns 機種一覧
 */
export const dataModelTransitionGraphSelectedKiListSelector = (
  state: RootState
) => state.dataModelTransitionGraph.selectKi;

// Reducer
export function dataModelTransitionGraphReducer(
  state = initialState,
  action: DataModelTransitionGraphAction
): DataModelTransitionGraphState {
  switch (action.type) {
    case FETCH_DATA_MODEL_TRANSITION_GRAPH_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_MODEL_TRANSITION_GRAPH_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataModelTransitionGraph: action.payload.graph,
      };
    case SEARCH_DATA_MODEL_TRANSITION_GRAPH:
      return {
        ...state,
        selectKi: action.payload.kiList,
      };
    case CHANGE_DATA_MODEL_TRANSITION_GRAPH_KI:
      return {
        ...state,
        selectKi: action.payload.kiList,
      };
    case RENEW_DATA_MODEL_TRANSITION_GRAPH:
      return initialState;
    default:
      return state;
  }
}
