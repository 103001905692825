import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import {
  makeKiSyokenDateFromDateRange,
  makeKiSyokenDateFromDateRangeWithoutCustom,
} from '../domain/kiSyoken/date';
import {
  defaultDateRangeParams,
  defaultShu,
} from '../domain/kiSyoken/defaultValue';
import {
  DataKiSyokenResponse,
  KiSyokenDateRangeParams,
  KiSyokenSearchParams,
  SettingsOptionsKiSyokenResponse,
} from '../domain/kiSyoken/types';
// import { FavoriteItem } from '../domain/favorites';
import { LoadingState } from '../domain/schemas';
import { ShuOption } from '../domain/shu';
import { User } from '../domain/user';

import {
  DataKiSyokenActionCreators,
  DataKiSyokenActionTypes,
  dataColumnsSwappedSelector,
  dataKiSyokenDataSelector,
  dataKiSyokenLoadingStateSelector,
} from '../redux/server/dataKiSyoken';
import {
  SettingsOptionsKiSyokenActionTypes,
  settingsOptionsKiSyokenFieldsSelector,
  settingsOptionsKiSyokenLoadingStateSelector,
  settingsOptionsKiSyokenSearchConditionSelector,
} from '../redux/server/settingsOptionsKiSyoken';
// import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import {
  ChangeKiSyokenFieldsAction,
  KiSyokenSettingActionCreators,
  KiSyokenSettingActionTypes,
  SearchKiSyokenSettingsAction,
  TriggerKiSyokenSwapFieldsAction,
  kiSyokenColumnsOrderSelector,
  kiSyokenCurrentShuSelector,
  kiSyokenSearchParamsSelector,
  kiSyokenSelectedDateRangeParamsSelector,
} from '../redux/ui/kiSyokenSettings';
import { UserActionTypes, userSelector } from '../redux/ui/user';
import { customSelect } from '../utils/customSelect';
import { recalcColumnOrder } from '../utils/orderedCell';
import {
  convertShuGroupToShuOption,
  selectShu2SearchCondition,
} from '../utils/shu';

/**
 * テーブルデータfetch用のラッパー関数。fetchしかつsearchParamsに値を設定する
 */
function* fetchDataKiSyokenSaga(searchParams: KiSyokenSearchParams) {
  yield put(DataKiSyokenActionCreators.fetchDataKiSyokenAction(searchParams));

  yield take(DataKiSyokenActionTypes.FETCH_DATA_KISYOKEN_SUCCESS);
  const dataKiSyoken: DataKiSyokenResponse = yield select(
    dataKiSyokenDataSelector
  );
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenSearchParamsAction(
      dataKiSyoken.setting
    )
  );
}

/**
 * 初回データ取得
 */
function* initFetchKiSyokenDataSaga() {
  const loadingStateKiSyoken: LoadingState = yield select(
    dataKiSyokenLoadingStateSelector
  );

  if (loadingStateKiSyoken !== 'prepare') {
    return;
  }

  // NOTE: 初期選択の店舗コードは、ユーザー情報または検索条件から取得する
  const user: User | undefined = yield select(userSelector);

  // ユーザがセットされていない場合は、初回を待機する
  if (!user?.userId) {
    yield take(UserActionTypes.SET_USER);
  }
  const { hallCodeInCharge }: User = yield select(userSelector);

  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsKiSyokenLoadingStateSelector
  );

  // 検索条件が取得されるまで待機する
  if (
    settingsOptionsLoadingState === 'prepare' ||
    settingsOptionsLoadingState === 'loading'
  ) {
    yield take(
      SettingsOptionsKiSyokenActionTypes.FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS
    );
  }

  const { startDate, startComparisonDate, endDate, endComparisonDate } =
    makeKiSyokenDateFromDateRangeWithoutCustom({
      dateRangeParams: defaultDateRangeParams,
    });

  const searchCondition: SettingsOptionsKiSyokenResponse['searchCondition'] =
    yield select(settingsOptionsKiSyokenSearchConditionSelector);

  const defaultHallCode =
    hallCodeInCharge && hallCodeInCharge.length > 0
      ? hallCodeInCharge
      : searchCondition.halls.at(0)?.code ?? '';

  const defaultShuGroupList = searchCondition.shuGroupList.at(0) ?? undefined;

  // 初期選択の店舗・期間・種別でデータを取得する
  yield fork(fetchDataKiSyokenSaga, {
    halls: [defaultHallCode],
    startDate: startDate,
    endDate: endDate,
    startComparisonDate: startComparisonDate,
    endComparisonDate: endComparisonDate,
    shuGroupIds: defaultShuGroupList?.id ?? undefined,
  } as KiSyokenSearchParams);

  // 店舗セレクターに初期値を設定
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenCurrentHallsAction([
      defaultHallCode,
    ])
  );
  // 商圏店舗セレクターに初期値を設定
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenMksHallCodesAction([
      'mksAll',
      ...(searchCondition.halls
        .find((h) => h.code === defaultHallCode)
        ?.hallsOfMarketArea?.map((h) => h.code) ?? []),
    ])
  );
  // 種別セレクターに初期値を設定
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenCurrentShuAction(
      defaultShuGroupList
        ? convertShuGroupToShuOption(defaultShuGroupList)
        : defaultShu
    )
  );
}

function* triggerKiSyokenSwapFieldsSaga(
  action: TriggerKiSyokenSwapFieldsAction
) {
  const { draggedId, droppedId } = action.payload;

  const KiSyokenData: DataKiSyokenResponse | undefined = yield select(
    dataKiSyokenDataSelector
  );
  if (KiSyokenData == null) {
    return;
  }

  const currentOrder: string[] = yield select(kiSyokenColumnsOrderSelector);

  const ordered = recalcColumnOrder(
    currentOrder.length === 0
      ? KiSyokenData.data.columns
      : currentOrder.map((code) => ({ code })),
    draggedId,
    droppedId
  );

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenColumnsOrderAction(ordered)
  );
}

/**
 * 検索用のクエリの日付を生成する、平日/土日祝のクエリの場合にはデータがAPIへリクエストする
 */
function makeSearchParams(
  dateRangeParams: KiSyokenDateRangeParams,
  searchParams: KiSyokenSearchParams
) {
  const {
    // 日付関連パラメータはdateRangeParamsで追加されるためすべて除外する
    startDate,
    endDate,
    startComparisonDate: _0,
    endComparisonDate: _1,
    ymdList,
    ymdComparisonList,
    ...params
  } = searchParams;

  return {
    ...params,
    ...(dateRangeParams.dateUnit === '自由選択'
      ? { ymdList, ymdComparisonList }
      : makeKiSyokenDateFromDateRange({
          dateRangeParams,
          searchParams,
        })),
  };
}

/**
 * 検索ボタンクリック
 */
function* searchActionSaga(action: SearchKiSyokenSettingsAction) {
  const { params, dateRangeParams, selectedKiList } = action.payload;

  // 選択中の種別・種別グループを取得する
  const currentShu = yield* customSelect(kiSyokenCurrentShuSelector);

  const searchParams: KiSyokenSearchParams = yield call(
    makeSearchParams,
    dateRangeParams,
    {
      kiList: selectedKiList,
      kiListForSijiritu: selectedKiList,
      makersForSijiritu: params.makers,
      sisTypesForSijiritu: params.sisTypes,
      ...params,
    }
  );

  // 期間をStoreに格納する
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenDateRangeParamsAction(
      dateRangeParams
    )
  );
  // 検索
  yield fork(fetchDataKiSyokenSaga, {
    ...searchParams,
    ...selectShu2SearchCondition(currentShu),
  });
}

function* resetSearchActionSaga() {
  // お気に入りを実装する際にお気に入りを考慮する必要がある
  const favoriteId = undefined;

  if (favoriteId == null) {
    yield put(DataKiSyokenActionCreators.renewDataKiSyokenAction());
    yield put(KiSyokenSettingActionCreators.renewKiSyokenSettingAction());
    yield fork(initFetchKiSyokenDataSaga);
    return;
  }

  // yield put(
  //   DataKiSyokenActionCreators.changeKiSyokenFavoriteAction(favoriteId)
  // );
}

/**
 * 変更後の店舗でデータを取得する
 */
function* changeCurrentHallsSaga(
  action: ReturnType<
    typeof KiSyokenSettingActionCreators.changeKiSyokenCurrentHallsAction
  >
) {
  // 店舗が選択されていない場合は処理を中断
  if (action.payload.halls === undefined) return;

  // 現在の検索条件を取得
  const searchParams: KiSyokenSearchParams = yield select(
    kiSyokenSearchParamsSelector
  );

  // 現在の種別を取得
  const currentShu: ShuOption = yield select(kiSyokenCurrentShuSelector);

  // 変更後の店舗でデータを取得する
  yield fork(fetchDataKiSyokenSaga, {
    ...searchParams,
    ...selectShu2SearchCondition(currentShu),
    halls: action.payload.halls,
  });
}

/**
 * 変更後の種別でデータを取得する
 */
function* changeCurrentShuSaga(
  action: ReturnType<
    typeof KiSyokenSettingActionCreators.changeKiSyokenCurrentShuAction
  >
) {
  // 種別が選択されていない場合は処理を中断
  if (action.payload.shu === undefined) return;

  // 現在の検索条件を取得
  const searchParams: KiSyokenSearchParams = yield select(
    kiSyokenSearchParamsSelector
  );

  // 変更後の種別でデータを取得する
  yield fork(fetchDataKiSyokenSaga, {
    ...searchParams,
    ...selectShu2SearchCondition(action.payload.shu),
  });
}

/**
 * 表示項目変更時のリクエスト
 */
function* changeKiSyokenFieldsSaga(action: ChangeKiSyokenFieldsAction) {
  const { notSyokenFields, isSyokenFields } = action.payload;

  const searchParams: KiSyokenSearchParams = yield select(
    kiSyokenSearchParamsSelector
  );

  // notSyokenFieldsとisSyokenFieldsを一つのオブジェクトにまとめる
  const combinedFields: string[] = [...notSyokenFields, ...isSyokenFields];

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenFieldsAction(combinedFields)
  );

  yield fork(fetchDataKiSyokenSaga, {
    ...searchParams,
    fields: combinedFields,
  });

  // 以下：新規追加された表示項目は一律前に追加処理
  const dataSyoken: DataKiSyokenResponse = yield select(
    dataColumnsSwappedSelector
  );
  const allFields: SettingsOptionsKiSyokenResponse['fields'] | undefined =
    yield select(settingsOptionsKiSyokenFieldsSelector);
  if (dataSyoken == null) {
    throw new Error('dataSyoken is not found');
  }
  if (allFields == null) {
    throw new Error('settingsOptions is not found');
  }

  const columnsOrder = dataSyoken.data.columns
    .map((column) => column.code)
    .filter((code) => code != null) as string[];
  const difference =
    combinedFields?.filter((field) => !columnsOrder.includes(field)) ?? [];

  const desiredOrder = allFields.ki.map(({ code }) => code);
  const sortedDifference = [...difference].sort((a, b) => {
    if (desiredOrder == null) {
      return 0;
    }
    return desiredOrder.indexOf(a) < desiredOrder.indexOf(b) ? -1 : 1;
  });

  const [kiTushoMei, ...rest] = columnsOrder;
  //fieldsが[]の時にデフォルトの表示項目が表示されるよう、空の配列を入れる
  const resultOrder = combinedFields.length
    ? [kiTushoMei, ...sortedDifference, ...rest]
    : [];

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenColumnsOrderAction(resultOrder)
  );
}

// 期間スライドコンポーネントがクリックされた時に検索期間を設定して再検索を実行する
function* searchDateRangeSlideSaga(
  action: ReturnType<
    typeof KiSyokenSettingActionCreators.searchKiSyokenDateRangeSlideAction
  >
) {
  const {
    dateRange,
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
  } = action.payload;
  // 現在の検索条件を取得
  const searchParams: KiSyokenSearchParams = yield select(
    kiSyokenSearchParamsSelector
  );

  // 現在の期間を取得
  const selectedDateRangeParams: KiSyokenDateRangeParams = yield select(
    kiSyokenSelectedDateRangeParamsSelector
  );

  // 期間をStoreに格納する
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenDateRangeParamsAction({
      ...selectedDateRangeParams,
      dateRange,
    })
  );
  // ローディング中の期間表示の整合性を保つために、フェッチ前に期間をStoreに格納する
  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenSearchParamsAction({
      ...searchParams,
      startDate,
      endDate,
      startComparisonDate,
      endComparisonDate,
    })
  );

  yield fork(fetchDataKiSyokenSaga, {
    ...searchParams,
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
  });
}

// 検索ボタンクリック時にデータを取得する
function* handleSearchSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.SEARCH_KI_SYOKEN_SETTINGS,
    searchActionSaga
  );
}

/**
 * 初回表示時のデータ取得
 */
function* handleInitFetchSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.INIT_KISYOKEN_SETTING,
    initFetchKiSyokenDataSaga
  );
}
/**
 * お気に入り選択中または未選択時のデフォルト値にする
 */
function* handleSearchResetSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.SEARCH_RESET_KI_SYOKEN_SEARCH_PARAMS,
    resetSearchActionSaga
  );
}

/**
 * 表示項目変更時のデータ取得
 */
function* handleChangeFieldSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_FIELDS,
    changeKiSyokenFieldsSaga
  );
}

/**
 * 表示項目並び順の変更
 */
function* handleFieldOrderSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.TRIGGER_KISYOKEN_SWAP_FIELDS,
    triggerKiSyokenSwapFieldsSaga
  );
}

/**
 * 選択中の店舗変更時、データを取得する
 */
function* handleChangeCurrentHallsSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_CURRENT_HALLS,
    changeCurrentHallsSaga
  );
}

/**
 * 選択中の種別変更時、データを取得する
 */
function* handleChangeCurrentShuSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_CURRENT_SHU,
    changeCurrentShuSaga
  );
}

/**
 * 期間スライドコンポーネントがクリックされた時に実行する処理
 */
function* handleDateRangeSlideSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.SEARCH_KISYOKEN_DATE_RANGE_SLIDE,
    searchDateRangeSlideSaga
  );
}

export function* kiSyokenSettingSaga() {
  yield fork(handleInitFetchSaga);
  yield fork(handleChangeFieldSaga);
  yield fork(handleFieldOrderSaga);
  yield fork(handleChangeCurrentHallsSaga);
  yield fork(handleChangeCurrentShuSaga);
  yield fork(handleDateRangeSlideSaga);
  yield fork(handleSearchSaga);
  yield fork(handleSearchResetSaga);
}
