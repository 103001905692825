import {
  DataKiGraph,
  DataKiGraphParams,
  GRAPH_DATE_TYPE,
  GRAPH_FIELD_TYPE,
  GraphDateType,
  GraphFieldType,
} from '../../domain/dataKiGraph';
import { DkSisSearchParams } from '../../domain/dkSis';
import { LoadingState, QueryParameter } from '../../domain/schemas';

import { ModelReportsSettingState } from '../ui/modelReportsSetting';

// Action Types

const SHOW_DATA_KI_GRAPH_LOADING = 'SHOW_DATA_KI_GRAPH_LOADING' as const;
const HIDE_DATA_KI_GRAPH_LOADING = 'HIDE_DATA_KI_GRAPH_LOADING' as const;
const CHANGE_DATA_KI_GRAPH_FIELD = 'CHANGE_DATA_KI_GRAPH_FIELD' as const;
const CHANGE_DATA_KI_GRAPH_DATE_TYPE =
  'CHANGE_DATA_KI_GRAPH_DATE_TYPE' as const;
const CHANGE_DATA_KI_GRAPH_SELECT_HALLS =
  'CHANGE_DATA_KI_GRAPH_SELECT_HALLS' as const;
const HIDE_DATA_KI_GRAPH = 'HIDE_DATA_KI_GRAPH' as const;
const FETCH_DATA_KI_GRAPH = 'FETCH_DATA_KI_GRAPH' as const;
const FETCH_DATA_KI_GRAPH_FROM_FAVORITE =
  'FETCH_DATA_KI_GRAPH_FROM_FAVORITE' as const;
const FETCH_DATA_KI_GRAPH_REQUEST = 'FETCH_DATA_KI_GRAPH_REQUEST' as const;
const FETCH_DATA_KI_GRAPH_SUCCESS = 'FETCH_DATA_KI_GRAPH_SUCCESS' as const;
const CHANGE_DATA_KI_GRAPH_DK_SIS_SEARCH_PARAMS =
  'CHANGE_DATA_KI_GRAPH_DK_SIS_SEARCH_PARAMS' as const;
const RENEW_DATA_KI_GRAPH = 'RENEW_DATA_KI_GRAPH' as const;

export const DataKiGraphActionTypes = {
  SHOW_DATA_KI_GRAPH_LOADING,
  HIDE_DATA_KI_GRAPH_LOADING,
  CHANGE_DATA_KI_GRAPH_FIELD,
  CHANGE_DATA_KI_GRAPH_DATE_TYPE,
  CHANGE_DATA_KI_GRAPH_SELECT_HALLS,
  HIDE_DATA_KI_GRAPH,
  FETCH_DATA_KI_GRAPH,
  FETCH_DATA_KI_GRAPH_FROM_FAVORITE,
  FETCH_DATA_KI_GRAPH_REQUEST,
  FETCH_DATA_KI_GRAPH_SUCCESS,
  CHANGE_DATA_KI_GRAPH_DK_SIS_SEARCH_PARAMS,
  RENEW_DATA_KI_GRAPH,
};

// Action Creators

// ローディングを表示する
function showLoadingAction() {
  return {
    type: SHOW_DATA_KI_GRAPH_LOADING,
    payload: { loadingState: 'loading' as const },
  };
}

// ローディングを非表示にする
function hideLoadingAction() {
  return {
    type: HIDE_DATA_KI_GRAPH_LOADING,
    payload: { loadingState: 'loaded' as const },
  };
}

// グラフの表示項目を変更する
function changeFieldAction(field: GraphFieldType) {
  return {
    type: CHANGE_DATA_KI_GRAPH_FIELD,
    payload: { field },
  };
}

// グラフの表示期間を変更する
function changeDateTypeAction(dateType: GraphDateType) {
  return {
    type: CHANGE_DATA_KI_GRAPH_DATE_TYPE,
    payload: { dateType },
  };
}

// グラフに表示する店舗を変更する
function changeSelectHallsAction(selectHalls: string[]) {
  return {
    type: CHANGE_DATA_KI_GRAPH_SELECT_HALLS,
    payload: { selectHalls },
  };
}

// 推移グラフ（店舗比較）を閉じる
function hideDataKiGraphAction() {
  return {
    type: HIDE_DATA_KI_GRAPH,
    payload: { ...initialState },
  };
}

// 指定した条件でグラフを取得する
function fetchDataKiGraphAction(
  queryParams: QueryParameter,
  selectHalls: string[],
  dkSisSearchParams: DkSisSearchParams,
  requestParams?: {
    hallId: string;
    mainFieldId: string;
  },
  isSelectedFromMainField?: boolean,
  onlyOneKiTagAverageTagIds?: string[]
) {
  return {
    type: FETCH_DATA_KI_GRAPH,
    payload: {
      queryParams,
      selectHalls,
      dkSisSearchParams,
      requestParams,
      isSelectedFromMainField,
      onlyOneKiTagAverageTagIds,
    },
  };
}

// 指定したグラフ用の検索条件でグラフを取得する（お気に入り適用）
function fetchDataKiGraphFromFavoriteAction(
  params: DataKiGraphParams,
  selectHalls: string[],
  selectedModelReportsKiList?: ModelReportsSettingState['selectedDataKiExpandedRow']
) {
  return {
    type: FETCH_DATA_KI_GRAPH_FROM_FAVORITE,
    payload: { params, selectHalls, selectedModelReportsKiList },
  };
}

// グラフの取得を開始時に呼ぶもの
function fetchDataKiGraphRequestAction() {
  return {
    type: FETCH_DATA_KI_GRAPH_REQUEST,
  };
}

// グラフの取得が成功した際、取得結果をreducerにpayloadで渡す
function fetchDataKiGraphSuccessAction(dataKiGraph: DataKiGraph) {
  return {
    type: FETCH_DATA_KI_GRAPH_SUCCESS,
    payload: { dataKiGraph },
  };
}

// DK-SIS検索条件を変更
function changeDataKiGraphDkSisSearchParamsAction(params: DkSisSearchParams) {
  return {
    type: CHANGE_DATA_KI_GRAPH_DK_SIS_SEARCH_PARAMS,
    payload: { params },
  };
}

// グラフの取得に失敗した際、payloadにエラー内容を入れる
function renewDataKiGraphAction() {
  return {
    type: RENEW_DATA_KI_GRAPH,
  };
}

export const DataKiGraphActionCreators = {
  showLoadingAction,
  hideLoadingAction,
  changeFieldAction,
  changeDateTypeAction,
  changeSelectHallsAction,
  hideDataKiGraphAction,
  fetchDataKiGraphAction,
  fetchDataKiGraphFromFavoriteAction,
  fetchDataKiGraphRequestAction,
  fetchDataKiGraphSuccessAction,
  changeDataKiGraphDkSisSearchParamsAction,
  renewDataKiGraphAction,
};

// Actions

export type FetchDataKiGraphAction = ReturnType<typeof fetchDataKiGraphAction>;
export type FetchDataKiGraphFromFavoriteAction = ReturnType<
  typeof fetchDataKiGraphFromFavoriteAction
>;
type HideDataKiGraphAction = ReturnType<typeof hideDataKiGraphAction>;
export type ChangeFieldAction = ReturnType<typeof changeFieldAction>;
export type ChangeDateTypeAction = ReturnType<typeof changeDateTypeAction>;
type ChangeSelectHallsAction = ReturnType<typeof changeSelectHallsAction>;
export type ChangeDataKiGraphDkSisSearchParamsAction = ReturnType<
  typeof changeDataKiGraphDkSisSearchParamsAction
>;

type DataKiGraphAction =
  | FetchDataKiGraphAction
  | FetchDataKiGraphFromFavoriteAction
  | HideDataKiGraphAction
  | ChangeFieldAction
  | ChangeDateTypeAction
  | ChangeSelectHallsAction
  | ReturnType<typeof showLoadingAction>
  | ReturnType<typeof hideLoadingAction>
  | ReturnType<typeof fetchDataKiGraphRequestAction>
  | ReturnType<typeof fetchDataKiGraphSuccessAction>
  | ChangeDataKiGraphDkSisSearchParamsAction
  | ReturnType<typeof renewDataKiGraphAction>;

// State

type DataKiGraphState = {
  dataKiGraph?: DataKiGraph;
  selectHalls: string[];
  loadingState: LoadingState;
};

export const initialState: DataKiGraphState = {
  dataKiGraph: undefined,
  selectHalls: [],
  loadingState: 'prepare',
};

// Selector

// 推移グラフ（店舗比較）データ
export function dataKiGraphSelector(rootState: {
  dataKiGraph: DataKiGraphState;
}) {
  // データが空の場合は空を返す
  if (rootState.dataKiGraph.dataKiGraph === undefined) return;

  // 非表示にするデータのインデックス（columnsとrows）
  const removeIdx = rootState.dataKiGraph.dataKiGraph.data.columns
    // 選択されている店舗以外を全て非表示にする
    // DK-SISデータがあれば常に表示する
    .reduce((prev: number[], current, idx) => {
      if (
        !rootState.dataKiGraph.selectHalls.includes(current.name) &&
        !current.name.toLowerCase().includes('dk-sis')
      )
        prev.push(idx);
      return prev;
    }, [])
    // 平均行が選択されている場合は表示する
    .filter((item) =>
      rootState.dataKiGraph.selectHalls.includes('null') && item === 1
        ? false
        : true
    );

  // フィルター後のデータ
  const columns = rootState.dataKiGraph.dataKiGraph.data.columns.filter(
    (_item, idx) => {
      if (idx === 0) return true; // 日付行は必ず含む
      return !removeIdx.includes(idx);
    }
  );
  const rows = rootState.dataKiGraph.dataKiGraph.data.rows.map((row) =>
    row.filter((_item, idx) => {
      if (idx === 0) return true; // 日付行は必ず含む
      return !removeIdx.includes(idx);
    })
  );

  const data: DataKiGraph = {
    setting: { ...rootState.dataKiGraph.dataKiGraph.setting },
    data: {
      columns,
      rows,
    },
  };

  return data;
}

// 現在の検索条件
export function dataKiGraphParamsSelector(rootState: {
  dataKiGraph: DataKiGraphState;
}) {
  return rootState.dataKiGraph.dataKiGraph?.setting;
}

// ローディング状態
export function dataKiGraphLoadingSelector(rootState: {
  dataKiGraph: DataKiGraphState;
}) {
  return rootState.dataKiGraph.loadingState;
}

// 選択中の表示項目
export function dataKiGraphFieldSelector(rootState: {
  dataKiGraph: DataKiGraphState;
}) {
  return (
    rootState.dataKiGraph.dataKiGraph?.setting.field ?? GRAPH_FIELD_TYPE.OUT
  );
}

// 選択中の表示期間
export function dataKiGraphDateTypeSelector(rootState: {
  dataKiGraph: DataKiGraphState;
}) {
  return (
    rootState.dataKiGraph.dataKiGraph?.setting.dateType ?? GRAPH_DATE_TYPE.DAILY
  );
}

// 選択中の店舗
export function dataKiGraphSelectHallsSelector(rootState: {
  dataKiGraph: DataKiGraphState;
}) {
  return rootState.dataKiGraph.selectHalls;
}

// 選択中できる店舗一覧
export function dataKiGraphHallsListSelector(rootState: {
  dataKiGraph: DataKiGraphState;
}) {
  if (rootState.dataKiGraph.dataKiGraph === undefined) return [];

  const hallsList = rootState.dataKiGraph.dataKiGraph.data.columns.reduce(
    (prev: string[], current, idx) => {
      if (idx === 1) prev.push('null'); // 平均・合計
      if (idx !== 0 && idx !== 1) prev.push(current.name); // 店舗

      return prev;
    },
    []
  );

  // 店舗一覧にDK-SISのデータも入っているので店舗一覧から削除する
  return hallsList.filter((x: string) => !x.toLowerCase().includes('dk-sis'));
}

// Reducer

export function dataKiGraphReducer(
  state = initialState,
  action: DataKiGraphAction
): DataKiGraphState {
  switch (action.type) {
    case FETCH_DATA_KI_GRAPH_SUCCESS:
      return {
        ...state,
        dataKiGraph: action.payload.dataKiGraph,
      };
    case FETCH_DATA_KI_GRAPH_FROM_FAVORITE:
      return {
        ...state,
        selectHalls: action.payload.selectHalls,
      };
    case SHOW_DATA_KI_GRAPH_LOADING:
    case HIDE_DATA_KI_GRAPH_LOADING:
      return {
        ...state,
        loadingState: action.payload.loadingState,
      };
    case CHANGE_DATA_KI_GRAPH_SELECT_HALLS:
      return {
        ...state,
        selectHalls: action.payload.selectHalls,
      };
    case HIDE_DATA_KI_GRAPH:
      return { ...action.payload };
    case RENEW_DATA_KI_GRAPH:
      return initialState;
    default:
      return state;
  }
}
