import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsChainStore } from '../../domain/settingsOptionsChainStore';

import { RootState } from '../../store';
import { settingsOptionsChainStoreDefaultValue } from '../../utils/settingsOptionsChainStoreDefaultValue';

// Action Types

const FETCH_SETTINGS_OPTIONS_CHAIN_STORE =
  'FETCH_SETTINGS_OPTIONS_CHAIN_STORE' as const;
const FETCH_SETTINGS_OPTIONS_CHAIN_STORE_REQUEST =
  'FETCH_SETTINGS_OPTIONS_CHAIN_STORE_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_CHAIN_STORE_SUCCESS =
  'FETCH_SETTINGS_OPTIONS_CHAIN_STORE_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_CHAIN_STORE =
  'RENEW_SETTINGS_OPTIONS_CHAIN_STORE' as const;
const CLEAR_SETTINGS_OPTIONS_CHAIN_STORE =
  'CLEAR_SETTINGS_OPTIONS_CHAIN_STORE' as const;

export const SettingsOptionsChainStoreActionTypes = {
  FETCH_SETTINGS_OPTIONS_CHAIN_STORE,
  FETCH_SETTINGS_OPTIONS_CHAIN_STORE_REQUEST,
  FETCH_SETTINGS_OPTIONS_CHAIN_STORE_SUCCESS,
  RENEW_SETTINGS_OPTIONS_CHAIN_STORE,
  CLEAR_SETTINGS_OPTIONS_CHAIN_STORE,
};

// Action Creators

function fetchSettingsOptionsChainStoreAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_CHAIN_STORE,
  };
}

function fetchSettingsOptionsChainStoreRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_CHAIN_STORE_REQUEST,
  };
}

function fetchSettingsOptionsChainStoreSuccessAction(
  settingsOptionsChainStore: SettingsOptionsChainStore
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_CHAIN_STORE_SUCCESS,
    payload: { settingsOptionsChainStore },
  };
}

function renewSettingsOptionsChainStoreAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_CHAIN_STORE,
  };
}

function clearSettingsOptionsChainStoreAction() {
  return {
    type: CLEAR_SETTINGS_OPTIONS_CHAIN_STORE,
  };
}

export const SettingsOptionsChainStoreActionCreators = {
  fetchSettingsOptionsChainStoreAction,
  fetchSettingsOptionsChainStoreRequestAction,
  fetchSettingsOptionsChainStoreSuccessAction,
  renewSettingsOptionsChainStoreAction,
  clearSettingsOptionsChainStoreAction,
};

// Actions

type FetchSettingsOptionsChainStoreAction = ReturnType<
  typeof fetchSettingsOptionsChainStoreAction
>;
type ClearSettingsOptionsChainStoreAction = ReturnType<
  typeof clearSettingsOptionsChainStoreAction
>;

type SettingsOptionsChainStoreAction =
  | FetchSettingsOptionsChainStoreAction
  | ReturnType<typeof fetchSettingsOptionsChainStoreRequestAction>
  | ReturnType<typeof fetchSettingsOptionsChainStoreSuccessAction>
  | ReturnType<typeof renewSettingsOptionsChainStoreAction>
  | ClearSettingsOptionsChainStoreAction;

// State

type SettingsOptionsChainStoreState = {
  loadingState: LoadingState;
  settingsOptionsChainStore: SettingsOptionsChainStore;
};

const initialState: SettingsOptionsChainStoreState = {
  loadingState: 'prepare',
  settingsOptionsChainStore: settingsOptionsChainStoreDefaultValue,
};

// Selector

export function settingsOptionsChainStoreSelector(rootState: {
  settingsOptionsChainStore: SettingsOptionsChainStoreState;
}) {
  return rootState.settingsOptionsChainStore.settingsOptionsChainStore;
}

/**
 * 現在のローディング状況
 * @returns ローディング状態（ローディング時:true）
 */
export const settingsOptionsChainStoreLoadingStateSelector = (
  state: RootState
) => {
  return state.settingsOptionsChainStore.loadingState;
};

/**
 * settingsOptionsのローディングステータスを取得する
 * @returns ローディング状態（ローディング時：true）
 */
export const settingsOptionsChainStoreLoadingSelector = createSelector(
  [
    settingsOptionsChainStoreSelector,
    settingsOptionsChainStoreLoadingStateSelector,
  ],
  (settingsOptionsChainStore, loadingState) => {
    // データが空かつ取得中の時のみローディングを表示する
    // MEMO: ページ離脱時にデータを残すようになりました。ページ表示時は念の為再取得するが、その時にはローディングいらないため
    return (
      settingsOptionsChainStore === undefined && loadingState === 'loading'
    );
  }
);

/**
 * SearchConditionだけ取得する
 * @returns SearchCondition
 */
export const settingsOptionsChainStoreSearchConditionSelector = createSelector(
  settingsOptionsChainStoreSelector,
  (settingsOptionsChainStore) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsChainStore === undefined)
      return settingsOptionsChainStoreDefaultValue.searchCondition;

    return settingsOptionsChainStore.searchCondition;
  }
);

/**
 * 表示項目の項目一覧すべて取得する
 * @returns すべての表示項目一覧
 */
const settingsOptionsChainStoreFieldsSelector = createSelector(
  settingsOptionsChainStoreSelector,
  (settingsOptionsChainStore) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsChainStore === undefined)
      return settingsOptionsChainStoreDefaultValue.fields;

    return settingsOptionsChainStore.fields;
  }
);

/**
 * 種別実績の表示項目一覧を取得する
 * @returns 表示項目一覧（種別実績）
 */
export const settingsOptionsChainStoreShuFieldsSelector = createSelector(
  settingsOptionsChainStoreFieldsSelector,
  (fields) => {
    return fields.shu;
  }
);

// 種別実績表示項目のFieldsのisNewがtrueのものがあるかどうかを取得する
export const settingsOptionsChainStoreShuFieldsIsNewSelector = createSelector(
  settingsOptionsChainStoreShuFieldsSelector,
  (fields) => {
    return fields.shu.some((item) => item.isNew);
  }
);

// 種別実績(全体実績)表示項目のFieldsのisNewがtrueのものがあるかどうかを取得する
export const settingsOptionsChainStoreShuWholeFieldsIsNewSelector =
  createSelector(settingsOptionsChainStoreShuFieldsSelector, (fields) => {
    return fields.whole.some((item) => item.isNew);
  });

/**
 * 新台/メイン機種の表示項目一覧を取得する
 * @returns 表示項目一覧（新台/メイン機種）
 */
export const settingsOptionsChainStoreKiFieldsSelector = createSelector(
  settingsOptionsChainStoreFieldsSelector,
  (fields) => {
    return fields.ki.shu;
  }
);

/**
 * 新台/メイン機種の表示項目のFieldsのisNewがtrueのものがあるかどうかを取得する
 */
export const settingsOptionsChainStoreKiShuFieldsIsNewSelector = createSelector(
  settingsOptionsChainStoreKiFieldsSelector,
  (fields) => {
    return fields.some((item) => item.isNew);
  }
);

/**
 * 新台/メイン機種の表示項目のコードを取得する
 */
export const settingsOptionsChainStoreKiFieldCodesSelector = createSelector(
  settingsOptionsChainStoreKiFieldsSelector,
  (options) => {
    return options.map((item) => item.code);
  }
);

/**
 * 種別フィルター、全体用のジャンル一覧を取得する
 * @returns 種別フィルター、全体用のジャンル一覧
 */
export const settingsOptionsShuWholeGenresSelector = createSelector(
  settingsOptionsChainStoreSelector,
  (settingsOptionsChain) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsChain === undefined)
      return settingsOptionsChainStoreDefaultValue.genres.shu.whole;

    return settingsOptionsChain.genres.shu.whole;
  }
);

/**
 * 種別フィルター、種別用のジャンル一覧を取得する
 * @returns 種別フィルター、種別用のジャンル一覧
 */
export const settingsOptionsShuGenresSelector = createSelector(
  settingsOptionsChainStoreSelector,
  (settingsOptionsChain) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsChain === undefined)
      return settingsOptionsChainStoreDefaultValue.genres.shu.shu;

    return settingsOptionsChain.genres.shu.shu;
  }
);

/**
 * 機種別フィルター、ホール用のジャンル一覧を取得する
 * @returns 機種別フィルター、ホール用のジャンル一覧
 */
export const settingsOptionsKiHallGenresSelector = createSelector(
  settingsOptionsChainStoreSelector,
  (settingsOptionsChain) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsChain === undefined) {
      return settingsOptionsChainStoreDefaultValue.genres.ki.shu;
    }
    return settingsOptionsChain.genres.ki.shu;
  }
);

/**
 * 種別実績の推移グラフ（店舗比較）のグラフ項目一覧を取得する
 * @returns グラフ項目一覧
 */
export const settingsOptionsChainStoreShuGraphFieldsSelector = (
  shuCode: string
) =>
  createSelector(settingsOptionsChainStoreFieldsSelector, (fields) => {
    // 店舗全体実績の場合
    if (shuCode === 'null')
      return fields.shu.whole.filter(
        (field) => field.isSelectableForGraph === true
      );

    // 各種別のテーブルの場合
    return fields.shu.shu.filter(
      (field) => field.isSelectableForGraph === true
    );
  });

/**
 * 新台/メイン機種の推移グラフ（機種比較）のグラフ項目一覧を取得する
 * @returns グラフ項目一覧
 */
export const settingsOptionsChainStoreKiGraphFieldsSelector = createSelector(
  settingsOptionsChainStoreFieldsSelector,
  (fields) => {
    return fields.ki.shu.filter((field) => field.isSelectableForGraph === true);
  }
);

// 機種コードからP-Sensorの機種コードに変換するテーブルを取得する
export const settingsOptionsChainModelNumberConversionTableSelector =
  createSelector(
    settingsOptionsChainStoreSelector,
    (settingsOptionsChain) => settingsOptionsChain?.kiCodeToPSensorKiCode || {}
  );

// Reducer

export function settingsOptionsChainStoreReducer(
  state = initialState,
  action: SettingsOptionsChainStoreAction
): SettingsOptionsChainStoreState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_CHAIN_STORE_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_CHAIN_STORE_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsChainStore: action.payload.settingsOptionsChainStore,
      };
    case RENEW_SETTINGS_OPTIONS_CHAIN_STORE:
      return initialState;
    case CLEAR_SETTINGS_OPTIONS_CHAIN_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
