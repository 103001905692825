import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { FetchShortenedUrlOriginalHashResponse } from '../domain/shortenedUrlOriginalHash';

import {
  FetchShortenedUrlOriginalHashAction,
  ShortenedUrlOriginalHashActionCreators,
  ShortenedUrlOriginalHashActionTypes,
} from '../redux/server/shortendUrlOriginalHash';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* fetchShortenedUrlOriginalHashSaga(
  api: Api,
  action: FetchShortenedUrlOriginalHashAction
) {
  try {
    const { shortenedHash } = action.payload.params;

    yield put(
      ShortenedUrlOriginalHashActionCreators.fetchShortenedUrlOriginalHashRequestAction()
    );
    const response: AxiosResponse<FetchShortenedUrlOriginalHashResponse> =
      yield call(
        api.get,
        `/shortenedUrl/original/${shortenedHash}`,
        buildConfig()
      );
    yield put(
      ShortenedUrlOriginalHashActionCreators.fetchShortenedUrlOriginalHashSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      ShortenedUrlOriginalHashActionCreators.renewShortenedUrlOriginalHashAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchShortenedUrlOriginalHashSaga(api: Api) {
  yield takeEvery(
    ShortenedUrlOriginalHashActionTypes.FETCH_SHORTENED_URL_ORIGINAL_HASH,
    fetchShortenedUrlOriginalHashSaga,
    api
  );
}

export function* shortenedUrlOriginalHashSagas(context: { api: Api }) {
  yield fork(handleFetchShortenedUrlOriginalHashSaga, context.api);
}
