import { createSelector } from 'reselect';

import {
  calcDefaultEndDate,
  calcDefaultStartDate,
} from '../../domain/settingsUnitModel';

// Action Types

const SELECT_SETTINGS_UNIT_MODEL_DATE_RANGE =
  'SELECT_SETTINGS_UNIT_MODEL_DATE_RANGE' as const;
const SELECT_SETTINGS_UNIT_MODEL_HALL_CODE =
  'SELECT_SETTINGS_UNIT_MODEL_HALL_CODE' as const;

// Action Creators

function selectSettingsUnitModelDateRangeAction(
  startDate: string,
  endDate: string
) {
  return {
    type: SELECT_SETTINGS_UNIT_MODEL_DATE_RANGE,
    payload: {
      startDate,
      endDate,
    },
  };
}

function selectSettingsUnitModelHallCodeAction(hallCode: string) {
  return {
    type: SELECT_SETTINGS_UNIT_MODEL_HALL_CODE,
    payload: {
      hallCode,
    },
  };
}

export const SettingsUnitModelActionCreators = {
  selectSettingsUnitModelDateRangeAction,
  selectSettingsUnitModelHallCodeAction,
};

// Actions
type SettingsUnitModelAction =
  | ReturnType<typeof selectSettingsUnitModelDateRangeAction>
  | ReturnType<typeof selectSettingsUnitModelHallCodeAction>;

// State

type SettingsUnitModelState = {
  hallCode: string;
  startDate: string;
  endDate: string;
};

const initialState: SettingsUnitModelState = {
  startDate: calcDefaultStartDate(),
  endDate: calcDefaultEndDate(),
  hallCode: 'none',
};

// Selector
function settingsUnitModelSelector(rootState: {
  settingsUnitModel: SettingsUnitModelState;
}) {
  return rootState.settingsUnitModel;
}

/**
 * 一覧画面で選択した検索期間の取得
 */
export const settingsUnitModelDateRangeSelector = createSelector(
  settingsUnitModelSelector,
  ({ startDate, endDate }) => ({
    startDate,
    endDate,
  })
);

/**
 * 一覧画面で選択したホールの取得
 */
export const settingsUnitModelHallCodeSelector = createSelector(
  settingsUnitModelSelector,
  ({ hallCode }) => hallCode
);

// Reducer

export function settingsUnitModelReducer(
  state = initialState,
  action: SettingsUnitModelAction
): SettingsUnitModelState {
  switch (action.type) {
    case SELECT_SETTINGS_UNIT_MODEL_DATE_RANGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SELECT_SETTINGS_UNIT_MODEL_HALL_CODE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
