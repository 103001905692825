import { createSelector } from 'reselect';

import { SharedUser } from '../../domain/favorites';
import { MarkingOption } from '../../domain/marking';
import { generateInitialResponse } from '../../domain/ppmShare/defaultValue';
import {
  PpmShareReportsFormConditions,
  PpmShareReportsSettingDateRangeParams,
} from '../../domain/ppmShare/types';
import { Option, OrderType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { ModelDateRange } from '../../utils/modelDateRange';
import { favoritesSelector } from './settingsFavorites';

/**
 * Action Types
 *
 * プレフィックスの意味
 * SEARCH: ReducerでStateの変更はしないがデータを取得する（検索ボタン等Sagaを発火させたい）
 * CHANGE: Stateを変更してデータも取得する
 * SELECT: Stateを変更する（データ取得は行わない）
 * RESET: Stateを初期状態に戻す（データ取得は行わない）
 */

const SEARCH_PPM_SHARE_REPORTS = 'SEARCH_PPM_SHARE_REPORTS' as const;
const SEARCH_RESET_PPM_SHARE_REPORTS =
  'SEARCH_RESET_PPM_SHARE_REPORTS' as const;
const SEARCH_PPM_SHARE_REPORTS_SORT = 'SEARCH_PPM_SHARE_REPORTS_SORT' as const;
const SEARCH_PPM_SHARE_REPORTS_MARKING =
  'SEARCH_PPM_SHARE_REPORTS_MARKING' as const;
const SEARCH_PPM_SHARE_REPORTS_FIELDS =
  'SEARCH_PPM_SHARE_REPORTS_FIELDS' as const;

const CHANGE_PPM_SHARE_REPORTS_FAVORITE =
  'CHANGE_PPM_SHARE_REPORTS_FAVORITE' as const;
const CHANGE_PPM_SHARE_REPORTS_CURRENT_SHU =
  'CHANGE_PPM_SHARE_REPORTS_CURRENT_SHU' as const;

const SELECT_PPM_SHARE_REPORTS_SEARCH_CONDITION =
  'SELECT_PPM_SHARE_REPORTS_SEARCH_CONDITION' as const;
const SELECT_PPM_SHARE_REPORTS_SHU = 'SELECT_PPM_SHARE_REPORTS_SHU' as const;
const SELECT_PPM_SHARE_REPORTS_CURRENT_SHU =
  'SELECT_PPM_SHARE_REPORTS_CURRENT_SHU' as const;
const SELECT_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS =
  'SELECT_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS' as const;
const SELECT_PPM_SHARE_REPORTS_DAI_COLUMNS_ORDER =
  'SELECT_PPM_SHARE_REPORTS_DAI_COLUMNS_ORDER' as const;
const SELECT_PPM_SHARE_REPORTS_KASIDAMA_COLUMNS_ORDER =
  'SELECT_PPM_SHARE_REPORTS_KASIDAMA_COLUMNS_ORDER' as const;

const RESET_PPM_SHARE_REPORTS_SEARCH_CONDITION =
  'RESET_PPM_SHARE_REPORTS_SEARCH_CONDITION' as const;
const RESET_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS =
  'RESET_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS' as const;
const RESET_PPM_SHARE_REPORTS_SHU = 'RESET_PPM_SHARE_REPORTS_SHU' as const;

const SAVE_PPM_SHARE_REPORTS_FAVORITE =
  'SAVE_PPM_SHARE_REPORTS_FAVORITE' as const;
const SAVE_AS_PPM_SHARE_REPORTS_FAVORITE =
  'SAVE_AS_PPM_SHARE_REPORTS_FAVORITE' as const;

const ADD_PPM_SHARE_REPORTS_TABLE_FILTER =
  'ADD_PPM_SHARE_REPORTS_TABLE_FILTER' as const;
const REMOVE_PPM_SHARE_REPORTS_TABLE_FILTER =
  'REMOVE_PPM_SHARE_REPORTS_TABLE_FILTER' as const;
const APPLY_PPM_SHARE_REPORTS_TABLE_FILTER =
  'APPLY_PPM_SHARE_REPORTS_TABLE_FILTER' as const;
const RESET_PPM_SHARE_REPORTS_TABLE_FILTER =
  'RESET_PPM_SHARE_REPORTS_TABLE_FILTER' as const;

const SEARCH_MODEL_REPORTS_FROM_PPM_SHARE =
  'SEARCH_MODEL_REPORTS_FROM_PPM_SHARE' as const;

const SEARCH_PPM_SHARE_REPORTS_DATE_RANGE_SLIDE =
  'SEARCH_PPM_SHARE_REPORTS_DATE_RANGE_SLIDE' as const;
const CREATE_PPM_SHARE_REPORTS_SHORTENED_URL =
  'CREATE_PPM_SHARE_REPORTS_SHORTENED_URL' as const;

export const PpmShareReportsSettingActionTypes = {
  SEARCH_PPM_SHARE_REPORTS,
  SEARCH_RESET_PPM_SHARE_REPORTS,
  SEARCH_PPM_SHARE_REPORTS_SORT,
  SEARCH_PPM_SHARE_REPORTS_MARKING,
  SEARCH_PPM_SHARE_REPORTS_FIELDS,
  CHANGE_PPM_SHARE_REPORTS_CURRENT_SHU,
  CHANGE_PPM_SHARE_REPORTS_FAVORITE,
  SELECT_PPM_SHARE_REPORTS_SEARCH_CONDITION,
  SELECT_PPM_SHARE_REPORTS_SHU,
  SELECT_PPM_SHARE_REPORTS_CURRENT_SHU,
  SELECT_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS,
  SELECT_PPM_SHARE_REPORTS_DAI_COLUMNS_ORDER,
  SELECT_PPM_SHARE_REPORTS_KASIDAMA_COLUMNS_ORDER,
  RESET_PPM_SHARE_REPORTS_SEARCH_CONDITION,
  RESET_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS,
  RESET_PPM_SHARE_REPORTS_SHU,
  SAVE_PPM_SHARE_REPORTS_FAVORITE,
  SAVE_AS_PPM_SHARE_REPORTS_FAVORITE,
  ADD_PPM_SHARE_REPORTS_TABLE_FILTER,
  REMOVE_PPM_SHARE_REPORTS_TABLE_FILTER,
  APPLY_PPM_SHARE_REPORTS_TABLE_FILTER,
  RESET_PPM_SHARE_REPORTS_TABLE_FILTER,
  SEARCH_MODEL_REPORTS_FROM_PPM_SHARE,
  SEARCH_PPM_SHARE_REPORTS_DATE_RANGE_SLIDE,
  CREATE_PPM_SHARE_REPORTS_SHORTENED_URL,
};

/**
 * Action Creators
 */

/**
 * 指定した検索条件でデータを取得する（検索ボタン押下時）
 * @param params 検索条件
 * @param selectedShu 選択中の種別
 * @param dateRange 期間
 * @param isComparison 比較期間の有無
 */
export function searchPpmShareReportsAction(
  params: PpmShareReportsFormConditions,
  selectedShu: ShuOption[],
  dateRange: PpmShareReportsSettingDateRangeParams['dateRange'],
  isComparison: PpmShareReportsSettingDateRangeParams['isComparison']
) {
  return {
    type: SEARCH_PPM_SHARE_REPORTS,
    payload: { params, selectedShu, dateRange, isComparison },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 */
export function searchResetPpmShareReportsAction() {
  return {
    type: SEARCH_RESET_PPM_SHARE_REPORTS,
  };
}

/**
 * 指定したソート条件でデータを再取得する
 * @param sort ソートする条件
 * @param order 昇順・降順
 */
export function searchPpmShareReportsSortAction(
  sort: string,
  order: OrderType
) {
  return {
    type: SEARCH_PPM_SHARE_REPORTS_SORT,
    payload: { sort, order },
  };
}

/**
 * 指定したマーキング条件でデータを再取得する
 * @param markingOption マーキング項目
 */
function searchPpmShareReportsMarkingAction(markingOption: MarkingOption) {
  return {
    type: SEARCH_PPM_SHARE_REPORTS_MARKING,
    payload: { markingOption },
  };
}

/**
 * 指定した表示項目に絞ってデータを再取得する
 * @param fields 表示項目
 */
export function searchPpmShareReportsFieldAction(fields: Option[]) {
  return {
    type: SEARCH_PPM_SHARE_REPORTS_FIELDS,
    payload: { fields },
  };
}

/**
 * 選択した種別のデータを取得する
 *
 * MEMO: 初回取得時とテーブル上部の種別選択で変更時に使用する
 * @param shu 絞り込む種別
 */
export function changePpmShareReportsCurrentShuAction(shu?: ShuOption) {
  return {
    type: CHANGE_PPM_SHARE_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 選択したお気に入りでデータを取得する
 *
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changePpmShareReportsFavoriteAction(favorite?: number) {
  return {
    type: CHANGE_PPM_SHARE_REPORTS_FAVORITE,
    payload: { favorite },
  };
}

/**
 * 検索条件を変更
 */
function selectPpmShareReportsSearchConditionAction(
  params: PpmShareReportsFormConditions
) {
  return {
    type: SELECT_PPM_SHARE_REPORTS_SEARCH_CONDITION,
    payload: { params },
  };
}

/**
 * 種別一覧を変更
 * @param shus 種別一覧
 */
export function selectPpmShareReportsShuAction(shus: ShuOption[]) {
  return {
    type: SELECT_PPM_SHARE_REPORTS_SHU,
    payload: { shus },
  };
}

/**
 * 絞り込む種別を変更
 *
 * MEMO: 検索時やお気に入り適用時に使用する（全体的なStateだけ変更して最終的にFetchしたいケース）
 * @param shu 絞り込む種別
 */
function selectPpmShareReportsCurrentShuAction(shu?: ShuOption) {
  return {
    type: SELECT_PPM_SHARE_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 選択中の期間を変更
 * @param dateRange 期間
 * @param isComparison 比較期間の有無
 */
function selectPpmShareReportsDateRangeParamsAction(
  dateRange: PpmShareReportsSettingDateRangeParams['dateRange'],
  isComparison: PpmShareReportsSettingDateRangeParams['isComparison']
) {
  return {
    type: SELECT_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS,
    payload: { dateRange, isComparison },
  };
}

/**
 * 選択中の期間をデフォルトに戻す
 */
function resetPpmShareReportsDateRangeParamsAction() {
  return {
    type: RESET_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS,
  };
}

/**
 * 検索条件をデフォルトに戻す
 */
function resetPpmShareReportsSearchConditionAction() {
  return {
    type: RESET_PPM_SHARE_REPORTS_SEARCH_CONDITION,
  };
}

/**
 * 選択中の種別一覧をデフォルトに戻す
 */
function resetPpmShareReportsShuAction() {
  return {
    type: RESET_PPM_SHARE_REPORTS_SHU,
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 * @param mode メモ更新モード
 */
function savePpmShareReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_PPM_SHARE_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 */
function saveAsPpmShareReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_PPM_SHARE_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * テーブルの非表示項目を追加する
 * @param columnCode カラム名
 */
function addPpmShareReportsTableFilterAction(columnCode: string) {
  return {
    type: ADD_PPM_SHARE_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの表示項目を削除する
 * @param columnCode カラム名
 */
function removePpmShareReportsTableFilterAction(columnCode: string) {
  return {
    type: REMOVE_PPM_SHARE_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する
 * @param tableFilterItems テーブルの非表示項目
 */
function applyPpmShareReportsTableFilterAction(tableFilterItems: string[]) {
  return {
    type: APPLY_PPM_SHARE_REPORTS_TABLE_FILTER,
    payload: { tableFilterItems },
  };
}

/**
 * テーブルの非表示項目をリセットする
 */
function resetPpmShareReportsTableFilterAction() {
  return {
    type: RESET_PPM_SHARE_REPORTS_TABLE_FILTER,
  };
}

/**
 * PPMシェア画面から機種集計画面に遷移する前に、機種集計の検索条件に指定したホールコードとマーキングコードを格納する
 * @param {string} hallCode 機種集計で表示するホールコード
 * @param {string} modelMarkingCode 機種集計で表示するマーキングコード
 */
function searchHallReportsFromChainAction(
  hallCode: string,
  modelMarkingCode: string
) {
  return {
    type: SEARCH_MODEL_REPORTS_FROM_PPM_SHARE,
    payload: { hallCode, modelMarkingCode },
  };
}

/**
 * 期間スライドコンポーネントがクリックされた時に、期間を変更して検索を実行する
 */
export function searchPpmShareReportsDateRangeSlideAction(
  dateRange: ModelDateRange,
  startDate: string,
  endDate: string,
  startComparisonDate: string | undefined,
  endComparisonDate: string | undefined
) {
  return {
    type: SEARCH_PPM_SHARE_REPORTS_DATE_RANGE_SLIDE,
    payload: {
      dateRange,
      startDate,
      endDate,
      startComparisonDate,
      endComparisonDate,
    },
  };
}

/**
 * 画面共有用の短縮URLを作成する
 */
function createPpmShareReportsShortenedUrlAction(
  pageName: string,
  locationUrl: string
) {
  return {
    type: CREATE_PPM_SHARE_REPORTS_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

export const PpmShareReportsSettingActionCreators = {
  searchPpmShareReportsAction,
  searchResetPpmShareReportsAction,
  searchPpmShareReportsSortAction,
  searchPpmShareReportsMarkingAction,
  searchPpmShareReportsFieldAction,
  changePpmShareReportsFavoriteAction,
  changePpmShareReportsCurrentShuAction,
  selectPpmShareReportsSearchConditionAction,
  selectPpmShareReportsShuAction,
  selectPpmShareReportsCurrentShuAction,
  selectPpmShareReportsDateRangeParamsAction,
  resetPpmShareReportsDateRangeParamsAction,
  resetPpmShareReportsSearchConditionAction,
  resetPpmShareReportsShuAction,
  savePpmShareReportsFavoriteAction,
  saveAsPpmShareReportsFavoriteAction,
  addPpmShareReportsTableFilterAction,
  removePpmShareReportsTableFilterAction,
  applyPpmShareReportsTableFilterAction,
  resetPpmShareReportsTableFilterAction,
  searchHallReportsFromChainAction,
  searchPpmShareReportsDateRangeSlideAction,
  createPpmShareReportsShortenedUrlAction,
};

/**
 * Actions
 */

export type SearchPpmShareReportsAction = ReturnType<
  typeof searchPpmShareReportsAction
>;
type SearchResetPpmShareReportsAction = ReturnType<
  typeof searchResetPpmShareReportsAction
>;
export type SearchPpmShareReportsSortAction = ReturnType<
  typeof searchPpmShareReportsSortAction
>;
export type SearchPpmShareReportsMarkingAction = ReturnType<
  typeof searchPpmShareReportsMarkingAction
>;
export type SearchPpmShareReportsFieldAction = ReturnType<
  typeof searchPpmShareReportsFieldAction
>;
export type ChangePpmShareReportsFavoriteAction = ReturnType<
  typeof changePpmShareReportsFavoriteAction
>;
export type ChangePpmShareReportsCurrentShuAction = ReturnType<
  typeof changePpmShareReportsCurrentShuAction
>;
type SelectKiShuAction = ReturnType<typeof selectPpmShareReportsShuAction>;
type SelectPpmShareReportsSearchConditionAction = ReturnType<
  typeof selectPpmShareReportsSearchConditionAction
>;
type SelectKiCurrentShuAction = ReturnType<
  typeof selectPpmShareReportsCurrentShuAction
>;
type SelectKiDateRangeParamsAction = ReturnType<
  typeof selectPpmShareReportsDateRangeParamsAction
>;
type ResetKiDateRangeParamsAction = ReturnType<
  typeof resetPpmShareReportsDateRangeParamsAction
>;
type ResetPpmShareReportsSearchConditionAction = ReturnType<
  typeof resetPpmShareReportsSearchConditionAction
>;
type ResetKiShuAction = ReturnType<typeof resetPpmShareReportsShuAction>;
export type SavePpmShareReportsFavoriteAction = ReturnType<
  typeof savePpmShareReportsFavoriteAction
>;
export type SaveAsPpmShareReportsFavoriteAction = ReturnType<
  typeof saveAsPpmShareReportsFavoriteAction
>;
type AddPpmShareReportsTableFilterAction = ReturnType<
  typeof addPpmShareReportsTableFilterAction
>;
type RemovePpmShareReportsTableFilterAction = ReturnType<
  typeof removePpmShareReportsTableFilterAction
>;
type ApplyPpmShareReportsTableFilterAction = ReturnType<
  typeof applyPpmShareReportsTableFilterAction
>;
type ResetPpmShareReportsTableFilterAction = ReturnType<
  typeof resetPpmShareReportsTableFilterAction
>;
type SearchHallReportsFromChainAction = ReturnType<
  typeof searchHallReportsFromChainAction
>;
export type SearchPpmShareReportsDateRangeSlideAction = ReturnType<
  typeof searchPpmShareReportsDateRangeSlideAction
>;
export type CreatePpmShareReportsShortenedUrlAction = ReturnType<
  typeof createPpmShareReportsShortenedUrlAction
>;

type PpmShareReportsSettingAction =
  | SearchPpmShareReportsAction
  | SearchResetPpmShareReportsAction
  | SearchPpmShareReportsSortAction
  | SearchPpmShareReportsMarkingAction
  | SearchPpmShareReportsFieldAction
  | ChangePpmShareReportsFavoriteAction
  | ChangePpmShareReportsCurrentShuAction
  | SelectPpmShareReportsSearchConditionAction
  | SelectKiShuAction
  | SelectKiCurrentShuAction
  | SelectKiDateRangeParamsAction
  | ResetKiDateRangeParamsAction
  | ResetPpmShareReportsSearchConditionAction
  | ResetKiShuAction
  | SavePpmShareReportsFavoriteAction
  | SaveAsPpmShareReportsFavoriteAction
  | AddPpmShareReportsTableFilterAction
  | RemovePpmShareReportsTableFilterAction
  | ApplyPpmShareReportsTableFilterAction
  | ResetPpmShareReportsTableFilterAction
  | SearchHallReportsFromChainAction
  | SearchPpmShareReportsDateRangeSlideAction
  | CreatePpmShareReportsShortenedUrlAction;

/**
 * State
 */

export type PpmShareReportsSettingState = {
  /**
   * 現在の検索条件
   */
  searchCondition: PpmShareReportsFormConditions;
  /**
   * 現在検索フォームで選択中の種別・種別グループ（選択可能な種別・種別グループ一覧）
   */
  selectedShu: ShuOption[];
  /**
   * 現在選択中の種別・種別グループ（テーブルに表示されている種別・種別グループ）
   */
  currentShu?: ShuOption;
  /**
   * 現在選択中の期間指定
   */
  selectedDateRangeParams: PpmShareReportsSettingDateRangeParams;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId?: number;
  /**
   * テーブルの非表示項目一覧
   */
  tableFilterItems: string[];
};

const initialDateRangeParams: PpmShareReportsSettingDateRangeParams = {
  dateRange: generateInitialResponse().dateRangeParams.dateRange,
  isComparison: generateInitialResponse().dateRangeParams.isComparison,
};

// Stateの初期値
const initialState: PpmShareReportsSettingState = {
  searchCondition: generateInitialResponse().setting,
  selectedShu: [],
  currentShu: undefined,
  selectedDateRangeParams: initialDateRangeParams,
  selectedFavoriteId: undefined,
  tableFilterItems: [],
};

/**
 * Selector
 */

/**
 * 機種集計設定全てを取得する
 * @returns 全設定データ
 */
export function ppmShareReportsSettingSelector(rootState: {
  ppmShareReportsSetting: PpmShareReportsSettingState;
}) {
  return rootState.ppmShareReportsSetting;
}

/**
 * PPMシェアの現在選択されている検索条件を取得する
 * @returns 現在選択されている検索条件
 */
export function ppmShareReportsSearchConditionSelector(rootState: {
  ppmShareReportsSetting: PpmShareReportsSettingState;
}) {
  return rootState.ppmShareReportsSetting.searchCondition;
}

/**
 * PPMシェアの検索フォームで選択中の種別・種別グループを取得する（選択可能な種別・種別グループ一覧）
 * @returns 選択可能な種別一覧
 */
export function ppmShareReportsSelectedShuSelector(rootState: {
  ppmShareReportsSetting: PpmShareReportsSettingState;
}) {
  return rootState.ppmShareReportsSetting.selectedShu;
}

/**
 * PPMシェアで選択中の種別・種別グループを取得する（テーブルに表示されている種別・種別グループ）
 * @returns 現在選択されている種別（undefined時未選択）
 */
export function ppmShareReportsSelectedCurrentShuSelector(rootState: {
  ppmShareReportsSetting: PpmShareReportsSettingState;
}) {
  return rootState.ppmShareReportsSetting.currentShu;
}

/**
 * PPMシェアで選択中の期間指定を取得する
 * @returns 選択中の機期間情報
 */
export function ppmShareReportsSelectedDateRangeParamsSelector(rootState: {
  ppmShareReportsSetting: PpmShareReportsSettingState;
}) {
  return rootState.ppmShareReportsSetting.selectedDateRangeParams;
}

/**
 * PPMシェアで選択中のお気に入りIDを取得する
 * @returns 現在選択中のお気に入りID（undefined時未選択）
 */
export const ppmShareReportsSelectedFavoriteSelector = (state: RootState) => {
  return state.ppmShareReportsSetting.selectedFavoriteId;
};

/**
 * PPMシェアで選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const ppmShareReportsSelectedFavoriteSettingSelector = createSelector(
  [ppmShareReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    const favoriteItem = favorites?.favorites?.find(
      (favorite) => favorite.id === favoriteId
    );

    return favoriteItem?.pageSetting?.ppmShareReports;
  }
);

/**
 * PPMシェアで現在選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const ppmShareReportsSelectedFavoriteDataSelector = createSelector(
  [ppmShareReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    return favorites?.favorites?.find((favorite) => favorite.id === favoriteId);
  }
);

/**
 * PPMシェアで選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
// TODO お気にいり実装時にコメントアウト
// export const ppmShareReportsSelectedFavoriteSettingSelector = createSelector(
//   [ppmShareReportsSelectedFavoriteSelector, favoritesSelector],
//   (favoriteId, favorites) => {
//     if (favoriteId === undefined) return;

//     const favoriteItem = favorites.favorites.find(
//       (favorite) => favorite.id === favoriteId
//     );

//     return favoriteItem?.pageSetting?.ppmShareReports;
//   }
// );

/**
 * Reducer
 */

export function ppmShareReportsSettingReducer(
  state = initialState,
  action: PpmShareReportsSettingAction
): PpmShareReportsSettingState {
  switch (action.type) {
    case SELECT_PPM_SHARE_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.payload.params,
      };
    case RESET_PPM_SHARE_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
      };
    case SELECT_PPM_SHARE_REPORTS_SHU:
      return {
        ...state,
        selectedShu: action.payload.shus,
      };
    case SELECT_PPM_SHARE_REPORTS_CURRENT_SHU:
    case CHANGE_PPM_SHARE_REPORTS_CURRENT_SHU:
      return {
        ...state,
        currentShu: action.payload.shu,
      };
    case RESET_PPM_SHARE_REPORTS_SHU:
      return {
        ...state,
        selectedShu: [],
      };
    case SELECT_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS: {
      const dateRange = action.payload.dateRange;
      const isComparison = action.payload.isComparison;
      return {
        ...state,
        selectedDateRangeParams: {
          dateRange,
          isComparison,
        },
      };
    }
    case RESET_PPM_SHARE_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: initialState.selectedDateRangeParams,
      };
    case CHANGE_PPM_SHARE_REPORTS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favorite,
      };
    case ADD_PPM_SHARE_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [
          ...state.tableFilterItems,
          action.payload.columnCode,
        ],
      };
    case REMOVE_PPM_SHARE_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: state.tableFilterItems.filter(
          (columnCode) => columnCode !== action.payload.columnCode
        ),
      };
    case APPLY_PPM_SHARE_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: action.payload.tableFilterItems,
      };
    case RESET_PPM_SHARE_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [],
      };
    default:
      return state;
  }
}
