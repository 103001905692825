import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ChainStoreReportsFavorite } from '../domain/chain/types';
import { ChainReportsFormConditions } from '../domain/chainReportsFormConditions';
import {
  DataChainStoreShu,
  DataChainStoreShuData,
  DataChainStoreShuParams,
  DataChainStoreShuSummary,
} from '../domain/dataChainStoreShu';
import { REPORT_MARKING_CONDITIONS } from '../domain/marking';
import { Column } from '../domain/schemas';

import {
  ChangeDataChainStoreShuWholeColumnsOrderAction,
  DataChainStoreShuWholeActionCreators,
  DataChainStoreShuWholeActionTypes,
  FetchDataChainStoreShuWholeAction,
  FetchDataChainStoreShuWholeSummaryAction,
  SearchDataChainStoreShuWholeAction,
  SearchDataChainStoreShuWholeFavoriteAction,
  SearchDataChainStoreShuWholeFieldTypeAction,
  SearchDataChainStoreShuWholeMarkingAction,
  SearchDataChainStoreShuWholeSortAction,
  dataChainStoreShuWholeColumnsOrderSelector,
  dataChainStoreShuWholeDataColumnsSelector,
  dataChainStoreShuWholeIsExistSelector,
  dataChainStoreShuWholeLoadingSelector,
  dataChainStoreShuWholeOrderedData,
  dataChainStoreShuWholeSearchConditionSelector,
} from '../redux/server/dataChainStoreShuWhole';
import { DataHallDailyCommentsActionCreators } from '../redux/server/dataHallDailyComments';
import {
  chainStoreReportsSearchConditionSelector,
  chainStoreReportsSelectedFavoriteSettingSelector,
} from '../redux/ui/chainStoreReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { findComparativeSection } from '../utils/findComparativeSection';
import { recalcColumnOrder } from '../utils/orderedCell';
import { changeNameDateUnit } from '../utils/reportsDateRange';
import { searchConditionToDateRangeParams } from './chainStoreReportsSettingSaga';
import { handleErrorSaga } from './errorSaga';

/**
 * チェーン店レポート 種別実績（店舗全体実績）テーブルを取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataChainStoreShuWholeSaga(
  api: Api,
  action: FetchDataChainStoreShuWholeAction
) {
  try {
    yield put(
      DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeRequestAction()
    );

    const { params } = action.payload;

    const response: AxiosResponse<DataChainStoreShu> = yield call(
      api.get,
      '/data/chainStore/shu/whole',
      buildConfig(params)
    );

    yield put(
      DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeSuccessAction(
        response.data
      )
    );
    if (
      response?.data?.setting?.ymdList &&
      response.data.setting.ymdList.length > 0
    ) {
      yield put(
        DataHallDailyCommentsActionCreators.fetchDataHallDailyCommentsRequestAction(
          response.data.setting.ymdList[0]
        )
      );
    }
  } catch (error: unknown) {
    yield put(
      DataChainStoreShuWholeActionCreators.renewDataChainStoreShuWholeAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * チェーン店レポート 種別実績（店舗全体実績）Summaryを取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataChainStoreShuWholeSummarySaga(
  api: Api,
  action: FetchDataChainStoreShuWholeSummaryAction
) {
  try {
    yield put(
      DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeSummaryRequestAction()
    );

    const { params } = action.payload;

    const response: AxiosResponse<DataChainStoreShuSummary> = yield call(
      api.get,
      '/data/chainStore/shu/summary',
      buildConfig(params)
    );

    yield put(
      DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeSummarySuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataChainStoreShuWholeActionCreators.renewDataChainStoreShuWholeAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 表示項目が変更されている場合並び替えに反映する
 * @param shuCode 種別コード
 */
function* columnsOrderCheckSaga() {
  const fields: Column[] = yield select(
    dataChainStoreShuWholeDataColumnsSelector
  );

  const fieldCodes = fields.map((field) => field.code);

  // テーブル列の並び順
  let columnsOrder: string[] = yield select(
    dataChainStoreShuWholeColumnsOrderSelector
  );

  if (!columnsOrder || columnsOrder.length === 0) {
    // ソート順が設定されていない場合、初期配置をデフォルトで設定する
    columnsOrder = fields.map((column) => column.code);
  }

  const sorted = [...fieldCodes].sort((a, b) => {
    // 店舗名は常に先頭
    if (b === 'hlMei') {
      return 1;
    }
    return columnsOrder.indexOf(b) > columnsOrder.indexOf(a) ? -1 : 1;
  });

  yield put(
    DataChainStoreShuWholeActionCreators.selectDataChainStoreShuWholeColumnsOrderAction(
      sorted
    )
  );
}

/**
 * 初回取得の処理（初回取得・リセット時共通）
 */
function* firstFetchDataChainStoreShuWholeSaga() {
  // 現在の検索フォームの検索条件
  const searchFormCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );
  // 現在選択中のお気に入りのチェーン店レポートの各種データ
  const chainStoreReportsFavorite: ChainStoreReportsFavorite = yield select(
    chainStoreReportsSelectedFavoriteSettingSelector
  );

  // お気に入りがデフォルトの時
  if (!chainStoreReportsFavorite?.dataChainStoreShuWhole) {
    // 種別実績（店舗全体実績）テーブルデータ取得
    yield put(
      DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeAction(
        searchFormCondition
      )
    );
    // 種別実績（店舗全体実績）Summaryデータ取得
    yield put(
      DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeSummaryAction(
        searchFormCondition
      )
    );

    return;
  }

  // 過去のお気に入りを考慮してデフォルト値を設定する
  const comparativeSection = findComparativeSection(
    chainStoreReportsFavorite.selectedDateRangeParams.dateRange,
    chainStoreReportsFavorite?.selectedComparativeSection
  );

  // お気に入りがあればお気に入りの検索条件を適用し、なければ検索フォームの検索条件を適用する
  // MEMO: 種別実績テーブルのお気に入り適用はIntersectionObserverでIntersectingしたときに取得する仕様のため、ここでお気に入り適用している

  // 期間を再計算した検索条件
  const recalcSearchCondition = searchConditionToDateRangeParams(
    chainStoreReportsFavorite.selectedDateRangeParams.dateRange,
    // ボタン名称が古いお気に入りを変換
    changeNameDateUnit(
      chainStoreReportsFavorite.selectedDateRangeParams.dateUnit
    ),
    chainStoreReportsFavorite.selectedDateRangeParams.isComparison,
    chainStoreReportsFavorite.dataChainStoreShuWhole.setting,
    comparativeSection
  );

  //ymdList対応以前に作成したお気に入りを考慮
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = chainStoreReportsFavorite.dataChainStoreShuWhole.setting;

  const {
    startDate: startDate2,
    endDate: endDate2,
    ...rest2
  } = chainStoreReportsFavorite?.dataChainStoreShuWholeSummary?.setting ?? {};

  // 種別実績（店舗全体実績）テーブルデータ取得
  yield put(
    DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeAction({
      ...rest,
      ...recalcSearchCondition,
    })
  );
  // 種別実績（店舗全体実績）Summaryデータ取得
  yield put(
    DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeSummaryAction(
      {
        ...rest2,
        ymdList: recalcSearchCondition.ymdList,
      }
    )
  );
}

/**
 * チェーン店レポート 種別実績（店舗全体実績）を初回取得する
 */
function* initDataChainStoreShuWholeSaga() {
  // ローディング状態
  const isLoading: boolean = yield select(
    dataChainStoreShuWholeLoadingSelector
  );
  // データが存在する
  const isExist: boolean = yield select(dataChainStoreShuWholeIsExistSelector);

  // データが存在する・ローディング中・エラー時の時は初回取得を行わない
  if (isExist || isLoading) return;

  // 初回取得
  yield fork(firstFetchDataChainStoreShuWholeSaga);
}

/**
 * チェーン店レポート 種別実績（店舗全体実績） 検索ボタン押下時に行う処理
 */
function* searchDataChainStoreShuWholeSaga(
  action: SearchDataChainStoreShuWholeAction
) {
  // 検索フォームで指定した検索条件
  const params = action.payload.params;

  // 現在の種別実績テーブルの検索条件を取得する
  const searchCondition: DataChainStoreShuParams = yield select(
    dataChainStoreShuWholeSearchConditionSelector
  );

  // 種別実績（店舗全体実績）Summaryを取得
  yield put(
    DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeSummaryAction(
      {
        areas: params.areas,
        halls: params.halls,
        ymdList: params.ymdList,
        excludeToday: params.excludeToday,
      }
    )
  );

  // 種別実績＊店舗全体実績）テーブルを取得
  yield put(
    DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeAction({
      ...searchCondition, // テーブル固有の検索条件を混ぜる
      areas: params.areas,
      halls: params.halls,
      ymdList: params.ymdList,
      ymdComparisonList: params.ymdComparisonList,
      excludeToday: params.excludeToday,
      containsAreaAverage: params.containsAreaAverage,
    })
  );
}

/**
 * チェーン店レポート 種別実績（店舗全体実績） リセットボタン押下時に行う処理
 */
function* searchDataChainStoreShuWholeResetSaga() {
  // 初回条件で表示中のテーブルを再取得
  yield fork(firstFetchDataChainStoreShuWholeSaga);
}

/**
 * チェーン店レポート 店舗全体実績 お気に入り適用時に行う処理
 */
function* searchDataChainStoreShuWholeFavoriteSaga(
  action: SearchDataChainStoreShuWholeFavoriteAction
) {
  const { favorite } = action.payload;
  // 現在の検索フォームの検索条件
  const searchFormCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );

  // お気に入りがデフォルトの時
  if (!favorite.dataChainStoreShuWhole) {
    // 種別実績（店舗全体実績）テーブルデータ取得
    yield put(
      DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeAction(
        searchFormCondition
      )
    );
    // 種別実績（店舗全体実績）Summaryデータ取得
    yield put(
      DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeSummaryAction(
        searchFormCondition
      )
    );

    return;
  }

  // 過去のお気に入りを考慮してデフォルト値を設定する
  const comparativeSection = findComparativeSection(
    favorite.selectedDateRangeParams.dateRange,
    favorite?.selectedComparativeSection
  );

  // お気に入りがあればお気に入りの検索条件を適用し、なければ検索フォームの検索条件を適用する
  // MEMO: 種別実績テーブルのお気に入り適用はIntersectionObserverでIntersectingしたときに取得する仕様のため、ここでお気に入り適用している

  // 期間を再計算した検索条件
  const recalcSearchCondition = searchConditionToDateRangeParams(
    favorite.selectedDateRangeParams.dateRange,
    // ボタン名称が古いお気に入りを変換
    changeNameDateUnit(favorite.selectedDateRangeParams.dateUnit),
    favorite.selectedDateRangeParams.isComparison,
    favorite.dataChainStoreShuWhole.setting,
    comparativeSection
  );

  //ymdList対応以前に作成したお気に入りを考慮
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = favorite.dataChainStoreShuWhole.setting;

  const {
    startDate: startDate2,
    endDate: endDate2,
    ...rest2
  } = favorite.dataChainStoreShuWholeSummary?.setting ?? {};

  // 種別実績（店舗全体実績）テーブルデータ取得
  yield put(
    DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeAction({
      ...rest,
      ...recalcSearchCondition,
    })
  );
  // 種別実績（店舗全体実績）Summaryデータ取得
  yield put(
    DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeSummaryAction(
      {
        ...rest2,
        ymdList: recalcSearchCondition.ymdList,
      }
    )
  );
}

/**
 * 指定したソート条件の種別実績（店舗全体実績）のテーブルデータを取得する
 */
function* searchDataChainStoreShuWholeSortSaga(
  action: SearchDataChainStoreShuWholeSortAction
) {
  const { sort, order } = action.payload;

  // 現在の検索条件
  const searchCondition: DataChainStoreShuParams = yield select(
    dataChainStoreShuWholeSearchConditionSelector
  );

  // 種別実績（店舗全体実績）テーブルを再取得
  yield put(
    DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeAction({
      ...searchCondition,
      sort,
      order,
    })
  );
}

/**
 * 指定したマーキング条件の種別実績（店舗全体実績）のテーブルデータを取得する
 */
function* searchDataChainStoreShuWholeMarkingSaga(
  action: SearchDataChainStoreShuWholeMarkingAction
) {
  const { markingOption, isFiltering } = action.payload;

  // 現在の検索条件
  const searchCondition: DataChainStoreShuParams = yield select(
    dataChainStoreShuWholeSearchConditionSelector
  );

  // 種別実績（店舗全体実績）テーブルを再取得
  yield put(
    DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeAction({
      ...searchCondition,
      marking:
        markingOption.code === REPORT_MARKING_CONDITIONS.at(0)?.code
          ? undefined
          : markingOption.code,
      isFiltering,
    })
  );
}

/**
 * 指定した表示項目の種別実績（店舗全体実績）のテーブルデータを取得する
 */
function* searchDataChainStoreShuWholeFieldTypeSaga(
  action: SearchDataChainStoreShuWholeFieldTypeAction
) {
  const { fields } = action.payload;

  // 現在の検索条件
  const searchCondition: DataChainStoreShuParams = yield select(
    dataChainStoreShuWholeSearchConditionSelector
  );

  // 種別実績（店舗全体実績）テーブルを再取得
  yield put(
    DataChainStoreShuWholeActionCreators.fetchDataChainStoreShuWholeAction({
      ...searchCondition,
      fields: fields.map((field) => field.code),
    })
  );
}

/**
 * ドラッグ＆ドロップしたセルのIDを元に並び替え情報を登録する
 */
function* changeDataChainStoreShuWholeColumnsOrderSaga(
  action: ChangeDataChainStoreShuWholeColumnsOrderAction
) {
  const tableData: DataChainStoreShuData = yield select(
    dataChainStoreShuWholeOrderedData
  );

  const ordered = recalcColumnOrder(
    tableData.columns,
    action.payload.draggedId,
    action.payload.droppedId
  );

  yield put(
    DataChainStoreShuWholeActionCreators.selectDataChainStoreShuWholeColumnsOrderAction(
      ordered
    )
  );
}

function* handleSearchSaga() {
  // 初回取得
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.INIT_DATA_CHAIN_STORE_SHU_WHOLE,
    initDataChainStoreShuWholeSaga
  );
  // 検索時
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_WHOLE,
    searchDataChainStoreShuWholeSaga
  );
  // リセット時
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_RESET,
    searchDataChainStoreShuWholeResetSaga
  );
  // お気に入り時
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FAVORITE,
    searchDataChainStoreShuWholeFavoriteSaga
  );
  // ソート
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_SORT,
    searchDataChainStoreShuWholeSortSaga
  );
  // マーキング
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_MARKING,
    searchDataChainStoreShuWholeMarkingSaga
  );
  // 表示項目
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_WHOLE_FIELD_TYPE,
    searchDataChainStoreShuWholeFieldTypeSaga
  );
  // 並び替え
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.CHANGE_DATA_CHAIN_STORE_SHU_WHOLE_COLUMNS_ORDER,
    changeDataChainStoreShuWholeColumnsOrderSaga
  );
}

function* handleFetchDataChainStoreShuWholeSaga(api: Api) {
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.FETCH_DATA_CHAIN_STORE_SHU_WHOLE,
    fetchDataChainStoreShuWholeSaga,
    api
  );
  // 表示項目と並び替えのチェック
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUCCESS,
    columnsOrderCheckSaga
  );
}

function* handleFetchDataChainStoreShuWholeSummarySaga(api: Api) {
  yield takeEvery(
    DataChainStoreShuWholeActionTypes.FETCH_DATA_CHAIN_STORE_SHU_WHOLE_SUMMARY,
    fetchDataChainStoreShuWholeSummarySaga,
    api
  );
}

/**
 * 種別集計データに関するタスクを実行する
 * @param context AxiosInstance
 */
export function* dataChainStoreShuWholeSagas(context: { api: Api }) {
  yield fork(handleFetchDataChainStoreShuWholeSaga, context.api);
  yield fork(handleFetchDataChainStoreShuWholeSummarySaga, context.api);
  yield fork(handleSearchSaga);
}
