import { createSelector } from 'reselect';

import { SharedUser } from '../../domain/favorites';
import { dataSwapColumnsOrder } from '../../domain/mode/dataSwapColumnsOrder';
import { defaultDateRangeParams } from '../../domain/mode/defaultValue';
import { ModeDateRangeParams, ModeSearchParams } from '../../domain/mode/types';
import { ShuOption } from '../../domain/shu';

import { convertShuOption, getShuOption } from '../../utils/shu';
import { dataModeDataSelector } from '../server/dataMode';
import { dataModeKiListDataSelector } from '../server/dataModeKiList';
import { settingsOptionsModeSearchConditionSelector } from '../server/settingsOptionsMode';
import { favoritesSelector } from './settingsFavorites';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_DATA_MODE = 'INIT_DATA_MODE' as const;

const SEARCH_DATA_MODE = 'SEARCH_DATA_MODE' as const;
const SEARCH_RESET_DATA_MODE = 'SEARCH_RESET_DATA_MODE' as const;

const CHANGE_MODE_CURRENT_SHU = 'CHANGE_MODE_CURRENT_SHU' as const;
const CHANGE_MODE_CURRENT_KI = 'CHANGE_MODE_CURRENT_KI' as const;
const CHANGE_MODE_FIELDS = 'CHANGE_MODE_FIELDS' as const;

const SELECT_MODE_SEARCH_PARAMS = 'SELECT_MODE_SEARCH_PARAMS' as const;
const SELECT_MODE_DATE_RANGE_PARAMS = 'SELECT_MODE_DATE_RANGE_PARAMS' as const;
const SELECT_MODE_SELECTED_KILIST = 'SELECT_MODE_SELECTED_KILIST' as const;
const SELECT_MODE_SELECTED_SHU = 'SELECT_MODE_SELECTED_SHU' as const;
const SELECT_MODE_HALLS_AND_SISTYPES =
  'SELECT_MODE_HALLS_AND_SISTYPES' as const;

const SELECT_MODE_COLUMNS_MODE_SPECIFIC_ORDER =
  'SELECT_MODE_COLUMNS_MODE_SPECIFIC_ORDER' as const;
const SELECT_MODE_COLUMNS_NOT_MODE_SPECIFIC_ORDER =
  'SELECT_MODE_COLUMNS_NOT_MODE_SPECIFIC_ORDER' as const;

const TRIGGER_NOT_MODE_SWAP_FIELDS = 'TRIGGER_NOT_MODE_SWAP_FIELDS' as const;
const TRIGGER_MODE_SWAP_FIELDS = 'TRIGGER_MODE_SWAP_FIELDS' as const;

const CHANGE_MODE_FAVORITE = 'CHANGE_MODE_FAVORITE' as const;
const SAVE_AS_MODE_FAVORITE = 'SAVE_AS_MODE_FAVORITE' as const;
const SAVE_MODE_FAVORITE = 'SAVE_MODE_FAVORITE' as const;
const SAVE_MODE_FAVORITE_MEMO = 'SAVE_MODE_FAVORITE_MEMO' as const;

const CREATE_MODE_SHORTENED_URL = 'CREATE_MODE_SHORTENED_URL' as const;

export const ModeSettingActionTypes = {
  INIT_DATA_MODE,

  SEARCH_DATA_MODE,
  SEARCH_RESET_DATA_MODE,

  CHANGE_MODE_CURRENT_SHU,
  CHANGE_MODE_CURRENT_KI,
  CHANGE_MODE_FIELDS,

  SELECT_MODE_SEARCH_PARAMS,
  SELECT_MODE_DATE_RANGE_PARAMS,
  SELECT_MODE_SELECTED_KILIST,
  SELECT_MODE_SELECTED_SHU,
  SELECT_MODE_HALLS_AND_SISTYPES,
  SELECT_MODE_COLUMNS_NOT_MODE_SPECIFIC_ORDER,
  SELECT_MODE_COLUMNS_MODE_SPECIFIC_ORDER,

  TRIGGER_NOT_MODE_SWAP_FIELDS,
  TRIGGER_MODE_SWAP_FIELDS,

  CHANGE_MODE_FAVORITE,
  SAVE_AS_MODE_FAVORITE,
  SAVE_MODE_FAVORITE,
  SAVE_MODE_FAVORITE_MEMO,

  CREATE_MODE_SHORTENED_URL,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 検索条件の変更でデータを取得する
 */
function initDataModeAction() {
  return {
    type: INIT_DATA_MODE,
  };
}

/**
 * 検索条件の変更でデータを取得する
 *
 * paramsからは検索ボタン押下で変更されない値が除外されています
 */
function searchDataModeAction({
  params,
  dateRangeParams,
  selectedShu,
  selectedKiList,
}: {
  params: Omit<
    ModeSearchParams,
    | 'kiList'
    | 'shuGroupIds'
    | 'shuList'
    | 'sort'
    | 'order'
    | 'modeSpecificFields'
    | 'notModeSpecificFields'
  >;
  dateRangeParams: ModeDateRangeParams;
  selectedShu: ShuOption[];
  selectedKiList: string[];
}) {
  return {
    type: SEARCH_DATA_MODE,
    payload: {
      params,
      dateRangeParams,
      selectedShu,
      selectedKiList,
    },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 */
function searchResetDataModeAction() {
  return {
    type: SEARCH_RESET_DATA_MODE,
  };
}

/**
 * 種別変更
 */
function changeModeCurrentShuAction(currentShu: ShuOption) {
  return {
    type: CHANGE_MODE_CURRENT_SHU,
    payload: { currentShu },
  };
}

/**
 * 機種変更
 */
function changeModeCurrentKiAction(kiCode: string) {
  return {
    type: CHANGE_MODE_CURRENT_KI,
    payload: { kiCode },
  };
}

/**
 * 表示項目変更
 */
function changeModeFieldsAction(modeFields: string[], notModeFields: string[]) {
  return {
    type: CHANGE_MODE_FIELDS,
    payload: { modeFields, notModeFields },
  };
}

/**
 * 検索条件を変更
 */
function selectModeSearchParamsAction(params: ModeSearchParams) {
  return {
    type: SELECT_MODE_SEARCH_PARAMS,
    payload: { params },
  };
}

/**
 * 検索条件の期間を変更
 */
function selectModeDateRangeParamsAction(dateRangeParams: ModeDateRangeParams) {
  return {
    type: SELECT_MODE_DATE_RANGE_PARAMS,
    payload: { dateRangeParams },
  };
}

/**
 * 検索フォームで選択されている機種の更新
 */
function selectModeSelectedKiListAction(kiList: string[]) {
  return {
    type: SELECT_MODE_SELECTED_KILIST,
    payload: { kiList },
  };
}

/**
 * 検索フォームで選択されている種別の更新
 */
function selectModeSelectedShuAction(shuOptions: ShuOption[]) {
  return {
    type: SELECT_MODE_SELECTED_SHU,
    payload: { shuOptions },
  };
}

/**
 * 非モード別項目（機種基本情報）の並び順を変更
 */
function selectModeColumnsNotModeSpecificAction(
  columnsNotModeSpecific: string[]
) {
  return {
    type: SELECT_MODE_COLUMNS_NOT_MODE_SPECIFIC_ORDER,
    payload: { columnsNotModeSpecific },
  };
}

/**
 * モード別項目（通常項目）の並び順を変更
 */
function selectModeColumnsModeSpecificAction(columnsModeSpecific: string[]) {
  return {
    type: SELECT_MODE_COLUMNS_MODE_SPECIFIC_ORDER,
    payload: { columnsModeSpecific },
  };
}

/**
 * 非モード項目の入れ替え
 */
function triggerNotModeSwapFieldsAction(draggedId: string, droppedId: string) {
  return {
    type: TRIGGER_NOT_MODE_SWAP_FIELDS,
    payload: { draggedId, droppedId },
  };
}

/**
 * モード項目の入れ替え
 */
function triggerModeSwapFieldsAction(draggedId: string, droppedId: string) {
  return {
    type: TRIGGER_MODE_SWAP_FIELDS,
    payload: { draggedId, droppedId },
  };
}

/**
 * 選択したお気に入りでデータを取得する
 *
 * undefinedが戻されるときは未選択
 */
function changeModeFavoriteAction(favoriteId: number | undefined) {
  return {
    type: CHANGE_MODE_FAVORITE,
    payload: { favoriteId },
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 */
function saveAsModeFavoriteAction({
  name,
  isShared,
  memo,
  sharedUser,
}: {
  name: string;
  isShared: boolean;
  memo: string;
  sharedUser: SharedUser[];
}) {
  return {
    type: SAVE_AS_MODE_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * 選択中のお気に入りを上書き保存する
 */
function saveModeFavoriteAction({
  name,
  isShared,
  memo,
  sharedUser,
}: {
  name: string;
  isShared: boolean;
  memo: string;
  sharedUser: SharedUser[];
}) {
  return {
    type: SAVE_MODE_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * 選択中のお気に入りのメモを上書き保存する
 */
function saveModeFavoriteMemoAction({ memo }: { memo: string }) {
  return {
    type: SAVE_MODE_FAVORITE_MEMO,
    payload: { memo },
  };
}

/**
 * 画面共有用の短縮URLを作成する
 */
export function createModeShortenedUrlAction(
  pageName: string,
  locationUrl: string
) {
  return {
    type: CREATE_MODE_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

export const ModeSettingActionCreators = {
  initDataModeAction,

  searchDataModeAction,
  searchResetDataModeAction,

  changeModeCurrentShuAction,
  changeModeCurrentKiAction,
  changeModeFieldsAction,

  selectModeSearchParamsAction,
  selectModeSelectedShuAction,
  selectModeSelectedKiListAction,
  selectModeDateRangeParamsAction,

  selectModeColumnsNotModeSpecificAction,
  selectModeColumnsModeSpecificAction,

  triggerNotModeSwapFieldsAction,
  triggerModeSwapFieldsAction,

  changeModeFavoriteAction,
  saveAsModeFavoriteAction,
  saveModeFavoriteAction,
  saveModeFavoriteMemoAction,

  createModeShortenedUrlAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type SearchDataModeAction = ReturnType<typeof searchDataModeAction>;

export type ChangeModeCurrentKiAction = ReturnType<
  typeof changeModeCurrentKiAction
>;
export type ChangeModeCurrentShuAction = ReturnType<
  typeof changeModeCurrentShuAction
>;
export type ChangeModeFieldsAction = ReturnType<typeof changeModeFieldsAction>;
type ChangeModeFavoriteAction = ReturnType<typeof changeModeFavoriteAction>;

export type TriggerNotModeSwapFieldsAction = ReturnType<
  typeof triggerNotModeSwapFieldsAction
>;
export type TriggerModeSwapFieldsAction = ReturnType<
  typeof triggerModeSwapFieldsAction
>;

export type SaveModeFavoriteAction = ReturnType<typeof saveModeFavoriteAction>;
export type SaveAsModeFavoriteAction = ReturnType<
  typeof saveAsModeFavoriteAction
>;
export type SaveModeFavoriteMemoAction = ReturnType<
  typeof saveModeFavoriteMemoAction
>;

export type CreateModeShortenedUrlAction = ReturnType<
  typeof createModeShortenedUrlAction
>;

type ModeSettingAction =
  | ReturnType<typeof initDataModeAction>
  | SearchDataModeAction
  | ReturnType<typeof searchResetDataModeAction>
  | ChangeModeCurrentKiAction
  | ChangeModeCurrentShuAction
  | ChangeModeFieldsAction
  | ReturnType<typeof selectModeSelectedKiListAction>
  | ReturnType<typeof selectModeSelectedShuAction>
  | ReturnType<typeof selectModeSearchParamsAction>
  | ReturnType<typeof selectModeDateRangeParamsAction>
  | ReturnType<typeof selectModeColumnsNotModeSpecificAction>
  | ReturnType<typeof selectModeColumnsModeSpecificAction>
  | TriggerNotModeSwapFieldsAction
  | TriggerModeSwapFieldsAction
  | ChangeModeFavoriteAction
  | SaveAsModeFavoriteAction
  | SaveModeFavoriteAction
  | SaveModeFavoriteMemoAction
  | CreateModeShortenedUrlAction;

/* ---------------------------------------------------------------
 * State
 */

export type ModeSettingState = {
  searchParams: ModeSearchParams | undefined;
  selectedShu: ShuOption[];
  selectedKiList: string[];
  selectedDateRangeParams: ModeDateRangeParams;
  columnsNotModeSpecificOrder: string[];
  columnsModeSpecificOrder: string[];
  selectedFavoriteId: number | undefined;
};

const initialState: ModeSettingState = {
  searchParams: undefined,
  selectedShu: [],
  selectedKiList: [],
  selectedDateRangeParams: defaultDateRangeParams,
  columnsNotModeSpecificOrder: [],
  columnsModeSpecificOrder: [],
  selectedFavoriteId: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

export function modeSettingSelector(rootState: {
  modeSetting: ModeSettingState;
}) {
  return rootState.modeSetting;
}

/**
 * [モード別集計] 検索に使用された文字列クエリ
 *
 * 基本的にはデータのレスポンスのsettingを使用することになりますが、この値はお気に入りで使用されます
 */
export const modeSearchParamsSelector = createSelector(
  modeSettingSelector,
  (modeSetting) => {
    return modeSetting.searchParams;
  }
);

/**
 * [モード別集計] 検索フォームで検索された種別一覧
 */
export const modeSelectedSelectedShuSelector = createSelector(
  modeSettingSelector,
  (modeSetting) => {
    return modeSetting.selectedShu;
  }
);

/**
 * [モード別集計] 種別スライダーで選択できる種別一覧
 */
export const selectableModeShuOptionsSelector = createSelector(
  [modeSelectedSelectedShuSelector, settingsOptionsModeSearchConditionSelector],
  (selectedShu, searchCondition) => {
    if (searchCondition == null) {
      return;
    }
    if (selectedShu.length > 0) {
      return selectedShu;
    }
    return getShuOption(searchCondition);
  }
);

/**
 * [モード別集計] 種別スライダーで選択されている種別
 */
export const modeCurrentShuSelector = createSelector(
  [dataModeDataSelector, settingsOptionsModeSearchConditionSelector],
  (modeData, searchCondition) => {
    return (
      modeData?.setting &&
      searchCondition?.shuGroupList &&
      convertShuOption(modeData.setting, searchCondition.shuGroupList)
    );
  }
);

/**
 * [モード別集計] 検索フォームで検索された機種一覧
 */
export const modeSelectedKiListSelector = createSelector(
  modeSettingSelector,
  (modeSetting) => {
    return modeSetting.selectedKiList;
  }
);

/**
 * [モード別集計] 検索スライダーで選択できる機種一覧
 */
export const selectableModeKiListSelector = createSelector(
  [modeSelectedKiListSelector, dataModeKiListDataSelector],
  (selectedKiList, dataKiList) => {
    if (dataKiList == null) {
      return;
    }
    if (selectedKiList.length > 0) {
      return dataKiList.data.kiList.filter(({ code }) =>
        selectedKiList.includes(code)
      );
    }
    return dataKiList.data.kiList;
  }
);

/**
 * [モード別集計] 機種スライダーで選択されている機種
 */
const modeCurrentKiSelector = createSelector(
  [dataModeDataSelector, dataModeKiListDataSelector],
  (modeData, dataKiList) => {
    const kiCode = modeData?.setting.kiList?.at(0);
    const kiList = dataKiList?.data.kiList;

    const selectedKi =
      kiList?.find(({ code }) => code === kiCode) ?? kiList?.at(0);

    return selectedKi;
  }
);

/**
 * [モード別集計] 機種スライダーで選択されている機種の機種コード
 */
export const modeCurrentKiCodeSelector = createSelector(
  [modeCurrentKiSelector],
  (ki) => {
    return ki?.code;
  }
);

/**
 * [モード別集計] 機種スライダーで選択されている機種の機種名
 */
export const modeCurrentKiNameSelector = createSelector(
  [modeCurrentKiSelector],
  (ki) => {
    return ki?.name;
  }
);

/**
 * [モード別集計] 検索フォームで検索された期間
 */
export const modeSelectedDateRangeParamsSelector = createSelector(
  modeSettingSelector,
  (modeSetting) => {
    return modeSetting.selectedDateRangeParams;
  }
);

/**
 * [モード別集計] 非モード項目の並び順
 */
export const modeColumnsNotModeSpecificOrderSelector = createSelector(
  modeSettingSelector,
  (modeSetting) => {
    return modeSetting.columnsNotModeSpecificOrder;
  }
);

/**
 * [モード別集計] モード項目の並び順
 */
export const modeColumnsModeSpecificOrderSelector = createSelector(
  modeSettingSelector,
  (modeSetting) => {
    return modeSetting.columnsModeSpecificOrder;
  }
);

/**
 * [モード別集計] 選択中のお気に入りID
 *
 * undefinedが戻されるときは未選択
 */
export const modeSelectedFavoriteSelector = createSelector(
  modeSettingSelector,
  (modeSetting) => {
    return modeSetting.selectedFavoriteId;
  }
);

/**
 * [モード別集計] 選択中のお気に入りデータ
 *
 * undefinedが戻されるときは未選択
 */
export const modeSelectedFavoriteDataSelector = createSelector(
  [modeSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId == null) {
      return;
    }

    return favorites?.favorites?.find((favorite) => favorite.id === favoriteId);
  }
);

/**
 * [モード別集計] 表示入れ替え済みの表示項目データ
 *
 * 表示項目の新規項目追加時に、columnsOrderの更新が必要になりますが
 * 一度もDnDをしていない場合にはcolumnsOrderが存在していないため実テーブルのデータを使用します
 */
export const dataModeColumnsSwappedSelector = createSelector(
  [
    dataModeDataSelector,
    modeColumnsNotModeSpecificOrderSelector,
    modeColumnsModeSpecificOrderSelector,
  ],
  (dataSis, columnsNotModeOrder, columnsModeOrder) => {
    if (dataSis == null) {
      return dataSis;
    }

    const swappedFieldsOrder = dataSwapColumnsOrder(
      dataSis,
      columnsNotModeOrder,
      columnsModeOrder
    );

    return swappedFieldsOrder;
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function modeSettingReducer(
  state = initialState,
  action: ModeSettingAction
): ModeSettingState {
  switch (action.type) {
    case SEARCH_DATA_MODE:
      return {
        ...state,
        searchParams: action.payload.params,
        selectedDateRangeParams: action.payload.dateRangeParams,
        selectedShu: action.payload.selectedShu,
        selectedKiList: action.payload.selectedKiList,
      };
    case SEARCH_RESET_DATA_MODE:
      return {
        ...state,
        searchParams: undefined,
        selectedDateRangeParams: initialState.selectedDateRangeParams,
        selectedShu: initialState.selectedShu,
        selectedKiList: initialState.selectedKiList,
      };
    case CHANGE_MODE_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favoriteId,
      };
    case SELECT_MODE_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload.params,
      };
    case SELECT_MODE_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: action.payload.dateRangeParams,
      };
    case SELECT_MODE_SELECTED_SHU:
      return {
        ...state,
        selectedShu: action.payload.shuOptions,
      };
    case SELECT_MODE_SELECTED_KILIST:
      return {
        ...state,
        selectedKiList: action.payload.kiList,
      };
    case SELECT_MODE_COLUMNS_NOT_MODE_SPECIFIC_ORDER: {
      return {
        ...state,
        columnsNotModeSpecificOrder: action.payload.columnsNotModeSpecific,
      };
    }
    case SELECT_MODE_COLUMNS_MODE_SPECIFIC_ORDER: {
      return {
        ...state,
        columnsModeSpecificOrder: action.payload.columnsModeSpecific,
      };
    }
    default:
      return state;
  }
}
