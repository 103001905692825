import { eachDayOfInterval, format } from 'date-fns';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { filteredShuOptions } from '../domain/chain/filteredShuOptions';
import { ComparisonGraphParams } from '../domain/comparisonGraph';
import { DataHallKadoParams } from '../domain/dataHallKado';
import { DataHallKiParams } from '../domain/dataHallKi';
import {
  DataHallShuParams,
  DataHallShuSummaryParams,
} from '../domain/dataHallShu';
import { DataKadoGraphParams } from '../domain/dataKadoGraph';
import { FavoriteItem } from '../domain/favorites';
import { HallReportsFavorite } from '../domain/hall/types';
import { HallReportsFormConditions } from '../domain/hallReportsFormConditions';
import { Hall, LoadingState } from '../domain/schemas';
import { SettingsOptionsHall } from '../domain/settingsOptionsHall';
import { ShuOption } from '../domain/shu';

import { DataHallDailyCommentsActionCreators } from '../redux/server/dataHallDailyComments';
import {
  DataHallKiActionCreators,
  dataHallKiColumnsOrderDataSelector,
  dataHallKiSettingSelector,
} from '../redux/server/dataHallKi';
import {
  DataHallKiGraphActionCreators,
  dataHallKiGraphSelectedKiListAllSelector,
  dataHallKiGraphSettingSelector,
} from '../redux/server/dataHallKiGraph';
import { DataHallMksActionCreators } from '../redux/server/dataHallMks';
import {
  DataHallShuActionCreators,
  dataHallShuColumnsOrderSelector,
  dataHallShuSettingSelector,
  dataHallShuSummarySettingSelector,
} from '../redux/server/dataHallShu';
import {
  DataHallShuKadoActionCreators,
  dataHallShuKadoParamsSelector,
} from '../redux/server/dataHallShuKadoTable';
import {
  DataHallShuKadoTableGraphActionCreators,
  dataHallShuKadoTableGraphParamsSelector,
} from '../redux/server/dataHallShuKadoTableGraph';
import {
  SettingsOptionsHallActionTypes,
  settingsOptionsHallHallSelector,
  settingsOptionsHallLoadingStateSelector,
  settingsOptionsHallSelector,
} from '../redux/server/settingsOptionsHall';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import {
  ChangeHallReportsFavoriteAction,
  CreateHallReportsShortenedUrlAction,
  HallReportsSettingActionCreators,
  HallReportsSettingActionTypes,
  HallReportsSettingState,
  SaveAsHallReportsFavoriteAction,
  SaveHallReportsFavoriteAction,
  SearchHallReportsAction,
  SearchHallReportsDateRangeSlideAction,
  hallReportsComparativeSectionSelector,
  hallReportsKiSelectedTableFilterSelector,
  hallReportsSearchConditionSelector,
  hallReportsSelectedDateRangeParamsSelector,
  hallReportsSelectedFavoriteDataSelector,
  hallReportsSelectedFavoriteSelector,
  hallReportsSelectedFavoriteSettingSelector,
  hallReportsSelectedShuSelector,
  hallReportsSelectedTabIDSelector,
  hallReportsSelectedTableFilterSelector,
  hallReportsSettingSelector,
  searchHallReportsAction,
} from '../redux/ui/hallReportsSetting';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import { userHallInChargeSelector } from '../redux/ui/user';
import { ComparativeSection } from '../utils/comparativeSectionUtil';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import { findComparativeSection } from '../utils/findComparativeSection';
import {
  ReportsDateRange,
  ReportsDateUnit,
  changeNameDateUnit,
  makeDateFromDateUnitAndRange,
} from '../utils/reportsDateRange';
import { selectShus2HallReportSearchCondition } from '../utils/shu';

/**
 * 選択中の種別の情報を元に期間指定部分の検索条件を加工する
 * @param dateRange 現在選択中の期間
 * @param dateUnit 現在選択中の日付単位
 * @param isComparison 比較期間の有無
 * @param formConditions 現在の検索条件
 * @returns 加工された期間指定の検索条件
 */
export const searchConditionToDateRangeParams = (
  dateRange: ReportsDateRange,
  dateUnit: ReportsDateUnit,
  isComparison: boolean,
  formConditions: HallReportsFormConditions,
  comparativeSection?: ComparativeSection | undefined
): HallReportsFormConditions => {
  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    const date = makeDateFromDateUnitAndRange(
      dateRange,
      dateUnit,
      new Date(),
      undefined,
      comparativeSection,
      formConditions.excludeToday
    );
    return {
      ymdList: date.ymdList.map((date) => format(date, 'yyyy-MM-dd')),
      ymdComparisonList: isComparison
        ? date.ymdComparisonList.map((date) => format(date, 'yyyy-MM-dd'))
        : undefined,
    };
  }

  return {
    // dateが算出されていない場合はカスタムと判断し、保持されている検索パラメータの値をそのまま送る
    // 保持されている検索パラメータにymdListがない場合は、ymdList対応以前のお気に入りと判断し、startDateとendDateからymdListを算出する
    ymdList:
      formConditions.ymdList !== undefined
        ? formConditions.ymdList
        : formConditions.startDate !== undefined &&
          formConditions.endDate !== undefined
        ? eachDayOfInterval({
            start: new Date(formConditions.startDate),
            end: new Date(formConditions.endDate),
          }).map((date) => format(date, 'yyyy-MM-dd'))
        : undefined,
    // 比較期間の有無で startComparisonDateとendComparisonDate を送るか判定する
    ymdComparisonList: isComparison
      ? formConditions.ymdComparisonList !== undefined
        ? formConditions.ymdComparisonList
        : formConditions.startComparisonDate !== undefined &&
          formConditions.endComparisonDate !== undefined
        ? eachDayOfInterval({
            start: new Date(formConditions.startComparisonDate),
            end: new Date(formConditions.endComparisonDate),
          }).map((date) => format(date, 'yyyy-MM-dd'))
        : undefined
      : undefined,
  };
};

/**
 * お気に入り選択時、検索条件に反映する
 * @param action Action
 */
function* applyFavoriteByIdSaga(action: ChangeHallReportsFavoriteAction) {
  // グラフ自動開閉に関する状態を初期化する
  yield put(
    HallReportsSettingActionCreators.resetHallReportsIsGraphClosedByUser()
  );
  yield put(HallReportsSettingActionCreators.resetHallReportsCheckedKiList());

  // 選択されたお気に入りID
  const selectedFavoriteId = action.payload.favorite;

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (selectedFavoriteId === undefined) {
    yield put(HallReportsSettingActionCreators.searchResetHallReportsAction());
    yield put(
      HallReportsSettingActionCreators.resetHallReportsTableFilterAction()
    );
    yield put(
      HallReportsSettingActionCreators.resetAllHallReportsKiTableFilterAction()
    );
    return;
  }

  // 現在選択中のお気に入りの店舗レポートの各種データ
  const favorite: HallReportsFavorite = yield select(
    hallReportsSelectedFavoriteSettingSelector
  );

  if (favorite == null) {
    throw new Error('該当するお気に入りが見つかりません');
  }
  yield fork(applyFavoriteSaga, favorite);
}

/**
 * HallReportsFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(favorite: HallReportsFavorite) {
  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsHallLoadingStateSelector
  );
  if (settingsOptionsLoadingState !== 'loaded') {
    yield take(
      SettingsOptionsHallActionTypes.FETCH_SETTINGS_OPTIONS_HALL_SUCCESS
    );
  }
  const settingsOptions: SettingsOptionsHall = yield select(
    settingsOptionsHallSelector
  );

  // 期間を適用する
  const dateRange = favorite.selectedDateRangeParams.dateRange;
  // ボタン名称が古いお気に入りを変換
  const dateUnit = changeNameDateUnit(
    favorite.selectedDateRangeParams.dateUnit
  );
  const isComparison = favorite.selectedDateRangeParams.isComparison;

  // 過去のお気に入りを考慮してデフォルト値を設定する
  const comparativeSection = findComparativeSection(
    favorite.selectedDateRangeParams.dateRange,
    favorite.comparativeSection
  );

  yield put(
    HallReportsSettingActionCreators.selectHallReportsDateRangeParamsAction(
      dateRange,
      dateUnit,
      isComparison,
      comparativeSection
    )
  );

  // 検索条件の期間部分を再計算
  const dateRangeParams = searchConditionToDateRangeParams(
    dateRange,
    dateUnit,
    isComparison,
    favorite.searchCondition,
    comparativeSection
  );

  //ymdList対応以前に作成したお気に入りを考慮
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = favorite.searchCondition;

  // 検索条件を適用する
  yield put(
    HallReportsSettingActionCreators.selectHallReportsSearchConditionAction({
      ...rest,
      // 期間を再算出して上書きする
      ...dateRangeParams,
    })
  );

  let shuOptions: ShuOption[] = [];
  // 選択された種別を適用する
  // 存在しない種別は除外する
  if (favorite.selectedShu.length > 0) {
    shuOptions = filteredShuOptions(
      favorite.selectedShu,
      settingsOptions.searchCondition
    );
  }

  yield put(
    HallReportsSettingActionCreators.selectHallReportsShuAction(shuOptions)
  );

  // 種別実績のお気に入り適用
  if (favorite.selectedTabId === 'hallReportsShu') {
    // 種別実績テーブルを取得
    if (favorite.dataHallShu?.setting !== undefined) {
      //ymdList対応以前に作成したお気に入りを考慮
      const {
        startDate,
        endDate,
        startComparisonDate,
        endComparisonDate,
        ...rest
      } = favorite.dataHallShu.setting;
      yield put(
        DataHallShuActionCreators.fetchDataHallShuAction({
          ...rest,
          // 期間を再算出して上書きする
          ...dateRangeParams,
          ...selectShus2HallReportSearchCondition(shuOptions),
        })
      );
    }
    // 種別実績テーブルSummaryを取得
    if (favorite.dataHallShuSummary?.setting !== undefined) {
      //ymdList対応以前に作成したお気に入りを考慮
      const { startDate, endDate, ...rest } =
        favorite.dataHallShuSummary.setting;
      yield put(
        DataHallShuActionCreators.fetchDataHallShuSummaryAction({
          ...rest,
          // 期間を再算出して上書きする
          ...dateRangeParams,
          ...selectShus2HallReportSearchCondition(shuOptions),
        })
      );
    }
    // 時間帯別稼働数テーブルを取得
    if (favorite.dataHallShuKadoTable?.setting !== undefined) {
      //ymdList対応以前に作成したお気に入りを考慮
      const { startDate, endDate, ...rest } =
        favorite.dataHallShuKadoTable.setting;
      yield put(
        DataHallShuKadoActionCreators.fetchDataHallShuKadoAction({
          ...rest,
          // 期間を再算出して上書きする
          ...dateRangeParams,
          ...selectShus2HallReportSearchCondition(shuOptions),
        })
      );
    }
    // 時間帯別稼働数グラフを取得
    if (favorite.dataHallShuKadoTableGraph?.setting !== undefined) {
      //ymdList対応以前に作成したお気に入りを考慮
      const {
        startDate,
        endDate,
        startComparisonDate,
        endComparisonDate,
        shuList,
        ...rest
      } = favorite.dataHallShuKadoTableGraph.setting;

      const selectedShuList = shuList?.at(0);
      const filteredShuList = selectedShuList
        ? settingsOptions.searchCondition.shuList.find(
            (shu) => shu === selectedShuList
          )
        : undefined;

      yield put(
        DataHallShuKadoTableGraphActionCreators.fetchDataHallShuKadoTableGraphAction(
          {
            ...rest,
            // 期間を再算出して上書きする
            ...dateRangeParams,
            shuList: filteredShuList ? [filteredShuList] : undefined,
          }
        )
      );
    }

    // 種別実績の並び替え情報を適用
    if (favorite.dataHallShu?.columnsOrder) {
      yield put(
        DataHallShuActionCreators.selectDataHallShuColumnsOrderAction(
          favorite.dataHallShu.columnsOrder
        )
      );
    }

    // 稼動グラフの数値ラベル表示を復元
    yield put(
      HallReportsSettingActionCreators.selectKadoGraphShowNumberLabelAction(
        favorite.showShuKadoGraphNumberLabel ?? true
      )
    );

    // 新台/メイン機種のデータを破棄する
    yield fork(disposeHallReportsKiData);
    // 商圏実績タブのデータを破棄する
    yield put(DataHallMksActionCreators.clearDataHallMks());
  }

  // 新台/メイン機種のお気に入り適用
  if (favorite.selectedTabId === 'hallReportsKi') {
    // 新台/メイン機種のデータを破棄する
    yield fork(disposeHallReportsKiData);

    // fetch時に再取得したいため、コメントがあれば削除
    const date = favorite.searchCondition.ymdList?.at(0);
    if (date) {
      yield put(
        DataHallDailyCommentsActionCreators.clearDataHallDailyCommentsAction(
          date
        )
      );
    }

    // 新台/メイン機種テーブルをお気に入り条件で取得
    yield put(
      DataHallKiActionCreators.searchDataHallKiFavoriteAction({
        ...favorite,
        searchCondition: {
          ...favorite.searchCondition,
          ...selectShus2HallReportSearchCondition(shuOptions),
        },
        selectedShu: shuOptions,
      })
    );

    // 新台/メイン機種の並び替え情報を適用
    if (favorite.dataHallKi?.columnsOrder) {
      yield put(
        DataHallKiActionCreators.selectDataHallKiColumnsOrderAllAction(
          favorite.dataHallKi.columnsOrder
        )
      );
    }
    // 新台/メイン機種推移グラフ（機種）の取得
    const dataHallKiGraphParams = favorite.dataHallKiGraph?.setting;
    if (dataHallKiGraphParams !== undefined) {
      const dataHallKiGraph = Object.keys(dataHallKiGraphParams).reduce(
        (acc, shuCode) => {
          return {
            ...acc,
            [shuCode]: {
              setting: dataHallKiGraphParams[shuCode],
              data: null,
            },
          };
        },
        {}
      );

      yield put(
        DataHallKiGraphActionCreators.selectDataHallKiGraphSettingForFavorite(
          dataHallKiGraph
        )
      );

      // 絞り込む機種コードを登録
      yield put(
        DataHallKiGraphActionCreators.changeDataHallKiGraphKiAll(
          favorite.dataHallKiGraph?.selectKi ?? {}
        )
      );
    }

    // グラフの数値ラベル表示を復元
    yield put(
      HallReportsSettingActionCreators.selectKiGraphShowNumberLabelAction(
        favorite.showKiGraphNumberLabel ?? true
      )
    );

    // 種別実績のデータを破棄する
    yield fork(disposeHallReportsShuData);
    // 商圏実績タブのデータを破棄する
    yield put(DataHallMksActionCreators.clearDataHallMks());
  }

  // 商圏実績タブのお気に入り適用
  if (favorite.selectedTabId === 'hallReportsMks' && favorite?.mks) {
    yield put(
      HallReportsSettingActionCreators.restoreMksFromFavoriteAction(
        favorite.mks
      )
    );
    // 新台/メイン機種のデータを破棄する
    yield fork(disposeHallReportsKiData);
    // 種別実績のデータを破棄する
    yield fork(disposeHallReportsShuData);
  }

  // テーブルフィルター項目を取得する
  // 項目一覧を反映する
  yield put(
    HallReportsSettingActionCreators.applyHallReportsTableFilterAction(
      favorite.tableFilterItems ?? []
    )
  );
  yield put(
    HallReportsSettingActionCreators.applyHallReportsKiTableFilterAction(
      favorite.tableFilterItemsKi ?? []
    )
  );

  // タブを選択
  yield put(
    HallReportsSettingActionCreators.selectHallReportsTabIDAction(
      favorite.selectedTabId ?? 'hallReportsShu'
    )
  );

  // ユーザーが閉じたグラフの種別を復元
  if (favorite.isKiGraphClosedByUser) {
    yield put(
      HallReportsSettingActionCreators.selectHallReportsIsGraphClosedByUserForFavorite(
        favorite.isKiGraphClosedByUser
      )
    );
  }

  // チェックされた機種を復元
  if (favorite.checkedModelCodes) {
    yield put(
      HallReportsSettingActionCreators.selectCheckedKiListForFavorite(
        favorite.checkedModelCodes
      )
    );
  }
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(action: SaveHallReportsFavoriteAction) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    hallReportsSelectedFavoriteSelector
  );
  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem = yield select(
    hallReportsSelectedFavoriteDataSelector
  );
  // 現在の店舗レポートの設定すべて
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined) return;

  // 変更後のお気に入りデータ
  let favorite: FavoriteItem = {};
  if (action.payload.mode === 'memo') {
    // メモのみ更新モードでの変更後のお気に入りデータ
    favorite = {
      ...selectedFavorite,
      memo: action.payload.memo,
    };
  } else {
    favorite = {
      name: action.payload.name,
      isShared: action.payload.isShared,
      pageSetting,
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    };
  }

  // お気に入りを上書き
  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
      selectedFavoriteId,
      favorite
    )
  );
}

/**
 * お気に入りに保存するデータの生成
 */
function* setPageSetting() {
  // 現在の店舗レポートの設定すべて
  const pageSetting: HallReportsSettingState = yield select(
    hallReportsSettingSelector
  );
  // 種別実績テーブルの検索条件を取得
  const dataHallShuParams: DataHallShuParams | undefined = yield select(
    dataHallShuSettingSelector
  );
  // 種別実績テーブルSummaryの検索条件を取得
  const dataHallShuSummaryParams: DataHallShuSummaryParams | undefined =
    yield select(dataHallShuSummarySettingSelector);
  // 時間帯別稼働数テーブルの検索条件を取得
  const dataHallShuKadoTableParams: DataHallKadoParams | undefined =
    yield select(dataHallShuKadoParamsSelector);
  // 時間帯別稼働数グラフの検索条件を取得
  const dataHallShuKadoGraphParams: DataKadoGraphParams | undefined =
    yield select(dataHallShuKadoTableGraphParamsSelector);
  // 新台/メイン機種テーブルの検索条件を取得
  const dataHallKiParams: { [key: string]: DataHallKiParams } = yield select(
    dataHallKiSettingSelector
  );
  // 新台/メイン機種グラフの検索条件を取得
  const dataHallKiGraphParams: {
    [key: string]: ComparisonGraphParams;
  } = yield select(dataHallKiGraphSettingSelector);
  // 新台/メイン機種グラフの選択中の種別
  const dataHallKiGraphSelectKi: {
    [key: string]: string[] | undefined;
  } = yield select(dataHallKiGraphSelectedKiListAllSelector);
  // 種別実績テーブル列の並び順
  const dataHallShuColumnsOrder: string[] | undefined = yield select(
    dataHallShuColumnsOrderSelector
  );
  // 新台/メイン機種テーブル列の並び順
  const dataHallKiColumnsOrder: {
    [key: string]: string[] | undefined;
  } = yield select(dataHallKiColumnsOrderDataSelector);

  // テーブルフィルターで選択中の項目
  const tableFilterSelectedItems: string[] | undefined = yield select(
    hallReportsSelectedTableFilterSelector
  );

  // 比較区分を取得
  const comparativeSection: ComparativeSection = yield select(
    hallReportsComparativeSectionSelector
  );

  const tableFilterKiSelectedItems: {
    code: string;
    items: string[];
  }[] = yield select(hallReportsKiSelectedTableFilterSelector);

  const favorite: FavoriteItem['pageSetting'] = {
    hallReports: {
      searchCondition: pageSetting.searchCondition,
      selectedShu: pageSetting.selectedShu,
      selectedDateRangeParams: pageSetting.selectedDateRangeParams,
      selectedTabId: pageSetting.selectedTabId,
      //種別実績の検索条件
      ...(pageSetting.selectedTabId === 'hallReportsShu'
        ? {
            dataHallShu: {
              setting: dataHallShuParams ?? {},
              columnsOrder: dataHallShuColumnsOrder,
            },
            dataHallShuSummary: { setting: dataHallShuSummaryParams ?? {} },
            dataHallShuKadoTable: {
              setting: dataHallShuKadoTableParams ?? {},
            },
            dataHallShuKadoTableGraph: {
              setting: dataHallShuKadoGraphParams ?? {},
            },
          }
        : {}),
      //新台/メイン機種の検索条件
      ...(pageSetting.selectedTabId === 'hallReportsKi'
        ? {
            dataHallKi: {
              setting: dataHallKiParams,
              columnsOrder: dataHallKiColumnsOrder,
            },
            dataHallKiGraph: {
              setting: dataHallKiGraphParams,
              selectKi: dataHallKiGraphSelectKi,
            },
          }
        : {}),
      tableFilterItems: tableFilterSelectedItems || [],
      tableFilterItemsKi: tableFilterKiSelectedItems || [],
      mks: pageSetting.mks,
      showShuKadoGraphNumberLabel: pageSetting.showKadoGraphNumberLabel,
      showKiGraphNumberLabel: pageSetting.showKiGraphNumberLabel,
      isKiGraphClosedByUser: pageSetting.isKiGraphClosedByUser,
      checkedModelCodes: pageSetting.checkedModelCodes,
      comparativeSection: comparativeSection,
    },
  };
  return favorite;
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(action: SaveAsHallReportsFavoriteAction) {
  // 現在の店舗レポートの設定すべて
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // 変更後のお気に入りデータ
  const favorite: FavoriteItem = {
    name: action.payload.name,
    isShared: action.payload.isShared,
    pageName: '店舗レポート',
    pageSetting,
    memo: action.payload.memo,
    privatelySharedUsers: action.payload.sharedUser,
  };

  // お気に入りを上書き
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([favorite], {})
  );
}

/**
 * 店舗レポート種別実績内のテーブルとグラフのデータを破棄する
 */
function* disposeHallReportsShuData() {
  // 種別実績のテーブルとSummaryのデータを破棄
  yield put(DataHallShuActionCreators.clearAllDataHallShuAction());
  // 時間帯別稼働数テーブルのデータを破棄
  yield put(DataHallShuKadoActionCreators.clearDataHallShuKadoTableAction());
  // 時間帯別稼働数グラフのデータを破棄
  yield put(
    DataHallShuKadoTableGraphActionCreators.clearDataHallShuKadoTableGraphAction()
  );
}

/**
 * 店舗レポート新台/メイン機種内のテーブルとグラフのデータを破棄する
 */
function* disposeHallReportsKiData() {
  // 店舗レポート新台/メイン機種のデータを破棄
  yield put(DataHallKiActionCreators.clearAllDataHallKiAction());
  // 店舗レポート新台/メイン機種 推移グラフ（機種比較）のデータを破棄
  yield put(DataHallKiGraphActionCreators.hideAllDataHallKiGraphAction());
}

/**
 * 検索ボタン押下時に行う処理
 * @param action 検索時のAction
 */
function* searchHallReportsActionSaga(action: SearchHallReportsAction) {
  // 検索条件をセット
  yield put(
    HallReportsSettingActionCreators.selectHallReportsSearchConditionAction(
      action.payload.params
    )
  );

  // 種別一覧をセット
  yield put(
    HallReportsSettingActionCreators.selectHallReportsShuAction(
      action.payload.selectedShu
    )
  );

  const { dateRange, dateUnit, isComparison, comparativeSection } =
    action.payload;

  // dateRange・dateUnit（選択中の日付単位・期間）を更新する
  yield put(
    HallReportsSettingActionCreators.selectHallReportsDateRangeParamsAction(
      dateRange,
      dateUnit,
      isComparison,
      comparativeSection
    )
  );

  // 検索条件の期間部分を再計算
  const dateRangeParams = searchConditionToDateRangeParams(
    dateRange,
    dateUnit,
    isComparison,
    action.payload.params,
    comparativeSection
  );

  // 検索条件を混ぜる
  const searchCondition = {
    ...action.payload.params,
    ...selectShus2HallReportSearchCondition(action.payload.selectedShu),
    ...dateRangeParams,
  };

  // 現在選択中のタブを取得（表示しているタブだけ再取得を行う）
  const tabID: string = yield select(hallReportsSelectedTabIDSelector);

  // 種別実績
  if (tabID === 'hallReportsShu') {
    // 開いていないタブのデータを破棄する
    yield put(DataHallKiActionCreators.clearAllDataHallKiAction()); // 新台/メイン機種
    // 商圏実績タブのデータを破棄する
    yield put(DataHallMksActionCreators.clearDataHallMks());

    // 種別実績を再取得
    yield put(
      DataHallShuActionCreators.searchDataHallShuAction(searchCondition)
    );
    // 時間帯別稼働数テーブルを再取得
    yield put(
      DataHallShuKadoActionCreators.searchDataHallShuKadoTableAction(
        searchCondition
      )
    );
    // 時間帯別稼働数グラフを再取得
    yield put(
      DataHallShuKadoTableGraphActionCreators.searchDataHallShuKadoTableGraphAction(
        searchCondition
      )
    );
    return;
  }

  // 新台/メイン機種
  if (tabID === 'hallReportsKi') {
    // 開いていないタブのデータを破棄する
    yield fork(disposeHallReportsShuData); // 種別実績
    // 商圏実績タブのデータを破棄する
    yield put(DataHallMksActionCreators.clearDataHallMks());

    // 新台/メイン機種テーブルを再取得
    yield put(DataHallKiActionCreators.searchDataHallKiAction(searchCondition));
    return;
  }
}

/**
 * 条件リセット用の初期化処理
 */
function* resetSearchActionSaga() {
  // 店舗レポート種別実績内のテーブルとグラフのデータを破棄する
  yield fork(disposeHallReportsShuData);
  // 店舗レポート新台/メイン機種のテーブルとグラフデータを破棄する
  yield fork(disposeHallReportsKiData);
  // 店舗レポート新台/メイン機種 推移グラフ（機種比較）のデータを破棄
  yield put(DataHallKiGraphActionCreators.hideAllDataHallKiGraphAction());
  // 店舗レポート商圏はuseEffectで再取得しているためここで破棄は行わない

  // 現在選択中のお気に入りを取得する
  const favoriteId: number | undefined = yield select(
    hallReportsSelectedFavoriteSelector
  );

  // お気に入りが選択されている場合はお気に入り適用
  if (favoriteId !== undefined) {
    yield put(
      HallReportsSettingActionCreators.changeHallReportsFavoriteAction(
        favoriteId
      )
    );
    return;
  }

  // デフォルトの検索条件に戻す
  yield put(
    HallReportsSettingActionCreators.resetHallReportsSearchConditionAction()
  );
  yield put(HallReportsSettingActionCreators.resetHallReportsShuAction());

  // 検索条件に店舗を指定する（初期化された検索条件に店舗情報は無く、未指定の場合、全店舗のデータがレスポンスされる）
  const hallCodeInCharge: string | null = yield select(
    userHallInChargeSelector
  );
  const halls: Hall[] = yield select(settingsOptionsHallHallSelector);
  const defaultHall = hallCodeInCharge ?? halls.map((hall) => hall.code).at(0);
  yield put(
    HallReportsSettingActionCreators.selectHallReportsCurrentHallsAction(
      defaultHall ? [defaultHall] : []
    )
  );

  // 選択中の期間をリセットする
  yield put(
    HallReportsSettingActionCreators.resetHallReportsDateRangeParamsAction()
  );

  // 現在選択中のタブを取得（表示しているタブだけ再取得を行う）
  const tabID: string = yield select(hallReportsSelectedTabIDSelector);

  // 種別実績
  if (tabID === 'hallReportsShu') {
    // 種別実績テーブル・Summary初回取得
    yield put(DataHallShuActionCreators.initDataHallShuAction());
    // 時間帯別稼働数テーブル初回取得
    yield put(DataHallShuKadoActionCreators.initDataHallShuKadoTableAction());
    // 時間帯別稼働数グラフ初回取得
    yield put(
      DataHallShuKadoTableGraphActionCreators.initDataHallShuKadoTableGraphAction()
    );
    return;
  }

  // MEMO: 商圏はuseEffectで再取得するためSagaでは再取得を行わない
  // MEMO: 新台/メイン機種はIntersectionObserverで再取得するためSagaでは再取得を行わない
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createHallReportsShortenedUrlSaga(
  action: CreateHallReportsShortenedUrlAction
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

/**
 * 検索ボタン押下時のActionがDispatchされるのを監視
 */
function* handleSearchSaga() {
  // データを再取得する
  yield takeEvery(
    HallReportsSettingActionTypes.SEARCH_HALL_REPORTS,
    searchHallReportsActionSaga
  );
}

/**
 * 条件リセットボタン押下時のActionがDispatchされるのを監視
 */
function* handleResetSaga() {
  // 条件リセット用の初期化処理
  yield takeEvery(
    HallReportsSettingActionTypes.SEARCH_RESET_HALL_REPORTS,
    resetSearchActionSaga
  );
}

function* handleFavoriteSaga() {
  // お気に入り選択
  yield takeEvery(
    HallReportsSettingActionTypes.CHANGE_HALL_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    HallReportsSettingActionTypes.SAVE_AS_HALL_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
  // お気に入り上書き保存
  yield takeEvery(
    HallReportsSettingActionTypes.SAVE_HALL_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
}

// 期間スライドコンポーネントがクリックされた時に検索期間を設定して再検索を実行する
function* searchDateRangeSlideSaga(
  action: SearchHallReportsDateRangeSlideAction
) {
  const { dateRange, ymdList, ymdComparisonList } = action.payload;
  // 検索条件の項目を取得
  const searchCondition: HallReportsFormConditions = yield select(
    hallReportsSearchConditionSelector
  );

  const { isComparison, dateUnit } = yield select(
    hallReportsSelectedDateRangeParamsSelector
  );

  const comparativeSection: ComparativeSection = yield select(
    hallReportsComparativeSectionSelector
  );

  const shuOptions: ShuOption[] = yield select(hallReportsSelectedShuSelector);

  yield put(
    searchHallReportsAction(
      {
        ...searchCondition,
        ymdList,
        ymdComparisonList,
      },
      shuOptions,
      dateRange,
      dateUnit,
      isComparison,
      comparativeSection
    )
  );

  const selectedTabId: string = yield select(hallReportsSelectedTabIDSelector);

  // 期間変更があった場合、タブ移動でデータの再取得が必要になるため
  // 現在表示していないタブの既存データをクリアする
  // 商圏は毎回タブの切替で戻ってきたときに再取得しなおしてるため本来は削除不要
  switch (selectedTabId) {
    case 'hallReportsShu': {
      yield put(DataHallKiActionCreators.renewDataHallKiAction());
      yield put(DataHallMksActionCreators.renewDataHallMks());
      break;
    }
    case 'hallReportsMks': {
      yield put(DataHallShuActionCreators.renewDataHallShuAction());
      yield put(DataHallKiActionCreators.renewDataHallKiAction());
      break;
    }
    case 'hallReportsKi': {
      yield put(DataHallShuActionCreators.renewDataHallShuAction());
      yield put(DataHallMksActionCreators.renewDataHallMks());
      break;
    }
  }
}

// 期間スライドコンポーネントがクリックされた時に実行する処理
function* handleDateRangeSlideSaga() {
  yield takeEvery(
    HallReportsSettingActionTypes.SEARCH_HALL_REPORTS_DATE_RANGE_SLIDE,
    searchDateRangeSlideSaga
  );
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    HallReportsSettingActionTypes.CREATE_HALL_REPORTS_SHORTENED_URL,
    createHallReportsShortenedUrlSaga
  );
}

/**
 * 店舗レポートの設定に関するタスクを実行する
 */
export function* hallReportsSettingSaga() {
  yield fork(handleSearchSaga);
  yield fork(handleResetSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleDateRangeSlideSaga);
  yield fork(handleShortenedUrlSaga);
}
