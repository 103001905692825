import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';
import {
  SettingsKiLink,
  SettingsKiLinkParams,
  SettingsKiLinkPutParams,
} from '../../domain/settingsKiLink';

/**
 * TODO:
 * loadingStateを異なるAPIで共通で利用しているため、それぞれのLoading状態を個別にもたせる必要があります
 * https://github.com/DKClaris/claris-general/issues/3710
 */

// Action Types

const FETCH_SETTINGS_KI_LINK = 'FETCH_SETTINGS_KI_LINK' as const;
const FETCH_SETTINGS_KI_LINK_REQUEST =
  'FETCH_SETTINGS_KI_LINK_REQUEST' as const;
const FETCH_SETTINGS_KI_LINK_SUCCESS =
  'FETCH_SETTINGS_KI_LINK_SUCCESS' as const;
const RENEW_SETTINGS_KI_LINK = 'RENEW_SETTINGS_KI_LINK' as const;
const PUT_SETTINGS_KI_LINK = 'PUT_SETTINGS_KI_LINK' as const;
const PUT_SETTINGS_KI_LINK_REQUEST = 'PUT_SETTINGS_KI_LINK_REQUEST' as const;
const PUT_SETTINGS_KI_LINK_SUCCESS = 'PUT_SETTINGS_KI_LINK_SUCCESS' as const;
const PUT_SETTINGS_KI_LINK_FAILURE = 'PUT_SETTINGS_KI_LINK_FAILURE' as const;

export const SettingsKiLinkActionTypes = {
  FETCH_SETTINGS_KI_LINK,
  FETCH_SETTINGS_KI_LINK_REQUEST,
  FETCH_SETTINGS_KI_LINK_SUCCESS,
  RENEW_SETTINGS_KI_LINK,
  PUT_SETTINGS_KI_LINK,
  PUT_SETTINGS_KI_LINK_REQUEST,
  PUT_SETTINGS_KI_LINK_SUCCESS,
  PUT_SETTINGS_KI_LINK_FAILURE,
};

// Action Creators

function fetchSettingsKiLinkAction(query: SettingsKiLinkParams) {
  return {
    type: FETCH_SETTINGS_KI_LINK,
    payload: { query },
  };
}

function fetchSettingsKiLinkRequestAction() {
  return {
    type: FETCH_SETTINGS_KI_LINK_REQUEST,
  };
}

function fetchSettingsKiLinkSuccessAction(settingsKiLink: SettingsKiLink) {
  return {
    type: FETCH_SETTINGS_KI_LINK_SUCCESS,
    payload: { settingsKiLink },
  };
}

function renewSettingsKiLinkAction() {
  return {
    type: RENEW_SETTINGS_KI_LINK,
  };
}

function putSettingsKiLinkAction(
  kiCode: string,
  query: SettingsKiLinkPutParams[]
) {
  return {
    type: PUT_SETTINGS_KI_LINK,
    payload: { kiCode, query },
  };
}

function putSettingsKiLinkRequestAction() {
  return {
    type: PUT_SETTINGS_KI_LINK_REQUEST,
  };
}

function putSettingsKiLinkSuccessAction(settingsKiLink: SettingsKiLink) {
  return {
    type: PUT_SETTINGS_KI_LINK_SUCCESS,
    payload: { settingsKiLink },
  };
}

function putSettingsKiLinkFailureAction(error: ClarisApiError) {
  return {
    type: PUT_SETTINGS_KI_LINK_FAILURE,
    payload: { error },
  };
}

export const SettingsKiLinkActionCreators = {
  fetchSettingsKiLinkAction,
  fetchSettingsKiLinkRequestAction,
  fetchSettingsKiLinkSuccessAction,
  putSettingsKiLinkAction,
  putSettingsKiLinkRequestAction,
  putSettingsKiLinkSuccessAction,
  putSettingsKiLinkFailureAction,
  renewSettingsKiLinkAction,
};

// Actions

export type FetchSettingsKiLinkAction = ReturnType<
  typeof fetchSettingsKiLinkAction
>;
export type PutSettingsKiLinkAction = ReturnType<
  typeof putSettingsKiLinkAction
>;

type SettingsKiLinkAction =
  | FetchSettingsKiLinkAction
  | ReturnType<typeof fetchSettingsKiLinkRequestAction>
  | ReturnType<typeof fetchSettingsKiLinkSuccessAction>
  | ReturnType<typeof renewSettingsKiLinkAction>
  | PutSettingsKiLinkAction
  | ReturnType<typeof putSettingsKiLinkRequestAction>
  | ReturnType<typeof putSettingsKiLinkSuccessAction>
  | ReturnType<typeof putSettingsKiLinkFailureAction>;

// State

type SettingsKiLinkState = {
  settingsKiLink: SettingsKiLink | undefined;
  loadingState: LoadingState;
  error: ClarisApiError | undefined;
};

const initialState: SettingsKiLinkState = {
  settingsKiLink: undefined,
  loadingState: 'prepare',
  error: undefined,
};

// Selector

export function settingsKiLinkSelector(rootState: {
  settingsKiLink: SettingsKiLinkState;
}) {
  return rootState.settingsKiLink.settingsKiLink;
}

export function settingsKiLinkLoadingStateSelector(rootState: {
  settingsKiLink: SettingsKiLinkState;
}) {
  return rootState.settingsKiLink.loadingState;
}

// Reducer

export function settingsKiLinkReducer(
  state = initialState,
  action: SettingsKiLinkAction
) {
  switch (action.type) {
    case FETCH_SETTINGS_KI_LINK_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_KI_LINK_SUCCESS:
      return {
        ...state,
        settingsKiLink: action.payload.settingsKiLink,
        loadingState: 'loaded',
      };
    case PUT_SETTINGS_KI_LINK_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case PUT_SETTINGS_KI_LINK_SUCCESS:
      return {
        ...state,
        settingsKiLink: action.payload.settingsKiLink,
        loadingState: 'loaded',
      };
    case PUT_SETTINGS_KI_LINK_FAILURE:
      return {
        ...state,
        loadingState: 'failed',
        error: action.payload.error,
      };
    case RENEW_SETTINGS_KI_LINK:
      return initialState;
    default:
      return state;
  }
}
