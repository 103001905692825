import {
  DkSisSearchParams,
  SisAreaOption,
  SisBreakEvenForPachinkoOption,
  SisBreakEvenForPachisloOption,
  SisRankOption,
} from '../domain/dkSis';

type SisCondition =
  | 'sisBreakEvenForPachinko'
  | 'sisBreakEvenForPachislo'
  | 'sisRank'
  | 'sisArea';

export const NO_GRAPH = 'noGraph';
const SEPARATOR = '*-*';

const convertSisOptionsToGraphOptions =
  (category: SisCondition) =>
  ({ value, label }: { value: string; label: string }) => {
    let prefix = '';
    if (category === 'sisBreakEvenForPachinko') {
      prefix = '【損益分岐】 パチンコ';
    }
    if (category === 'sisBreakEvenForPachislo') {
      prefix = '【損益分岐】 パチスロ';
    }
    if (category === 'sisRank') {
      prefix = '【ランク】 ';
    }
    if (category === 'sisArea') {
      prefix = '【エリア】 ';
    }
    return {
      category: category as SisCondition,
      value: `${category}${SEPARATOR}${value}`,
      label: `${prefix}${label}`,
    };
  };

export const dksisConditionOptions: {
  category: SisCondition | typeof NO_GRAPH | 'none';
  value: string;
  label: string;
}[] = [
  {
    category: NO_GRAPH,
    label: '表示なし',
    value: 'noGraph',
  },
  {
    category: 'none',
    label: '全国',
    value: 'none',
  },
  ...SisBreakEvenForPachinkoOption.map(
    convertSisOptionsToGraphOptions('sisBreakEvenForPachinko')
  ),
  ...SisBreakEvenForPachisloOption.map(
    convertSisOptionsToGraphOptions('sisBreakEvenForPachislo')
  ),
  ...SisRankOption.map(convertSisOptionsToGraphOptions('sisRank')),
  ...SisAreaOption.map(convertSisOptionsToGraphOptions('sisArea')),
];

export const getDefaultDkSisSearchConditionValue = (
  isDkSisOn: boolean,
  dkSisSearchParams: DkSisSearchParams
) => {
  if (!isDkSisOn) {
    return NO_GRAPH;
  }

  const { sisBreakEvenForPachinko, sisBreakEvenForPachislo, sisRank, sisArea } =
    dkSisSearchParams || {};
  if (sisBreakEvenForPachinko) {
    return `sisBreakEvenForPachinko${SEPARATOR}${sisBreakEvenForPachinko}`;
  }
  if (sisBreakEvenForPachislo) {
    return `sisBreakEvenForPachislo${SEPARATOR}${sisBreakEvenForPachislo}`;
  }
  if (sisRank) {
    return `sisRank${SEPARATOR}${sisRank}`;
  }
  if (sisArea) {
    return `sisArea${SEPARATOR}${sisArea}`;
  }

  return 'none';
};

const defaultSisParams = {
  sisBreakEvenForPachinko: undefined,
  sisBreakEvenForPachislo: undefined,
  sisRank: undefined,
  sisArea: undefined,
  needsSis: false,
};

export const convertOptionValueToSisParam = (selectedOptionValue: string) => {
  if (selectedOptionValue === NO_GRAPH) {
    return defaultSisParams;
  }
  if (selectedOptionValue === 'none') {
    return {
      ...defaultSisParams,
      needsSis: true,
    };
  }
  const [property, value] = selectedOptionValue.split(SEPARATOR);
  return {
    ...defaultSisParams,
    needsSis: true,
    [property]: value,
  };
};

/**
 * 機種集計テーブルの推移グラフを開くアイコンクリック時に送信するDK-SISパラメータを作る
 */
export const getDkSisSearchParamsForOpenFromModelReportsRow = (
  isDkSisOn: boolean,
  dkSisSearchParams: DkSisSearchParams
) => {
  const option = getDefaultDkSisSearchConditionValue(
    isDkSisOn,
    dkSisSearchParams
  );
  return convertOptionValueToSisParam(option);
};
