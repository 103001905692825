import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsTerminalTransition } from '../../domain/settingsOptionsTerminalTransition';

import { RootState } from '../../store';
import { settingsOptionsTerminalTransitionDefaultValue } from '../../utils/settingsOptionsTerminalTransitionDefaultValue';
import { dataTerminalTransitionSettingAreasSelector } from './dataTerminalTransition';

// Action Types

const FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION =
  'FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION' as const;
const FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_REQUEST =
  'FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_SUCCESS =
  'FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_TERMINAL_TRANSITION =
  'RENEW_SETTINGS_OPTIONS_TERMINAL_TRANSITION' as const;

export const SettingsOptionsTerminalTransitionActionTypes = {
  FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION,
  FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_REQUEST,
  FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_SUCCESS,
  RENEW_SETTINGS_OPTIONS_TERMINAL_TRANSITION,
};

// Action Creators

function fetchSettingsOptionsTerminalTransitionAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION,
  };
}

function fetchSettingsOptionsTerminalTransitionRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_REQUEST,
  };
}

function fetchSettingsOptionsTerminalTransitionSuccessAction(
  settingsOptionsTerminalTransition: SettingsOptionsTerminalTransition
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_SUCCESS,
    payload: { settingsOptionsTerminalTransition },
  };
}

function renewSettingsOptionsTerminalTransitionAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_TERMINAL_TRANSITION,
  };
}

export const SettingsOptionsTerminalTransitionActionCreators = {
  fetchSettingsOptionsTerminalTransitionAction,
  fetchSettingsOptionsTerminalTransitionRequestAction,
  fetchSettingsOptionsTerminalTransitionSuccessAction,
  renewSettingsOptionsTerminalTransitionAction,
};

// Actions

type FetchSettingsOptionsTerminalTransitionAction = ReturnType<
  typeof fetchSettingsOptionsTerminalTransitionAction
>;

type SettingsOptionsTerminalTransitionAction =
  | FetchSettingsOptionsTerminalTransitionAction
  | ReturnType<typeof fetchSettingsOptionsTerminalTransitionRequestAction>
  | ReturnType<typeof fetchSettingsOptionsTerminalTransitionSuccessAction>
  | ReturnType<typeof renewSettingsOptionsTerminalTransitionAction>;

// State

type SettingsOptionsTerminalTransitionState = {
  settingsOptionsTerminalTransition?: SettingsOptionsTerminalTransition;
  loadingState: LoadingState;
};

const initialState: SettingsOptionsTerminalTransitionState = {
  settingsOptionsTerminalTransition: undefined,
  loadingState: 'prepare',
};

// Selector

// settingsOptionsを全て取得する
export const settingsOptionsTerminalTransitionSelector = (state: RootState) => {
  const settings =
    state.settingsOptionsTerminalTransition.settingsOptionsTerminalTransition;
  // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
  return settings ? settings : settingsOptionsTerminalTransitionDefaultValue;
};

// 現在のローディング状態を取得
export const settingsOptionsTerminalTransitionLoadingStateSelector = (
  state: RootState
) => {
  return state.settingsOptionsTerminalTransition.loadingState;
};

// フィルター用のジャンル一覧を取得する
export const settingsOptionsTerminalTransitionGenresSelector = createSelector(
  settingsOptionsTerminalTransitionSelector,
  (settingsOptionsTerminalTransition) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsTerminalTransition === undefined)
      return settingsOptionsTerminalTransitionDefaultValue.genres.transition;

    return settingsOptionsTerminalTransition.genres.transition;
  }
);

// SearchConditionだけ取得する
export const settingsOptionsTerminalTransitionSearchConditionSelector =
  createSelector(
    settingsOptionsTerminalTransitionSelector,
    (settingsOptionsTerminalTransition) => {
      // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
      return settingsOptionsTerminalTransition
        ? settingsOptionsTerminalTransition.searchCondition
        : settingsOptionsTerminalTransitionDefaultValue.searchCondition;
    }
  );

// fieldsだけ取得する
export const settingsOptionsTerminalTransitionFieldsSelector = createSelector(
  settingsOptionsTerminalTransitionSelector,
  (settingsOptionsTerminalTransition) => {
    return (
      (settingsOptionsTerminalTransition &&
        settingsOptionsTerminalTransition.fields) ||
      []
    );
  }
);

/**
 * 検索されたエリアのホール一覧を取得する
 */
export const settingsOptionsTerminalTransitionSearchConditionHallsBySearchedAreaSelector =
  createSelector(
    [
      settingsOptionsTerminalTransitionSearchConditionSelector,
      dataTerminalTransitionSettingAreasSelector,
    ],
    ({ halls, areas }, selectedAreaIds) => {
      if (!selectedAreaIds) {
        return halls ?? [];
      }
      const hallCodesInAreas = areas
        .filter((x) => selectedAreaIds.includes(x.id))
        .flatMap((x) => x.halls);

      return halls.filter((x) => hallCodesInAreas.includes(x.code));
    }
  );

export const settingsOptionsTerminalTransitionFieldsIsNewSelector =
  createSelector(settingsOptionsTerminalTransitionFieldsSelector, (fields) => {
    return [...fields.transition, ...fields.nonTransition].some(
      (field) => field.isNew
    );
  });

// Reducer

export function settingsOptionsTerminalTransitionReducer(
  state = initialState,
  action: SettingsOptionsTerminalTransitionAction
): SettingsOptionsTerminalTransitionState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION_SUCCESS:
      return {
        loadingState: 'loaded',
        settingsOptionsTerminalTransition:
          action.payload.settingsOptionsTerminalTransition,
      };
    case RENEW_SETTINGS_OPTIONS_TERMINAL_TRANSITION:
      return initialState;
    default:
      return state;
  }
}
