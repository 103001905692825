import { AxiosResponse } from 'axios';
import {
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  DataSettingsUnitModelAll,
  DataSettingsUnitModelDataByHallAndDate,
  DataSettingsUnitModelDataById,
} from '../domain/dataSettingsUnitModel';

import {
  DataSettingsUnitModelActionCreators,
  DataSettingsUnitModelActionTypes,
  FetchDataSettingsUnitModelDataByHallCodeAndDateRequest,
  FetchDataSettingsUnitModelDataByIdRequest,
  FetchDataSettingsUnitModelListAllRequest,
  PatchDataSettingsUnitModelDataByIdRequest,
  PostDataSettingsUnitModelDataByHallCodeAndDateRequest,
} from '../redux/server/dataSettingsUnitModel';
import {
  settingsUnitModelDateRangeSelector,
  settingsUnitModelHallCodeSelector,
} from '../redux/ui/settingsUnitModel';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsUnitModelSaga(
  api: Api,
  action: FetchDataSettingsUnitModelListAllRequest
) {
  try {
    const response: AxiosResponse<DataSettingsUnitModelAll> = yield call(
      api.get,
      `/settings/daiKisyuList`,
      buildConfig(action.payload)
    );
    yield put(
      DataSettingsUnitModelActionCreators.fetchDataSettingsUnitModelListAllSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataSettingsUnitModelActionCreators.renewDataSettingsUnitModelListAllAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* fetchSettingsUnitModelDataByHallAndDateSaga(
  api: Api,
  action: FetchDataSettingsUnitModelDataByHallCodeAndDateRequest
) {
  try {
    const { hallCode, openingDate } = action.payload;
    const response: AxiosResponse<DataSettingsUnitModelDataByHallAndDate> =
      yield call(
        api.get,
        `/settings/daiKisyuList/${hallCode}/${openingDate}`,
        buildConfig(action.payload)
      );
    yield put(
      DataSettingsUnitModelActionCreators.fetchDataSettingsUnitModelDataByHallCodeAndDateSuccessAction(
        {
          ...response.data,
          hallCode,
        }
      )
    );
  } catch (error: unknown) {
    yield put(
      DataSettingsUnitModelActionCreators.renewDataSettingsUnitModelListAllAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* postSettingsUnitModelDataByHallAndDateSaga(
  api: Api,
  action: PostDataSettingsUnitModelDataByHallCodeAndDateRequest
) {
  try {
    const { hallCode, openingDate, data } = action.payload;
    yield call(
      api.post,
      `/settings/daiKisyuList/${hallCode}/${openingDate}`,
      { data },
      buildConfig()
    );
    yield put(
      DataSettingsUnitModelActionCreators.postDataSettingsUnitModelDataByHallCodeAndDateSuccessAction()
    );

    const { startDate, endDate }: { startDate: string; endDate: string } =
      yield select(settingsUnitModelDateRangeSelector);
    yield put(
      DataSettingsUnitModelActionCreators.fetchDataSettingsUnitModelListAllRequestAction(
        startDate,
        endDate,
        hallCode
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      DataSettingsUnitModelActionCreators.postDataSettingsUnitModelDataByHallCodeAndDateFailureAction(
        error
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* fetchSettingsUnitModelDataByIdSaga(
  api: Api,
  action: FetchDataSettingsUnitModelDataByIdRequest
) {
  try {
    const { id } = action.payload;
    const response: AxiosResponse<DataSettingsUnitModelDataById> = yield call(
      api.get,
      `/settings/daiKisyuList/${id}`,
      buildConfig(action.payload)
    );
    yield put(
      DataSettingsUnitModelActionCreators.fetchDataSettingsUnitModelDataByIdSuccessAction(
        {
          ...response.data,
          id,
        }
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      DataSettingsUnitModelActionCreators.fetchDataSettingsUnitModelDataByIdFailureAction(
        error
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* patchSettingsUnitModelDataByIdSaga(
  api: Api,
  action: PatchDataSettingsUnitModelDataByIdRequest
) {
  try {
    const { id, body } = action.payload;
    yield call(api.patch, `/settings/daiKisyuList/${id}`, body, buildConfig());
    yield put(
      DataSettingsUnitModelActionCreators.patchDataSettingsUnitModelDataByIdSuccessAction()
    );

    const { startDate, endDate }: { startDate: string; endDate: string } =
      yield select(settingsUnitModelDateRangeSelector);
    const hallCode: string = yield select(settingsUnitModelHallCodeSelector);
    yield put(
      DataSettingsUnitModelActionCreators.fetchDataSettingsUnitModelListAllRequestAction(
        startDate,
        endDate,
        hallCode
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      DataSettingsUnitModelActionCreators.patchDataSettingsUnitModelDataByIdFailureAction(
        error
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataSettingsUnitModelSaga(api: Api) {
  yield takeLatest(
    DataSettingsUnitModelActionTypes.FETCH_DATA_SETTINGS_UNIT_MODEL_LIST_ALL_REQUEST,
    fetchSettingsUnitModelSaga,
    api
  );
}

function* handleFetchDataSettingsUnitModelDataByHallAndDateSaga(api: Api) {
  yield takeLatest(
    DataSettingsUnitModelActionTypes.FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST,
    fetchSettingsUnitModelDataByHallAndDateSaga,
    api
  );
}

function* handlePostDataSettingsUnitModelDetailSaga(api: Api) {
  yield takeLatest(
    DataSettingsUnitModelActionTypes.POST_DATA_SETTINGS_UNIT_MODEL_DATA_BY_HALL_CODE_AND_DATE_REQUEST,
    postSettingsUnitModelDataByHallAndDateSaga,
    api
  );
}

function* handleFetchDataSettingsUnitModelByIdSaga(api: Api) {
  yield takeLatest(
    DataSettingsUnitModelActionTypes.FETCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST,
    fetchSettingsUnitModelDataByIdSaga,
    api
  );
}

function* handlePatchDataSettingsUnitModelByIdSaga(api: Api) {
  yield takeEvery(
    DataSettingsUnitModelActionTypes.PATCH_DATA_SETTINGS_UNIT_MODEL_DATA_BY_ID_REQUEST,
    patchSettingsUnitModelDataByIdSaga,
    api
  );
}

function* handlePatchDataSettingsUnitIsEnabledSaga(api: Api) {
  yield takeEvery(
    DataSettingsUnitModelActionTypes.PATCH_DATA_SETTINGS_UNIT_MODEL_IS_ENABLED_REQUEST,
    patchSettingsUnitModelDataByIdSaga,
    api
  );
}

export function* dataSettingsUnitModelSagas(context: { api: Api }) {
  yield fork(handleFetchDataSettingsUnitModelSaga, context.api);
  yield fork(
    handleFetchDataSettingsUnitModelDataByHallAndDateSaga,
    context.api
  );
  yield fork(handlePostDataSettingsUnitModelDetailSaga, context.api);
  yield fork(handleFetchDataSettingsUnitModelByIdSaga, context.api);
  yield fork(handlePatchDataSettingsUnitModelByIdSaga, context.api);
  yield fork(handlePatchDataSettingsUnitIsEnabledSaga, context.api);
}
