export const defaultExcludeToday = true;

export const defaultDateRangeParams = {
  dateUnit: '範囲選択' as const,
  dateRange: '今週' as const,
  comparisonSection: '前週' as const,
};

export const defaultShu = {
  code: '',
  name: '種別全体',
  group: '',
  type: 'none', // shu.tsのselectShu2SearchConditionで種別未指定として扱われるように'none'を設定しておく
};
