import { add, endOfMonth, set, startOfMonth } from 'date-fns';

import { TransitiveDateType } from '../domain/dataTerminalTransition';

import { calcStartDateFromDateType } from './date';

// 日次の期間推移で使用する期間タイプ
export const dialyTransitiveDateRanges = [
  '前月',
  '今月',
  '7日',
  '14日',
  '30日',
  'カスタム',
] as const;
export type DailyTransitiveDateRange =
  (typeof dialyTransitiveDateRanges)[number];

// 週次の期間推移で使用する期間タイプ
export const weeklyTransitiveDateRanges = ['7週', '13週', 'カスタム'] as const;
export type WeeklyTransitiveDateRange =
  (typeof weeklyTransitiveDateRanges)[number];

const weeklyTransitiveDateRangesForTerminalTransition = [
  '前月',
  '今月',
  '13週',
  'カスタム',
] as const;
export type WeeklyTransitiveDateRangesForTerminalTransition =
  (typeof weeklyTransitiveDateRangesForTerminalTransition)[number];

// 月次の期間推移で使用する期間タイプ
export const monthlyTransitiveDateRanges = [
  '前年',
  '今年',
  '7ヶ月',
  '13ヶ月',
  'カスタム',
] as const;
export type MonthlyTransitiveDateRange =
  (typeof monthlyTransitiveDateRanges)[number];

// 機種別推移の月次期間タイプ
const monthlyTransitiveDateRangesForModelTransition = [
  '3ヶ月',
  '7ヶ月',
  '13ヶ月',
  'カスタム',
] as const;
export type MonthlyTransitiveDateRangesForModelTransition =
  (typeof monthlyTransitiveDateRangesForModelTransition)[number];

export type TransitiveDateRange =
  | DailyTransitiveDateRange
  | WeeklyTransitiveDateRange
  | WeeklyTransitiveDateRangesForTerminalTransition
  | MonthlyTransitiveDateRange
  | MonthlyTransitiveDateRangesForModelTransition;

/**
 * 期間推移：日次
 * 検索パラメータに渡す日付データを算出する
 * @param dateRange 期間指定
 * @param currentDate 基準日（未指定の場合は本日）
 */
export const makeDateForDailyFromDateRange = (
  dateRange: DailyTransitiveDateRange,
  currentDate: Date = new Date()
): { startDate: Date; endDate: Date } => {
  let startDate = currentDate;
  let endDate = currentDate;
  switch (dateRange) {
    case '前月': {
      const lastMonth = add(currentDate, { months: -1 });
      startDate = startOfMonth(lastMonth);
      endDate = endOfMonth(lastMonth);
      break;
    }
    case '今月': {
      startDate = startOfMonth(currentDate);
      break;
    }
    case '7日': {
      startDate = add(currentDate, { days: -6 });
      break;
    }
    case '14日': {
      startDate = add(currentDate, { days: -13 });
      break;
    }
    case '30日': {
      startDate = add(currentDate, { days: -29 });
      break;
    }
  }
  return {
    startDate,
    endDate,
  };
};

/**
 * 期間推移：週次
 * 検索パラメータに渡す日付データを算出する
 * @param dateRange 期間指定
 * @param currentDate 基準日（未指定の場合は本日）
 */
export const makeDateForWeeklyFromDateRange = (
  dateRange:
    | WeeklyTransitiveDateRange
    | WeeklyTransitiveDateRangesForTerminalTransition,
  currentDate: Date = new Date()
): { startDate: Date; endDate: Date } => {
  let startDate = currentDate;
  let endDate = currentDate;
  switch (dateRange) {
    case '7週': {
      startDate = calcStartDateFromDateType('7週', currentDate);
      break;
    }
    case '13週': {
      startDate = calcStartDateFromDateType('13週', currentDate);
      break;
    }
    case '前月': {
      startDate = calcStartDateFromDateType('前月', currentDate);
      endDate = endOfMonth(startDate);
      break;
    }
    case '今月': {
      startDate = calcStartDateFromDateType('今月', currentDate);
      endDate = endOfMonth(startDate);
      break;
    }
  }
  return {
    startDate,
    endDate,
  };
};

/**
 * 期間推移：月次
 * 検索パラメータに渡す日付データを算出する
 * @param dateRange 期間指定
 * @param currentDate 基準日（未指定の場合は本日）
 */
export const makeDateForMonthlyFromDateRange = (
  dateRange:
    | MonthlyTransitiveDateRange
    | MonthlyTransitiveDateRangesForModelTransition,
  currentDate: Date = new Date()
): { startDate: Date; endDate: Date } => {
  let startDate = currentDate;
  let endDate = currentDate;
  switch (dateRange) {
    case '前年': {
      const lastYear = add(startDate, { years: -1 });
      startDate = startOfMonth(set(lastYear, { month: 0 }));
      endDate = endOfMonth(set(lastYear, { month: 11 }));
      break;
    }
    case '今年': {
      startDate = startOfMonth(set(currentDate, { month: 0 }));
      break;
    }
    case '3ヶ月': {
      startDate = calcStartDateFromDateType('3ヶ月', currentDate);
      break;
    }
    case '7ヶ月': {
      startDate = calcStartDateFromDateType('7ヶ月', currentDate);
      break;
    }
    case '13ヶ月': {
      startDate = calcStartDateFromDateType('13ヶ月', currentDate);
      break;
    }
  }
  return {
    startDate,
    endDate,
  };
};

/**
 * 期間推移と期間単位から対応する開始日と終了日を文字列で返す
 * 検索パラメータに渡す日付データを算出する
 *
 * @param {TransitiveDateType} dateType 推移の種類
 * @param {TransitiveDateRange} dateRange 期間指定
 * @param {Date} currentDate 基準日（未指定の場合は本日）
 * @return {{startDate: Date, endDate: Date}} 計算で得られた日付
 */
export const calcStartEndDate = (
  dateType: TransitiveDateType,
  dateRange: TransitiveDateRange,
  currentDate: Date = new Date()
): { startDate: Date; endDate: Date } => {
  let range = {
    startDate: currentDate,
    endDate: currentDate,
  };
  if (dateType === 'daily') {
    range = makeDateForDailyFromDateRange(
      dateRange as DailyTransitiveDateRange,
      currentDate
    );
  }
  if (dateType === 'weekly') {
    range = makeDateForWeeklyFromDateRange(
      dateRange as WeeklyTransitiveDateRange,
      currentDate
    );
  }
  if (dateType === 'monthly') {
    range = makeDateForMonthlyFromDateRange(
      dateRange as MonthlyTransitiveDateRange,
      currentDate
    );
  }
  return range;
};
